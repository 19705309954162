import styled from 'styled-components';

import {
  breakpoints,
  colors,
  convertPxToRem,
  fonts,
  paragraphs,
  radius
} from '@proprioo/salatim';

export const Background = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: ${colors.green.base};
`;

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${breakpoints.xsmall}px) and (orientation: landscape) {
    flex-direction: row;
  }
`;

export const LoginLayout = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 50%;

  @media (min-width: ${breakpoints.xsmall}px) and (orientation: landscape) {
    width: 50%;
    height: 100%;
  }
`;

export const LoginCover = styled.div`
  display: flex;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('/static/pictures/login-cover-casavo.png');
  width: 100%;
  height: 50%;

  @media (min-width: ${breakpoints.xsmall}px) and (orientation: portrait) {
    background-position: center bottom;
  }

  @media (min-width: ${breakpoints.xsmall}px) and (orientation: landscape) {
    background-position: right center;
    width: 50%;
    height: 100%;
  }
`;

export const LogoContainer = styled.div`
  color: white;
  text-align: center;
  margin: ${convertPxToRem(16)} 0;

  > svg {
    width: ${convertPxToRem(300)};
    height: ${convertPxToRem(42)};

    @media (min-width: ${breakpoints.small}px) {
      width: ${convertPxToRem(434)};
      height: ${convertPxToRem(60)};
    }
  }
`;

export const TitleContainer = styled.div`
  ${paragraphs.body1};
  color: white;
  text-align: center;
  margin: ${convertPxToRem(8)};
`;

export const LoginButton = styled.button`
  ${paragraphs.body1};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${convertPxToRem(24)} ${convertPxToRem(32)} 0;
  padding: ${convertPxToRem(12)} 0;
  border: none;
  border-radius: ${radius.normal};
  background-color: white;
  cursor: pointer;

  :hover {
    background: ${colors.grey.base10};
  }

  > svg {
    margin-right: ${convertPxToRem(8)};
  }

  @media (min-width: ${breakpoints.small}px) {
    margin: ${convertPxToRem(32)} auto 0;
    padding: ${convertPxToRem(12)} ${convertPxToRem(80)};
  }
`;

export const Copyright = styled.div`
  color: white;
  font-size: ${fonts.smaller};
  margin-top: ${convertPxToRem(40)};
  padding: ${convertPxToRem(8)};
  text-align: center;

  @media (min-width: ${breakpoints.small}px) {
    padding: ${convertPxToRem(24)};
  }
`;

import { useTranslation } from 'next-i18next';
// import i18n from 'i18n';
import { useRouter } from 'next/router';
import { FC, Fragment, PropsWithChildren, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import useSWR from 'swr';
import Cookies from 'universal-cookie';

import LoginPanel from '@/components/app/loginPanel/LoginPanel';
import { COOKIE_KEY_LNG } from '@/constants/global';
import { useActiveAgents } from '@/hooks/agents/useActiveAgents';
import { useCurrentAgent } from '@/hooks/agents/useCurrentAgent';
import {
  addUserProperties,
  EVENT_NAME,
  identifyUser,
  trackEvent
} from '@/utils/mixPanel';
import { getFullName } from '@proprioo/hokkaido';
import { Loader } from '@proprioo/salatim';
import * as Sentry from '@sentry/nextjs';

import { StatusEndpointProps } from '../interfaces';
import { authSelector } from './AuthState';

export const RecoilAuth: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  const { asPath } = useRouter();

  const activeAgents = useActiveAgents();
  const currentAgent = useCurrentAgent();
  const [auth, updateAuth] = useRecoilState(authSelector);

  const {
    data: authStatus,
    error,
    isLoading
  } = useSWR<StatusEndpointProps>('/api/auth/login', {
    revalidateOnFocus: auth.isConnected
  });

  useEffect(() => {
    if (authStatus && !error && !isLoading) {
      if (authStatus?.user?.email) {
        // Identify user for Sentry
        Sentry.setUser(authStatus.user);

        // Identify user for MixPanel
        identifyUser(authStatus.user.original?.email || authStatus.user.email);
        trackEvent(EVENT_NAME.SIGNED_IN, {
          connectedAsEmail: authStatus.user.email,
          connectedAsRole: authStatus.user.roles,
          landingPagePath: asPath,
          originalRole: authStatus.user.original?.roles || authStatus.user.roles
        });

        // Store default lng in cookies to reuse it for next logins
        const cookies = new Cookies();
        const cookieLng = cookies.get(COOKIE_KEY_LNG);

        cookies.set(COOKIE_KEY_LNG, cookieLng || authStatus.user.lng, {
          maxAge: 3 * 31 * 24 * 60 * 60,
          path: '/'
        });
      } else {
        Sentry.setUser(null);
      }

      updateAuth({
        isConnected: !authStatus.isAnonymous,
        ...(authStatus.user &&
          authStatus.userPermissions && {
            user: {
              ...authStatus.user,
              permissions: authStatus.userPermissions
            }
          })
      });
    }
  }, [authStatus, error, isLoading]);

  useEffect(() => {
    // Ideally, we need to add these properties when we create an agent (but we need to add properties to existing agents)
    if (currentAgent?.id && auth.user?.email && !auth.user.original?.email) {
      const {
        activationDate,
        country,
        email,
        firstname,
        lastname,
        id,
        squads
      } = currentAgent;

      addUserProperties({
        Country: country,
        Email: email,
        Id: id,
        Name: getFullName(firstname, lastname),
        OnboardingDate: activationDate,
        Roles: auth.user.roles,
        Squads: squads.map(({ name }) => name)
      });
    }

    // Handle non-agent user
    if (!currentAgent?.id && auth.user?.email) {
      addUserProperties({ Email: auth.user.email, Roles: auth.user.roles });
    }
  }, [currentAgent, auth?.user]);

  return (
    <Fragment>
      {isLoading ? (
        <Loader text={t('loading')} />
      ) : !auth.isConnected ? (
        <LoginPanel />
      ) : (
        Boolean(activeAgents.length) && children
      )}
    </Fragment>
  );
};

export default RecoilAuth;

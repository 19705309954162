import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { SortToggleButton } from './SortToggle.styles';
import { getSortIcon, SortedState } from './utils';

export type SortToggleProps = {
  sortedState: SortedState;
  onClick?(event: unknown): void;
};

const SortToggle: FC<SortToggleProps> = ({ onClick, sortedState }) => {
  const { t } = useTranslation();

  return (
    <SortToggleButton
      isActive={!!sortedState}
      onClick={onClick}
      aria-label={t('sort')}
      disabled={!onClick}
    >
      {getSortIcon(sortedState)}
    </SortToggleButton>
  );
};

export default SortToggle;

import Link from 'next/link';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import {
  breakpoints,
  colors,
  convertPxToRem,
  paragraphs,
  radius
} from '@proprioo/salatim';

export const SkeletonWrapper = styled.div`
  display: inline-flex;

  & + & {
    margin-left: ${convertPxToRem(16)};
  }
`;

export const IconWrapper = styled.span<{ color: string; isActive: boolean }>`
  position: relative;
  display: inline-flex;
  border-radius: ${radius.big};
  padding: ${convertPxToRem(8)};

  ${({ color, isActive }) =>
    isActive
      ? css`
          background-color: ${color};
          path {
            fill: ${transparentize(0.3, colors.dark.base)};
          }
        `
      : css`
          background-color: ${colors.grey.base40};
          path {
            fill: ${colors.dark.base80};
          }
        `}
`;

export const TitleWrapper = styled.span<{ isActive: boolean }>`
  ${paragraphs.body1}
  display: none;
  color: ${({ isActive }) =>
    isActive ? colors.dark.base : colors.dark.base60};
  transition: color 0.2s ease-out;

  @media (min-width: ${breakpoints.xsmall}px) {
    display: inline;
  }
`;

export const Layout = styled(Link)<{ hasTabs: boolean; isActive: boolean }>`
  display: inline-flex;
  padding: ${convertPxToRem(16)} ${convertPxToRem(24)};
  align-items: center;
  border-radius: ${radius.big};
  transition: all 0.2s ease-out;
  outline: 0;

  ${IconWrapper} + ${TitleWrapper} {
    margin-left: ${convertPxToRem(8)};
  }

  ${({ hasTabs, isActive }) =>
    isActive
      ? css`
          background-color: white;
          ${hasTabs &&
          `
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;`}
        `
      : `
      :hover {
        background-color: ${colors.grey.base20};
      }
    `}
`;

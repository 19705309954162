import { useTranslation } from 'next-i18next';
import { FC, Fragment } from 'react';
import { useRecoilValue } from 'recoil';

import AlertCard from '@/components/app/alertCard/AlertCard';
import { AlertCardStatus } from '@/components/app/alertCard/AlertCard.utils';
import TablePagination from '@/components/app/tablePagination/TablePagination';
import TableHeader from '@/components/myCustomers/common/desktop/tableHeader/TableHeader';
import TableRow from '@/components/myCustomers/common/desktop/tableRow/TableRow';
import { DesktopTableLoader } from '@/components/myCustomers/common/loaders/Loaders';
import {
  PaginationWrapper,
  Table,
  TableWrapper
} from '@/components/myCustomers/common/styles/Table.styles';
import { leadViewSelector } from '@/components/state/LeadViewState';
import { useExternalLeadViewTable } from '@/hooks/useExternalLeadViewTable/useExternalLeadViewTable';
import { PAGE_VIEW_TITLE } from '@/utils/gtm';
import { generateProjectId } from '@/utils/tracking';

import { ExternalLeadViewTableData, LeadViewTab } from '../../../interfaces';
import { getSection, getTabUrl } from '../../../LeadView.utils';
import {
  getActiveFilters,
  getSectionFilters
} from '../../filters/LeadFilters.utils';
import { ExternalLeadsProps } from '../interfaces';
import { AlertCardWrapper } from './ExternalLeadsDesktop.styles';

const ExternalLeadsDesktop: FC<ExternalLeadsProps> = ({
  agentIds,
  currentPage,
  currentTab,
  isLoading,
  isTeamLead = false,
  opportunities
}) => {
  const { t } = useTranslation();
  const { leadViewFilters } = useRecoilValue(leadViewSelector);
  const { getHeaderGroups, getRowModel, getPageCount, getState } =
    useExternalLeadViewTable({
      currentTab,
      isTeamLead,
      opportunities,
      pageIndex: currentPage - 1
    });

  if (isLoading) {
    return <DesktopTableLoader />;
  }

  const { pageIndex } = getState().pagination;

  const headerGroups = getHeaderGroups();
  const rows = getRowModel().rows;

  const section = getSection(currentTab);
  const sectionFilters = getActiveFilters(
    getSectionFilters(section, leadViewFilters)
  );

  return (
    <Fragment>
      <div>
        <Fragment>
          {currentTab === LeadViewTab.LEADS_ALL_EXTERNAL && (
            <AlertCardWrapper>
              <AlertCard
                status={AlertCardStatus.WARNING}
                text={t('externalLeadsAutomaticAttributionTabInformation')}
              />
            </AlertCardWrapper>
          )}
          {currentTab === LeadViewTab.LEADS_ALL_EXTERNAL_EXPIRED && (
            <AlertCardWrapper>
              <AlertCard
                status={AlertCardStatus.WARNING}
                text={t('externalLeadsExpiredTabInformation')}
              />
            </AlertCardWrapper>
          )}
          <TableWrapper>
            <Table data-test="external-leads-desktop-table">
              <TableHeader<ExternalLeadViewTableData>
                headerGroups={headerGroups}
              />
              <tbody>
                {rows.map((row, index) => (
                  <TableRow<ExternalLeadViewTableData>
                    key={`${row.id}-${index}`}
                    link={`/external-lead/${row.original.opportunity.pigeExternalId}`}
                    linkPageView={generateProjectId(
                      PAGE_VIEW_TITLE.LEADS_EXTERNAL,
                      `${row.original.opportunity.pigeExternalId}`
                    )}
                    row={row}
                  />
                ))}
              </tbody>
            </Table>
          </TableWrapper>
        </Fragment>
      </div>
      <PaginationWrapper>
        <TablePagination
          idTitle={PAGE_VIEW_TITLE.LEADS}
          pageCount={getPageCount()}
          pageIndex={pageIndex}
          pageLink={page =>
            getTabUrl(currentTab, page, agentIds, sectionFilters)
          }
        />
      </PaginationWrapper>
    </Fragment>
  );
};

export default ExternalLeadsDesktop;

import styled from 'styled-components';

import {
  colors,
  convertPxToRem,
  fonts,
  paragraphs,
  radius
} from '@proprioo/salatim';

export const Layout = styled.span<{ isActive: boolean }>`
  ${paragraphs.body2};
  font-size: ${fonts.smallest};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 0 ${convertPxToRem(8)};
  border-radius: ${radius.big};
  background-color: ${({ isActive }) =>
    isActive ? colors.dark.base : colors.dark.base60};
  transition: background-color 0.2s ease-out;
`;

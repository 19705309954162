import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { nullOrString } from '@proprioo/hokkaido';

import { getRemainingTimeToAttributeLead } from '../../../LeadView.utils';
import { RemainingTime } from './RemainingTime.styles';

type RemainingTimeCellProps = {
  assignedEndDate: nullOrString;
};

const RemainingTimeCell: FC<RemainingTimeCellProps> = ({ assignedEndDate }) => {
  const { t } = useTranslation();

  const hoursRemaining = getRemainingTimeToAttributeLead(assignedEndDate);

  return (
    <RemainingTime>
      {t('valueWithUnitNoSpacing', { unit: t('hour'), value: hoursRemaining })}
    </RemainingTime>
  );
};

export default RemainingTimeCell;

import { FC } from 'react';

import CustomerCell from '@/components/myCustomers/common/desktop/cells/CustomerCell/CustomerCell';

import { LeadViewTableCellProps } from '../interface';

const LeadCustomerCell: FC<LeadViewTableCellProps> = ({
  opportunity: { firstName, lastName, nextEvent }
}) => (
  <CustomerCell
    firstName={firstName}
    lastName={lastName}
    targetDate={nextEvent?.targetDate}
  />
);

export default LeadCustomerCell;

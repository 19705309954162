import { fetcher } from '@/utils/http';
import { HttpMethod, nullOrNumber } from '@proprioo/hokkaido';

import { AgentChatId, CleanedAgent } from './Agent.interfaces';

export const updateAgent = (agent: CleanedAgent): Promise<CleanedAgent> =>
  fetcher(`/api/sales/agent/${agent.id}`, {
    body: JSON.stringify(agent),
    method: HttpMethod.POST
  });

export const updateAgentChatId = (agent: AgentChatId): Promise<CleanedAgent> =>
  fetcher(`/api/sales/agent/${agent.id}/chat`, {
    body: JSON.stringify(agent),
    method: HttpMethod.POST
  });

export const deleteAgent = (
  agentId: number,
  replacementAgentId: number,
  replacementAgentBuyerId: nullOrNumber
): Promise<unknown> =>
  fetcher(`/api/sales/agent/${agentId}`, {
    body: JSON.stringify({
      agentId,
      replacementAgentBuyerId,
      replacementAgentId
    }),
    method: HttpMethod.DELETE
  });

export const createAgent = (agent: CleanedAgent): Promise<CleanedAgent> =>
  fetcher('/api/sales/agent', {
    body: JSON.stringify(agent),
    method: HttpMethod.POST
  });

import { FC, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { leadViewSelector } from '@/components/state/LeadViewState';
import { useSmartphoneDevice } from '@proprioo/salatim';

import { LeadViewTab, SaleOpportunity } from '../interfaces';
import DesktopLeads from './desktopLeads/DesktopLeads';
import { filterLeadOpportunities } from './filters/LeadFilters.utils';
import MobileLeads from './mobileLeads/MobileLeads';

export type LeadsProps = {
  currentPage: number;
  currentTab: LeadViewTab;
  isLoading: boolean;
  opportunities: SaleOpportunity[];
  agentIds?: number[];
  isTeamLead?: boolean;
};

const Leads: FC<LeadsProps> = ({
  agentIds,
  currentPage,
  currentTab,
  isLoading,
  isTeamLead = false,
  opportunities
}) => {
  const isSmartphone = useSmartphoneDevice();
  const { leadViewFilters } = useRecoilValue(leadViewSelector);

  const filteredOpportunities = useMemo(
    () => filterLeadOpportunities(opportunities, leadViewFilters),
    [opportunities, leadViewFilters]
  );

  const commonProps = { isLoading, opportunities: filteredOpportunities };

  return (
    <div role="tabpanel" id="opportunities">
      {isSmartphone ? (
        <MobileLeads {...commonProps} />
      ) : (
        <DesktopLeads
          {...commonProps}
          agentIds={agentIds}
          currentPage={currentPage}
          currentTab={currentTab}
          isTeamLead={isTeamLead}
        />
      )}
    </div>
  );
};

export default Leads;

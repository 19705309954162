import styled, { css } from 'styled-components';

import { colors, convertPxToRem, paragraphs } from '@proprioo/salatim';

export const Layout = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  margin-top: auto;
  width: 100%;
  max-height: 50%;

  ${({ isOpen }) =>
    isOpen &&
    css`
      ${Links} {
        height: 100%;
      }

      ${AgentToggle} {
        transform: rotate(180deg);
      }
    `}
`;

export const AdminLinksWrapper = styled.span`
  background-color: ${colors.green.base};
`;

export const AgentName = styled.div`
  ${paragraphs.bodyLight2};
  margin-left: ${convertPxToRem(8)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Agent = styled.div<{ isOpen: boolean }>`
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  width: 100%;
  padding: ${convertPxToRem(8)};
  color: white;
  cursor: pointer;

  ${AgentName} {
    opacity: 0;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      flex-direction: row;
      padding: ${convertPxToRem(8)} ${convertPxToRem(16)};

      ${AgentName} {
        opacity: 1;
      }
    `}
`;

export const Links = styled.div`
  height: 0;
  overflow-y: auto;
`;

export const AgentToggle = styled.div`
  margin-left: auto;
  transition: transform 0.2s ease-out;

  svg {
    height: ${convertPxToRem(32)};
    width: ${convertPxToRem(32)};

    path {
      fill: white;
    }
  }
`;

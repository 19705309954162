import styled from 'styled-components';

import { colors, convertPxToRem, resetCssButton } from '@proprioo/salatim';

export const SortToggleButton = styled.button<{ isActive?: boolean }>`
  ${resetCssButton};
  display: inline-flex;
  color: ${({ isActive }) =>
    isActive ? colors.terracota.base : colors.grey.base};

  > svg {
    width: ${convertPxToRem(24)};
    height: ${convertPxToRem(24)};
  }
`;

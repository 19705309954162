import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { FC, Fragment, ReactElement } from 'react';

import ChevronLeft from '@/assets/icons/chevron-left.svg';
import SearchIcon from '@/assets/icons/search.svg';
import { useHistory } from '@/hooks/useHistory/useHistory';
import { FEATURE_NAME } from '@/hooks/useUserFeaturePermissions/interfaces';
import { useUserFeaturePermissions } from '@/hooks/useUserFeaturePermissions/useUserFeaturePermissions';
import { EventPageView, PAGE_VIEW_TITLE, sendPageView } from '@/utils/gtm';
import { generateButtonId } from '@/utils/tracking';
import {
  Button,
  ButtonAppearance,
  ButtonSizes,
  ButtonTheme,
  H3,
  IconPosition,
  useMobileDevice,
  useModal
} from '@proprioo/salatim';

import EnvTag from '../envTag/EnvTag';
import Search from './search/Search';
import { NavigationContainer, NavigationTitleTag } from './TopBar.styles';

type TopBarProps = {
  title?: string;
  topBarItems?: ReactElement | null;
};

export const TopBar: FC<TopBarProps> = ({ title, topBarItems = null }) => {
  const { t } = useTranslation();
  const { isAvailable, goBack } = useHistory();
  const { asPath } = useRouter();

  const isMobile = useMobileDevice();
  const hasAccessToSearchBar = useUserFeaturePermissions(
    FEATURE_NAME.TOPBAR_SEARCH
  );

  const { createModal, closeModal, openModal } = useModal({
    onOpen: () =>
      sendPageView(
        { path: asPath, title: PAGE_VIEW_TITLE.SEARCH_MOBILE },
        EventPageView.MODAL_OPENING
      )
  });

  return (
    <NavigationContainer id="topbar" data-test="topbar">
      <NavigationTitleTag>
        {title && <H3 title={t(title)} isAlternative={true} />}
        {isAvailable && (
          <Button
            id={generateButtonId(PAGE_VIEW_TITLE.NAVBAR, 'back')}
            label={t('back')}
            icon={<ChevronLeft />}
            onClick={goBack}
            size={ButtonSizes.SMALL}
            iconPosition={IconPosition.LEFT}
            appearance={ButtonAppearance.NO_APPEARANCE}
          />
        )}
        {!isMobile && <EnvTag />}
      </NavigationTitleTag>
      {!isMobile && hasAccessToSearchBar && <Search />}
      <NavigationTitleTag>
        {topBarItems && <Fragment>{topBarItems}</Fragment>}
        {isMobile && hasAccessToSearchBar && (
          <Fragment>
            {createModal({
              children: <Search onCallback={closeModal} />,
              title: t('search')
            })}
            <Button
              data-test="search-mobile"
              size={ButtonSizes.LARGE}
              theme={ButtonTheme.BLUE}
              rounded={true}
              label={t('search')}
              icon={<SearchIcon />}
              onClick={openModal}
            />
          </Fragment>
        )}
      </NavigationTitleTag>
    </NavigationContainer>
  );
};

export default TopBar;

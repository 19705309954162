import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { getEstimationStageTagProps } from '@/components/listing/utils';
import { SaleOpportunity } from '@/components/myCustomers/mySellers/interfaces';
import { TAG_CLOSE_ARIA_LABEL } from '@/constants/global';
import { Tag, TagAppearance } from '@proprioo/salatim';

export type StageTagProps = Pick<
  SaleOpportunity,
  'lastAppointmentStatus' | 'estimationStage'
>;

const StageTag: FC<StageTagProps> = ({
  estimationStage,
  lastAppointmentStatus
}) => {
  const { t } = useTranslation();

  const { color, label } = getEstimationStageTagProps(
    estimationStage,
    lastAppointmentStatus
  );

  return (
    <Tag
      appearance={TagAppearance.BUSINESS}
      closeAriaLabel={t(TAG_CLOSE_ARIA_LABEL)}
      color={color}
      labels={[t(label)]}
    />
  );
};

export default StageTag;

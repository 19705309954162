import { FC, Fragment } from 'react';

import Bullet from '@/components/myCustomers/common/bullet/Bullet';

import { BulletColor } from '../bullet/Bullet.interfaces';
import { Layout } from './ActivityBullets.styles';

export type ActivityBulletsProps = {
  lateCount: number;
  todayCount: number;
};

const ActivityBullets: FC<ActivityBulletsProps> = ({
  lateCount,
  todayCount
}) => {
  return lateCount + todayCount > 0 ? (
    <Layout>
      {todayCount > 0 && (
        <Bullet color={BulletColor.BLUE} label="someTasksAreLateForToday" />
      )}
      {lateCount > 0 && (
        <Bullet color={BulletColor.RED} label="someTasksAreLate" />
      )}
    </Layout>
  ) : (
    <Fragment />
  );
};

export default ActivityBullets;

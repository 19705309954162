import { FC, useMemo } from 'react';

import { useMySellersCurrentSectionTabs } from '@/hooks/useMySellersCurrentSectionTabs/useMySellersCurrentSectionTabs';

import {
  ExternalOpportunitiesPerTab,
  LeadViewTab,
  SaleOpportunitiesPerTab
} from '../interfaces';
import { getSection, getSectionParams } from '../LeadView.utils';
import Tab from '../tab/Tab';
import { TabsInner, TabsLayout } from './NavBarSubSection.styles';

export type NavBarSubSectionProps = {
  currentTab: LeadViewTab;
  externalOpportunities: ExternalOpportunitiesPerTab;
  opportunities: SaleOpportunitiesPerTab;
  agentIds?: number[];
};

const NavBarSubSection: FC<NavBarSubSectionProps> = ({
  agentIds,
  currentTab,
  externalOpportunities,
  opportunities
}) => {
  const currentSection = useMemo(() => getSection(currentTab), [currentTab]);
  const currentSectionTabs = useMySellersCurrentSectionTabs(currentSection);

  const { color } = useMemo(
    () => getSectionParams(currentSection),
    [currentSection]
  );

  return (
    <div role="tabpanel" id="tabs">
      <TabsLayout role="tablist">
        <TabsInner color={color}>
          {currentSectionTabs.map(tab => (
            <Tab
              agentIds={agentIds}
              currentTab={currentTab}
              externalOpportunities={externalOpportunities[tab]}
              key={tab}
              kind={tab}
              opportunities={opportunities[tab]}
            />
          ))}
        </TabsInner>
      </TabsLayout>
    </div>
  );
};

export default NavBarSubSection;

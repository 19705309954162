import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import qs from 'qs';
import { useRecoilState } from 'recoil';

import SalesPlansIcon from '@/assets/vectors/menu/sellers.svg';
import { menuSelector } from '@/components/state/MenuState';
import { useCurrentAgent } from '@/hooks/agents/useCurrentAgent';
import { useIsTeamLeadRole } from '@/hooks/auth/useIsTeamLeadRole';
import { useAgentIdsFromTeamLead } from '@/hooks/squads/useAgentIdsFromTeamLead';
import { useActiveLink } from '@/hooks/useActiveLink/useActiveLink';
import { QS_ARRAY_PARAMS } from '@/utils/helpers';

import { ItemLabel, ToolItem } from '../MenuItems.styles';

const MySellersItem = () => {
  const { t } = useTranslation();

  const [isOpen, updateMenu] = useRecoilState(menuSelector);
  const currentAgent = useCurrentAgent();
  const isTeamLead = useIsTeamLeadRole();
  const agentIds = useAgentIdsFromTeamLead();
  const isActive = useActiveLink('/my-sellers');

  const leadViewParams = qs.stringify(
    { agentOwners: isTeamLead ? agentIds : currentAgent?.id },
    QS_ARRAY_PARAMS
  );

  return (
    <Link
      href={`/my-sellers${leadViewParams}`}
      id="sidebar-menu_sales-plans"
      onMouseEnter={() => {
        if (!isOpen) {
          updateMenu(true);
        }
      }}
    >
      <ToolItem isActive={isActive} isOpen={isOpen}>
        <SalesPlansIcon />
        <ItemLabel>{t('mySellers')}</ItemLabel>
      </ToolItem>
    </Link>
  );
};

export default MySellersItem;

import { format } from 'date-fns-tz';
import { toDate } from 'date-fns-tz';
import { toZonedTime } from 'date-fns-tz';
import { i18n } from 'next-i18next';

import { EMPTY_PROJECT } from '@/components/state/utils';
import { DEFAULT_TIMEZONE } from '@/constants/global';
import { numberOrString } from '@/utils/interfaces';
import { EstimationStage } from '@proprioo/hokkaido';
import { Locale } from 'date-fns';

export const formatLocaleDay = (
  date: Date | numberOrString,
  key: string,
  locale: Locale
) =>
  format(
    toDate(
      toZonedTime(
        new Date(typeof date === 'string' ? date.replace('[UTC]', '') : date),
        DEFAULT_TIMEZONE
      )
    ),
    i18n?.t(key, { ns: 'date' }) || '',
    {
      locale,
      timeZone: DEFAULT_TIMEZONE
    }
  );

export const resetLocation = () => {
  const { address, city, postalCode, location, street, streetNumber } =
    EMPTY_PROJECT;

  return {
    address,
    city,
    location,
    postalCode,
    street,
    streetNumber
  };
};

const SELLING_STAGES = [
  EstimationStage.LEAD,
  EstimationStage.BACKLOGS,
  EstimationStage.PROSPECT,
  EstimationStage.APPOINTMENT_TO_DO,
  EstimationStage.VALUATION_TO_SEND
];

export const opportunityIsComplete = (stage: EstimationStage) =>
  SELLING_STAGES.includes(stage);

export const estimationStageOutOfRange = (stage: EstimationStage) =>
  !SELLING_STAGES.includes(stage);

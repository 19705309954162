import { DEFAULT_OTHER_CRITERIAS } from '@/utils/criterias';
import { IS_NOT_SELLER, IS_SELLER } from '@/utils/customer';
import {
  BuyerSource,
  Location,
  nullOrNumber,
  OptionProps,
  POSTAL_CODES,
  PropertyEnum
} from '@proprioo/hokkaido';
import { GoogleSearchType } from '@proprioo/salatim';

import { BuyerQualificationFormState } from '../buyer/interfaces';
import { OnlineAlert } from '../qualification/sellerQualification/interfaces';
import { EMPTY_USER } from '../state/utils';
import {
  AlertFormState,
  AlertObjectPayload,
  AlertUpdateFormState,
  CreatedFrom,
  CriteriaRangeTypes,
  CriteriaState,
  LocationSearchType,
  LocationState,
  MinMax,
  ProjectPayload,
  ProjectState,
  RangeType,
  SelectedProperties
} from './interfaces';

export const DISTRICT_POSTCODE_REGEX = /(^[a-z]+)\s[0-9]+/i;
export const defaultPropertyTypes: SelectedProperties = {
  flat: false,
  house: false
};

export const getSelectOptions = {
  creditStageOptions: [
    { label: 'noNeed', value: 'noNeed' },
    { label: 'noButNeed', value: 'noButINeedOne' },
    { label: 'underReviewCredit', value: 'inProgress' },
    { label: 'validatedCredit', value: 'accepted' }
  ],
  creditStageVisitOptions: [
    { label: 'yes', value: 'true' },
    { label: 'no', value: 'false' }
  ],
  isSellerOptions: [
    { label: 'yes', value: IS_SELLER },
    { label: 'no', value: IS_NOT_SELLER }
  ],
  purchaseStageOptions: [
    { label: 'startToFindOut', value: 'kickOff' },
    { label: 'alreadyMadeVisits', value: 'visiting' },
    { label: 'alreadyMadeAnOffer', value: 'alreadyMadeOffer' }
  ]
};

export const getBuyerSourceLabel = (buyerSource: BuyerSource) => {
  switch (buyerSource) {
    case BuyerSource.BILLBOARD:
      return 'billboard';
    case BuyerSource.BUYER_REQUEST:
      return 'buyerRequest';
    case BuyerSource.BUYER_VISIT:
      return 'buyerVisit';
    case BuyerSource.CASAVO_INTERESTED_BUYERS:
      return 'casavo';
    case BuyerSource.FIND_NEST:
      return 'chercheMonNid';
    case BuyerSource.INCOMING_CALL:
      return 'incomingCall';
    case BuyerSource.MARKETING_EVENT:
      return 'marketingEvent';
    case BuyerSource.NETWORK:
      return 'personalNetwork';
    case BuyerSource.OCP_CHALLENGE:
      return 'commercialOperation';
    case BuyerSource.OTHER:
      return 'other';
    case BuyerSource.PROSPECTING:
      return 'prospecting';
    case BuyerSource.SELLER:
      return 'seller';
    case BuyerSource.THIRD_PARTY:
      return 'thirdPartyAdvertisers';
    case BuyerSource.WEBSITE:
      return 'website';
  }
};

export const sourceOptions: OptionProps[] = Object.values(BuyerSource).map(
  source => ({ label: getBuyerSourceLabel(source), value: source })
);

export const formatSellerPayload = (isSeller: OptionProps | null) => {
  const isSellerValue = isSeller?.value;
  switch (isSellerValue) {
    case IS_SELLER:
      return true;
    case IS_NOT_SELLER:
      return false;
    default:
      return;
  }
};

export const normalizeSellerPayload = (seller?: boolean) => {
  switch (seller) {
    case true:
      return IS_SELLER;
    case false:
      return IS_NOT_SELLER;
    default:
      return;
  }
};

export const getPropertyTypes = (propertyTypes: SelectedProperties) =>
  Object.keys(propertyTypes).filter(
    (property: string) => propertyTypes[property as 'flat' | 'house']
  );

export const formalizeDistrict = (value: string): string => {
  const matched: RegExpMatchArray | null = value.match(DISTRICT_POSTCODE_REGEX);
  return matched ? matched[0] : value;
};

export const orderByAsc = (values: string[]): string[] =>
  values.sort((firstValue: string, secondValue: string) => {
    if (firstValue > secondValue) {
      return 1;
    }
    if (firstValue < secondValue) {
      return -1;
    }
    return 0;
  });

export const joinValues = (values: string[], separator = ', '): string =>
  orderByAsc(values).join(`${separator}`);

export const generateEmptyRange = (): MinMax => ({});

export const formIsEmpty = <T>(formState: T, defaultFormState: T) =>
  JSON.stringify(formState) === JSON.stringify(defaultFormState);

export const formatAreas = (locationState: LocationState[] = []): string[] =>
  locationState.map(({ name }) => getLocationNameWithoutDistrict(name));

export const convertPropertyTypes = (typeBien: string[] = []) => {
  if (!typeBien.length) return { flat: true, house: true };

  return {
    flat: typeBien.includes(PropertyEnum.FLAT),
    house: typeBien.includes(PropertyEnum.HOUSE)
  };
};

export const normalizedProjectPayload = (
  project: ProjectPayload
): ProjectState => {
  const {
    enabled,
    createdFrom,
    creator,
    internalComment,
    source,
    agentOwner,
    isSeller
  } = project;

  const { isSellerOptions, creditStageOptions, purchaseStageOptions } =
    getSelectOptions;

  const [sellerStage] = getOption(
    isSellerOptions,
    normalizeSellerPayload(isSeller)
  );
  const [creditStage] = getOption(creditStageOptions, project.loanStage);
  const [purchaseStage] = getOption(
    purchaseStageOptions,
    project.purchaseStage
  );
  const [leadSourceToOption] = getOption(sourceOptions, source);

  return {
    agentOwner: agentOwner?.toString(10) || '0',
    createdFrom,
    creator,
    creditStage: creditStage ? creditStage : null,
    enabled,
    internalComment,
    isSeller: sellerStage ? sellerStage : null,
    purchaseStage: purchaseStage ? purchaseStage : null,
    source: leadSourceToOption ? leadSourceToOption : null
  };
};

export const normalizedCriteriaPayload = (
  alert: AlertObjectPayload
): CriteriaState => {
  const { zones, enabled, isMap, propertyTypes, ...rest } = alert;

  const locationState = zones.map(({ name, ...rest }) => ({
    ...rest,
    name: getLocationNameWithoutDistrict(name)
  }));
  const types = convertPropertyTypes(propertyTypes);

  const nbBedrooms: MinMax = {
    max: rest.maxBedrooms,
    min: rest.minBedrooms
  };
  const rooms: MinMax = {
    max: rest.maxRooms,
    min: rest.minRooms
  };
  const budget: MinMax = {
    max: rest.maxPrice,
    min: rest.minPrice
  };
  const surface: MinMax = {
    max: rest.maxSurface,
    min: rest.minSurface
  };
  const land: MinMax = {
    max: rest.maxLandingArea,
    min: rest.minLandingArea
  };

  const other = {
    groundFloor: rest.groundFloor || false,
    hasCellarOrAnnex: rest.hasCellarOrAnnex || false,
    hasLift: rest.hasLift || false,
    hasOutdoorSpace: rest.hasOutdoorSpace || false,
    hasParking: rest.hasParking || false,
    hasPool: rest.hasPool || false,
    withoutRenovationWork: rest.withoutRenovationWork || false
  };

  return {
    budget,
    enabled,
    isMap,
    land,
    locationState,
    nbBedrooms,
    other,
    propertyTypes: types,
    rooms,
    surface
  };
};

export const getOption = (options: OptionProps[], value?: string) =>
  options.filter(option => option.value === value);

export const getCriteriaOnRangeChange = (
  criteria: CriteriaState,
  criteriaRangeKey: CriteriaRangeTypes,
  newValue: nullOrNumber,
  rangeType: RangeType
): MinMax => {
  const range = criteria[criteriaRangeKey] || generateEmptyRange();
  range[rangeType] = newValue;
  return range;
};

export const generateDefaultCriteria = (): CriteriaState => ({
  budget: generateEmptyRange(),
  enabled: true,
  isMap: false,
  land: generateEmptyRange(),
  locationState: [],
  nbBedrooms: generateEmptyRange(),
  other: DEFAULT_OTHER_CRITERIAS,
  propertyTypes: defaultPropertyTypes,
  rooms: generateEmptyRange(),
  surface: generateEmptyRange()
});

export const generateEmptyProject = (): ProjectState => ({
  agentOwner: null,
  createdFrom: CreatedFrom.TOOL,
  creditStage: null,
  enabled: true,
  isSeller: null,
  purchaseStage: null,
  source: null
});

export const generateEmptyBuyerQualification =
  (): BuyerQualificationFormState => {
    const { user, ...rest } = generateEmptyAlertOpportunity();
    return {
      ...rest,
      customer: EMPTY_USER
    };
  };

export const generateEmptyAlertOpportunity = (): AlertFormState => ({
  criteria: generateDefaultCriteria(),
  notification: false,
  project: generateEmptyProject(),
  user: EMPTY_USER
});

export const formatPayloadToAlert = (
  payload: OnlineAlert
): AlertUpdateFormState => {
  const { alertID, projectID, createdAt, lastModified, alert, project } =
    payload;

  return {
    ...generateEmptyAlertOpportunity(),
    alertID,
    createdAt,
    criteria: normalizedCriteriaPayload(alert),
    lastModified,
    project: normalizedProjectPayload(project),
    projectID
  };
};

export const formatPropertyTypesToPropertyListing = (
  propertyTypes: string[]
) => {
  const formatedPropertyTypes = [];
  if (propertyTypes.includes(PropertyEnum.FLAT)) {
    formatedPropertyTypes.push(PropertyEnum.FLAT);
  }
  if (propertyTypes.includes(PropertyEnum.HOUSE)) {
    formatedPropertyTypes.push(PropertyEnum.HOUSE);
  }
  return formatedPropertyTypes;
};

export const getLocationType = (
  types: string[],
  postalCode?: string
): LocationSearchType | undefined => {
  if (types.includes(GoogleSearchType.ADMINISTRATIVE_AREA_LEVEL_1)) {
    return LocationSearchType.REGION;
  }

  if (types.includes(GoogleSearchType.ADMINISTRATIVE_AREA_LEVEL_2)) {
    return LocationSearchType.COUNTY;
  }

  if (types.includes(GoogleSearchType.NEIGHBORHOOD)) {
    return LocationSearchType.NEIGHBORHOOD;
  }

  if (types.includes(GoogleSearchType.POSTAL_CODE) && postalCode) {
    if (POSTAL_CODES[postalCode]) {
      return LocationSearchType.SUBMUNICIPALITY;
    } else {
      return LocationSearchType.MUNICIPALITY;
    }
  }

  if (types.includes(GoogleSearchType.SUBLOCALITY)) {
    return LocationSearchType.SUBMUNICIPALITY;
  }

  if (types.includes(GoogleSearchType.LOCALITY)) {
    return LocationSearchType.MUNICIPALITY;
  }

  return;
};

export const getLocationNameWithoutDistrict = (name: string) =>
  name.replace(/e Arrondissement/i, 'ème').replace(/er Arrondissement/i, 'er');

export const getLocationIds = (locationState: LocationState[]) =>
  locationState.map(({ id }) => id);

export const getLocationNames = (locationState: LocationState[]) =>
  locationState.map(({ name }) => name);

export const checkLatLngBounds = ({ lat, lng }: Location): boolean => {
  // France polygon (Corse not included)
  const polygon = new google.maps.Polygon({
    paths: [
      { lat: 51.3576328, lng: 2.3144245 },
      { lat: 48.5517282, lng: -5.8395767 },
      { lat: 43.2872652, lng: -2.2024155 },
      { lat: 41.2518709, lng: 3.5803413 },
      { lat: 43.5844944, lng: 8.2448101 },
      { lat: 46.720917, lng: 7.4511337 },
      { lat: 48.9868074, lng: 8.5756016 },
      { lat: 51.4181045, lng: 2.3071289 }
    ]
  });

  return google.maps.geometry.poly.containsLocation({ lat, lng }, polygon);
};

export const getBuyerProjectUrl = (buyerId: string, alertId: string): string =>
  `/customer/${buyerId}/qualification/buyer/${alertId}`;

export const getCriteriaInputLabel = (
  inputType: RangeType,
  criteriaType: CriteriaRangeTypes
): string => {
  if (inputType === RangeType.MAX) {
    switch (criteriaType) {
      case CriteriaRangeTypes.BUDGET:
        return 'budgetMax';
      case CriteriaRangeTypes.LAND:
        return 'landMax';
      case CriteriaRangeTypes.NB_BEDROOMS:
        return 'bedroomsMax';
      case CriteriaRangeTypes.ROOMS:
        return 'roomsMax';
      case CriteriaRangeTypes.SURFACE:
        return 'surfaceMax';
    }
  } else {
    switch (criteriaType) {
      case CriteriaRangeTypes.BUDGET:
        return 'budgetMin';
      case CriteriaRangeTypes.LAND:
        return 'landMin';
      case CriteriaRangeTypes.NB_BEDROOMS:
        return 'bedroomsMin';
      case CriteriaRangeTypes.ROOMS:
        return 'roomsMin';
      case CriteriaRangeTypes.SURFACE:
        return 'surfaceMin';
    }
  }
};

export const getCriteriaErrorLabel = (
  criteriaType: CriteriaRangeTypes,
  isMaxError: boolean
): string => {
  if (isMaxError) {
    switch (criteriaType) {
      case CriteriaRangeTypes.BUDGET:
        return 'maxBudgetError';
      case CriteriaRangeTypes.LAND:
        return 'maxSurfaceLandError';
      case CriteriaRangeTypes.NB_BEDROOMS:
        return 'maxBedroomsCountError';
      case CriteriaRangeTypes.ROOMS:
        return 'maxRoomsCountError';
      case CriteriaRangeTypes.SURFACE:
        return 'maxSurfaceError';
    }
  } else {
    switch (criteriaType) {
      case CriteriaRangeTypes.BUDGET:
        return 'budgetError';
      case CriteriaRangeTypes.LAND:
        return 'landSurfaceError';
      case CriteriaRangeTypes.NB_BEDROOMS:
        return 'bedroomsCountError';
      case CriteriaRangeTypes.ROOMS:
        return 'roomsCountError';
      case CriteriaRangeTypes.SURFACE:
        return 'surfaceError';
    }
  }
};

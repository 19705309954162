import { Fragment, useMemo } from 'react';

import { Permission } from '@/components/state/interfaces';

import { useAuth } from '../auth/useAuth';
import { useIsTechRole } from '../auth/useIsTechRole';
import { ComponentsMappingProps } from './useUserPermissionsBuilder.interfaces';

const FRAGMENT_KEY = 'fragment-';

export const useUserPermissionsBuilder = (
  componentsMapping: ComponentsMappingProps
) => {
  const { user } = useAuth();

  const isTechRole = useIsTechRole();

  return useMemo(
    () =>
      [...componentsMapping]
        .sort((a, b) => a.position - b.position)
        .map(({ Component, url }) => {
          const pathPermissions = user?.permissions.urls[url] || [];

          if (isTechRole) {
            return <Component key={url} />;
          }

          return pathPermissions.includes(Permission.READ) ? (
            <Component key={url} />
          ) : (
            <Fragment key={`${FRAGMENT_KEY}${url}`} />
          );
        })
        .filter(component => !component.key?.startsWith(FRAGMENT_KEY)),
    [componentsMapping, isTechRole, user?.permissions]
  );
};

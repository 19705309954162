import { ReactElement } from 'react';

import { nullOrString } from '@proprioo/hokkaido';

export enum SaveStatus {
  NONE = 'none',
  PENDING = 'pending',
  SUCCESS = 'success',
  FAIL = 'fail'
}

export type SaveProps = {
  disabled: boolean;
  saveIdButton: nullOrString;
  label?: ReactElement;
  onClick(): void;
};

import { useTranslation } from 'next-i18next';
import { FC, isValidElement, PropsWithChildren, ReactElement } from 'react';

import {
  AlertCardActions,
  AlertCardContent,
  AlertCardLayout,
  AlertCardTitle,
  AlertCardWrapper
} from './AlertCard.styles';
import { AlertCardStatus, getAlertCardLabel } from './AlertCard.utils';
import AlertCardIcon from './AlertCardIcon/AlertCardIcon';

export type AlertCardProps = {
  status: AlertCardStatus;
  actions?: ReactElement;
  dataTest?: string;
  isIconDisplayed?: boolean;
  text?: string;
  title?: string;
};

const AlertCard: FC<PropsWithChildren<AlertCardProps>> = ({
  actions,
  children,
  dataTest,
  isIconDisplayed = true,
  status,
  text,
  title
}) => {
  const { t } = useTranslation();

  return (
    <AlertCardLayout
      aria-label={t(getAlertCardLabel(status))}
      data-test={dataTest || 'alert-card'}
      role="alert"
      status={status}
    >
      <AlertCardContent>
        {isIconDisplayed && <AlertCardIcon status={status} />}
        <AlertCardWrapper>
          {title && <AlertCardTitle>{title}</AlertCardTitle>}
          {text && (
            <span
              dangerouslySetInnerHTML={{
                __html: text
              }}
            />
          )}
          {children}
        </AlertCardWrapper>
      </AlertCardContent>
      {isValidElement(actions) && (
        <AlertCardActions>{actions}</AlertCardActions>
      )}
    </AlertCardLayout>
  );
};

export default AlertCard;

import styled from 'styled-components';

import { convertPxToRem } from '@proprioo/salatim';

export const TagWrapper = styled.div`
  margin-left: ${convertPxToRem(16)};

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

import {
  LocationSearchType,
  LocationState
} from '@/components/buyerQualification/interfaces';

type DictionaryPlace = Record<string, LocationState>;

export const DISTRICT_GEO_CODES: Record<string, string[]> = {
  'FR-13055': [
    'FR-13201',
    'FR-13202',
    'FR-13203',
    'FR-13204',
    'FR-13205',
    'FR-13206',
    'FR-13207',
    'FR-13208',
    'FR-13209',
    'FR-13210',
    'FR-13211',
    'FR-13212',
    'FR-13213',
    'FR-13214',
    'FR-13215',
    'FR-13216'
  ],
  'FR-69123': [
    'FR-69381',
    'FR-69382',
    'FR-69383',
    'FR-69384',
    'FR-69385',
    'FR-69386',
    'FR-69387',
    'FR-69388',
    'FR-69389'
  ],
  'FR-75056': [
    'FR-75101',
    'FR-75102',
    'FR-75103',
    'FR-75104',
    'FR-75105',
    'FR-75106',
    'FR-75107',
    'FR-75108',
    'FR-75109',
    'FR-75110',
    'FR-75111',
    'FR-75112',
    'FR-75113',
    'FR-75114',
    'FR-75115',
    'FR-75116',
    'FR-75117',
    'FR-75118',
    'FR-75119',
    'FR-75120'
  ]
};

export const COUNTY_GEO_CODES: Record<string, string[]> = {
  'FR-ARA': [
    'FR-01',
    'FR-03',
    'FR-07',
    'FR-15',
    'FR-26',
    'FR-38',
    'FR-42',
    'FR-43',
    'FR-63',
    'FR-69',
    'FR-73',
    'FR-74'
  ],
  'FR-BFC': [
    'FR-21',
    'FR-25',
    'FR-39',
    'FR-58',
    'FR-70',
    'FR-71',
    'FR-89',
    'FR-90'
  ],
  'FR-BRE': ['FR-22', 'FR-29', 'FR-35', 'FR-56'],
  'FR-COR': ['FR-2A', 'FR-2B'],
  'FR-CVL': ['FR-18', 'FR-28', 'FR-36', 'FR-37', 'FR-41', 'FR-45'],
  'FR-GES': [
    'FR-08',
    'FR-10',
    'FR-51',
    'FR-52',
    'FR-54',
    'FR-55',
    'FR-57',
    'FR-67',
    'FR-68',
    'FR-88'
  ],
  'FR-HDF': ['FR-02', 'FR-59', 'FR-60', 'FR-62', 'FR-80'],
  'FR-IDF': [
    'FR-75',
    'FR-77',
    'FR-78',
    'FR-91',
    'FR-92',
    'FR-93',
    'FR-94',
    'FR-95'
  ],
  'FR-NAQ': [
    'FR-16',
    'FR-17',
    'FR-19',
    'FR-23',
    'FR-24',
    'FR-33',
    'FR-40',
    'FR-47',
    'FR-64',
    'FR-79',
    'FR-86',
    'FR-87'
  ],
  'FR-NOR': ['FR-14', 'FR-27', 'FR-50', 'FR-61', 'FR-76'],
  'FR-OCC': [
    'FR-09',
    'FR-11',
    'FR-12',
    'FR-30',
    'FR-31',
    'FR-32',
    'FR-34',
    'FR-46',
    'FR-48',
    'FR-65',
    'FR-66',
    'FR-81',
    'FR-82'
  ],
  'FR-PAC': ['FR-04', 'FR-05', 'FR-06', 'FR-13', 'FR-83', 'FR-84'],
  'FR-PDL': ['FR-44', 'FR-49', 'FR-53', 'FR-72', 'FR-85']
};

const MARSEILLE_2: LocationState = {
  geom: [],
  id: 'FR-13202',
  name: 'Marseille 2ème',
  type: LocationSearchType.SUBMUNICIPALITY
};

const CHESNAY_ROCQUENCOURT: LocationState = {
  geom: [],
  id: 'FR-78158',
  name: 'Le Chesnay-Rocquencourt',
  type: LocationSearchType.MUNICIPALITY
};

const ANNECY: LocationState = {
  geom: [],
  id: 'FR-74010',
  name: 'Annecy',
  type: LocationSearchType.MUNICIPALITY
};

const PALADRU_VILLAGES: LocationState = {
  geom: [],
  id: 'FR-38292',
  name: 'Villages du Lac de Paladru',
  type: LocationSearchType.MUNICIPALITY
};

const EVRY_COURCOURONNES: LocationState = {
  geom: [],
  id: 'FR-91228',
  name: 'Évry-Courcouronnes',
  type: LocationSearchType.MUNICIPALITY
};

const EPAGNY_METZ_TESSY: LocationState = {
  geom: [],
  id: 'FR-74112',
  name: 'Epagny Metz-Tessy',
  type: LocationSearchType.MUNICIPALITY
};

const CHAUMES_EN_RETZ: LocationState = {
  geom: [],
  id: 'FR-44005',
  name: 'Chaumes-en-Retz',
  type: LocationSearchType.MUNICIPALITY
};

const BELLEVIGNY: LocationState = {
  geom: [],
  id: 'FR-85019',
  name: 'Bellevigny',
  type: LocationSearchType.MUNICIPALITY
};

const ANCENIS_SAINT_GEREON: LocationState = {
  geom: [],
  id: 'FR-44003',
  name: 'Ancenis-Saint-Géréon',
  type: LocationSearchType.MUNICIPALITY
};

const BELLEVILLE: LocationState = {
  geom: [],
  id: 'FR-69019',
  name: 'Belleville-en-Beaujolais',
  type: LocationSearchType.MUNICIPALITY
};

const MACHECOUL_SAINT_MEME: LocationState = {
  geom: [],
  id: 'FR-44087',
  name: 'Machecoul-Saint-Même',
  type: LocationSearchType.MUNICIPALITY
};

const VALLONS_DE_L_ERDRE: LocationState = {
  geom: [],
  id: 'FR-44180',
  name: "Vallons-de-l'Erdre",
  type: LocationSearchType.MUNICIPALITY
};

const LOIREAUXENCE: LocationState = {
  geom: [],
  id: 'FR-44213',
  name: 'Loireauxence',
  type: LocationSearchType.MUNICIPALITY
};

const ESSARTS_EN_BOCAGE: LocationState = {
  geom: [],
  id: 'FR-85084',
  name: 'Essarts en Bocage',
  type: LocationSearchType.MUNICIPALITY
};

const OREE_D_ANJOU: LocationState = {
  geom: [],
  id: 'FR-49069',
  name: "Orée d'Anjou",
  type: LocationSearchType.MUNICIPALITY
};

const MARENNES_HIERS_BROUAGE: LocationState = {
  geom: [],
  id: 'FR-17219',
  name: 'Marennes-Hiers-Brouage',
  type: LocationSearchType.MUNICIPALITY
};

const LES_ABRETS_EN_DAUPHINE: LocationState = {
  geom: [],
  id: 'FR-38001',
  name: 'Les Abrets en Dauphiné',
  type: LocationSearchType.MUNICIPALITY
};

const VILLEMAURY: LocationState = {
  geom: [],
  id: 'FR-28330',
  name: 'Villemaury',
  type: LocationSearchType.MUNICIPALITY
};

const OMBREE_D_ANJOU: LocationState = {
  geom: [],
  id: 'FR-49248',
  name: "Ombrée d'Anjou",
  type: LocationSearchType.MUNICIPALITY
};

const THIZY_LES_BOURGS: LocationState = {
  geom: [],
  id: 'FR-69248',
  name: 'Thizy-les-Bourgs',
  type: LocationSearchType.MUNICIPALITY
};

const BEAUPREAU_EN_MAUGES: LocationState = {
  geom: [],
  id: 'FR-49023',
  name: 'Beaupréau-en-Mauges',
  type: LocationSearchType.MUNICIPALITY
};

const INGRANDES_LE_FRESNE_SUR_LOIRE: LocationState = {
  geom: [],
  id: 'FR-49160',
  name: 'Ingrandes-Le Fresne sur Loire',
  type: LocationSearchType.MUNICIPALITY
};

const SEVREMOINE: LocationState = {
  geom: [],
  id: 'FR-49301',
  name: 'Sèvremoine',
  type: LocationSearchType.MUNICIPALITY
};

const MORET_LOING_ET_ORVANNE: LocationState = {
  geom: [],
  id: 'FR-77316',
  name: 'Moret-Loing-et-Orvanne',
  type: LocationSearchType.MUNICIPALITY
};

/* eslint-disable */
export const LOCATION_STATE_FROM_PLACE_ID: DictionaryPlace = {
  ChIJw5c6BqrByRIRGwhUuj22otk: MARSEILLE_2, // Marseille 2 wording
  ChIJXczrp_7AyRIREMy2UKkZCBw: MARSEILLE_2, // Marseille 2 postal code
  ChIJIc8v3w995kcRfuFlZGHww1c: CHESNAY_ROCQUENCOURT, // Le Chesnay-Rocquencourt
  ChIJZ0DhTAF95kcRBf65J29J5mI: CHESNAY_ROCQUENCOURT, // Le Chesnay
  ChIJcd79BmV95kcR0DiMaMOCCwQ: CHESNAY_ROCQUENCOURT, // Rocquencourt
  ChIJyVEFHPqPi0cRujQFYoEWeEI: ANNECY, // Annecy
  ChIJb81CnMGFi0cREJq65CqrCAQ: ANNECY, // Pringy
  'ChIJC5L-T5aPi0cRwEQ_IzW5nZ8': ANNECY, // Annecy-le-Vieux
  ChIJ0ewzR0KFi0cR3rhcqumUaYw: ANNECY, // Cran-Gevrier
  ChIJp5rNtRCFi0cRkGMNPma0_VM: ANNECY, // Meythet
  'ChIJA9EIOrOai0cRXL5-7WoDX-Q': ANNECY, // Seynod
  ChIJLQcLOS0ei0cR1vGu8HFTxmo: PALADRU_VILLAGES, // Paladru
  'ChIJNY9kEwfgikcREHG-5CqrCAQ': PALADRU_VILLAGES, // Le Pin
  ChIJtRs9VNwfi0cRuHQ8AivYsmw: PALADRU_VILLAGES, // Villages du Lac de Paladru
  ChIJ_a3NinLe5UcRsEeLaMOCCwQ: EVRY_COURCOURONNES, // Courcouronnes
  ChIJc80Aqh7e5UcROTx5ohKaxi8: EVRY_COURCOURONNES, // Évry
  ChIJD4p2DBne5UcRmKoINUem8ZM: EVRY_COURCOURONNES, // Évry-Courcouronnes
  ChIJ1ZmG8k2Ei0cRIKC65CqrCAQ: EPAGNY_METZ_TESSY, // Épagny
  ChIJOSfF2qyFi0cRaSqnUkfhXXE: EPAGNY_METZ_TESSY, // Épagny Metz-Tessy
  ChIJbV_MECqgBUgRbRGBWcieXOA: CHAUMES_EN_RETZ, // Chaumes-en-Retz
  ChIJJX2gebWfBUgRoPMNHlI3DQQ: CHAUMES_EN_RETZ, // Arthon-en-Retz
  ChIJO1ROG7aYBUgRcPENHlI3DQQ: CHAUMES_EN_RETZ, // Chéméré
  ChIJy3B_TgwtBEgRb74eRmRo4fQ: BELLEVIGNY, // Bellevigny
  ChIJDcN71Q0tBEgRtBhox3nSRaI: BELLEVIGNY, // Belleville-sur-Vie
  ChIJMVnbVsEsBEgRsK0JHlI3DQQ: BELLEVIGNY, // Saligny
  ChIJkTwA40ADBkgRIm_c2ygjxhU: ANCENIS_SAINT_GEREON, // Ancenis-Saint-Géréon
  ChIJXahR4kADBkgRwPMNHlI3DQQ: ANCENIS_SAINT_GEREON, // Ancenis
  ChIJExxk8o4DBkgRAOoNHlI3DQQ: ANCENIS_SAINT_GEREON, // Saint-Géréon
  ChIJ5aaxx3l980cRqeiVPKnj7W4: BELLEVILLE, // Belleville-en-Beaujolais
  ChIJD9LJCV9980cRFMuUzThAONk: BELLEVILLE, // Belleville
  ChIJd9eZiHR980cRcBy75CqrCAQ: BELLEVILLE, // Saint-Jean-d'Ardières
  ChIJY6RHLdakBUgRLpixnR1Ayy4: MACHECOUL_SAINT_MEME, // Machecoul-Saint-Même
  'ChIJhaWydBilBUgRi-74Gq06yhk': MACHECOUL_SAINT_MEME, // Machecoul
  ChIJgSyIk7KkBUgR0OgNHlI3DQQ: MACHECOUL_SAINT_MEME, // Saint-Même-le-Tenu
  ChIJt8cVSDKvCEgRw57XraLXBoQ: VALLONS_DE_L_ERDRE, // Vallons-de-l'Erdre
  'ChIJv1skLa-uCEgRqkmVSGD0a1E': VALLONS_DE_L_ERDRE, // Bonneuvre
  ChIJ01NCm_2mCEgRfJPN5nY2Rpo: VALLONS_DE_L_ERDRE, // Maumusson
  ChIJ0dAZmSmvCEgR4OgNHlI3DQQ: VALLONS_DE_L_ERDRE, // Saint-Mars-la-Jaille
  ChIJH8GGL0yuCEgRdlju_m2qhcM: VALLONS_DE_L_ERDRE, // Saint-Sulpice-des-Landes
  ChIJQQmVBlCjCEgRcOYNHlI3DQQ: VALLONS_DE_L_ERDRE, // Vritz
  ChIJiW2iOMwfBkgROGQuRxiT4lo: LOIREAUXENCE, // Loireauxence
  ChIJL9BxKlCgCEgRQPMNHlI3DQQ: LOIREAUXENCE, // Belligné
  ChIJ3Z6awwEgBkgRRS6Q1XAdbmI: LOIREAUXENCE, // La Chapelle-Saint-Sauveur
  ChIJDVAUnP8dBkgREq7bpSko090: LOIREAUXENCE, // La Rouxière
  'ChIJgXTPI-AeBkgR6Bp0qQCQF2g': LOIREAUXENCE, // Varades
  ChIJcdnN3BiBBkgRibLgIKmw71Y: ESSARTS_EN_BOCAGE, // Essarts-en-Bocage
  ChIJq_RSi9uABkgRwLgJHlI3DQQ: ESSARTS_EN_BOCAGE, // Les Essarts
  ChIJrzxHRYYqBEgR4LsJHlI3DQQ: ESSARTS_EN_BOCAGE, // Boulogne
  ChIJFWVCyN6HBkgRqrp66ULIhuA: ESSARTS_EN_BOCAGE, // L'Oie
  ChIJLUIIA7CHBkgRQLEJHlI3DQQ: ESSARTS_EN_BOCAGE, // Sainte-Florence
  ChIJAxweFbcFBkgRTBFRdtOHdgA: OREE_D_ANJOU, // Orée d'Anjou
  'ChIJ-TBi5FkGBkgRYJgNHlI3DQQ': OREE_D_ANJOU, // Champtoceaux
  ChIJXdE37w4bBkgRJtDk4CpZU6Y: OREE_D_ANJOU, // Bouzillé
  ChIJof_RREYEBkgROYgpL5OzCDo: OREE_D_ANJOU, // Drain
  ChIJ2xPjr8cIBkgRGeslgvdTaAY: OREE_D_ANJOU, // Landemont
  ChIJ3Q8d0cYEBkgRlyp0qviqbe8: OREE_D_ANJOU, // Liré
  hIJMTx3VKUPBkgRYIwNHlI3DQQ: OREE_D_ANJOU, // Saint-Christophe-la-Couperie
  ChIJ3VyYt34FBkgRAIsNHlI3DQQ: OREE_D_ANJOU, // Saint-Laurent-des-Autels
  ChIJMzmoYBkGBkgRgIkNHlI3DQQ: OREE_D_ANJOU, // Saint-Sauveur-de-Landemont
  ChIJqSaC6nH9BUgRYr2FFHlbcKs: OREE_D_ANJOU, // La Varenne
  ChIJQam1iwpmAUgR0CDbAcPv2bk: MARENNES_HIERS_BROUAGE, // Marennes-Hiers-Brouage
  'ChIJXR-pA95oAUgRIJ3uYJLTBQQ': MARENNES_HIERS_BROUAGE, // Hiers-Brouage
  ChIJkYpMl99lAUgRQJvuYJLTBQQ: MARENNES_HIERS_BROUAGE, // Marennes
  'ChIJd-D4WjQZi0cRMjmMHbE-39M': LES_ABRETS_EN_DAUPHINE, // Les Abrets en Dauphiné
  'ChIJAS08jDUZi0cR0IK-5CqrCAQ': LES_ABRETS_EN_DAUPHINE, // Les Abrets
  ChIJoSCgI9cYi0cRKMcFNWES8Ok: LES_ABRETS_EN_DAUPHINE, // Fitilieu
  'ChIJObKZ9Ccci0cRMIG-5CqrCAQ': LES_ABRETS_EN_DAUPHINE, // La Bâtie-Divisin
  ChIJKZ1eFTmH5EcRYC2GO7HCR1M: VILLEMAURY, // Villemaury
  'ChIJa-y23wx95EcRibpZYhzoSHE': VILLEMAURY, // Civry
  ChIJNzSgf7yA5EcRoHw5BdfIDQQ: VILLEMAURY, // Lutz-En-Dunois
  ChIJBx16rliG5EcRcHg5BdfIDQQ: VILLEMAURY, // Ozoir-Le-Breuil
  'ChIJw3d3tA-H5EcRYHY5BdfIDQQ': VILLEMAURY, // Saint-Cloud-En-Dunois
  'ChIJE1m_ufC-CEgRDHZ6d4mBXes': OMBREE_D_ANJOU, // Ombrée d'Anjou
  'ChIJgwkucPO3CEgRH2YB4KN-XC0': OMBREE_D_ANJOU, // Pouancé
  'ChIJD-pzFli_CEgRMnJPT73NLpc': OMBREE_D_ANJOU, // La Chapelle-Hullin
  ChIJgReohpy4CEgR8uZne5YSbxs: OMBREE_D_ANJOU, // Chazé-Henry
  'ChIJTaWzWEa-CEgRQJYNHlI3DQQ': OMBREE_D_ANJOU, // Combrée
  ChIJyW8YIXi_CEgRghDs5cWbAsE: OMBREE_D_ANJOU, // Grugé-l'Hôpital
  ChIJEULonEm5CEgREI8NHlI3DQQ: OMBREE_D_ANJOU, // Noëllet
  ChIJf3QJDXe3CEgRkI0NHlI3DQQ: OMBREE_D_ANJOU, // La Prévière
  ChIJMYeH6Ye5CEgRs_7qcE8RoCQ: OMBREE_D_ANJOU, // Saint-Michel-et-Chanveaux
  ChIJrVu51yS8CEgRcIcNHlI3DQQ: OMBREE_D_ANJOU, // Le Tremblay
  'ChIJR7R-dtG4CEgRu76ovBHikNs': OMBREE_D_ANJOU, // Vergonnes
  ChIJV7LWT95z9EcRDxYNDHEHMCA: THIZY_LES_BOURGS, // Thizy-les-Bourgs
  ChIJJbK6S4Vz9EcRACi75CqrCAQ: THIZY_LES_BOURGS, // Bourg-de-Thizy
  'ChIJoX-CZtN29EcRACe75CqrCAQ': THIZY_LES_BOURGS, // La Chapelle-de-Mardore
  ChIJxwmdA5V29EcRhfloTJoYLN8: THIZY_LES_BOURGS, // Mardore
  ChIJeUvWEkFx9EcRqGBaC5TDXRA: THIZY_LES_BOURGS, // Marnand
  ChIJG5tC5d1z9EcRBeDYUO3n5d4: THIZY_LES_BOURGS, // Thizy
  ChIJ9YEOxPoVBkgRrDVYM1tnW8A: BEAUPREAU_EN_MAUGES, // Baupréau-en-Mauges - Beaupréau
  ChIJ3TNjQLU_BkgRTEBvqTlp5cQ: BEAUPREAU_EN_MAUGES, // Andrezé
  ChIJk5bisq8VBkgRMJgNHlI3DQQ: BEAUPREAU_EN_MAUGES, // La Chapelle-du-Genêt
  ChIJxwPvyUMSBkgRcJMNHlI3DQQ: BEAUPREAU_EN_MAUGES, // Gesté
  ChIJI8B2LW45BkgRm5sbNi29eI8: BEAUPREAU_EN_MAUGES, // Jallais
  ChIJ0703VdQ4BkgRKush6q5ZuOc: BEAUPREAU_EN_MAUGES, // La Jubaudière
  ChIJC8yhBoE8BkgRUJWjmlo_BkE: BEAUPREAU_EN_MAUGES, // Le Pin-en-Mauges
  ChIJjxb4O8A7BkgRAI4NHlI3DQQ: BEAUPREAU_EN_MAUGES, // La Poitevinière
  ChIJEezWeOAUBkgREJNlDj8CXuQ: BEAUPREAU_EN_MAUGES, // Saint-Philbert-en-Mauges
  ChIJfUaQXF4TBkgR1zZrutOQXE4: BEAUPREAU_EN_MAUGES, // Villedieu-la-Blouère
  'ChIJEw3UU-cgBkgRSTF8B5EKEgo': INGRANDES_LE_FRESNE_SUR_LOIRE, // Ingrandes-le-Fresne sur Loire
  'ChIJ-6e0W9wgBkgR8JINHlI3DQQ': INGRANDES_LE_FRESNE_SUR_LOIRE, // Ingrandes
  ChIJs2OT9wAhBkgRQPANHlI3DQQ: INGRANDES_LE_FRESNE_SUR_LOIRE, // Le Fresne-sur-Loire
  ChIJOXY2BOpqBkgR6Jy0GDEHsok: SEVREMOINE, // Sèvremoine
  ChIJWWoKPrhqBkgRmom3bEe8QXI: SEVREMOINE, // Saint-Macaire-en-Mauges
  ChIJsWZ6azppBkgR8JENHlI3DQQ: SEVREMOINE, // Le Longeron
  ChIJsYt0u5ptBkgRUJANHlI3DQQ: SEVREMOINE, // Montfaucon-Montigné
  ChIJz8jes6dsBkgRe7BWPOODpTs: SEVREMOINE, // La Renaudière
  ChIJ33X2BnlsBkgR0IwNHlI3DQQ: SEVREMOINE, // Roussay
  ChIJW_wZivZqBkgRwIwNHlI3DQQ: SEVREMOINE, // Saint-André-de-la-Marche
  ChIJUybrTgNzBkgRSSxy6NdPQoo: SEVREMOINE, // Saint-Crespin-sur-Moine
  ChIJlalb7U9tBkgRgIsNHlI3DQQ: SEVREMOINE, // Saint-Germain-sur-Moine
  ChIJqyAbiSANBkgRwIcNHlI3DQQ: SEVREMOINE, // Tillières
  ChIJo1fEJ0BuBkgRMfKfjYIFudo: SEVREMOINE, // Torfou
  'ChIJNa7piZZg70cRY6-tKm3icbs': MORET_LOING_ET_ORVANNE, // Moret-Loing-et-Orvanne
  ChIJ3xRVR39g70cRvccCoxks04k: MORET_LOING_ET_ORVANNE, // Moret-sur-Loing
  'ChIJB1BzhJtg70cRNs0xQSuAy-8': MORET_LOING_ET_ORVANNE, // Ecuelles
  ChIJcT8QLUhg70cRnVL7GNqfUUI: MORET_LOING_ET_ORVANNE, // Episy
  ChIJoW_wEk1n70cRYDhTjuWq_kM: MORET_LOING_ET_ORVANNE, // Montarlot
  'ChIJ-27tdcFf70cRIEiMaMOCCwQ': MORET_LOING_ET_ORVANNE // Veneux-les-Sablons
};
/* eslint-enable */

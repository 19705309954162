type OffsetProps = {
  margin?: number;
  isWithHeader?: boolean;
};

export const getOffset = ({
  margin = 0,
  isWithHeader = false
}: OffsetProps): number => {
  const topbar = document.querySelector('#topbar')?.clientHeight || 0;
  const tabs = document.querySelector('#tabs')?.clientHeight || 0;
  const sidebar = document.querySelector('#sidebar')?.clientHeight || 0;
  const loginAs =
    document.querySelector('#impersonated-user')?.clientHeight || 0;
  const banner = document.querySelector('#banner-alert')?.clientHeight || 0;
  const menu = document.querySelector('#menu')?.clientHeight || 0;

  const offset = topbar + loginAs + banner + tabs + margin;

  if (isWithHeader) {
    return offset + sidebar + menu;
  }

  return offset;
};

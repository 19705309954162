import { UserRole } from '@/components/state/interfaces';
import { hasRole } from '@/components/state/utils';

import { useAuth } from './useAuth';

export const useIsAdminRole = (): boolean => {
  const { user } = useAuth();

  return (
    hasRole(UserRole.APP_SALES_ADMIN, user) || hasRole(UserRole.TECH, user)
  );
};

import styled from 'styled-components';

export const SectionsLayout = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

import { Fragment } from 'react';
import { createPortal } from 'react-dom';
import { useRecoilValue } from 'recoil';

import { loaderSelector } from '@/components/state/LoaderState';

import GlobalLoader from './globalLoader/GlobalLoader';

const Loader = () => {
  const { isLoading, ref, text } = useRecoilValue(loaderSelector);

  return (
    <Fragment>
      {isLoading &&
        createPortal(
          <GlobalLoader fullScreen={ref ? false : true} text={text} />,
          ref || document.body
        )}
    </Fragment>
  );
};

export default Loader;

import { useTranslation } from 'next-i18next';
import { FC, Fragment } from 'react';

import { getUserName } from '@/components/app/layout/sidebarItems/externalLeadSidebar/utils';

import { ExternalOpportunityNormalized } from '../../../interfaces';

export type NameCellProps = Pick<
  ExternalOpportunityNormalized,
  'firstname' | 'lastname'
>;

const NameCell: FC<NameCellProps> = ({ firstname, lastname }) => {
  const { t } = useTranslation();

  const userName = getUserName(firstname, lastname);

  return <Fragment>{userName || t('hyphen')}</Fragment>;
};

export default NameCell;

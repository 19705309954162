import { useTranslation } from 'next-i18next';
import Link from 'next/link';

import SellerProjectIcon from '@/assets/icons/seller-project.svg';
import { PAGE_VIEW_TITLE } from '@/utils/gtm';
import { generateButtonId } from '@/utils/tracking';

const CreateSellerProjectItem = () => {
  const { t } = useTranslation();

  return (
    <Link
      data-test="new-seller"
      href="/qualification/seller"
      id={generateButtonId(PAGE_VIEW_TITLE.MENU, 'projectSellerCreate')}
    >
      <SellerProjectIcon />
      {t('createSellerProject')}
    </Link>
  );
};

export default CreateSellerProjectItem;

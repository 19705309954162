export enum AlertCardStatus {
  DISPLAY,
  ERROR,
  INFO,
  SUCCESS,
  WARNING
}

export const getAlertCardLabel = (status: AlertCardStatus) => {
  switch (status) {
    case AlertCardStatus.DISPLAY:
      return 'message';
    case AlertCardStatus.ERROR:
      return 'anErrorOccured';
    case AlertCardStatus.INFO:
      return 'info';
    case AlertCardStatus.SUCCESS:
      return 'congratulations';
    case AlertCardStatus.WARNING:
      return 'warning';
  }
};

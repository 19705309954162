import styled from 'styled-components';

import { colors, convertPxToRem } from '@proprioo/salatim';

export const LayoutPagination = styled.ul`
  display: flex;
  column-gap: ${convertPxToRem(4)};
  row-gap: ${convertPxToRem(4)};
  padding: 0;
`;

export const Dots = styled.span`
  padding: 0 ${convertPxToRem(4)};
  color: ${colors.grey.base80};
`;

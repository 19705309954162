;globalThis["__sentryRewritesTunnelPath__"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"6a015e127955a675979c9738f43e2bbe44b955b5"};globalThis["__sentryBasePath"] = undefined;globalThis["__rewriteFramesAssetPrefixPath__"] = "/backoffice/v0.3.11-6a015e12";import * as Sentry from '@sentry/nextjs';

const DSN_STAGING =
  'https://eed3a9669bcb49cfa2abdc017b853ca4@o216259.ingest.us.sentry.io/2290372';

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_URL || DSN_STAGING;

const isStaging = SENTRY_DSN === DSN_STAGING;

Sentry.init({
  dsn: SENTRY_DSN,
  environment: isStaging ? 'staging' : 'production',
  ignoreErrors: [
    'gtag is not defined',
    'gtag',
    'Cancel rendering route',
    'The operation is insecure.',
    /ResizeObserver/,
    'Fetch is aborted',
    'Non-Error promise rejection captured with value: Ignore this error'
  ],
  tracesSampleRate: isStaging ? 0 : 0.1
});

import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Fragment, ReactNode, RefObject, useEffect, useReducer } from 'react';
import { flushSync } from 'react-dom';
import { useSetRecoilState } from 'recoil';
import * as yup from 'yup';

import Check from '@/assets/icons/check-circle.svg';
import { useActionBarContext } from '@/components/app/actionBar/ActionBarContext';
import { SaveProps } from '@/components/app/actionBar/ActionBarContext.interfaces';
import ConfirmModalButtons from '@/components/app/confirmModalButtons/ConfirmModalButtons';
import { dictionnaryIsEmpty } from '@/components/qualification/utils';
import { loaderSelector } from '@/components/state/LoaderState';
import { logError } from '@/utils/log';
import {
  Dictionary,
  transformYupErrorsIntoDictionary
} from '@proprioo/hokkaido';
import { useModal } from '@proprioo/salatim';

import { defaultValues, FormActions, reducerForm } from './form.reducer';
import { EventPageView, PAGE_VIEW_TITLE, sendPageView } from './gtm';
import { toastError, toastSuccess } from './notification';

type OnValidationProps<T> = {
  updatedValues: T;
  errors: Dictionary;
  updateErrors(errors: Dictionary): void;
};

type OnModalValidationProps<T> = {
  values: T;
  acceptForm(): void;
  rejectForm(): void;
};

type ConfirmationModal = {
  isEnabled: boolean;
  title: string;
  children?: ReactNode;
  icon?: JSX.Element;
  text?: string;
};

type Options = {
  confirmationMessage: string;
  errorMessage: string;
  showValidationToast: boolean;
  isInModal: boolean;
  loaderRef: RefObject<HTMLElement> | null;
  loaderText: string;
  useActionBar: boolean;
  useLoader: boolean;
};

const defaultOptions: Options = {
  confirmationMessage: 'saveValidated',
  errorMessage: 'saveError',
  isInModal: false,
  loaderRef: null,
  loaderText: 'savingInProgress',
  showValidationToast: true,
  useActionBar: true,
  useLoader: true
};

type UseFormProps<T> = {
  initialValues: T;
  pageViewTitle: PAGE_VIEW_TITLE;
  validationSchema: yup.ObjectSchema<yup.AnyObject>;
  options?: Partial<Options>;
  onValidationSuccess(
    callback: OnValidationProps<T>
  ): Promise<(() => void) | void>;
  onValidationFail?(callback: OnValidationProps<T>): void;
  onModalValidation?(props: OnModalValidationProps<T>): ConfirmationModal;
};

export function useForm<T>({
  initialValues,
  pageViewTitle,
  validationSchema,
  onValidationSuccess,
  onValidationFail,
  onModalValidation,
  options
}: UseFormProps<T>) {
  const { asPath, events } = useRouter();

  const {
    actionBar: { saveOptions },
    overwriteSaveOptions,
    saveActions
  } = useActionBarContext();
  const { t } = useTranslation();

  const [
    {
      values,
      isLoading,
      isSubmitting,
      isSucceeding,
      hasAccepted,
      hasChanged,
      hasErrors,
      refreshErrors,
      errors
    },
    dispatch
  ] = useReducer(reducerForm<T>, { values: initialValues, ...defaultValues });

  const updateLoader = useSetRecoilState(loaderSelector);

  const {
    confirmationMessage,
    errorMessage,
    showValidationToast,
    isInModal,
    loaderRef,
    loaderText,
    useActionBar,
    useLoader
  } = { ...defaultOptions, ...options };

  const acceptForm = async () => {
    closeModal();

    dispatch(FormActions.acceptForm());
  };

  const rejectForm = () => {
    closeModal();

    if (useActionBar) {
      saveActions.onFail();
      updateActionBarSaveOptions({ disabled: true });
    }

    dispatch(FormActions.rejectForm());
  };

  const modalValidation =
    onModalValidation && onModalValidation({ acceptForm, rejectForm, values });

  const { openModal, closeModal, createModal } = useModal({
    onAbort: () => {
      if (useActionBar) {
        saveActions.onFail();
      }

      dispatch(FormActions.rejectForm());
    },
    onOpen: () => {
      dispatch(FormActions.submitCancel());
    }
  });

  const createConfirmationModal = () => (
    <Fragment>
      {modalValidation?.isEnabled &&
        createModal({
          children: modalValidation?.children || (
            <div data-test="modal-confirmation-modal">
              <ConfirmModalButtons
                data-test="modal-confirmation-modal-buttons"
                onAccept={acceptForm}
                onReject={rejectForm}
              />
            </div>
          ),
          icon: modalValidation.icon || <Check />,
          isAlternative: true,
          text: modalValidation?.text ? t(modalValidation.text) : undefined,
          title: t(modalValidation.title)
        })}
    </Fragment>
  );

  const updateActionBarSaveOptions = (newSaveOptions: Partial<SaveProps>) => {
    if (useActionBar) {
      overwriteSaveOptions({
        disabled: true,
        label: saveOptions?.label,
        onClick: () => submitForm(),
        saveIdButton: saveOptions?.saveIdButton || null,
        ...newSaveOptions
      });
    }
  };

  useEffect(() => {
    if (!isSucceeding && hasChanged) {
      const handleRouteChange = () => {
        if (!window.confirm(t('formExitConfirmation'))) {
          // For NProgress to stop the loading indicator
          events.emit('routeChangeError');
          throw 'Ignore this error';
        }
      };

      window.onbeforeunload = () => t('formExitConfirmation');
      events.on('routeChangeStart', handleRouteChange);

      return () => {
        events.off('routeChangeStart', handleRouteChange);
        window.onbeforeunload = () => null;
      };
    }
  }, [isSucceeding, hasChanged]);

  // to activate actionBar when needed
  useEffect(() => {
    updateActionBarSaveOptions({ disabled: true });
  }, []);

  useEffect(() => {
    if (!hasErrors && Object.keys(errors).length) {
      const error = document.querySelector('.error');

      if (error) {
        error.scrollIntoView({
          block: 'end',
          inline: 'nearest'
        });
      }

      dispatch(FormActions.updateHasErrors());
    }
  }, [errors, hasErrors]);

  useEffect(() => {
    if (refreshErrors) {
      updateErrors(validateForm());
    } else {
      if (hasChanged && saveOptions?.disabled) {
        updateActionBarSaveOptions({ disabled: false });
      }
    }
  }, [values]);

  useEffect(() => {
    if (useLoader && !isInModal) {
      updateLoader({ isLoading, ref: loaderRef?.current, text: loaderText });
    }
  }, [useLoader, isLoading]);

  useEffect(() => {
    if (isSubmitting) {
      sendForm();
    }
  }, [isSubmitting]);

  const validateForm = (): Dictionary => {
    try {
      validationSchema.validateSync(values, { abortEarly: false });
      return {};
    } catch (error) {
      return transformYupErrorsIntoDictionary(error);
    }
  };

  const validateFormWithoutSubmitting = () => {
    const errorsForm = validateForm();

    if (!dictionnaryIsEmpty(errorsForm)) {
      dispatch(
        FormActions.submitErrorWithoutSubmitting({ errors: errorsForm })
      );

      return sendPageView(
        {
          errors: JSON.stringify(errorsForm),
          path: asPath,
          title: pageViewTitle
        },
        EventPageView.ERROR
      );
    }
  };

  const resetForm = () => {
    dispatch(FormActions.reset({ values: initialValues }));
    updateActionBarSaveOptions({ disabled: true });
  };

  const submitForm = () => {
    dispatch(FormActions.submitStart());
  };

  const updateValues = (newValues: Partial<T>) => {
    if (!isLoading) {
      dispatch(FormActions.updateValues({ values: newValues }));
    }
  };

  const updateValuesFromInput = (newValues: Partial<T>) => {
    if (!isLoading) {
      dispatch(FormActions.updateValuesFromInput({ values: newValues }));
    }
  };

  const updateErrors = (newErrors: Dictionary) => {
    dispatch(FormActions.updateErrors({ errors: newErrors }));

    if (Object.keys(newErrors).length) {
      if (!saveOptions?.disabled) {
        updateActionBarSaveOptions({ disabled: true });
      }
    } else {
      if (saveOptions?.disabled) {
        updateActionBarSaveOptions({ disabled: false });
      }
    }
  };

  const toggleLoading = () =>
    flushSync(() => dispatch(FormActions.toggleLoading()));

  const sendForm = async () => {
    const errorsForm = validateForm();

    if (!dictionnaryIsEmpty(errorsForm)) {
      dispatch(FormActions.submitError({ errors: errorsForm }));

      if (onValidationFail) {
        onValidationFail({
          errors: errorsForm,
          updateErrors,
          updatedValues: values
        });
      }

      if (useActionBar) {
        saveActions.onFail();
        updateActionBarSaveOptions({ disabled: true });
      }

      return sendPageView(
        {
          errors: JSON.stringify(errorsForm),
          path: asPath,
          title: pageViewTitle
        },
        EventPageView.ERROR
      );
    }

    if (modalValidation?.isEnabled && !hasAccepted) {
      return openModal();
    }

    try {
      const onSubmitSuccess = await onValidationSuccess({
        errors,
        updateErrors,
        updatedValues: values
      });

      if (!isInModal) {
        toggleLoading();
      }

      if (showValidationToast) {
        toastSuccess(confirmationMessage);
      }

      if (useActionBar) {
        saveActions.onSuccess();
        updateActionBarSaveOptions({ disabled: true });
      }

      flushSync(() => dispatch(FormActions.submitSuccess()));

      if (onSubmitSuccess) {
        onSubmitSuccess();
      }
    } catch (error) {
      if (!isInModal) {
        toggleLoading();
      }

      if (showValidationToast) {
        toastError(error?.formError || errorMessage);
      }

      if (useActionBar) {
        saveActions.onFail();
        updateActionBarSaveOptions({ disabled: false });
      }

      logError(`Failed during form validation on page view: ${pageViewTitle}`, {
        error,
        values
      });

      flushSync(() =>
        dispatch(
          FormActions.submitError({
            errors: { internalServerError: 'internalServerError' }
          })
        )
      );
    }
  };

  return {
    createConfirmationModal,
    errors,
    hasChanged,
    isLoading,
    resetForm,
    submitForm,
    toggleLoading,
    updateValues,
    updateValuesFromInput,
    validateFormWithoutSubmitting,
    values
  };
}

import { Fragment, useEffect } from 'react';
import useSWR from 'swr';

import { parseUrl } from './utils';

const VersionManager = () => {
  const { data } = useSWR<{ hash: string }>('/api/versionning');

  const currentHash = `${process.env.COMMIT_HASH}`;

  useEffect(() => {
    if (data?.hash && currentHash !== data.hash) {
      const url = parseUrl(data.hash, window.location.href);

      window.location.href = url;
    }
  }, [currentHash, data]);

  return <Fragment />;
};

export default VersionManager;

import { parseISO } from 'date-fns/parseISO';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { Activity } from '@/components/activity/interfaces';
import { getActivityTypeLabel } from '@/components/activity/utils';
import { getRemainingTimeToCreateActivity } from '@/components/externalLead/attributionBanner/utils';
import RelativeDate from '@/components/myCustomers/common/relativeDate/RelativeDate';
import {
  DangerInfo,
  PrimaryInfo
} from '@/components/myCustomers/common/styles/MyCustomers.styles';
import { nullOrString } from '@proprioo/hokkaido';
import { useSmartphoneDevice } from '@proprioo/salatim';

import { ExternalOpportunityStatus } from '../../../interfaces';
import { DangerInfoWrapper, Layout } from './ActivityCell.styles';
import { getDateWrapper } from './ActivityCell.utils';

export type ActivityCellProps = {
  assignedDate: nullOrString;
  pigeStatus: ExternalOpportunityStatus;
  activity?: Activity;
  isUpcomingActivity?: boolean;
};

const ActivityCell: FC<ActivityCellProps> = ({
  activity,
  assignedDate,
  isUpcomingActivity = false,
  pigeStatus
}) => {
  const { t } = useTranslation();

  const isSmartphone = useSmartphoneDevice();

  if (
    !activity &&
    pigeStatus === ExternalOpportunityStatus.ACCEPTED &&
    isUpcomingActivity
  ) {
    const remainingTime = getRemainingTimeToCreateActivity(assignedDate);

    return (
      <Layout>
        <DangerInfoWrapper>
          <DangerInfo>
            {t('remainingTimeToCreateActivity', { time: remainingTime })}
          </DangerInfo>
        </DangerInfoWrapper>
      </Layout>
    );
  }

  if (!activity) {
    return (
      <Layout>
        <PrimaryInfo>
          {isUpcomingActivity ? t('nothingPlanned') : t('hyphen')}
        </PrimaryInfo>
      </Layout>
    );
  }

  const { activityType, completionDate, isAllDay, targetDate } = activity;

  const parsedTargetDate = parseISO(targetDate);
  const DateWrapper = getDateWrapper(
    isAllDay,
    isUpcomingActivity,
    parsedTargetDate
  );

  return (
    <Layout>
      {t(getActivityTypeLabel(activityType))}
      {!isSmartphone ? <br /> : ' '}
      <DateWrapper>
        <RelativeDate
          date={completionDate ? parseISO(completionDate) : parsedTargetDate}
          isCapitalized={!isSmartphone}
        />
      </DateWrapper>
    </Layout>
  );
};

export default ActivityCell;

import { atom, selector } from 'recoil';

import { LeadViewSection } from '../myCustomers/mySellers/interfaces';
import { LeadViewFilters } from '../myCustomers/mySellers/leads/filters/interfaces';
import { DEFAULT_LEAD_VIEW_FILTERS } from '../myCustomers/mySellers/leads/filters/LeadFilters.utils';

export type LeadViewState = {
  leadViewFilters: LeadViewFilters;
  leadViewFilterHistory: LeadViewFilters;
  section: LeadViewSection;
};

export const leadViewAtom = atom<LeadViewState>({
  default: {
    leadViewFilterHistory: DEFAULT_LEAD_VIEW_FILTERS,
    leadViewFilters: DEFAULT_LEAD_VIEW_FILTERS,
    section: LeadViewSection.LEADS
  },
  key: 'leadViewAtom'
});

export const leadViewSelector = selector<LeadViewState>({
  get: ({ get }) => get(leadViewAtom),
  key: 'leadViewSelector',
  set: ({ set }, leadViewValues) => set(leadViewAtom, leadViewValues)
});

import { format } from 'date-fns/format';
import { isAfter } from 'date-fns/isAfter';
import { isBefore } from 'date-fns/isBefore';
import { parse } from 'date-fns/parse';
import { parseISO } from 'date-fns/parseISO';
import { i18n } from 'next-i18next';

import { DATE_ISO_FORMAT, DATE_SLASH_FORMAT } from '@/constants/global';
import { isDefined } from '@/utils/helpers';
import {
  compareOfferDates,
  compareOfferDatesAndStatus
} from '@/utils/offer.utils';
import {
  CustomerProfile,
  Country,
  EstimationStage,
  OpportunityProps,
  UserProps,
  VisitStatus
} from '@proprioo/hokkaido';

import { OfferStatus } from '../buyer/interfaces';
import {
  ContractSignatory,
  ContractSignatoryType,
  ContractStatus,
  OfferProps
} from '../listing/contract/Contract.interfaces';
import { filterSignatoriesByType } from '../listing/contract/Contract.utils';
import { isListingSold } from '../listing/utils';
import { Project } from '../qualification/sellerQualification/interfaces';
import { getDefaultEmail } from '../qualification/utils';
import { CustomerInformation } from '../state/interfaces';
import {
  CleanedOffer,
  GroupedOffers,
  GroupedVisits,
  Offer,
  OfferByUser,
  Visit,
  VisitByUser,
  VisitsByStage
} from './interfaces';

export const getStatus = (status: VisitStatus): string => {
  switch (status) {
    case VisitStatus.ACCEPTED:
      return 'done';
    case VisitStatus.AGENT_DENIED:
      return 'canceledByAgent';
    case VisitStatus.BUYER_NRP:
      return 'noBuyerResponse';
    case VisitStatus.CANCELED:
      return 'canceledByBuyer';
    case VisitStatus.DENIED:
      return 'deniedByAgentOrSeller';
    case VisitStatus.NO_SHOW:
      return 'noShow';
    case VisitStatus.SELLER_DENIED:
      return 'canceledBySeller';
    default:
      return 'awaitingApproval';
  }
};

export const visitsInPast = (visits: Visit[]): boolean => {
  const now = Date.now();
  return visits
    .filter(({ status }) => status === VisitStatus.ACCEPTED)
    .every(({ visit_datetime_utc }) =>
      isBefore(parseISO(visit_datetime_utc), now)
    );
};

export const groupVisits = (visits: Visit[]): GroupedVisits =>
  visits.reduce((acc: GroupedVisits, item) => {
    const { visitor } = item;

    if (visitor) {
      if (!acc[visitor]) {
        acc[visitor] = {
          isPast: false,
          visits: []
        };
      }

      acc[visitor].visits.push(item);
      acc[visitor].visits.sort(
        (a, b) =>
          new Date(a.visit_datetime_utc).getTime() -
          new Date(b.visit_datetime_utc).getTime()
      );

      acc[visitor].isPast = visitsInPast(acc[visitor].visits);
    }

    return acc;
  }, {});

export const getVisitsByUser = (visits: GroupedVisits): VisitByUser[] =>
  Object.keys(visits).reduce((acc: VisitByUser[], visit) => {
    acc.push(visits[visit]);
    return acc;
  }, []);

export const isVisitCanceled = (status: VisitStatus) =>
  [
    VisitStatus.AGENT_DENIED,
    VisitStatus.CANCELED,
    VisitStatus.DENIED,
    VisitStatus.NO_SHOW,
    VisitStatus.SELLER_DENIED
  ].includes(status);

export const getInvalidVisits = (visits: Visit[]): boolean =>
  visits.every(({ status }) => isVisitCanceled(status));

export const removeVisitWhenOfferIsMade = (
  visits: VisitByUser[],
  userIds: string[]
) =>
  visits.filter(item =>
    item.visits.some(({ visitor }) => visitor && !userIds.includes(visitor))
  );

export const separateVisits = (visits: VisitByUser[]): VisitsByStage =>
  visits.reduce(
    (acc: VisitsByStage, item) => {
      const isUnique: boolean = item.visits.length === 1;
      const invalidVisits: boolean = getInvalidVisits(item.visits);

      if ((isUnique && invalidVisits) || invalidVisits) {
        acc.cancelledVisits.push(item);
      } else {
        if (item.isPast) {
          acc.pastVisits.push(item);
        }

        if (!item.isPast) {
          acc.futureVisits.push(item);
        }
      }

      return acc;
    },
    {
      cancelledVisits: [],
      futureVisits: [],
      pastVisits: []
    }
  );

export const getLastPastVisitId = (visits: Visit[]) => {
  const pastVisits = visits.filter(
    ({ status, visit_datetime_utc }) =>
      status === VisitStatus.ACCEPTED &&
      isBefore(parseISO(visit_datetime_utc), new Date())
  );

  if (pastVisits.length === 0) {
    return;
  }

  const [lastVisit] = pastVisits.sort(
    (a, b) =>
      new Date(b.visit_datetime_utc).getTime() -
      new Date(a.visit_datetime_utc).getTime()
  );

  return lastVisit.uuid;
};

export const hasOldOffer = (offers: CleanedOffer[]) =>
  offers.some(({ isNewOffer }) => !isNewOffer);

export const hasNewOfferInProgress = (offers: CleanedOffer[]) =>
  offers.some(
    ({ status, isNewOffer }) =>
      isNewOffer &&
      [
        OfferStatus.PENDING,
        OfferStatus.CREATED,
        OfferStatus.SENT_TO_CLIENTS,
        OfferStatus.SENT_TO_SELLERS,
        OfferStatus.VALIDATION_BY_AGENT
      ].includes(status)
  );

export const canMakeOffer = (
  visitId: string,
  visits: Visit[],
  offers: CleanedOffer[],
  estimationStage: EstimationStage
) => {
  if (
    visitId === getLastPastVisitId(visits) &&
    !hasNewOfferInProgress(offers) &&
    !isListingSold(estimationStage)
  ) {
    return true;
  }

  return false;
};

export const orderByDate = (visits: VisitByUser[]): VisitByUser[] =>
  [...visits].sort((a, b) => {
    const [lastA] = [...a.visits].reverse();
    const [lastB] = [...b.visits].reverse();

    return (
      new Date(lastA.visit_datetime_utc).getTime() -
      new Date(lastB.visit_datetime_utc).getTime()
    );
  });

export const orderByDateAndRecap = (visits: VisitByUser[]): VisitByUser[] =>
  [...visits].sort((a, b) => {
    const [lastA] = [...a.visits].reverse();
    const [lastB] = [...b.visits].reverse();

    const { recap_visite: briefA } = lastA;
    const { recap_visite: briefB } = lastB;

    if (!briefA && briefB) {
      return -1;
    }

    if (briefA && !briefB) {
      return 1;
    }

    return (
      new Date(lastB.visit_datetime_utc).getTime() -
      new Date(lastA.visit_datetime_utc).getTime()
    );
  });

export const getFakeVisitFromOffer = (offer: CleanedOffer): VisitByUser => ({
  isPast: true,
  visits: [
    {
      annonce_id: offer.propertyId,
      appointment_id: '',
      contact_budget: null,
      contact_email: '',
      contact_id: null,
      contact_info: null,
      contact_interest: null,
      contact_name: '',
      contact_tel: '',
      created: offer.createdAt,
      creationOrigin: '',
      first_recap: offer.createdAt,
      interest: null,
      listing_request_id: null,
      loan_needed: false,
      loan_secured: null,
      modified: offer.createdAt,
      pipedrive_id: null,
      pipedrive_status: null,
      recap_visite: 'Offre réalisée sans visite',
      refus_visite: null,
      seller: false,
      seller_listed: null,
      status: VisitStatus.ACCEPTED,
      uuid: '',
      viewing_type: '',
      visit_datetime: offer.createdAt,
      visit_datetime_utc: offer.createdAt,
      visitor: offer.userId
    }
  ]
});

export const groupOffers = (
  offers: Offer[],
  visits: GroupedVisits
): GroupedOffers =>
  offers
    .map(
      ({
        id,
        property_id,
        mainUserId,
        amount,
        expiry,
        status,
        created_at,
        isNewOffer,
        isFromWebsite
      }) => ({
        amount,
        createdAt: created_at,
        expiry,
        id,
        isFromWebsite,
        isNewOffer,
        propertyId: property_id,
        status,
        userId: mainUserId
      })
    )
    .reduce((acc: GroupedOffers, item) => {
      const { userId } = item;

      if (!acc[userId]) {
        acc[userId] = {
          offers: [],
          visit: visits[userId] || getFakeVisitFromOffer(item)
        };
      }

      acc[userId].offers.push(item);
      acc[userId].offers.sort(compareOfferDates);

      return acc;
    }, {});

export const getOffersByUser = (offers: GroupedOffers): OfferByUser[] =>
  Object.keys(offers).reduce((acc: OfferByUser[], offer) => {
    acc.push(offers[offer]);

    acc.sort((a, b) =>
      compareOfferDatesAndStatus(
        b.offers[b.offers.length - 1],
        a.offers[a.offers.length - 1]
      )
    );

    return acc;
  }, []);

export const getActiveOffers = (offers: CleanedOffer[]): boolean =>
  offers.filter(
    offer =>
      (offer.status !== OfferStatus.CANCELED &&
        isAfter(
          parse(
            offer.expiry,
            i18n?.t(DATE_SLASH_FORMAT, { ns: 'date' }) || '',
            new Date()
          ),
          new Date()
        )) ||
      offer.status === OfferStatus.SIGNED
  ).length > 0;

export const isValidUserName = (user: CustomerInformation): boolean => {
  const invalidName = 'xx';

  return (
    user.firstName.toLowerCase() !== invalidName &&
    user.lastName.toLowerCase() !== invalidName
  );
};

export const generateCustomer = (customer: UserProps): UserProps =>
  customer.email
    ? customer
    : {
        ...customer,
        email: getDefaultEmail(customer.firstName, customer.lastName)
      };

export const removeOffersWithoutBuyers = (offers: OfferProps[]): OfferProps[] =>
  offers.filter(
    offer =>
      filterSignatoriesByType(CustomerProfile.BUYER, offer.signatories).length
  );

export const convertOfferPropsToOffer = (newOffers: OfferProps[]): Offer[] =>
  removeOffersWithoutBuyers(newOffers).map(
    ({
      modifiedDate,
      price,
      loanNeeded,
      loanAmount,
      id,
      listingId,
      validityDate,
      status,
      signatories,
      creator
    }) => ({
      amount: price,
      created_at: modifiedDate,
      expiry: validityDate
        ? format(
            new Date(validityDate),
            i18n?.t(DATE_ISO_FORMAT, { ns: 'date' }) || ''
          )
        : '',
      id,
      isFromWebsite: !isDefined(creator),
      isNewOffer: true,
      loan_amount: loanAmount,
      loan_needed: loanNeeded,
      mainUserId: extractUserIdFromNewOffer(signatories),
      property_id: listingId,
      status: oldOfferStatusFromNewOffer(status)
    })
  );

export const extractUserIdFromNewOffer = (signatories: ContractSignatory[]) => {
  const buyers = filterSignatoriesByType(CustomerProfile.BUYER, signatories);

  const mainBuyerUserId = buyers.find(
    ({ signatoryType }) => signatoryType === ContractSignatoryType.MAIN
  )?.userId;

  return mainBuyerUserId || buyers[0].userId;
};

export const oldOfferStatusFromNewOffer = (
  status: ContractStatus | string
): OfferStatus => {
  switch (status) {
    case ContractStatus.GENERATED:
      return OfferStatus.CREATED;
    case ContractStatus.CANCELED:
      return OfferStatus.CANCELED;
    case ContractStatus.WITHDRAWN:
      return OfferStatus.WITHDRAWN;
    case ContractStatus.SIGNED:
      return OfferStatus.SIGNED;
    case ContractStatus.DELETED:
      return OfferStatus.DELETED;
    case ContractStatus.SENT_TO_CLIENTS:
      return OfferStatus.SENT_TO_CLIENTS;
    case ContractStatus.SENT_TO_SELLERS:
      return OfferStatus.SENT_TO_SELLERS;
    case ContractStatus.VALIDATION_BY_AGENT:
      return OfferStatus.VALIDATION_BY_AGENT;
    default:
      return OfferStatus.PENDING;
  }
};

export const getOfferStatusTranslationKey = (
  status: OfferStatus,
  isPast: boolean,
  isFromWebsite = false
) => {
  if (isPast && [OfferStatus.CREATED, OfferStatus.PENDING].includes(status)) {
    return 'expiredOffer';
  }

  switch (status) {
    case OfferStatus.CANCELED:
      return 'canceledOffer';
    case OfferStatus.SIGNED:
      return 'countersignedOffer';
    case OfferStatus.DELETED:
      return 'deletedOffer';
    case OfferStatus.SENT_TO_CLIENTS:
      return 'offerSentToBuyers';
    case OfferStatus.SENT_TO_SELLERS:
      return 'offerSentToSellers';
    case OfferStatus.VALIDATION_BY_AGENT:
      return 'offerSignedByBuyers';
    case OfferStatus.WITHDRAWN:
      return 'withdrawnOffer';
    default:
      return isFromWebsite ? 'receivedOffer' : 'offerToBeSent';
  }
};

export const formatOpportunity = (
  project: Project,
  customerId: string
): OpportunityProps & { country?: Country; pigeId?: string } => {
  const {
    agent,
    city,
    country = Country.FRANCE,
    declaredAsBuyer,
    landArea,
    leadComments,
    leadProvider,
    leadSource,
    livingArea,
    location,
    pigeId,
    postalCode,
    street,
    streetNumber,
    treatmentPriority,
    type
  } = project;

  return {
    agentId: parseInt(agent.value, 10),
    city,
    country,
    declaredAsBuyer,
    floorArea: livingArea,
    latitude: location.lat,
    leadProvider,
    longitude: location.lng,
    postCode: postalCode,
    propertyType: type,
    street,
    streetNumber,
    treatmentPriority: treatmentPriority.value,
    userId: customerId,
    ...(landArea && { gardenArea: landArea }),
    ...(leadComments && { leadComments }),
    ...(leadSource && { leadSource: leadSource.value }),
    ...(pigeId && { pigeId })
  };
};

import styled from 'styled-components';

import { colors, convertPxToRem, paragraphs, radius } from '@proprioo/salatim';

import { IconWrapper } from '../activityIndicator/ActivityIndicator.styles';

export const TableWrapper = styled.div`
  margin-top: ${convertPxToRem(24)};
  overflow: hidden;
  border-radius: ${radius.big};
  background-color: white;
`;

export const Table = styled.table`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  border-spacing: 0;
`;

export const TableBodyCell = styled.td`
  ${paragraphs.body2};
  color: ${colors.dark.base};
  position: relative;
  cursor: pointer;

  :first-of-type {
    padding-left: 0;
  }

  > a {
    display: block;
    width: 100%;
    height: 100%;
    color: inherit;
    padding: ${convertPxToRem(12)};
  }
`;

export const PaginationWrapper = styled.div`
  margin-top: ${convertPxToRem(24)};
`;

export const MultilineCell = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${convertPxToRem(4)};
`;

export const ActivityCellWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  column-gap: ${convertPxToRem(12)};
  align-items: center;
`;

export const ActivityIndicatorWrapper = styled.div`
  align-self: stretch;
  min-height: ${convertPxToRem(48)};

  ${IconWrapper} {
    align-self: center;
  }
`;

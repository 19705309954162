import TagManager from 'react-gtm-module';

import { isProduction } from '@proprioo/hokkaido';

import { ArbitraryObject } from './interfaces';

export const GOOGLE_TAG_MANAGER = 'GTM-PPKZL82';
export const PAGE_VIEW_NAME = 'pageViewData';

export type PageViewItem =
  | string
  | number
  | ArbitraryObject
  | undefined
  | null
  | boolean;

export enum EventPageView {
  ECOMMERCE = 'ecommerce',
  ERROR = 'error',
  MODAL_OPENING = 'modalOpening',
  PAGE_VIEW = 'pageview'
}

export enum PAGE_VIEW_TITLE {
  ADMIN = 'admin',
  AGENT_ADMINISTRATION_CREATE = 'agent-administration-create',
  AGENT_ADMINISTRATION_MODIFY = 'agent-administration-modify',
  AGENT_AVAILABILITIES = 'agent-availabilities',
  AGENT_DASHBOARD = 'agent-dashboard',
  AMENDMENT = 'amendment',
  BUSINESS_STOCK_EXCHANGE = 'business-stock-exchange',
  BUYER_ACTIVITY = 'buyer-activity',
  BUYER_BASE = 'buyer-base',
  BUYER_BASE_OPPORTUNITY = 'buyer-matching',
  BUYER_BASE_EXTERNAL_LEAD = 'buyer-base-external-lead',
  BUYERS_MATCHING = 'buyers-matching',
  COMPANY_PICKER_CREATE = 'company-picker-create',
  CUSTOMER_COMMENT = 'customer-comment',
  CUSTOMER_PICKER_CREATE = 'customer-picker-create',
  EXTERNAL_LEAD_AD = 'external-lead-ad',
  EXTERNAL_LEAD_CONVERT = 'external-lead-convert',
  EXTERNAL_LEAD_FEED = 'external-lead-feed',
  EXTERNAL_LEAD_HISTORY = 'external-lead-history',
  EXTERNAL_LEAD_SIDEBAR = 'external-lead-sidebar',
  EXTERNAL_LEAD_SIMILAR_PROPERTIES = 'external-lead-similar-properties',
  FEE_DISTRIBUTION_UPDATE = 'fee-distribution-update',
  FEED_BUYER = 'buyer-project-activities',
  FEED_CUSTOMER = 'customer-activities',
  FEED_SELLER = 'seller-project-activities',
  FEED_TASK = 'my-activities',
  LEADS = 'seller-projects',
  LEADS_EXTERNAL = 'my-sellers-leads-external',
  LISTING_AD = 'internal-listing',
  LISTING_DOCUMENTS = 'listing-documents',
  LISTING_MANDATE = 'listing-mandate',
  LISTING_REQUESTS = 'listing-requests',
  LISTING_SALE = 'listing-sale',
  LISTING_SELLING = 'listing-selling',
  LOGIN = 'login',
  MAP_PROPERTIES = 'map-properties',
  MARKETING_POINT = 'marketing-point',
  MENU = 'sidebar-menu',
  MODAL_ACTIVITY_CTA = 'modal-activity-cta',
  MODAL_ACTIVITY_DELETE = 'modal-activity-delete',
  MODAL_ACTIVITY_MODIFY = 'modal-activity-modify',
  MODAL_ADMIN_SETTINGS = 'modal-admin-settings',
  MODAL_AGENT_INVOICE_DELETE = 'modal-agent-invoice-delete',
  MODAL_AGENT_INVOICE_DENY = 'modal-agent-invoice-deny',
  MODAL_AUTHENTIC_ACT = 'modal-authentic-act',
  MODAL_AUTHENTIC_DATE = 'modal-authentic-date',
  MODAL_AMENDMENT_CREATE = 'modal-amendment-create',
  MODAL_AMENDMENT_DELETE = 'modal-amendment-delete',
  MODAL_AGREEMENT = 'modal-agreement',
  MODAL_AGREEMENT_DATE = 'modal-agreement-date',
  MODAL_CONTRACT_CUSTOM_TERMS = 'modal-contract-custom-terms',
  MODAL_CREATE_ALERT_BUSINESS_STOCK_EXCHANGE = 'modal-create-alert-business-stock-exchange',
  MODAL_BUYER_INTEREST = 'modal-buyer-interest',
  MODAL_BUYER_MAP_RESET = 'modal-buyer-map-reset',
  MODAL_BUYERS_MATCHING = 'modal-buyers-matching',
  MODAL_CUSTOMER_INFO = 'modal-customer-info',
  MODAL_CUSTOMER_EXISTING_EDIT = 'modal-customer-existing-edit',
  MODAL_CONTRACT_SIGN = 'modal-contract-sign',
  MODAL_DELETE_AGENT = 'modal-delete-agent',
  MODAL_DELETE_FILE = 'modal-delete-file',
  MODAL_FAILED_CALL = 'modal-failed-call',
  MODAL_EXTERNAL_LEAD_ACCEPT = 'modal-external-lead-accept',
  MODAL_EXTERNAL_LEAD_ALERT = 'modal-external-lead-alert',
  MODAL_EXTERNAL_LEAD_EDIT_USER = 'modal-external-lead-edit-user',
  MODAL_EXTERNAL_LEAD_REFUSE = 'modal-external-lead-refuse',
  MODAL_EXTERNAL_LEAD_REMOVE = 'modal-external-lead-remove',
  MODAL_EXTERNAL_LEAD_UNASSIGN = 'modal-external-lead-unassign',
  MODAL_LANGUAGES = 'modal-languages',
  MODAL_LEAD_FILTERS = 'modal-lead-filters',
  MODAL_LISTING_HOLD = 'modal-listing-hold',
  MODAL_LISTING_LIGHTSHOP = 'modal-listing-lightshop',
  MODAL_LISTING_LIGHTSHOP_CANCEL = 'modal-listing-lightshop-cancel',
  MODAL_LISTING_LIGHTSHOP_HOSTING_PLAN_RENEWAL = 'modal-listing-lightshop-hosting-plan-renewal',
  MODAL_LISTING_PICTURES_DOWNLOAD = 'modal-listing-pictures-download',
  MODAL_LISTING_PICTURE_DELETE = 'modal-listing-picture-delete',
  MODAL_LISTING_PUBLISH = 'modal-listing-publish',
  MODAL_LISTING_SHARE = 'modal-listing-share',
  MODAL_LISTING_UNSAVED = 'modal-listing-unsaved-changes',
  MODAL_MANDATE_BOOK_REGISTRY_NUMBER = 'modal-mandate-book-registry-number',
  MODAL_MANDATE_CANCEL_REGISTRY_NUMBER = 'modal-mandate-cancel-registry-number',
  MODAL_MANDATE_DELETE = 'modal-mandate-delete',
  MODAL_MANDATE_USER_CONTACT_INFOS_UPDATE = 'modal-mandate-user-contact-infos-update',
  MODAL_MEETING_CANCEL = 'modal-meeting-cancel',
  MODAL_MEETING_QUALIFY = 'modal-meeting-qualify',
  MODAL_MEETING_WARN = 'modal-meeting-warn',
  MODAL_OFFER_TO_BUY = 'modal-offer-buy',
  MODAL_OFFER_SEND_SELLER = 'modal-offer-send-seller',
  MODAL_OFFER_DELETE = 'modal-offer-delete',
  MODAL_OPPORTUNITY_ARCHIVE = 'modal-opportunity-archive',
  MODAL_OPPORTUNITY_ASSIGN_LEAD = 'modal-opportunity-assign-lead',
  MODAL_OPPORTUNITY_CREATE_MODIFY_MEETING = 'modal-opportunity-create-modifiy-meeting',
  MODAL_OPPORTUNITY_UNARCHIVE = 'modal-opportunity-unarchive',
  MODAL_PDF = 'modal-pdf',
  MODAL_REFUSE_LEAD = 'modal-refuse-lead',
  MODAL_REQUESTS = 'modal-requests',
  MODAL_RESEARCH_MANDATE_BOOK_REGISTRY_NUMBER = 'modal-research-mandate-book-registry-number',
  MODAL_RESEARCH_MANDATE_DELETE = 'modal-research-mandate-delete',
  MODAL_SALE_PRICE_FEE = 'modal-sale-price-fee',
  MODAL_SEE_EXPIRED_PROPERTIES_BUSINESS_STOCK_EXCHANGE = 'modal-see-expired-properties-business-stock-exchange',
  MODAL_SEND_EMAIL = 'modal-send-email',
  MODAL_SIGNATURE_ADD = 'modal-signature-add-customer',
  MODAL_SIGNATURE_CANCEL = 'modal-signature-cancel',
  MODAL_SIGNATURE_DELETE = 'modal-signature-delete-customer',
  MODAL_SIGNATURE_REMINDED = 'modal-signature-reminder',
  MODAL_SIGNATURE_TRANSFORM = 'modal-signature-transform-customer',
  MODAL_VIRTUAL_UPGRADE = 'modal-virtual-upgrade',
  MODAL_VISIT_CANCEL = 'modal-visit-cancel',
  MODAL_VISIT_CREATE_CANCEL = 'modal-visit-create-cancel',
  MODAL_VISIT_CREATE_FUTURE = 'modal-visit-create-future',
  MODAL_VISIT_CREATE_PAST = 'modal-visit-create-past',
  MODAL_VISIT_CREATE_PAST_CANCELLED = 'modal-visit-create-past-cancelled',
  MODAL_VISIT_MODIFY = 'modal-visit-modify',
  MODAL_VISIT_QUALIFY = 'modal-visit-qualify',
  MODAL_WITHDRAWAL = 'modal-withdrawal',
  MY_BUYERS = 'my-buyers',
  NAVBAR = 'navbar',
  OFFER_FORM = 'offer-form',
  PERSONAL_PARAMETERS = 'personal-parameters',
  PRODUCT_NEWS = 'product-portal',
  QUALIFICATION_SELLER_CREATE = 'seller-qualif-create-customer',
  QUALIFICATION_SELLER_CREATE_OUTBOUND = 'seller-qualif-create-customer-outbound',
  QUALIFICATION_SELLER_EMPTY = 'seller-qualif-create-empty',
  QUALIFICATION_SELLER_EMPTY_OUTBOUND = 'seller-qualif-create-empty-outbound',
  QUALIFICATION_SELLER_COMMENT = 'seller-qualif-comment',
  QUALIFICATION_SELLER_OPEN = 'seller-qualif-open',
  QUALIFICATION_BUYER_CREATE = 'buyer-qualif-create-customer',
  QUALIFICATION_BUYER_EMPTY = 'buyer-qualif-create-empty',
  QUALIFICATION_BUYER_OPEN = 'buyer-qualif-open',
  RESEARCH_MANDATE = 'research-mandate',
  ROLES_PERMISSIONS_ADMIN = 'roles-permissions-admin',
  ROLES_PERMISSIONS_ADMIN_CREATE = 'roles-permissions-admin-create',
  ROLES_PERMISSIONS_ADMIN_DELETE = 'roles-permissions-admin-delete',
  SEARCH = 'search-bar',
  SEARCH_MOBILE = 'search-bar-mobile',
  SEARCH_PAGE_BUYER_PROJECT = 'search-page-buyer-project',
  SEARCH_PAGE_BUYER_WITHOUT_PROJECT = 'search-page-buyer-without-project',
  SEARCH_PAGE_SELLER_PROJECT = 'search-page-seller-project',
  SELLER_EXTERNAL_LEAD_AD_TAB = 'seller-external-lead-ad-tab',
  SELLER_MANDATE = 'seller-mandate',
  SERVICES = 'services',
  SETTINGS = 'settings',
  SHORTCUT = 'sidebar-shortcuts',
  USER_PROFILE = 'client-profile',
  VISITS = 'visit-manager',
  VISITS_NOTIFICATION = 'visit-notifications',
  YANPORT_PIGE = 'yanport-pige',
  ZONING = 'zoning'
}

export type PageViewProps = {
  title: PAGE_VIEW_TITLE;
  path: string;
  [key: string]: PageViewItem;
};

export const sendPageView = (
  options: PageViewProps,
  event: EventPageView = EventPageView.PAGE_VIEW
) => {
  const { title, path, ...rest } = options;

  if (isProduction() && window[PAGE_VIEW_NAME]) {
    TagManager.dataLayer({
      dataLayer: {
        event,
        page: {
          path,
          title
        },
        ...rest
      },
      dataLayerName: PAGE_VIEW_NAME
    });
  }
};

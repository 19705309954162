import { UserRole } from '@/components/state/interfaces';
import { hasOriginalRole } from '@/components/state/utils';

import { useAuth } from './useAuth';

export const useIsAdminOriginalRole = (): boolean => {
  const { user } = useAuth();

  return (
    hasOriginalRole(UserRole.APP_SALES_ADMIN, user) ||
    hasOriginalRole(UserRole.TECH, user)
  );
};

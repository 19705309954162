import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { getDealerTypeTranslationKey } from '@/components/buyersMatching/utils';
import { TAG_CLOSE_ARIA_LABEL } from '@/constants/global';
import { Tag, TagAppearance, TagColorsTypes } from '@proprioo/salatim';

import { ExternalOpportunityNormalized } from '../../../interfaces';

type AdvertiserCellProps = Pick<ExternalOpportunityNormalized, 'dealerTypes'>;

const AdvertiserCell: FC<AdvertiserCellProps> = ({ dealerTypes }) => {
  const { t } = useTranslation();

  const tags = dealerTypes.map(type => t(getDealerTypeTranslationKey(type)));

  return (
    <Tag
      appearance={TagAppearance.BUSINESS}
      closeAriaLabel={t(TAG_CLOSE_ARIA_LABEL)}
      color={TagColorsTypes.BLUE}
      labels={tags}
    />
  );
};

export default AdvertiserCell;

import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { Fragment } from 'react';
import { useRecoilState } from 'recoil';

import BuyerIcon from '@/assets/vectors/menu/buyers.svg';
import { menuSelector } from '@/components/state/MenuState';
import { useCurrentAgent } from '@/hooks/agents/useCurrentAgent';
import { useActiveLink } from '@/hooks/useActiveLink/useActiveLink';

import { ItemLabel, ToolItem } from '../MenuItems.styles';

const MyBuyersItem = () => {
  const { t } = useTranslation();

  const [isOpen, updateMenu] = useRecoilState(menuSelector);
  const currentAgent = useCurrentAgent();
  const isActive = useActiveLink('/my-buyers');

  if (!currentAgent?.id) {
    return <Fragment />;
  }

  return (
    <Link
      href="/my-buyers"
      id="sidebar-menu_my-buyers"
      onMouseEnter={() => {
        if (!isOpen) {
          updateMenu(true);
        }
      }}
    >
      <ToolItem isActive={isActive} isOpen={isOpen}>
        <BuyerIcon />
        <ItemLabel>{t('myBuyers')}</ItemLabel>
      </ToolItem>
    </Link>
  );
};

export default MyBuyersItem;

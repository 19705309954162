import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { colors } from '@proprioo/salatim';

import { IconWrapper, Layout } from './Badge.styles';

export type BadgeProps = {
  color: string;
  count: number;
  icon: JSX.Element;
  label: string;
};

const Badge: FC<BadgeProps> = ({ color, count, icon, label }) => {
  const { t } = useTranslation();
  const finalColor = count === 0 ? colors.dark.base20 : color;

  return (
    <Layout aria-label={t(label, { count })} color={finalColor}>
      <IconWrapper>{icon}</IconWrapper>
      {count}
    </Layout>
  );
};

export default Badge;

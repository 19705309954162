import styled from 'styled-components';

import {
  breakpoints,
  colors,
  convertPxToRem,
  fonts,
  paragraphs,
  resetCssButton
} from '@proprioo/salatim';

export const Layout = styled.div`
  ${paragraphs.body1};
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.blue.base60};
  padding: ${convertPxToRem(8)};
  font-size: ${fonts.small};

  @media (min-width: ${breakpoints.xsmall}px) {
    flex-direction: row;
    padding: ${convertPxToRem(8)} ${convertPxToRem(24)};
  }
`;

export const AvatarAndText = styled.div`
  display: flex;
  align-items: center;
`;

export const Text = styled.span`
  text-align: center;

  @media (min-width: ${breakpoints.xsmall}px) {
    text-align: left;
    margin: 0 ${convertPxToRem(12)};
  }
`;

export const Modify = styled.button`
  ${resetCssButton};
  font-weight: bold;
  cursor: pointer;
`;

import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { Agent } from '@/components/state/interfaces';
import { EstimationStage, getFullName } from '@proprioo/hokkaido';

import AgentAvatar from '../../avatar/agentAvatar/AgentAvatar';
import AvatarGroup from '../../avatar/avatarGroup/AvatarGroup';
import ISAvatar from '../../avatar/ISAvatar/ISAvatar';

export type SellerProjectOwnershipProps = {
  agentOwner: Agent;
  estimationStage: EstimationStage;
  viewingAgent?: Agent;
};

const SellerProjectOwnership: FC<SellerProjectOwnershipProps> = ({
  agentOwner,
  estimationStage,
  viewingAgent
}) => {
  const { t } = useTranslation();
  const { id, firstname, lastname } = agentOwner;

  return estimationStage === EstimationStage.BACKLOGS ? (
    <ISAvatar />
  ) : (
    <AvatarGroup>
      <AgentAvatar
        agentId={id}
        name={t('ownershipName', {
          name: getFullName(firstname, lastname)
        })}
      />
      {viewingAgent && viewingAgent.id !== agentOwner.id && (
        <AgentAvatar
          agentId={viewingAgent.id}
          name={t('viewingAgentValue', {
            agent: getFullName(viewingAgent.firstname, viewingAgent.lastname)
          })}
        />
      )}
    </AvatarGroup>
  );
};

export default SellerProjectOwnership;

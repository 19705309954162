import { useTranslation } from 'next-i18next';
import { FC, Fragment, PropsWithChildren } from 'react';
import { useRecoilState } from 'recoil';

import { googleMapsSelector } from '@/components/state/GoogleMapsState';
import { GOOGLE_MAPS_KEY } from '@/constants/global';
import { useLanguage } from '@/hooks/useLanguage/useLanguage';
import { LIBRARIES } from '@/utils/googleMaps';
import { logError } from '@/utils/log';
import { Loader } from '@proprioo/salatim';
import { LoadScriptNext } from '@react-google-maps/api';

const GoogleMapsScript: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  const { currentLanguage } = useLanguage();

  const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] =
    useRecoilState(googleMapsSelector);

  return (
    <LoadScriptNext
      googleMapsApiKey={GOOGLE_MAPS_KEY}
      key={`google-maps-script-${currentLanguage}`}
      language={currentLanguage}
      libraries={LIBRARIES}
      loadingElement={<Loader text={t('loading')} />}
      onError={loadError => {
        logError('Failed to load Google Maps', { loadError });
        location.reload();
      }}
      onLoad={() => setIsGoogleMapsLoaded(true)}
    >
      {isGoogleMapsLoaded ? <Fragment>{children}</Fragment> : <Fragment />}
    </LoadScriptNext>
  );
};

export default GoogleMapsScript;

import styled from 'styled-components';

import { convertPxToRem, paragraphs } from '@proprioo/salatim';

export const Layout = styled.span`
  display: flex;
  width: 100%;
  align-items: center;

  a + a {
    margin-left: ${convertPxToRem(16)};
  }
`;

export const CustomerName = styled.span`
  ${paragraphs.body2};
  margin-left: ${convertPxToRem(16)};
  font-weight: bold;
`;

import { useRouter } from 'next/router';
import { Fragment, useEffect } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';

import {
  History,
  historyManagerSelector
} from '@/components/state/HistoryManagerState';

const MAIN_PAGES = [
  '/',
  '/admin',
  '/agent/dashboard',
  '/agent/[agentId]/task-manager',
  '/agent/[agentId]/personal-parameters',
  '/business-stock-exchange',
  '/buyer',
  '/map-properties',
  '/my-buyers',
  '/my-sellers',
  '/qualification/buyer',
  '/qualification/seller',
  '/search-results',
  '/services',
  '/zoning'
];

const CHILD_PAGES = ['/customer/[customerId]', '/external-lead/[leadId]'];

const HistoryManager = () => {
  const { asPath, query, route } = useRouter();

  const [history, updateHistory] = useRecoilState(historyManagerSelector);
  const resetHistory = useResetRecoilState(historyManagerSelector);

  useEffect(() => {
    const updatedHistory = [...history];
    const isMainPage = MAIN_PAGES.includes(route);

    const queryItems: Pick<History, 'customerId' | 'leadId'> = {
      ...(query.customerId && { customerId: `${query.customerId}` }),
      ...(query.leadId && { leadId: `${query.leadId}` })
    };
    const historyItem = { ...queryItems, isMainPage, path: asPath, route };

    const motherIndex = history.findIndex(
      item => item.isMainPage === isMainPage && item.route === route
    );

    const childIndex = history.findIndex(item => {
      const [currentQuery] = Object.keys(queryItems);

      return (
        currentQuery &&
        item[currentQuery as keyof History] === query[currentQuery] &&
        !CHILD_PAGES.includes(item.route)
      );
    });

    if (motherIndex > -1) {
      updatedHistory.splice(motherIndex, 1);
    }

    if (childIndex > -1 && !CHILD_PAGES.includes(route)) {
      updatedHistory.splice(childIndex, 1);
    }

    updateHistory([...updatedHistory, historyItem]);

    return () => resetHistory();
  }, [asPath, route, query]);

  return <Fragment />;
};

export default HistoryManager;

import { useUserPermissionsBuilder } from '@/hooks/useUserPermissionsBuilder/useUserPermissionsBuilder';

import { Layout } from './MenuItems.styles';
import { MENU_ITEMS_MAPPING } from './MenuItems.utils';

const MenuItems = () => {
  const items = useUserPermissionsBuilder(MENU_ITEMS_MAPPING);

  return <Layout>{items}</Layout>;
};

export default MenuItems;

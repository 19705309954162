import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { BulletColor } from './Bullet.interfaces';
import { Inner, Layout } from './Bullet.styles';

export type BulletProps = {
  color: BulletColor;
  label?: string;
};

const Bullet: FC<BulletProps> = ({ color, label }) => {
  const { t } = useTranslation();

  return (
    <Layout {...(label && { 'aria-label': t(label) })}>
      <Inner color={color} />
    </Layout>
  );
};

export default Bullet;

import { GRAPH_TOKEN, X_REQUESTED_WITH } from '@/constants/global';
import { Dictionary, HttpMethod } from '@proprioo/hokkaido';

import { GraphOperationName } from './graph/enums';
import { ArbitraryObject, AuthTokens } from './interfaces';

export type FetchOptions = RequestInit & { tokens?: AuthTokens };

export const formatErrors = (errors: string[] = []) =>
  errors.reduce((acc: Dictionary, error) => {
    acc[error] = error;
    return acc;
  }, {});

async function fetchHandler<T>(response: Response): Promise<T> {
  if (response.ok) {
    return response
      .json()
      .then(json => Promise.resolve(json))
      .catch(() => Promise.resolve({ response }));
  } else {
    return response
      .json()
      .catch(() => {
        throw response.statusText;
      })
      .then(json => {
        throw json.errors;
      });
  }
}

export const fetcherSwr = (url: string) => fetch(url).then(r => r.json());

export async function fetcher<T>(
  url: string,
  options?: FetchOptions,
  binary?: boolean
): Promise<T> {
  const headers = new Headers(options?.headers);
  if (!headers.has('Content-Type') && !binary) {
    headers.append('Content-Type', 'application/json');
  }
  if (!headers.has('Accept')) {
    headers.append('Accept', 'application/json');
  }
  if (!headers.has('X-Requested-With')) {
    headers.append('X-Requested-With', X_REQUESTED_WITH);
  }

  const data = await fetch(url, {
    ...options,
    headers
  }).then(r => fetchHandler<T>(r));

  return data;
}

export const setGraphRequest = () => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('X-Requested-With', X_REQUESTED_WITH);
  headers.append('Authorization', `${GRAPH_TOKEN}`);
  return headers;
};

export const fetchGraphResponse = (
  response: Response,
  operationName: GraphOperationName
) => {
  const contentType = response.headers.get('Content-Type');
  if (contentType?.includes('application/json')) {
    return response.json().then(json => json);
  } else {
    throw new Error(
      `No application/json data in graph response for operation ${operationName}`
    );
  }
};

export async function graphRequest<T>(
  operationName: GraphOperationName,
  query: string,
  variables?: ArbitraryObject
) {
  const headers = setGraphRequest();
  const result: { data: T; errors?: [] } = await fetch(
    `${process.env.GRAPH_URL}`,
    {
      body: JSON.stringify({
        operationName,
        query,
        variables
      }),
      headers,
      method: HttpMethod.POST
    }
  )
    .then(response => fetchGraphResponse(response, operationName))
    .catch(error => error);
  return result;
}

import styled from 'styled-components';

import { colors, convertPxToRem, radius } from '@proprioo/salatim';

export const Layout = styled.div`
  display: flex;
  flex-wrap: nowrap;
  position: absolute;
  right: ${convertPxToRem(4)};
  top: ${convertPxToRem(4)};

  > span {
    border: ${convertPxToRem(1)} solid ${colors.grey.base40};
    border-radius: ${radius.rounded};

    & + span {
      margin-left: -${convertPxToRem(4)};
    }
  }
`;

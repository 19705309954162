import { differenceInHours } from 'date-fns/differenceInHours';
import { endOfDay } from 'date-fns/endOfDay';
import { format } from 'date-fns/format';
import { isBefore } from 'date-fns/isBefore';
import { i18n } from 'next-i18next';
import qs from 'qs';
import { Fragment, ReactElement } from 'react';

import ArchivedIcon from '@/assets/icons/archive-tab.svg';
import BuildingIcon from '@/assets/icons/building.svg';
import LogoIcon from '@/assets/icons/logo.svg';
import TagIcon from '@/assets/icons/tag.svg';
import UserIcon from '@/assets/icons/user.svg';
import { Activity, ActivityStatus } from '@/components/activity/interfaces';
import {
  getActivitiesByStatus,
  getLastPlannedActivity,
  getNextPlannedActivity
} from '@/components/activity/utils';
import { DealerType } from '@/components/buyersMatching/interfaces';
import { getDealerTypesValue } from '@/components/buyersMatching/utils';
import { formatPriceHistory } from '@/components/externalLead/history/utils';
import { calculateCommission } from '@/components/listing/contract/Contract.utils';
import { isListingSold } from '@/components/listing/utils';
import { YanportAds } from '@/components/marketingPoint/interfaces';
import { Agent } from '@/components/state/interfaces';
import { DATE_ISO_FORMAT, ONE_THOUSAND } from '@/constants/global';
import {
  parseStringifiedArrayFromBQ,
  QsArrayFormat,
  queryParamToArray
} from '@/utils/helpers';
import { ArbitraryObject, DictionaryNullOrBoolean } from '@/utils/interfaces';
import {
  EstimationStage,
  getFullName,
  LeadSource,
  nullOrString
} from '@proprioo/hokkaido';
import { colors } from '@proprioo/salatim';

import { SectionParams } from '../common/section/Section';
import {
  getPageIndexFromUrlParam,
  isEventLate,
  isEventToday,
  sortByDate
} from '../myCustomers.utils';
import {
  ExternalOpportunitiesPerTab,
  ExternalOpportunityNormalized,
  ExternalOpportunityRaw,
  ExternalOpportunityStatus,
  LeadViewSection,
  LeadViewTab,
  MarketingDealer,
  PriceHistory,
  QueryParam,
  SaleOpportunitiesPerTab,
  SaleOpportunity,
  SaleOpportunityEvent,
  TABS_MAPPING
} from './interfaces';

export const getSection = (tab: LeadViewTab): LeadViewSection => {
  const mapping = Object.entries(TABS_MAPPING).find(([_, tabs]) =>
    tabs.includes(tab)
  );
  return mapping ? (mapping[0] as LeadViewSection) : LeadViewSection.LEADS;
};

export const getTabs = (section: LeadViewSection): LeadViewTab[] =>
  TABS_MAPPING[section];

export const getSectionColor = (section: LeadViewSection): string => {
  switch (section) {
    case LeadViewSection.LEADS:
      return colors.red.base20;
    case LeadViewSection.PROSPECTS:
      return colors.terracota.base40;
    case LeadViewSection.SALE:
      return colors.blue.base;
    case LeadViewSection.SELLING:
      return colors.greenLight.base40;
    case LeadViewSection.ARCHIVED:
      return colors.grey.base60;
    default:
      return 'white';
  }
};

export const getSectionLabel = (section: LeadViewSection): string => {
  switch (section) {
    case LeadViewSection.ARCHIVED:
      return 'archivedPlural';
    case LeadViewSection.LEADS:
      return 'leads';
    case LeadViewSection.PROSPECTS:
      return 'prospects';
    case LeadViewSection.SALE:
      return 'sales';
    case LeadViewSection.SELLING:
      return 'commercializations';
  }
};

export const getSectionIcon = (section: LeadViewSection): ReactElement => {
  switch (section) {
    case LeadViewSection.LEADS:
      return <LogoIcon />;
    case LeadViewSection.PROSPECTS:
      return <UserIcon />;
    case LeadViewSection.SALE:
      return <TagIcon />;
    case LeadViewSection.SELLING:
      return <BuildingIcon />;
    case LeadViewSection.ARCHIVED:
      return <ArchivedIcon />;
    default:
      return <Fragment />;
  }
};

export const getSectionParams = (section: LeadViewSection): SectionParams => ({
  color: getSectionColor(section),
  icon: getSectionIcon(section),
  label: getSectionLabel(section)
});

export const getTabLabel = (tab: LeadViewTab): string => {
  switch (tab) {
    case LeadViewTab.ARCHIVED_ALL:
    case LeadViewTab.PROSPECTS_ALL:
    case LeadViewTab.SALE_ALL:
    case LeadViewTab.SELLING_ALL:
      return 'all';
    case LeadViewTab.LEADS_ALL:
      return 'casavoLeads';
    case LeadViewTab.LEADS_ALL_EXTERNAL:
      return 'pigeLeads';
    case LeadViewTab.LEADS_ALL_EXTERNAL_ATTRIBUTED_PICKING:
      return 'myPickingLeads';
    case LeadViewTab.LEADS_ALL_EXTERNAL_ATTRIBUTED_PIGE:
      return 'myPigeLeads';
    case LeadViewTab.LEADS_ALL_EXTERNAL_EXPIRED:
      return 'myExpiredLeads';
    case LeadViewTab.PROSPECTS_ESTIMATED:
      return 'valued_other';
    case LeadViewTab.PROSPECTS_QUALIFIED:
      return 'doneMeetings';
    case LeadViewTab.PROSPECTS_TO_QUALIFY:
      return 'scheduledValuations';
    case LeadViewTab.PROSPECTS_WITHOUT_MEETING:
      return 'withoutMeeting';
    case LeadViewTab.SALE_AUTHENTIC_ACT:
      return 'signedAuthenticActs';
    case LeadViewTab.SALE_COMPROMISE:
      return 'signedCompromises';
    case LeadViewTab.SALE_COUNTERSIGNED_OFFER:
      return 'countersignedOffers';
    case LeadViewTab.SELLING_ONLINE:
      return 'online';
    case LeadViewTab.SELLING_ON_HOLD:
      return 'paused';
    case LeadViewTab.SELLING_TO_PUBLISH:
      return 'toPublish';
  }
};

export const getFirstTabInSection = (section: LeadViewSection): LeadViewTab =>
  TABS_MAPPING[section][0];

export const formatUrlQuery = (dataToFormat: ArbitraryObject): string =>
  qs.stringify(dataToFormat, {
    addQueryPrefix: true,
    arrayFormat: QsArrayFormat.COMMA,
    encode: false
  });

export const getTabUrl = (
  tab: LeadViewTab,
  page = 1,
  agentIds?: number[],
  filter?: string[]
): string =>
  `/my-sellers${formatUrlQuery({ agentOwners: agentIds, filter, page, tab })}`;

export const getSectionUrl = (
  section: LeadViewSection,
  agentIds?: number[],
  filters?: string[]
): string => getTabUrl(getFirstTabInSection(section), 1, agentIds, filters);

export const isProspectionSource = (leadSource: LeadSource): boolean =>
  ![
    LeadSource.CASAVO_DELEGATION,
    LeadSource.CASAVO_LEAD,
    LeadSource.CASAVO_PROSPECT,
    LeadSource.CASAVO_SMP_IBUYER_MANDATE,
    LeadSource.CASAVO_SMP_MANDATE,
    LeadSource.CASAVO_VALUATION,
    LeadSource.ONLINE_VALUATION,
    LeadSource.STANDARD_PHONE_CALL,
    LeadSource.VALUATION_MEETING,
    LeadSource.PHONE_PROSPECTING_IS
  ].includes(leadSource);

export const isClosed = (estimationStage: EstimationStage): boolean =>
  ![
    EstimationStage.LEAD,
    EstimationStage.BACKLOGS,
    EstimationStage.PROSPECT,
    EstimationStage.APPOINTMENT_TO_DO,
    EstimationStage.MANDATE_TO_SEND,
    EstimationStage.UNSIGNED_MANDATE,
    EstimationStage.VALUATION_TO_SEND
  ].includes(estimationStage);

export const getLeadRevenue = (
  commission: number,
  price?: number,
  chargedFees?: number
): number => chargedFees || calculateCommission(price || 0, commission);

export const revenueInThousands = (revenue: number): number =>
  Math.round(revenue / ONE_THOUSAND);

export const isInTabProspects = (estimationStage: EstimationStage): boolean =>
  [
    EstimationStage.PROSPECT,
    EstimationStage.APPOINTMENT_TO_DO,
    EstimationStage.MANDATE_TO_SEND,
    EstimationStage.VALUATION_TO_SEND,
    EstimationStage.UNSIGNED_MANDATE
  ].includes(estimationStage);

export const filterOpportunitiesForTab = (
  opportunities: SaleOpportunity[],
  tab: LeadViewTab
): SaleOpportunity[] => {
  switch (tab) {
    case LeadViewTab.LEADS_ALL:
      return opportunities.filter(
        ({ estimationStage }) => estimationStage === EstimationStage.LEAD
      );
    case LeadViewTab.PROSPECTS_ALL:
      return opportunities.filter(({ estimationStage }) =>
        isInTabProspects(estimationStage)
      );
    case LeadViewTab.PROSPECTS_WITHOUT_MEETING:
      return opportunities.filter(
        ({ estimationStage }) => estimationStage === EstimationStage.PROSPECT
      );
    case LeadViewTab.PROSPECTS_TO_QUALIFY:
      return opportunities.filter(
        ({ estimationStage }) =>
          estimationStage === EstimationStage.APPOINTMENT_TO_DO
      );
    case LeadViewTab.PROSPECTS_ESTIMATED:
      return opportunities.filter(
        ({ estimationStage, price }) =>
          [
            EstimationStage.MANDATE_TO_SEND,
            EstimationStage.VALUATION_TO_SEND,
            EstimationStage.UNSIGNED_MANDATE
          ].includes(estimationStage) && price !== undefined
      );
    case LeadViewTab.PROSPECTS_QUALIFIED:
      return opportunities.filter(
        ({ estimationStage, price }) =>
          [
            EstimationStage.MANDATE_TO_SEND,
            EstimationStage.VALUATION_TO_SEND
          ].includes(estimationStage) && price === undefined
      );
    case LeadViewTab.SELLING_ALL:
      return opportunities.filter(({ estimationStage }) =>
        [
          EstimationStage.ONLINE,
          EstimationStage.ON_HOLD,
          EstimationStage.PAUSED
        ].includes(estimationStage)
      );
    case LeadViewTab.SELLING_ONLINE:
      return opportunities.filter(
        ({ estimationStage }) => estimationStage === EstimationStage.ONLINE
      );
    case LeadViewTab.SELLING_ON_HOLD:
      return opportunities.filter(
        ({ estimationStage }) => estimationStage === EstimationStage.PAUSED
      );
    case LeadViewTab.SELLING_TO_PUBLISH:
      return opportunities.filter(
        ({ estimationStage, firstPublicationDate }) =>
          estimationStage === EstimationStage.ON_HOLD && !firstPublicationDate
      );
    case LeadViewTab.SALE_ALL:
      return opportunities.filter(({ estimationStage }) =>
        isListingSold(estimationStage)
      );
    case LeadViewTab.SALE_AUTHENTIC_ACT:
      return opportunities.filter(
        ({ estimationStage }) =>
          estimationStage === EstimationStage.AUTHENTIC_ACT
      );
    case LeadViewTab.SALE_COMPROMISE:
      return opportunities.filter(
        ({ estimationStage }) => estimationStage === EstimationStage.COMPROMISE
      );
    case LeadViewTab.SALE_COUNTERSIGNED_OFFER:
      return opportunities.filter(
        ({ estimationStage }) => estimationStage === EstimationStage.SOLD
      );
    case LeadViewTab.ARCHIVED_ALL:
      return opportunities.filter(({ lastStage }) => Boolean(lastStage));
    default:
      return [];
  }
};

export const reduceOpportunities = (
  opportunities: SaleOpportunity[]
): SaleOpportunitiesPerTab =>
  Object.values(LeadViewTab).reduce<SaleOpportunitiesPerTab>((acc, tab) => {
    acc[tab] = filterOpportunitiesForTab(opportunities, tab).sort((a, b) =>
      sortByEventDate(a.nextEvent, b.nextEvent)
    );
    return acc;
  }, {} as SaleOpportunitiesPerTab);

export const countLateEvents = (opportunities: SaleOpportunity[]): number =>
  opportunities.filter(({ nextEvent }) => isEventLate(nextEvent)).length;

export const countTodayEvents = (opportunities: SaleOpportunity[]): number =>
  opportunities.filter(({ nextEvent }) => isEventToday(nextEvent)).length;

export const sortByEventDate = (
  eventA?: SaleOpportunityEvent,
  eventB?: SaleOpportunityEvent
): number => {
  const targetDateA = eventA
    ? eventA.targetDate
    : endOfDay(new Date()).toISOString();
  const targetDateB = eventB
    ? eventB.targetDate
    : endOfDay(new Date()).toISOString();

  return sortByDate(targetDateA, targetDateB);
};

export const sortByRevenue = (
  opportunityA: SaleOpportunity,
  opportunityB: SaleOpportunity
): number => {
  const revenueA = getLeadRevenue(
    opportunityA.commission,
    opportunityA.price,
    opportunityA.chargedFees
  );
  const revenueB = getLeadRevenue(
    opportunityB.commission,
    opportunityB.price,
    opportunityB.chargedFees
  );

  return revenueA - revenueB;
};

export const sortAndFilterAgents = (agents: Agent[], search: string): Agent[] =>
  agents
    .filter(({ firstname, lastname }) =>
      getFullName(firstname, lastname).toLowerCase().match(search.toLowerCase())
    )
    .sort((a, b) =>
      getFullName(a.firstname, a.lastname).localeCompare(
        getFullName(b.firstname, b.lastname)
      )
    );

export const getOpportunityLinkFeed = ({
  userId,
  estimationId
}: SaleOpportunity): string =>
  `/customer/${userId}/seller/${estimationId}/feed`;

export const getLeadViewNavigation = (
  tab: QueryParam,
  page: QueryParam,
  agents?: QueryParam
) => {
  const currentTab =
    !Array.isArray(tab) &&
    Object.values(LeadViewTab).includes(tab as LeadViewTab)
      ? (tab as LeadViewTab)
      : LeadViewTab.LEADS_ALL;
  const currentPage = getPageIndexFromUrlParam(page);

  const parsedAgents = queryParamToArray(agents);
  const currentAgentIds = parsedAgents
    .map(agent => parseInt(agent, 10))
    .sort((a, b) => a - b);

  return { currentAgentIds, currentPage, currentTab };
};

export const isExternalLeadTab = (tab: LeadViewTab): boolean =>
  [
    LeadViewTab.LEADS_ALL_EXTERNAL,
    LeadViewTab.LEADS_ALL_EXTERNAL_ATTRIBUTED_PIGE,
    LeadViewTab.LEADS_ALL_EXTERNAL_ATTRIBUTED_PICKING,
    LeadViewTab.LEADS_ALL_EXTERNAL_EXPIRED
  ].includes(tab);

export const getExternalOpportunitiesNormalized = (
  opportunities: ExternalOpportunityRaw[],
  interestById: DictionaryNullOrBoolean = {}
): ExternalOpportunityNormalized[] =>
  opportunities.map(({ activities, pige, pigeProperties, potentialBuyer }) => {
    const {
      agentId,
      assignedDate,
      assignedEndDate,
      createdAt: pigeCreatedAt,
      createdBy: pigeCreatedBy,
      email: customEmail,
      firstname: customFirstname,
      id: pigeId,
      lastname: customLastname,
      modifiedAt: pigeModifiedAt,
      modifiedBy: pigeModifiedBy,
      phone: customPhone,
      pigeExternalId,
      refusalReason,
      status
    } = pige;
    const {
      adresse,
      adresse_numero,
      ads,
      appartement_balcon_surface,
      appartement_cave,
      appartement_etage_bien,
      appartement_nb_chambres,
      appartement_nb_pieces,
      appartement_nb_sdb,
      appartement_nb_sde,
      appartement_parking,
      appartement_qualite,
      appartement_surface_carrez,
      appartement_surface_habitable,
      ascenseur,
      balcony_terrace,
      bloctel,
      charge_copropriete_mois,
      code_postal,
      cuisine_equipee,
      description,
      dpe,
      geo_location_ids,
      ges,
      images,
      immeuble_annee_construction,
      latitude,
      longitude,
      marketing_dealers,
      marketing_publication_start_date,
      modified_at,
      nb_floor_listing,
      number,
      orientation_est,
      orientation_nord,
      orientation_ouest,
      orientation_sud,
      prix,
      prix_historique,
      sold_by_agency,
      sold_by_owner,
      sold_by_representative,
      swimming_pool,
      type_bien,
      valide,
      ville
    } = pigeProperties;

    const [marketingDealer] =
      parseStringifiedArrayFromBQ<MarketingDealer[]>(marketing_dealers);
    const { email, name, type } = marketingDealer;

    return {
      ads: parseStringifiedArrayFromBQ<YanportAds[]>(ads),
      agentId,
      assignedDate,
      assignedEndDate,
      balconySurfaceArea: appartement_balcon_surface,
      city: ville,
      constructionYear: immeuble_annee_construction,
      dealerTypes: getDealerTypesValue(
        sold_by_agency,
        sold_by_owner,
        sold_by_representative
      ),
      description,
      dpe: dpe || 0,
      electricityFees: charge_copropriete_mois,
      email: customEmail || email || '',
      firstname: customFirstname || '',
      floor: appartement_etage_bien,
      floorsCount: nb_floor_listing,
      ges: ges || 0,
      hasBloctel: bloctel ? bloctel === 'OK' : false,
      hasCellar: appartement_cave || false,
      hasElevator: ascenseur || false,
      hasFurnishedKitchen: cuisine_equipee || false,
      hasOutdoorSpace: balcony_terrace || false,
      hasParking: appartement_parking || false,
      hasPool: swimming_pool || false,
      isInterested:
        interestById[pigeExternalId] !== undefined
          ? interestById[pigeExternalId]
          : null,
      isInternal: false,
      isOnline: valide === 1,
      isProspectLead: status === ExternalOpportunityStatus.PROSPECT,
      lastActivity: activities
        ? getLastPlannedActivity<Activity>(
            getActivitiesByStatus(activities.activities, ActivityStatus.DONE)
          )
        : undefined,
      lastname: customLastname || name || '',
      latitude,
      longitude,
      marketingDealerType: type,
      modifiedAt: modified_at,
      nbBathrooms: appartement_nb_sdb || 0,
      nbBedrooms: appartement_nb_chambres || 0,
      nbRooms: appartement_nb_pieces || 0,
      nbShowerRooms: appartement_nb_sde || 0,
      orientationEast: orientation_est,
      orientationNorth: orientation_nord,
      orientationSouth: orientation_sud,
      orientationWest: orientation_ouest,
      phone: customPhone || number || '',
      pictures: parseStringifiedArrayFromBQ<string[]>(images),
      pigeCreatedAt,
      pigeCreatedBy,
      pigeExternalId,
      pigeId,
      pigeModifiedAt,
      pigeModifiedBy,
      postCode: code_postal,
      price: prix,
      priceHistory: prix_historique
        ? formatPriceHistory(
            parseStringifiedArrayFromBQ<PriceHistory[]>(prix_historique)
          )
        : [
            {
              date: format(
                new Date(marketing_publication_start_date),
                i18n?.t(DATE_ISO_FORMAT, { ns: 'date' }) || ''
              ),
              previousPrice: 0,
              price: prix
            }
          ],
      propertyType: type_bien,
      publicationDate: marketing_publication_start_date,
      quality: appartement_qualite,
      refusalReason,
      scores: potentialBuyer ? potentialBuyer.scores : [],
      status,
      street: adresse,
      streetNumber: adresse_numero,
      surface: appartement_surface_habitable,
      surfaceCarrez: appartement_surface_carrez,
      upcomingActivity: activities
        ? getNextPlannedActivity<Activity>(
            getActivitiesByStatus(
              activities.activities,
              ActivityStatus.TO_BE_DONE
            )
          )
        : undefined,
      zonesId: geo_location_ids
    };
  });

export const getExternalOpportunitiesPerTab = (
  opportunities: ExternalOpportunityNormalized[]
): ExternalOpportunitiesPerTab =>
  Object.values(LeadViewTab).reduce<ExternalOpportunitiesPerTab>((acc, tab) => {
    acc[tab] = opportunities.filter(({ dealerTypes, isOnline, status }) => {
      switch (tab) {
        case LeadViewTab.LEADS_ALL_EXTERNAL:
          return (
            isOnline &&
            status === ExternalOpportunityStatus.AUTOMATIC_ATTRIBUTION
          );
        case LeadViewTab.LEADS_ALL_EXTERNAL_ATTRIBUTED_PIGE:
          return (
            isOnline &&
            [
              ExternalOpportunityStatus.ACCEPTED,
              ExternalOpportunityStatus.PERMANENTLY_ATTRIBUTED
            ].includes(status) &&
            dealerTypes.includes(DealerType.OWNER)
          );
        case LeadViewTab.LEADS_ALL_EXTERNAL_ATTRIBUTED_PICKING:
          return (
            isOnline &&
            [
              ExternalOpportunityStatus.ACCEPTED,
              ExternalOpportunityStatus.PERMANENTLY_ATTRIBUTED
            ].includes(status) &&
            !dealerTypes.includes(DealerType.OWNER)
          );
        case LeadViewTab.LEADS_ALL_EXTERNAL_EXPIRED:
          return (
            !isOnline &&
            [
              ExternalOpportunityStatus.ACCEPTED,
              ExternalOpportunityStatus.PERMANENTLY_ATTRIBUTED
            ].includes(status)
          );
        default:
          return false;
      }
    });

    return acc;
  }, {} as ExternalOpportunitiesPerTab);

export const getRemainingTimeToAttributeLead = (
  assignedEndDate: nullOrString
): number => {
  if (!assignedEndDate) return 0;

  const today = new Date();
  const endDate = new Date(assignedEndDate);

  return isBefore(today, endDate)
    ? differenceInHours(endDate, today, { roundingMethod: 'round' })
    : 0;
};

export const getTabTooltipTranslationKey = (tab: LeadViewTab): string => {
  switch (tab) {
    case LeadViewTab.LEADS_ALL:
      return 'allCasavoLeadsTabTooltip';
    case LeadViewTab.LEADS_ALL_EXTERNAL:
      return 'allPigeLeadsTabTooltip';
    case LeadViewTab.LEADS_ALL_EXTERNAL_ATTRIBUTED_PIGE:
      return 'pigeLeadsTabTooltip';
    case LeadViewTab.LEADS_ALL_EXTERNAL_ATTRIBUTED_PICKING:
      return 'pickingLeadsTabTooltip';
    case LeadViewTab.LEADS_ALL_EXTERNAL_EXPIRED:
      return 'expiredPigeLeadsTabTooltip';
    default:
      return '';
  }
};

export const getIsLoadingByCurrentTab = (
  currentTab: LeadViewTab,
  isArchivedExternalOpportunitiesLoading: boolean,
  isArchivedOpportunitiesLoading: boolean,
  isCurrentExternalOpportunitiesLoading: boolean,
  isCurrentOpportunitiesLoading: boolean
): boolean => {
  switch (currentTab) {
    case LeadViewTab.ARCHIVED_ALL:
      return isArchivedOpportunitiesLoading;
    case LeadViewTab.LEADS_ALL_EXTERNAL:
    case LeadViewTab.LEADS_ALL_EXTERNAL_ATTRIBUTED_PIGE:
    case LeadViewTab.LEADS_ALL_EXTERNAL_ATTRIBUTED_PICKING:
      return isCurrentExternalOpportunitiesLoading;
    case LeadViewTab.LEADS_ALL_EXTERNAL_EXPIRED:
      return isArchivedExternalOpportunitiesLoading;
    default:
      return isCurrentOpportunitiesLoading;
  }
};

import { FC, PropsWithChildren, ReactElement, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useRecoilState } from 'recoil';

import ActionBarProvider from '@/components/app/actionBar/ActionBarContext';
import { UserRole } from '@/components/state/interfaces';
import { hasOriginalRole } from '@/components/state/utils';
import { useAuth } from '@/hooks/auth/useAuth';
import { useCurrentPathUserPermissions } from '@/hooks/useCurrentPathUserPermissions/useCurrentPathUserPermissions';
import { useOnClickOutside } from '@proprioo/salatim';

import { menuSelector } from '../../state/MenuState';
import ActionBar from '../actionBar/ActionBar';
import GlobalError from '../globalError/GlobalError';
import Banner from './banner/Banner';
import ImpersonationBar from './impersonationBar/ImpersonationBar';
import {
  Content,
  ContentAndTopbar,
  ContentChildren,
  ContentOverflow,
  ContentTabs,
  MainContainer,
  MainContent,
  MainMenu
} from './MainLayout.styles';
import MenuBar from './menuBar/MenuBar';
import CustomerMobileSidebarTitle from './sidebarItems/sidebar/mobileSidebarTitle/customerMobileSidebarTitle/CustomerMobileSidebarTitle';
import Sidebar from './sidebarItems/sidebar/Sidebar';
import TopBar from './topBar/TopBar';

export type MainLayoutProps = {
  backgroundColor?: string;
  contentTabs?: ReactElement | null;
  is404Page?: boolean;
  isFullHeight?: boolean;
  isFullWidth?: boolean;
  mobileSidebarTitle?: FC;
  sidebarItems?: ReactElement | null;
  showSidebar?: boolean;
  title?: string;
  topBarItems?: ReactElement | null;
};

const MainLayout: FC<PropsWithChildren<MainLayoutProps>> = ({
  backgroundColor = 'white',
  children,
  contentTabs = null,
  is404Page,
  isFullHeight = false,
  isFullWidth = false,
  mobileSidebarTitle = CustomerMobileSidebarTitle,
  showSidebar = true,
  sidebarItems = null,
  title,
  topBarItems = null
}) => {
  const { user } = useAuth();
  const { canUserRead } = useCurrentPathUserPermissions();

  const [isOpen, toggleMenu] = useRecoilState(menuSelector);
  const mainRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const canAccessAdminSettings =
    hasOriginalRole(UserRole.APP_SALES_ADMIN, user) ||
    hasOriginalRole(UserRole.MOPS, user) ||
    hasOriginalRole(UserRole.TEAM_LEADS, user) ||
    hasOriginalRole(UserRole.TECH, user);

  useHotkeys('esc', () => toggleMenu(false));

  useOnClickOutside(menuRef, event => {
    if (!menuRef.current?.contains(event.target as Node)) {
      toggleMenu(false);
    }
  });

  return (
    <ActionBarProvider>
      <MainMenu
        id="menu"
        isOpen={isOpen}
        onMouseLeave={() => toggleMenu(false)}
        ref={menuRef}
      >
        <MenuBar />
      </MainMenu>
      <MainContainer>
        <MainContent data-test="main-content" ref={mainRef}>
          <Banner />
          {canAccessAdminSettings && <ImpersonationBar />}
          <ContentAndTopbar>
            <TopBar title={title} topBarItems={topBarItems} />
            <Content
              isFullHeight={
                isFullHeight || Boolean(contentTabs) || Boolean(sidebarItems)
              }
            >
              {showSidebar && canUserRead && (
                <Sidebar
                  data-test="sidebar"
                  mobileSidebarTitle={mobileSidebarTitle}
                >
                  {sidebarItems}
                </Sidebar>
              )}
              <ContentOverflow
                backgroundColor={backgroundColor}
                data-test="content-overflow"
                id="content-overflow"
              >
                {contentTabs && canUserRead && (
                  <ContentTabs id="tabs">{contentTabs}</ContentTabs>
                )}
                <ContentChildren
                  data-test="content-children"
                  isFullHeight={isFullHeight}
                  isFullWidth={isFullWidth}
                  isWithSidebar={showSidebar}
                >
                  {!canUserRead && !is404Page ? (
                    <GlobalError errorCode="403" text="forbidden" />
                  ) : (
                    children
                  )}
                </ContentChildren>
              </ContentOverflow>
            </Content>
            <ActionBar isSidebarVisible={showSidebar} />
          </ContentAndTopbar>
        </MainContent>
      </MainContainer>
    </ActionBarProvider>
  );
};

export default MainLayout;

import styled from 'styled-components';

import { colors, convertPxToRem, paragraphs } from '@proprioo/salatim';

export const TagWrapper = styled.div`
  span {
    color: ${colors.dark.base80};
    font-weight: normal;
    white-space: nowrap;
  }

  path {
    fill: ${colors.dark.base80};
  }
`;

export const MultiTagsWrapper = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: ${convertPxToRem(8)};
`;

export const InlineItemsWrapper = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  column-gap: ${convertPxToRem(12)};
  align-items: center;
`;

export const PrimaryInfo = styled.span`
  ${paragraphs.body2}
  color: ${colors.dark.base};
`;

export const ImportantInfo = styled(PrimaryInfo)`
  font-weight: bold;
`;

export const SecondaryInfo = styled.span`
  ${paragraphs.body2}
  color: ${colors.dark.base60};
`;

export const DangerInfo = styled.span`
  ${paragraphs.body2}
  color: ${colors.red.base};
  font-weight: bold;
`;

import qs from 'qs';

import { QS_ARRAY_PARAMS } from '@/utils/helpers';
import { Dictionary } from '@proprioo/hokkaido';

export const parseUrl = (hash: string, url: string) => {
  const [originalPath, ...params] = Object.entries(
    qs.parse(url, {
      delimiter: '?'
    })
  );

  const newParams = params.reduce((acc: Dictionary, [key, value]) => {
    const filteredValue = `${value}`
      .split('&')
      .filter(item => !item.includes('refreshAppToken'))
      .join('&');

    acc[key] = filteredValue;

    return acc;
  }, {});

  const query = qs.stringify(
    { ...newParams, refreshAppToken: hash },
    QS_ARRAY_PARAMS
  );

  const [basePath] = originalPath;

  return `${basePath}${query}`;
};

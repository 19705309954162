import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import Avatar, { AvatarProps } from '../Avatar';

export const ISAvatar: FC<Partial<AvatarProps>> = ({
  dataTest,
  name,
  size
}) => {
  const { t } = useTranslation();

  return (
    <Avatar
      name={name || t('insideSalesOwnership')}
      dataTest={dataTest}
      size={size}
    >
      {t('insideSalesInitials')}
    </Avatar>
  );
};

export default ISAvatar;

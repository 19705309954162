import {
  FinancingMethod,
  ListingStatus,
  nullOrNumber,
  nullOrString,
  VisitStatus
} from '@proprioo/hokkaido';

import { OtherCriteriasProps } from '../buyerbase/filters/otherCriterias/OtherCriterias';
import {
  CriteriaState,
  MinMax,
  ProjectState
} from '../buyerQualification/interfaces';
import { CustomerInformation } from '../state/interfaces';
import { numberOrString } from '@/utils/interfaces';

export enum ActivityTitle {
  CANCELED_VISIT = 'canceledVisit',
  FUTUR_VISIT = 'futureVisit',
  CONTACT_REQUEST = 'contactRequest',
  OFFER = 'offer',
  PAST_VISIT = 'pastVisit',
  UNQUALIFIED_VISIT = 'unqualifiedVisit',
  VISIT = 'visit'
}

export enum BuyerInterest {
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High'
}

export enum BuyerScore {
  A_PLUS = 1,
  A = 2,
  B = 3,
  C = 4
}

export enum OfferStatus {
  CANCELED = 'canceled',
  CREATED = 'created',
  DELETED = 'deleted',
  PENDING = 'pending',
  SENT_TO_CLIENTS = 'sentToClients',
  SENT_TO_SELLERS = 'sentToSellers',
  SIGNED = 'signed',
  VALIDATION_BY_AGENT = 'validationByAgent',
  WITHDRAWN = 'withdrawn'
}

export enum PriorityKind {
  OFFMARKET = 'offMarket',
  PRIORITY = 'priority'
}

export type ListingRequestResponse = {
  archived: boolean;
  createdAt: string;
  id: number;
  mainUserId: string;
  property_id: number;
  waiting_call: boolean;
  hubspot_customer_id?: number;
  hubspot_deal_id?: number;
  message?: string;
  priority_kind?: PriorityKind;
  source?: string;
};

export type ListingRequest = {
  archived: boolean;
  createdAt: string;
  id: number;
  mainUserId: string;
  propertyId: number;
  waitingCall: boolean;
  hubspotCustomerId?: number;
  hubspotDealId?: number;
  message?: string;
  priorityKind?: PriorityKind;
  source?: string;
};

export type BuyerOffer = {
  id: number | string;
  amount: number;
  contactId: nullOrNumber;
  createdAt: string;
  expiry: string;
  loanAmount: nullOrNumber;
  loanNeeded: FinancingMethod;
  mainUserId: string;
  propertyId: number;
  status: OfferStatus | null;
  yousignBuyer: nullOrString;
  yousignSeller: nullOrString;
  isFromWebsite?: boolean;
  isNewOffer?: boolean;
  modifiedDate?: string;
};

export type BaseActivity = {
  userId: nullOrString;
  propertyId: number;
};

export type CleanedListingRequest = BaseActivity & {
  id: numberOrString;
  archived?: boolean;
  message?: string;
  source?: string;
  sourceCreatedAt: string;
};

export type CleanedVisit = BaseActivity & {
  id: string;
  appointmentId: nullOrString;
  interest: BuyerInterest | null;
  status: VisitStatus;
  visitDate: string[];
  visitRecap: nullOrString;
};

export type CleanedBuyerOffer = BaseActivity &
  Pick<
    BuyerOffer,
    | 'id'
    | 'amount'
    | 'createdAt'
    | 'loanAmount'
    | 'loanNeeded'
    | 'modifiedDate'
    | 'isNewOffer'
    | 'status'
    | 'expiry'
    | 'isFromWebsite'
  >;

export type BuyerActivityStatus = VisitStatus | CleanedBuyerOffer['status'];

export type ActivitiesVisit = CleanedListingRequest & {
  appointmentId: nullOrString;
  status: BuyerActivityStatus;
  visitDate: string[];
};

export type ActivitiesOffer = Omit<ActivitiesVisit, 'id'> &
  Pick<
    CleanedBuyerOffer,
    | 'amount'
    | 'createdAt'
    | 'expiry'
    | 'id'
    | 'isFromWebsite'
    | 'loanNeeded'
    | 'loanAmount'
    | 'modifiedDate'
    | 'status'
  >;

export type ActivityType = {
  title: string;
  items: (CleanedListingRequest | CleanedVisit | CleanedBuyerOffer)[];
};

export type Activities = {
  activitiesRequests: CleanedListingRequest[];
  activitiesVisits: ActivitiesVisit[];
  activitiesOffers: ActivitiesOffer[];
};

export type BuyerQualificationFormState = {
  customer: CustomerInformation;
  criteria: CriteriaState;
  project: ProjectState;
  notification: boolean;
  alertID?: string;
  userID?: string;
  projectID?: string;
  creator?: string;
  reallocateActivities?: boolean;
};

export type AlgoliaSearchState = {
  localisation?: string[] | string;
  typeBien?: string[];
  status?: ListingStatus;
  page?: string[];
  nbPieces?: MinMax;
  nbBedrooms?: MinMax;
  prix?: MinMax;
  surface?: MinMax;
  other?: OtherCriteriasProps;
};

export type TypedAlgoliaSearchState = {
  locationIds?: string[];
  nbPieces?: MinMax;
  nbBedrooms?: MinMax;
  prix?: MinMax;
  surface?: MinMax;
  typeBien?: string[];
  other?: OtherCriteriasProps;
};

import { toast } from 'react-toastify';

import { ToastStatus } from '@/components/app/toast/interfaces';
import Toast from '@/components/app/toast/Toast';

export const toastError = (text: string) =>
  toast.error(<Toast status={ToastStatus.ERROR} text={text} />, {
    autoClose: 4000
  });

export const toastSuccess = (text: string) =>
  toast.success(<Toast status={ToastStatus.SUCCESS} text={text} />);

export const toastWarning = (text: string) =>
  toast.warn(<Toast status={ToastStatus.WARNING} text={text} />);

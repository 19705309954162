import { FC, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { leadViewSelector } from '@/components/state/LeadViewState';
import { PAGE_VIEW_TITLE } from '@/utils/gtm';

import ActivityBullets from '../../common/activityBullets/ActivityBullets';
import Section from '../../common/section/Section';
import { LeadViewSection, SaleOpportunitiesPerTab } from '../interfaces';
import {
  getActiveFilters,
  getSectionFilters
} from '../leads/filters/LeadFilters.utils';
import {
  countLateEvents,
  countTodayEvents,
  getSectionParams,
  getSectionUrl,
  getTabs
} from '../LeadView.utils';

export type LeadSectionProps = {
  kind: LeadViewSection;
  isActive: boolean;
  isLoading: boolean;
  opportunities: SaleOpportunitiesPerTab;
  agentIds?: number[];
};

const LeadSection: FC<LeadSectionProps> = ({
  agentIds,
  kind,
  isActive,
  isLoading,
  opportunities
}) => {
  const [leadViewState, setLeadViewState] = useRecoilState(leadViewSelector);

  const { leadViewFilterHistory, leadViewFilters } = leadViewState;

  const sectionParams = useMemo(() => getSectionParams(kind), [kind]);
  const tabs = useMemo(() => getTabs(kind), [kind]);
  const sectionOpportunities = useMemo(
    () => tabs.map(tab => opportunities[tab]).flat(),
    [opportunities, tabs]
  );

  const hasTabs = tabs.length > 1;
  const sectionFilters = getActiveFilters(
    getSectionFilters(kind, leadViewFilterHistory)
  );

  const updateFilters = () => {
    if (!isActive) {
      const previousSection = leadViewState.section;
      const newHistory = getSectionFilters(
        previousSection,
        leadViewFilters,
        leadViewFilterHistory
      );
      const currentFilters = getSectionFilters(kind, leadViewFilterHistory);

      setLeadViewState({
        ...leadViewState,
        leadViewFilterHistory: newHistory,
        leadViewFilters: currentFilters
      });
    }
  };

  return (
    <Section
      bullet={
        <ActivityBullets
          lateCount={countLateEvents(sectionOpportunities)}
          todayCount={countTodayEvents(sectionOpportunities)}
        />
      }
      hasTabs={hasTabs}
      isActive={isActive}
      isLoading={isLoading}
      onClick={updateFilters}
      pageViewTitle={PAGE_VIEW_TITLE.LEADS}
      sectionParams={sectionParams}
      sectionUrl={getSectionUrl(kind, agentIds, sectionFilters)}
      tableContent="opportunities"
    />
  );
};

export default LeadSection;

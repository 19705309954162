import { getYear } from 'date-fns/getYear';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import Logo from '@/assets/icons/casavo.svg';
import GoogleIcon from '@/assets/icons/google.svg';
import {
  BACKOFFICE_URL,
  OAUTH_AUTHORIZE_URL,
  OAUTH_CLIENT_ID
} from '@/constants/global';
import { useBasePath } from '@/hooks/useBasePath/useBasePath';
import { PAGE_VIEW_TITLE, sendPageView } from '@/utils/gtm';

import {
  Background,
  Copyright,
  Layout,
  LoginButton,
  LoginCover,
  LoginLayout,
  LogoContainer,
  TitleContainer
} from './LoginPanel.styles';

const LoginPanel = () => {
  const { t } = useTranslation();
  const { asPath, push } = useRouter();

  const basePath = useBasePath();

  useEffect(() => {
    sendPageView({
      path: asPath,
      title: PAGE_VIEW_TITLE.LOGIN,
      url: window.location.hostname
    });
  }, []);

  return (
    <Background>
      <Layout>
        <LoginLayout>
          <LogoContainer>
            <Logo />
          </LogoContainer>
          <TitleContainer>{t('welcomeInApp')}</TitleContainer>
          <LoginButton
            data-test="login-button"
            onClick={async () =>
              // Instead of using Link component, we use router push because we do not want users to open this link in a new tab
              await push(
                `${OAUTH_AUTHORIZE_URL}?client_id=${OAUTH_CLIENT_ID}&response_type=code&redirect_uri=${encodeURI(
                  `${BACKOFFICE_URL}/auth/callback`
                )}&state=${basePath}`
              )
            }
          >
            <GoogleIcon />
            {t('loginWithGoogle')}
          </LoginButton>
          <Copyright>Made with ❤️ for you - {getYear(new Date())}</Copyright>
        </LoginLayout>
        <LoginCover />
      </Layout>
    </Background>
  );
};

export default LoginPanel;

import { useTranslation } from 'next-i18next';
import { useRecoilState } from 'recoil';

import Logo from '@/assets/icons/casavo.svg';
import Plus from '@/assets/icons/plus.svg';
import { useUserPermissionsBuilder } from '@/hooks/useUserPermissionsBuilder/useUserPermissionsBuilder';
import { PAGE_VIEW_TITLE } from '@/utils/gtm';
import { generateButtonId } from '@/utils/tracking';
import {
  Burger,
  Button,
  ButtonAppearance,
  ButtonSizes,
  ButtonTheme,
  Tooltip,
  useMobileDevice
} from '@proprioo/salatim';

import { menuSelector } from '../../../state/MenuState';
import EnvTag from '../envTag/EnvTag';
import MenuAgent from './menuAgent/MenuAgent';
import {
  CreateActions,
  Layout,
  LogoContainer,
  MenuContainer,
  MenuCreate,
  MenuHeader
} from './MenuBar.styles';
import { MENU_BAR_CREATE_ITEMS } from './MenuBar.utils';
import MenuItems from './menuItems/MenuItems';
import { useIsFrenchAndAdminRole } from '@/hooks/auth/useIsFrenchAndAdminRole';
import { FEATURE_FLAG_BRK_9218 } from '@/constants/global';
import { FEATURE_FLAG_STATE } from '@/utils/interfaces';

const MenuBar = () => {
  const { t } = useTranslation();

  const [isOpen, updateIsOpen] = useRecoilState(menuSelector);
  const createItems = useUserPermissionsBuilder(MENU_BAR_CREATE_ITEMS);
  const isMobile = useMobileDevice();
  const isQualificationAllowed = useIsFrenchAndAdminRole();

  const canCreateProject =
    isQualificationAllowed ||
    FEATURE_FLAG_BRK_9218 === FEATURE_FLAG_STATE.ACTIVATED;

  return (
    <Layout>
      <MenuContainer>
        <MenuHeader isOpen={isOpen} onClick={() => updateIsOpen(!isOpen)}>
          <LogoContainer data-test="logo">
            <Logo />
            {isMobile && <EnvTag />}
          </LogoContainer>
          <Burger color="white" isOpen={isOpen} onClick={() => ({})} />
        </MenuHeader>
        {Boolean(createItems.length) && canCreateProject && (
          <MenuCreate isOpen={isOpen}>
            <Tooltip
              interactive={true}
              tooltip={<CreateActions>{createItems}</CreateActions>}
              trigger="click"
              usePortal={false}
            >
              <Button
                appearance={ButtonAppearance.SECONDARY}
                icon={<Plus />}
                id={generateButtonId(PAGE_VIEW_TITLE.MENU, 'projectCreate')}
                label={t('createProject')}
                rounded={!isOpen}
                size={ButtonSizes.SMALL}
                theme={ButtonTheme.WHITE}
              />
            </Tooltip>
          </MenuCreate>
        )}
      </MenuContainer>
      <MenuItems />
      <MenuAgent />
    </Layout>
  );
};

export default MenuBar;

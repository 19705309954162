import * as yup from 'yup';

import { CompanyLegalForm } from '@/components/listing/contract/interfaces';

export const isJointOwnership = (legalForm: string | undefined) =>
  legalForm &&
  (legalForm as CompanyLegalForm) === CompanyLegalForm.JOINT_OWNERSHIP;

export const CompanyFormSchema = yup
  .object({
    city: yup.string().required(),
    cs: yup.number().nullable().max(1000000000),
    denomination: yup.string().required(),
    legalForm: yup.string().required(),
    postCode: yup.string().required(),
    rcs: yup.string().nullable(),
    street: yup.string().required(),
    users: yup.array().nullable()
  })
  .test('cs', 'cs', ({ cs, legalForm }) => {
    if (!isJointOwnership(legalForm)) {
      return Boolean(cs);
    }

    return true;
  })
  .test('rcs', 'rcs', ({ legalForm, rcs }) => {
    if (!isJointOwnership(legalForm)) {
      return Boolean(rcs);
    }

    return true;
  });

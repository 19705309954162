import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import BuildingIcon from '@/assets/icons/building.svg';
import HomeIcon from '@/assets/icons/home.svg';
import { getPropertyTypeLabel } from '@/components/app/cards/utils';
import { PrimaryInfo } from '@/components/myCustomers/common/styles/MyCustomers.styles';
import { PropertyEnum } from '@proprioo/hokkaido';

import { IconPrefixedInfo } from '../../Leads.styles';
import { housePropertyTypes } from '@/components/listing/utils';

export type ListingIconProps = {
  listingType: PropertyEnum;
  listingId?: string;
};

const ListingIcon: FC<ListingIconProps> = ({ listingId, listingType }) => {
  const { t } = useTranslation();

  const iconLabel = t(getPropertyTypeLabel(listingType));

  return (
    <IconPrefixedInfo>
      {listingType && housePropertyTypes.includes(listingType) ? (
        <HomeIcon aria-label={iconLabel} />
      ) : (
        <BuildingIcon aria-label={iconLabel} />
      )}
      {listingId && (
        <PrimaryInfo data-test="listing-id">{listingId}</PrimaryInfo>
      )}
    </IconPrefixedInfo>
  );
};

export default ListingIcon;

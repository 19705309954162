import { startOfDay } from 'date-fns/startOfDay';
import { subMonths } from 'date-fns/subMonths';
import qs from 'qs';

import { PICTURE_API_FULL_URL } from '@/constants/global';
import {
  COUNTY_GEO_CODES,
  DISTRICT_GEO_CODES
} from '@/constants/locationStates';
import { IMAGE_WIDTH } from '@/hooks/useMosaicPictures.tsx/useListingAdMosaicPictures';
import { QS_ARRAY_PARAMS } from '@/utils/helpers';
import {
  ArbitraryObject,
  DictionaryBoolean,
  DictionaryNullOrBoolean
} from '@/utils/interfaces';
import {
  computePictureFromAssets,
  EstimationStage,
  isNotEmpty,
  PropertyEnum
} from '@proprioo/hokkaido';

import { AlertByUser } from '../app/layout/contentTabs/activityBar/buyerProjectsTabs/utils';
import { LocationState } from '../buyerQualification/interfaces';
import { getLocationIds } from '../buyerQualification/utils';
import { ListingProps, ListingQuality } from '../listing/interfaces';
import { IMAGE_RATIO } from '../listing/listingAd/mosaic/carousel/Carousel.styles';
import {
  DealerType,
  FiltersState,
  FiltersStateWithLimitOffset,
  Interest,
  InterestByType,
  InterestType,
  InternalProperty,
  OtherCriteriasProps
} from './interfaces';

export const DEFAULT_OTHER_CRITERIAS: OtherCriteriasProps = {
  groundFloor: null,
  hasCellarOrAnnex: null,
  hasLift: null,
  hasOutdoorSpace: null,
  hasParking: null,
  hasPool: null,
  withoutRenovationWork: null
};

export const DEFAULT_STATUS: EstimationStage[] = [
  EstimationStage.ON_HOLD,
  EstimationStage.ONLINE,
  EstimationStage.PAUSED,
  EstimationStage.SOLD
];

export const DEFAULT_FILTERS_STATE_MATCHING: FiltersState = {
  agentIds: [],
  budget: { max: null, min: null },
  dealerTypes: [DealerType.AGENCY, DealerType.MANDATARY, DealerType.OWNER],
  hasPhone: false,
  includeAttributed: true,
  isMap: false,
  locationState: [],
  nbBedrooms: { max: null, min: null },
  nbRooms: { max: null, min: null },
  otherCriterias: DEFAULT_OTHER_CRITERIAS,
  propertyTypes: [PropertyEnum.FLAT, PropertyEnum.HOUSE],
  publicationDateFrom: null,
  publicationDateTo: null,
  status: DEFAULT_STATUS,
  surface: { max: null, min: null }
};

export const DEFAULT_FILTERS_STATE_PICKING: FiltersState = {
  ...DEFAULT_FILTERS_STATE_MATCHING,
  publicationDateFrom: +subMonths(startOfDay(new Date()), 6),
  publicationDateTo: null
};

export const getOtherCriteriasFromQuery = (
  value: object
): OtherCriteriasProps => {
  const otherCriteriasKeys = Object.keys(DEFAULT_OTHER_CRITERIAS);

  return Object.entries(value).reduce(
    (acc: OtherCriteriasProps, [key, value]) => {
      if (otherCriteriasKeys.includes(key)) {
        const valueCriteria = JSON.parse(`${value}`);
        return { ...acc, [key]: valueCriteria ? valueCriteria : null };
      }
      return acc;
    },
    DEFAULT_OTHER_CRITERIAS
  );
};

export const queryObjectToFiltersState = (
  query: ArbitraryObject,
  locationState: LocationState[],
  filters: FiltersState = DEFAULT_FILTERS_STATE_MATCHING
): FiltersState => {
  const defaultState = { ...filters };
  const acceptedKeys = Object.keys(filters);

  Object.entries(query).forEach(([key, value]) => {
    if (
      key === 'propertyTypes' ||
      key === 'status' ||
      key === 'dealerTypes' ||
      (key === 'agentIds' && !locationState.length)
    ) {
      return (defaultState[key] = [value].flat());
    }

    if (key === 'locationIds') {
      return (defaultState['locationState'] = locationState);
    }

    if (key === 'publicationDateFrom' || key === 'publicationDateTo') {
      return (defaultState[key] = value
        ? +new Date(parseInt(value, 10))
        : null);
    }

    if (key === 'other') {
      return (defaultState['otherCriterias'] =
        getOtherCriteriasFromQuery(value));
    }

    if (key === 'isMap' || key === 'includeAttributed' || key === 'hasPhone') {
      return (defaultState[key] = JSON.parse(`${value}`));
    }

    if (acceptedKeys.includes(key)) {
      return Object.defineProperties(defaultState, {
        [key]: {
          value: {
            max: isNotEmpty(value.max) ? parseInt(value.max, 10) : null,
            min: isNotEmpty(value.min) ? parseInt(value.min, 10) : null
          }
        }
      });
    }
  });

  return defaultState;
};

export const convertQueryObjectToString = (
  queryObject: ArbitraryObject
): string =>
  qs.stringify(queryObject, {
    ...QS_ARRAY_PARAMS,
    skipNulls: true
  });

export const filtersStateToQueryString = (
  filtersState: FiltersStateWithLimitOffset
): string => {
  const { locationState, otherCriterias, limit, offset, ...rest } =
    filtersState;

  const locationIds = getLocationIds(locationState);

  return convertQueryObjectToString({
    ...rest,
    limit,
    locationIds,
    offset,
    other: otherCriterias
  });
};

export const convertAlertObjectToFiltersState = (
  onlineAlert: AlertByUser | undefined,
  filters = DEFAULT_FILTERS_STATE_MATCHING
): FiltersState => {
  if (!onlineAlert?.alertID) {
    return filters;
  }

  const {
    alert: {
      groundFloor,
      hasCellarOrAnnex,
      hasLift,
      hasOutdoorSpace,
      hasParking,
      hasPool,
      isMap,
      maxBedrooms,
      maxPrice,
      maxRooms,
      maxSurface,
      minBedrooms,
      minPrice,
      minRooms,
      minSurface,
      propertyTypes,
      withoutRenovationWork,
      zones
    }
  } = onlineAlert;

  return {
    ...filters,
    budget: { max: maxPrice || null, min: minPrice || null },
    isMap,
    locationState: zones,
    nbBedrooms: { max: maxBedrooms || null, min: minBedrooms || null },
    nbRooms: { max: maxRooms || null, min: minRooms || null },
    otherCriterias: {
      groundFloor: groundFloor ? groundFloor : null,
      hasCellarOrAnnex: hasCellarOrAnnex ? hasCellarOrAnnex : null,
      hasLift: hasLift ? hasLift : null,
      hasOutdoorSpace: hasOutdoorSpace ? hasOutdoorSpace : null,
      hasParking: hasParking ? hasParking : null,
      hasPool: hasPool ? hasPool : null,
      withoutRenovationWork: withoutRenovationWork
        ? withoutRenovationWork
        : null
    },
    propertyTypes: propertyTypes as PropertyEnum[],
    status: DEFAULT_STATUS,
    surface: { max: maxSurface || null, min: minSurface || null }
  };
};

export const getCriteriasNb = (
  criterias: Partial<OtherCriteriasProps>
): number => Object.values(criterias).filter(Boolean).length;

export const formatCriterias = (listing: ListingProps): OtherCriteriasProps => {
  const {
    balconySurfaceArea,
    floor,
    gardenSurfaceArea,
    hasCellar,
    hasElevator,
    hasFacilityRoom,
    hasLocker,
    hasParking,
    hasPool,
    quality
  } = listing;

  return {
    groundFloor: floor === 0,
    hasCellarOrAnnex: Boolean(hasFacilityRoom || hasCellar),
    hasLift: Boolean(hasElevator),
    hasOutdoorSpace: Boolean(balconySurfaceArea || gardenSurfaceArea),
    hasParking: Boolean(hasParking || hasLocker),
    hasPool,
    withoutRenovationWork:
      quality === ListingQuality.NEW || quality === ListingQuality.CORRECT
  };
};

export const groupInterestByType = (interests: Interest[]) =>
  interests.reduce(
    (acc: InterestByType, interest) => {
      const { value, interestType } = interest;
      if (value) {
        acc[interestType]['liked'].push(interest);
      } else if (!value && value !== null) {
        acc[interestType]['archived'].push(interest);
      }

      return acc;
    },
    {
      [InterestType.PROPRIOO]: { archived: [], liked: [] },
      [InterestType.YANPORT]: { archived: [], liked: [] }
    }
  );

export const getInterestById = (
  interests: Interest[]
): DictionaryNullOrBoolean =>
  interests.reduce((acc: DictionaryNullOrBoolean, { id, value }) => {
    acc[id] = value;
    return acc;
  }, {});

export const getDealerTypesValue = (
  soldByAgency: boolean,
  soldByOwner: boolean,
  soldByRepresentative: boolean
): DealerType[] => {
  const dealerTypes = [];

  if (soldByAgency) dealerTypes.push(DealerType.AGENCY);
  if (soldByOwner) dealerTypes.push(DealerType.OWNER);
  if (soldByRepresentative) dealerTypes.push(DealerType.MANDATARY);

  return dealerTypes;
};

export const convertListingsByCriteriasIntoProperty = (
  listings: ListingProps[]
): InternalProperty[] =>
  listings.map(listing => {
    const {
      groundFloor,
      hasCellarOrAnnex,
      hasLift,
      hasOutdoorSpace,
      hasParking,
      hasPool,
      withoutRenovationWork
    } = formatCriterias(listing);

    const {
      city,
      estimation: { agentId, estimationStage, street, streetNumber },
      id,
      interest,
      bedroomsCount,
      roomsCount,
      pictures,
      postCode,
      price,
      type,
      mandateInfo,
      firstPublication,
      surfaceArea,
      userId,
      uuid
    } = listing;

    const computedPictures = pictures.map(pic =>
      computePictureFromAssets(
        pic,
        IMAGE_WIDTH,
        IMAGE_RATIO,
        PICTURE_API_FULL_URL
      )
    );

    return {
      agentOwner: agentId,
      city,
      customerId: userId,
      estimationStage,
      groundFloor: Boolean(groundFloor),
      hasCellarOrAnnex: Boolean(hasCellarOrAnnex),
      hasLift: Boolean(hasLift),
      hasOutdoorSpace: Boolean(hasOutdoorSpace),
      hasParking: Boolean(hasParking),
      hasPool: Boolean(hasPool),
      id,
      isInterested: interest !== undefined ? interest : null,
      isInternal: true,
      mandateInfo,
      nbBedrooms: bedroomsCount || null,
      nbRooms: roomsCount || null,
      notificationDate: '',
      pictures: computedPictures,
      postCode,
      price,
      propertyType: type,
      publicationDate: firstPublication || null,
      street,
      streetNumber: streetNumber || null,
      surface: surfaceArea,
      uuid,
      withoutRenovationWork: Boolean(withoutRenovationWork)
    };
  });

export const getAllGeocodesFromLocationState = (
  locationState?: LocationState[]
): string[] | null => {
  if (!locationState?.length) {
    return null;
  }

  return locationState.reduce((acc: string[], { id }: LocationState) => {
    if (DISTRICT_GEO_CODES[id]) {
      DISTRICT_GEO_CODES[id].map(i => acc.push(i));
    } else if (COUNTY_GEO_CODES[id]) {
      COUNTY_GEO_CODES[id].map(i => acc.push(i));
    } else {
      acc.push(id);
    }

    return acc;
  }, []);
};

export const getDealerTypeTranslationKey = (type: DealerType): string => {
  switch (type) {
    case DealerType.AGENCY:
      return 'agency';
    case DealerType.MANDATARY:
      return 'mandatary';
    default:
      return 'individuals';
  }
};

export const getOnlyDefinedCriterias = (
  criterias: DictionaryBoolean
): DictionaryBoolean =>
  Object.fromEntries(
    Object.entries(criterias).filter(([_, value]) => Boolean(value))
  );

export const getMapLink = (
  routeUrl: string,
  params: string,
  userId: string
) => {
  if (userId) {
    return `/customer/${userId}/matching/map${params}`;
  }

  return `/${routeUrl}/map${params}`;
};

export const getMapBackLink = (routeUrl: string, userId: string) => {
  if (userId) {
    return `/customer/${userId}/${routeUrl}`;
  }

  return `/${routeUrl}`;
};

import styled from 'styled-components';

import { Layout } from '@/components/myCustomers/common/activityBullets/ActivityBullets.styles';
import { colors, convertPxToRem, radius } from '@proprioo/salatim';

export const IconLayout = styled(Layout)`
  right: ${convertPxToRem(-2)};
  top: ${convertPxToRem(3)};

  & > span {
    border-color: ${colors.green.base};
  }
`;

export const IconWrapper = styled.span`
  position: relative;
  display: inline-flex;
  border-radius: ${radius.big};
`;

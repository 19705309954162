import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useRecoilValue } from 'recoil';

import { leadViewSelector } from '@/components/state/LeadViewState';

import { SaleOpportunity } from '../../interfaces';
import { filterLeadOpportunities } from '../filters/LeadFilters.utils';
import MobileLeadCard from '../mobileLeadCard/MobileLeadCard';
import { Inner, Layout } from './MobileLeads.styles';

export type MobileLeadsProps = {
  isLoading: boolean;
  opportunities: SaleOpportunity[];
};

const MobileLeads: FC<MobileLeadsProps> = ({ isLoading, opportunities }) => {
  const { leadViewFilters } = useRecoilValue(leadViewSelector);

  return (
    <Layout>
      {isLoading ? (
        <Skeleton count={3} height={180} />
      ) : (
        <Inner>
          {filterLeadOpportunities(opportunities, leadViewFilters).map(
            opportunity => (
              <MobileLeadCard
                key={opportunity.estimationId}
                opportunity={opportunity}
              />
            )
          )}
        </Inner>
      )}
    </Layout>
  );
};

export default MobileLeads;

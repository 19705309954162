import styled from 'styled-components';

import { convertPxToRem } from '@proprioo/salatim';

import { AvatarIconWrapper, AvatarLayout } from '../Avatar.styles';

export const AvatarGroupLayout = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;

  ${AvatarLayout} + ${AvatarLayout} {
    margin-right: ${convertPxToRem(-10)};
  }

  ${AvatarIconWrapper}, img {
    border: ${convertPxToRem(2)} solid white;
  }
`;

import { useTranslation } from 'next-i18next';
import { Fragment } from 'react';

import { ENV_TYPE, TAG_CLOSE_ARIA_LABEL } from '@/constants/global';
import { EnvLabels } from '@/utils/interfaces';
import { Tag, TagAppearance, TagColorsTypes } from '@proprioo/salatim';

import { TagWrapper } from './EnvTag.styles';
import { getEnvTranslationKey } from './utils';

const envLabel = Object.values(EnvLabels).find(label => ENV_TYPE === label);
const isProductionEnv = envLabel === EnvLabels.PRODUCTION;

const EnvTag = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      {envLabel && !isProductionEnv && (
        <TagWrapper>
          <Tag
            appearance={TagAppearance.BUSINESS}
            closeAriaLabel={t(TAG_CLOSE_ARIA_LABEL)}
            color={
              EnvLabels.SANDBOX === envLabel
                ? TagColorsTypes.GREEN_LIGHT
                : TagColorsTypes.TERRACOTA
            }
            labels={[
              t(getEnvTranslationKey(envLabel), {
                id: process.env.NEXT_PUBLIC_SENTRY_TAG?.substring(0, 6)
              })
            ]}
          />
        </TagWrapper>
      )}
    </Fragment>
  );
};

export default EnvTag;

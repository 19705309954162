import { useMemo } from 'react';

import {
  LeadViewSection,
  LeadViewTab
} from '@/components/myCustomers/mySellers/interfaces';
import { getTabs } from '@/components/myCustomers/mySellers/LeadView.utils';

import { useUserSectionsPermissions } from '../useUserSectionsPermissions/useUserSectionsPermissions';
import { useUserSubsectionsPermissions } from '../useUserSubsectionsPermissions/useUserSubsectionsPermissions';

export const useMySellerFirstAvailableTab = (): LeadViewTab => {
  const userSectionsPermissions = useUserSectionsPermissions(LeadViewSection);
  const userSubsectionsPermissions = useUserSubsectionsPermissions(LeadViewTab);

  const [firstAvailableSection] = useMemo(
    () =>
      Object.values(LeadViewSection).filter(
        section => userSectionsPermissions[section]
      ),
    [userSectionsPermissions]
  );

  const [firstAvailableTab] = useMemo(
    () =>
      getTabs(firstAvailableSection).filter(
        tab => userSubsectionsPermissions[tab]
      ),
    [firstAvailableSection, userSubsectionsPermissions]
  );

  return firstAvailableTab;
};

import { atom, selector } from 'recoil';

import { AuthProps } from '../interfaces';

export const authAtom = atom<AuthProps>({
  default: { isConnected: false },
  key: 'authAtom'
});

export const authSelector = selector<AuthProps>({
  get: ({ get }) => get(authAtom),
  key: 'authSelector',
  set: ({ set }, newValues) => set(authAtom, newValues)
});

export type LeadViewFilters = {
  isHighPriorityLead: boolean;
  hasListingWithoutVisit: boolean;
  hasPausedListing: boolean;
  isNearingExclusivityEnd: boolean;
  hasOldPrice: boolean;
  hasSignatureToSchedule: boolean;
  hasLateSignature: boolean;
};

export type LeadViewFiltersCount = {
  highPriorityLeadsCount: number;
  listingsWithoutVisitCount: number;
  pausedListingsCount: number;
  nearingExclusivityEndCount: number;
  oldPricesCount: number;
  signaturesToScheduleCount: number;
  lateSignaturesCount: number;
};

export enum LeadViewFilterType {
  IS_HIGH_PRIORITY_LEAD = 'isHighPriorityLead',
  HAS_LISTING_WITHOUT_VISIT = 'hasListingWithoutVisit',
  HAS_PAUSED_LISTING = 'hasPausedListing',
  IS_NEARING_EXCLUSIVITY_END = 'isNearingExclusivityEnd',
  HAS_OLD_PRICE = 'hasOldPrice',
  HAS_SIGNATURE_TO_SCHEDULE = 'hasSignatureToSchedule',
  HAS_LATE_SIGNATURE = 'hasLateSignature'
}

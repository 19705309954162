import styled, { css } from 'styled-components';

import { breakpoints, colors, convertPxToRem, radius } from '@proprioo/salatim';

export const CLOSED_WIDTH = convertPxToRem(64);

export const LayoutSidebar = styled.aside<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  background-color: ${colors.green.base5};
  color: ${colors.dark.base};
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  overflow-y: auto;

  @supports (scrollbar-gutter: stable) {
    scrollbar-gutter: stable;
  }

  @media (min-width: ${breakpoints.small}px) {
    transition: width 0.2s ease-in;
    width: ${convertPxToRem(300)};
  }

  @media (min-width: ${breakpoints.normal}px) {
    width: ${convertPxToRem(360)};
  }

  ${({ isOpen }) =>
    !isOpen &&
    css`
      display: flex;
      align-items: center;
      overflow: hidden;
      width: 100%;
      height: ${convertPxToRem(64)};

      ${LayoutContactButton} {
        right: 0;
      }

      @media (min-width: ${breakpoints.small}px) {
        display: block;
        height: auto;
        width: ${CLOSED_WIDTH};
      }

      ${ContentSidebar} {
        opacity: 0;
        transition: opacity 0s ease-in;
      }
    `}
`;

export const LayoutContactButton = styled.div`
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${convertPxToRem(16)};

  @media (min-width: ${breakpoints.small}px) {
    right: ${convertPxToRem(16)};
  }
`;

export const ContentSidebar = styled.div`
  position: relative;
  z-index: 0;
  height: 100%;
  transition: opacity 0.2s ease-out 0.2s;
  padding: ${convertPxToRem(18)} ${convertPxToRem(32)};

  @media (min-width: ${breakpoints.small}px) {
    width: 100%;
    margin: 0;
  }
`;

export const ContentSidebarWrapper = styled.div`
  padding-bottom: ${convertPxToRem(24)};
`;

export const OpenButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.terracota.base};
  cursor: pointer;
  border-radius: ${radius.rounded};
  transform: rotate(90deg);
  width: ${convertPxToRem(32)};
  height: ${convertPxToRem(32)};
  margin-left: auto;

  svg {
    width: 100%;
    height: 100%;
  }

  @media (min-width: ${breakpoints.small}px) {
    transform: rotate(0);
  }
`;

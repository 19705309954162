import { UserRole } from '@/components/state/interfaces';
import { hasRole } from '@/components/state/utils';

import { useAuth } from './useAuth';

export const useIsFrenchAndAdminRole = (): boolean => {
  const { user } = useAuth();

  return (
    hasRole(UserRole.APP_SALES_ADMIN, user) ||
    hasRole(UserRole.SALES, user) ||
    hasRole(UserRole.SELLER_SUCCESS, user) ||
    hasRole(UserRole.TEAM_LEADS, user) ||
    hasRole(UserRole.TECH, user)
  );
};

import { useTranslation } from 'next-i18next';

import {
  LeadViewSection,
  SaleOpportunity
} from '@/components/myCustomers/mySellers/interfaces';
import { formatCompactPrice } from '@/utils/price';

import { useLanguage } from '../useLanguage/useLanguage';
import { sumRevenues } from './utils';

export const useRevenues = (
  currentSection: LeadViewSection,
  opportunities: SaleOpportunity[]
): string => {
  const { t } = useTranslation();
  const { currentLocale } = useLanguage();

  return t('revenuesLabel', {
    revenues: formatCompactPrice(sumRevenues(opportunities), currentLocale),
    symbol: LeadViewSection.PROSPECTS === currentSection ? t('tilde') : ''
  });
};

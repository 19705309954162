import { Children, FC, Fragment, PropsWithChildren } from 'react';

import { AvatarGroupLayout } from './AvatarGroup.styles';

const AvatarGroup: FC<PropsWithChildren> = ({ children }) => {
  const avatars = Children.toArray(children);

  return avatars.length < 2 ? (
    <Fragment>{children}</Fragment>
  ) : (
    <AvatarGroupLayout data-test="avatar-group">
      {avatars.reverse()}
    </AvatarGroupLayout>
  );
};

export default AvatarGroup;

import { parseJSON } from 'date-fns/parseJSON';
import { useEffect, useRef } from 'react';

import { QueryParam } from '@/components/myCustomers/mySellers/interfaces';
import { normalizeString, nullOrString, OptionProps } from '@proprioo/hokkaido';

import { ArbitraryObject, EnvLabels, numberOrString } from './interfaces';
import { logError } from './log';
import { getOffset } from './offset';

const reNumber = /^-?\d+((,|\.)\d+)?$/;
const reFloat = /^-?\d+(,|\.)\d+$/;

export enum QsArrayFormat {
  COMMA = 'comma',
  INDICES = 'indices',
  BRACKETS = 'brackets',
  REPEAT = 'repeat'
}

export const QS_ARRAY_PARAMS = {
  addQueryPrefix: true,
  arrayFormat: QsArrayFormat.COMMA,
  encode: false
};

export const FETCHER_HEADERS = { 'x-api-key': `${process.env.DATA_URL_TOKEN}` };

export const flushPromises = async (timer = 0) =>
  new Promise(resolve => {
    setTimeout(resolve, timer);
  }).catch(error => console.log(error));

export const isNumber = (value?: numberOrString | null): boolean =>
  value !== null &&
  value !== undefined &&
  (typeof value === 'number' || reNumber.test(value));

export const isDefined = (value?: numberOrString | null): boolean =>
  value !== null && value !== undefined;

export const toNumber = (value?: string): number | undefined => {
  if (isNumber(value)) {
    return reFloat.test(value!)
      ? parseFloat(value!.replace(',', '.'))
      : parseInt(value!, 10);
  }
  return undefined;
};

export const pickOptionFromValue = (
  options: OptionProps[],
  value?: numberOrString
): OptionProps | null =>
  options.find(({ value: optionValue }) => {
    const formattedValue = isNumber(value) ? value?.toString() : value;
    return formattedValue === optionValue;
  }) || null;

export function usePrevious<T>(value: T) {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, []);

  return ref.current;
}

export const parseGMTDate = (date: string): string =>
  new Date(parseJSON(date).getTime()).toISOString();

export const getExpirationTime = (minuts: number) =>
  Math.round(Date.now() / 1000) + minuts * 60;

export const getFileSize = (sizeInMo: number) => sizeInMo * 1024 * 1024;

export const getEnvLabel = (env: string) => {
  switch (env) {
    case EnvLabels.STAGING:
      return 'preprod';
    case EnvLabels.LOCAL:
    case EnvLabels.TEST:
      return 'dev';
    case EnvLabels.SANDBOX:
      return 'demo';
    default:
      return 'prod';
  }
};

export const queryParamToArray = (query: QueryParam): string[] =>
  query?.length ? `${query}`.split(',') : [];

export const getLocationIdsFromQuery = (query: ArbitraryObject): string[] =>
  queryParamToArray(query.locationIds);

export const scrollToBlock = (node: HTMLDivElement, timer: number) => {
  const handler = setTimeout(() => {
    const offset = getOffset({ margin: 70 });
    document
      .querySelector('#content-overflow')
      ?.scrollTo(0, node.offsetTop - offset);
    clearTimeout(handler);
  }, timer);
};

export const parseStringifiedArrayFromBQ = <T>(
  stringifiedArray?: nullOrString
): T | never[] => {
  if (stringifiedArray) {
    try {
      return JSON.parse(
        stringifiedArray
          .replace(/(\\")|(?<!\\)"(?=([a-zA-Z]|,|}))/g, 'toBeReplaced')
          .replace(/"/g, '')
          .replace(/toBeReplaced/g, '"')
          .replace(/(?<=:\s")(.*?)'(.*?)(?="(,\s'|}]))/g, matching =>
            matching.replace(/'/g, 'toBeReplaced')
          )
          .replace(/".*?".*?'/g, matching =>
            !matching.includes(", '") && !matching.includes("'}")
              ? matching.replace(/"/g, '')
              : matching
          )
          .replace(/'/g, '"')
          .replace(/toBeReplaced/g, "'")
          .replace(/False/g, 'false')
          .replace(/True/g, 'true')
      );
    } catch (error) {
      logError('Failed to parse stringified array from BiqQuery', {
        error,
        stringifiedArray
      });
      return [];
    }
  }

  return [];
};

export const toKebabCase = (value: string): string =>
  normalizeString(value).replace(/\s|'/g, '-');

import { useRecoilValue } from 'recoil';

import { countrySelector } from '@/components/state/CountryState';
import { Country } from '@proprioo/hokkaido';

import { useCurrentAgent } from '../agents/useCurrentAgent';

export const useCurrentCountry = (): Country => {
  const currentAgent = useCurrentAgent();
  const customizedCountry = useRecoilValue(countrySelector);

  return customizedCountry || currentAgent?.country || Country.FRANCE;
};

import { useMemo } from 'react';

import {
  LeadViewSection,
  LeadViewTab
} from '@/components/myCustomers/mySellers/interfaces';
import { getTabs } from '@/components/myCustomers/mySellers/LeadView.utils';

import { useUserSubsectionsPermissions } from '../useUserSubsectionsPermissions/useUserSubsectionsPermissions';

export const useMySellersCurrentSectionTabs = (
  currentSection: LeadViewSection
) => {
  const userSubsectionsPermissions = useUserSubsectionsPermissions(LeadViewTab);

  return useMemo(
    () =>
      getTabs(currentSection).filter(tab => userSubsectionsPermissions[tab]),
    [currentSection, userSubsectionsPermissions]
  );
};

import { UserRole } from '@/components/state/interfaces';
import { hasRole } from '@/components/state/utils';

import { useAuth } from './useAuth';

export const useIsTechRole = (): boolean => {
  const { user } = useAuth();

  return hasRole(UserRole.TECH, user);
};

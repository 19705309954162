import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { useMobileDevice } from '@proprioo/salatim';

import { ToastStatus } from './interfaces';
import { IconWrapper, TextWrapper, ToastWrapper } from './Toast.styles';
import { getToastIcon } from './utils';

type ToastProps = {
  status: ToastStatus;
  text: string;
};

const Toast: FC<ToastProps> = ({ status, text }) => {
  const { t } = useTranslation();

  const isMobile = useMobileDevice();
  const toastIcon = getToastIcon(status);

  return (
    <ToastWrapper data-test="toast-wrapper" status={status} isMobile={isMobile}>
      <IconWrapper data-test="toast-icon-wrapper">{toastIcon}</IconWrapper>
      <TextWrapper data-test="toast-text-wrapper">{t(text)}</TextWrapper>
    </ToastWrapper>
  );
};

export default Toast;

import { PAGE_VIEW_TITLE } from './gtm';

export const generateButtonId = (title: PAGE_VIEW_TITLE, label: string) =>
  `${title}_button_${label}`;

export const generateProjectId = (title: PAGE_VIEW_TITLE, label: string) =>
  `${title}_project_${label}`;

export const generateCustomerId = (title: PAGE_VIEW_TITLE, label: string) =>
  `${title}_customer_${label}`;

import { FC } from 'react';

import ActivityIndicator from '@/components/myCustomers/common/activityIndicator/ActivityIndicator';
import CustomerName from '@/components/myCustomers/common/customerName/CustomerName';
import {
  ActivityCellWrapper,
  ActivityIndicatorWrapper
} from '@/components/myCustomers/common/styles/Table.styles';
import { CustomerNameInfo } from '@/components/myCustomers/myCustomers.interfaces';

export type CustomerCellProps = Partial<CustomerNameInfo> & {
  targetDate?: string;
};

const CustomerCell: FC<CustomerCellProps> = ({
  firstName,
  lastName,
  targetDate
}) => (
  <ActivityCellWrapper data-test="activity-cell-wrapper">
    <ActivityIndicatorWrapper data-test="activity-indicator-wrapper">
      <ActivityIndicator targetDate={targetDate} />
    </ActivityIndicatorWrapper>
    <CustomerName
      firstName={firstName}
      isImportant={false}
      lastName={lastName}
    />
  </ActivityCellWrapper>
);

export default CustomerCell;

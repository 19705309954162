import { transparentize } from 'polished';
import styled from 'styled-components';

import { convertPxToRem, shadows } from '@proprioo/salatim';

export const TabsLayout = styled.div`
  overflow-x: auto;
  background-color: white;
  box-shadow: ${shadows.level1};
`;

export const TabsInner = styled.div<{ color: string }>`
  display: flex;
  border-bottom: ${convertPxToRem(3)} solid
    ${({ color }) => transparentize(0.85, color)};
`;

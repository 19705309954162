import { FunctionComponent } from 'react';

import {
  LocationState,
  MinMax
} from '@/components/buyerQualification/interfaces';
import { PAGE_VIEW_TITLE } from '@/utils/gtm';
import {
  EstimationStage,
  nullOrBoolean,
  nullOrNumber,
  nullOrString,
  PropertyEnum
} from '@proprioo/hokkaido';

import { MandateInfo } from '../listing/interfaces';

export enum DealerType {
  AGENCY = 'agency',
  MANDATARY = 'mandatary',
  OWNER = 'owner'
}

export enum InterestType {
  PROPRIOO = 'proprioo',
  YANPORT = 'yanport'
}

export enum RangeAttributes {
  BUDGET = 'budget',
  NB_BEDROOMS = 'nbBedrooms',
  NB_ROOMS = 'nbRooms',
  SURFACE = 'surface'
}

export type MatchingCard<T> = {
  pageViewTitle: PAGE_VIEW_TITLE;
  listing: T;
  placeholder: string;
};

export type BuyerMatching<T> = {
  apiUrl: string;
  card: FunctionComponent<MatchingCard<T>>;
  initialFilters: FiltersState;
  max: number;
  pageViewTitle: PAGE_VIEW_TITLE;
  placeholder: string;
  routeUrl: string;
  type: InterestType;
};

type InterestByValue = {
  liked: Interest[];
  archived: Interest[];
};

export type ConfigurationMatching<T> = Record<InterestType, BuyerMatching<T>>;
export type InterestByType = Record<InterestType, InterestByValue>;

export type FiltersState = {
  agentIds: number[];
  budget: MinMax;
  dealerTypes: DealerType[];
  hasPhone: boolean;
  includeAttributed: boolean;
  isMap: boolean;
  locationState: LocationState[];
  nbBedrooms: MinMax;
  nbRooms: MinMax;
  otherCriterias: OtherCriteriasProps;
  propertyTypes: PropertyEnum[];
  publicationDateFrom: nullOrNumber;
  publicationDateTo: nullOrNumber;
  status: EstimationStage[];
  surface: MinMax;
};

export type FiltersStateFromQuery = Omit<FiltersState, 'locationState'> & {
  locationIds: string[];
};

export type FiltersStateWithLimitOffset = FiltersState & {
  limit?: number;
  offset?: number;
};

export type Interest = {
  id: string;
  interestType: InterestType;
  userId: string;
  value: nullOrBoolean;
};

export type InternalProperty = {
  agentOwner: number;
  city: string;
  customerId: string;
  estimationStage: EstimationStage;
  groundFloor: boolean;
  hasCellarOrAnnex: boolean;
  hasLift: boolean;
  hasOutdoorSpace: boolean;
  hasParking: boolean;
  hasPool: boolean;
  id: number;
  isInterested: nullOrBoolean;
  isInternal: boolean;
  mandateInfo: MandateInfo | null;
  nbBedrooms: nullOrNumber;
  nbRooms: nullOrNumber;
  notificationDate: string;
  pictures: string[];
  postCode: string;
  price: nullOrNumber;
  propertyType: PropertyEnum;
  publicationDate: nullOrString;
  street: string;
  streetNumber: nullOrString;
  surface: number;
  uuid: string;
  withoutRenovationWork: boolean;
};

export type ListingEstimation = {
  agentOwner: number;
  estimationStage: EstimationStage;
  street: string;
  streetNumber: number;
};

export type ListingPicture = {
  dir: string;
  name: string;
};

export type OtherCriteriasProps = {
  groundFloor: nullOrBoolean;
  hasCellarOrAnnex: nullOrBoolean;
  hasLift: nullOrBoolean;
  hasParking: nullOrBoolean;
  hasPool: nullOrBoolean;
  hasOutdoorSpace: nullOrBoolean;
  withoutRenovationWork: nullOrBoolean;
};

import { FC } from 'react';

import LeadTag from '@/components/app/leadTag/LeadTag';
import { MultiTagsWrapper } from '@/components/myCustomers/common/styles/MyCustomers.styles';
import StageTag from '@/components/myCustomers/mySellers/leads/common/stageTag/StageTag';

import { LeadViewTableCellProps } from '../interface';

const StatusCell: FC<LeadViewTableCellProps> = ({ opportunity }) => {
  const { estimationStage, lastAppointmentStatus, leadSource, lastStage } =
    opportunity;

  return (
    <MultiTagsWrapper>
      <LeadTag source={leadSource} />
      <StageTag
        estimationStage={lastStage || estimationStage}
        lastAppointmentStatus={lastAppointmentStatus}
      />
    </MultiTagsWrapper>
  );
};

export default StatusCell;

import { useCurrentAgent } from '../agents/useCurrentAgent';
import { useAgentsByAgentId } from './useAgentsByTeamLeadId';

export const useAgentIdsFromTeamLead = () => {
  const currentAgent = useCurrentAgent();
  const agents = useAgentsByAgentId(currentAgent?.id);

  if (!currentAgent?.id) return [];

  const agentIds = agents.map(({ id }) => id);

  return [...new Set([currentAgent.id, ...agentIds])].sort((a, b) => a - b);
};

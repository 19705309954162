import { parseJSON } from 'date-fns/parseJSON';

import {
  ActivitiesOffer,
  BuyerOffer,
  CleanedBuyerOffer,
  OfferStatus
} from '@/components/buyer/interfaces';
import { CleanedOffer } from '@/components/visit/interfaces';

export type GeneralOffer =
  | CleanedOffer
  | BuyerOffer
  | CleanedBuyerOffer
  | ActivitiesOffer;

const CANCELED_STATUS = [
  OfferStatus.WITHDRAWN,
  OfferStatus.DELETED,
  OfferStatus.CANCELED
];

export const compareOfferDates = (
  first: GeneralOffer,
  second: GeneralOffer,
  isChronological = true
) => {
  const firstOfferTime = parseJSON(
    first.modifiedDate || first.createdAt
  ).getTime();
  const secondOfferTime = parseJSON(
    second.modifiedDate || second.createdAt
  ).getTime();

  return isChronological
    ? firstOfferTime - secondOfferTime
    : secondOfferTime - firstOfferTime;
};

export const compareOfferDatesAndStatus = (
  first: GeneralOffer,
  second: GeneralOffer,
  isChronological = true
) => {
  const firstStatusCanceled = Boolean(
    first.status && CANCELED_STATUS.includes(first.status)
  );
  const secondStatusCanceled = Boolean(
    second.status && CANCELED_STATUS.includes(second.status)
  );

  if (firstStatusCanceled && secondStatusCanceled) {
    return compareOfferDates(first, second, isChronological);
  }

  if (firstStatusCanceled && !secondStatusCanceled) {
    return -1;
  }

  if (!firstStatusCanceled && secondStatusCanceled) {
    return 1;
  }

  return compareOfferDates(first, second, isChronological);
};

import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';

import AlertCard from '@/components/app/alertCard/AlertCard';
import { AlertCardStatus } from '@/components/app/alertCard/AlertCard.utils';
import MainLayout from '@/components/app/layout/MainLayout';
import PageViewTracker from '@/components/app/pageViewTracker/PageViewTracker';
import AgentsSquadsSelector from '@/components/myCustomers/mySellers/agentsSquadsSelector/AgentsSquadsSelector';
import { LeadViewTab } from '@/components/myCustomers/mySellers/interfaces';
import LeadView from '@/components/myCustomers/mySellers/LeadView';
import {
  formatUrlQuery,
  getLeadViewNavigation,
  getSection
} from '@/components/myCustomers/mySellers/LeadView.utils';
import TeamLeadView from '@/components/myCustomers/mySellers/TeamLeadView';
import { leadViewSelector } from '@/components/state/LeadViewState';
import { useCurrentAgent } from '@/hooks/agents/useCurrentAgent';
import { useIsTeamLeadRole } from '@/hooks/auth/useIsTeamLeadRole';
import { useAgentIdsFromTeamLead } from '@/hooks/squads/useAgentIdsFromTeamLead';
import { useMySellersCurrentSectionTabs } from '@/hooks/useMySellersCurrentSectionTabs/useMySellersCurrentSectionTabs';
import { useMySellerFirstAvailableTab } from '@/hooks/useMySellersFirstAvailableTab/useMySellersFirstAvailableTab';
import { PAGE_VIEW_TITLE } from '@/utils/gtm';
import { getLocalesFromStaticProps } from '@/utils/locales';
import { colors } from '@proprioo/salatim';

const MySellersPage = () => {
  const { t } = useTranslation();
  const { query, push, replace } = useRouter();

  const [leadViewState, setLeadViewState] = useRecoilState(leadViewSelector);
  const resetLeadViewState = useResetRecoilState(leadViewSelector);

  const currentAgent = useCurrentAgent();
  const agentIds = useAgentIdsFromTeamLead();
  const isTeamLead = useIsTeamLeadRole();
  const firstAvailableTab = useMySellerFirstAvailableTab();

  const { page, tab, agentOwners, filter } = query;
  const { currentTab, currentPage, currentAgentIds } = getLeadViewNavigation(
    tab,
    page,
    agentOwners
  );

  const currentSection = useMemo(() => getSection(currentTab), [currentTab]);
  const currentSectionTabs = useMySellersCurrentSectionTabs(currentSection);

  useEffect(() => {
    (async () => {
      if (currentAgent?.id) {
        if (!tab) {
          if (isTeamLead) {
            const defaultQueryString = formatUrlQuery({
              agentOwners: agentIds,
              filter,
              page: currentPage,
              tab: firstAvailableTab
            });

            return await push(`/my-sellers${defaultQueryString}`, undefined, {
              shallow: true
            });
          } else {
            return await push(
              `/my-sellers?tab=${firstAvailableTab}`,
              undefined,
              {
                shallow: true
              }
            );
          }
        }

        if (!currentSectionTabs.includes(tab as LeadViewTab)) {
          return await push(`/my-sellers?tab=${firstAvailableTab}`, undefined, {
            shallow: true
          });
        }
      }
    })();
  }, [currentAgent?.id, currentSectionTabs, isTeamLead, tab]);

  useEffect(() => {
    if (isTeamLead) {
      setLeadViewState({ ...leadViewState, section: currentSection });
    }

    return () => resetLeadViewState();
  }, [currentSection, isTeamLead]);

  const setAgentIds = (newAgentIds: number[]) => {
    if (newAgentIds !== currentAgentIds) {
      const defaultQueryString = formatUrlQuery({
        agentOwners: newAgentIds,
        filter,
        page: currentPage,
        tab: currentTab
      });

      replace(`/my-sellers${defaultQueryString}`, undefined, { shallow: true });
    }
  };

  return (
    <MainLayout
      backgroundColor={colors.grey.base5}
      showSidebar={false}
      title="leads"
      {...(isTeamLead && {
        topBarItems: (
          <AgentsSquadsSelector
            agentIds={currentAgentIds}
            setAgentIds={setAgentIds}
          />
        )
      })}
    >
      <PageViewTracker title={PAGE_VIEW_TITLE.LEADS} />
      {!currentAgent?.id ? (
        <AlertCard
          status={AlertCardStatus.WARNING}
          text={t('mySellersPageAccessWarning')}
        />
      ) : isTeamLead ? (
        <TeamLeadView
          currentAgentIds={currentAgentIds}
          currentPage={currentPage}
          currentTab={currentTab}
        />
      ) : (
        <LeadView currentPage={currentPage} currentTab={currentTab} />
      )}
    </MainLayout>
  );
};

export const getStaticProps = getLocalesFromStaticProps;

export default MySellersPage;

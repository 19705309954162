import styled from 'styled-components';

import { colors, convertPxToRem } from '@proprioo/salatim';

export const Tr = styled.tr`
  transition: background 0.2s ease-out;

  :hover {
    background-color: ${colors.grey.base10};
  }

  :not(:last-child) td {
    border-bottom: ${convertPxToRem(1)} solid ${colors.grey.base40};
  }
`;

import { differenceInDays } from 'date-fns/differenceInDays';

import { BuyerScore } from '@/components/buyer/interfaces';
import { PropertyEnum } from '@proprioo/hokkaido';
import { TagColorsTypes } from '@proprioo/salatim';

export enum Recentness {
  RECENT = 'recent',
  OLD = 'old',
  OLDER = 'older'
}

export const getScoreTagColor = (score: BuyerScore): TagColorsTypes => {
  switch (score) {
    case BuyerScore.A_PLUS:
      return TagColorsTypes.GREEN_LIGHT;
    case BuyerScore.A:
      return TagColorsTypes.GREEN_LIGHT;
    case BuyerScore.B:
      return TagColorsTypes.GREEN_LIGHT;
    case BuyerScore.C:
      return TagColorsTypes.GREY_LIGHT;
    default:
      return TagColorsTypes.RED_LIGHT;
  }
};

export const getRecentness = (date: string | Date) => {
  const month = 30;
  const twoWeeks = 15;
  const difference = differenceInDays(new Date(), new Date(date));
  return difference < month
    ? difference < twoWeeks
      ? Recentness.RECENT
      : Recentness.OLD
    : Recentness.OLDER;
};

export const getPropertyTypeLabel = (propertyType: PropertyEnum) => {
  switch (propertyType) {
    case PropertyEnum.ATTIC:
      return 'attic';
    case PropertyEnum.BUILDING:
      return 'building';
    case PropertyEnum.DUPLEX:
      return 'duplex';
    case PropertyEnum.FLAT:
      return 'flat';
    case PropertyEnum.HOUSE:
      return 'house';
    case PropertyEnum.LOFT:
      return 'loft';
    case PropertyEnum.MANSARD:
      return 'mansard';
    case PropertyEnum.PARKING:
      return 'parking';
    case PropertyEnum.RUSTIC_HOUSE:
      return 'rusticHouse';
    case PropertyEnum.TOWN_HOUSE:
      return 'townHouse';
    case PropertyEnum.VILLA:
      return 'villa';
  }
};

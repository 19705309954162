import { isBefore } from 'date-fns/isBefore';
import { isSameDay } from 'date-fns/isSameDay';
import { parseISO } from 'date-fns/parseISO';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import {
  ActivityIndicatorWrapper,
  IconWrapper
} from './ActivityIndicator.styles';
import { getActivityIndicatorParams } from './ActivityIndicator.utils';

export type ActivityIndicatorProps = {
  targetDate?: string;
};

const ActivityIndicator: FC<ActivityIndicatorProps> = ({ targetDate }) => {
  const { t } = useTranslation();

  const now = new Date();
  const isToday = !!targetDate && isSameDay(parseISO(targetDate), now);
  const isLate = !!targetDate && isBefore(parseISO(targetDate), now);
  const isComing = !!targetDate && !isToday && !isLate;

  const { color, icon, label } = getActivityIndicatorParams(
    isToday,
    isLate,
    isComing
  );

  return (
    <ActivityIndicatorWrapper aria-label={t(label)}>
      <IconWrapper color={color}>{icon}</IconWrapper>
    </ActivityIndicatorWrapper>
  );
};

export default ActivityIndicator;

import { flexRender, HeaderGroup } from '@tanstack/react-table';

import SortToggle from '../sortToggle/SortToggle';
import { Tr } from '../tableRow/TableRow.styles';
import { TableHeadCell, TableHeadInner } from './TableHeader.styles';

export type TableHeaderProps<T> = {
  headerGroups: HeaderGroup<T>[];
};

const TableHeader = <T,>({ headerGroups }: TableHeaderProps<T>) => (
  <thead>
    {headerGroups.map((headerGroup, index) => (
      <Tr key={`${headerGroup.id}-${index}`} data-test="table-head-row">
        {headerGroup.headers.map(({ getContext, id, column }, key) => {
          const {
            getCanSort,
            columnDef,
            getToggleSortingHandler,
            getIsSorted
          } = column;

          return (
            <TableHeadCell key={`${id}-${key}`} data-test={`${id}`}>
              <TableHeadInner>
                <span>{flexRender(columnDef.header, getContext())}</span>
                {getCanSort() && (
                  <SortToggle
                    sortedState={getIsSorted()}
                    onClick={getToggleSortingHandler()}
                  />
                )}
              </TableHeadInner>
            </TableHeadCell>
          );
        })}
      </Tr>
    ))}
  </thead>
);

export default TableHeader;

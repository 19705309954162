import { isAfter } from 'date-fns/isAfter';

import {
  AppointmentStatus,
  ExtendedProject,
  MeetingProps,
  OpportunityOrigin
} from '@/components/qualification/sellerQualification/interfaces';
import { IDFSquads } from '@/constants/global';
import { DatabaseStatus } from '@/utils/interfaces';
import {
  FinancingMethod,
  Language,
  MandateType,
  PropertyEnum,
  nullOrString
} from '@proprioo/hokkaido';

import {
  AmendmentProps,
  ContractStatus,
  ContractType,
  MandateProps,
  OfferProps
} from '../listing/contract/Contract.interfaces';
import {
  Company,
  CompanyLegalForm,
  Entity,
  EntityCategory
} from '../listing/contract/interfaces';
import {
  Agent,
  AuthUserProps,
  CustomerInformation,
  SquadRole,
  Squads,
  UserInformations,
  UserRole
} from './interfaces';

export const EMPTY_USER: CustomerInformation = {
  about: null,
  customerProfile: null,
  email: '',
  firstName: '',
  id: '',
  isAgent: false,
  language: Language.FRENCH,
  lastName: '',
  modifiedBy: null,
  phone: '',
  promotionalConsent: false
};

export const EMPTY_ENTITY: Entity = {
  category: EntityCategory.PERSON,
  companyId: null,
  createdBy: 'tech@proprioo.fr',
  hasDashboardAccess: true,
  id: '',
  modifiedBy: null,
  projectId: '',
  status: DatabaseStatus.ACTIVATED,
  userId: null,
  willBeNotified: false
};

export const EMPTY_PROJECT: ExtendedProject = {
  address: '',
  agent: null,
  city: '',
  creationOrigin: OpportunityOrigin.BACKOFFICE,
  declaredAsBuyer: null,
  hasLeadProvider: false,
  landArea: null,
  leadComments: '',
  leadProvider: null,
  leadSource: null,
  livingArea: null,
  location: { lat: 0, lng: 0 },
  postalCode: '',
  reallocateActivities: false,
  street: '',
  streetNumber: '',
  transactionId: null,
  treatmentPriority: null,
  type: PropertyEnum.FLAT,
  updateViewingAgent: true
};

export const EMPTY_COMPANY: Company = {
  createdBy: 'tech@proprioo.fr',
  cs: null,
  denomination: '',
  endDate: null,
  id: '',
  legalForm: CompanyLegalForm.SCI,
  modifiedBy: null,
  rcs: null,
  status: DatabaseStatus.ACTIVATED,
  users: []
};

export const EMPTY_CUSTOMER: UserInformations = {
  alerts: [],
  contracts: [],
  listingRequests: [],
  meetings: [],
  offers: [],
  opportunities: [],
  researchMandates: [],
  user: EMPTY_USER,
  valuations: [],
  visits: []
};

export const EMPTY_MANDATE: MandateProps = {
  agentSignatureLink: null,
  contractTerms: [],
  contractTermsCustom: [],
  createdDate: '',
  description: '',
  documentType: ContractType.FULL,
  exclusivityExpirationDate: null,
  id: '',
  mandateType: MandateType.SIMPLE,
  modifiedDate: '',
  price: 0,
  rentingInfo: '',
  sellerFeePercentage: 0,
  signatories: [],
  signatureType: null,
  status: ContractStatus.GENERATED,
  uploadedFile: false,
  validityDate: null
};

export const EMPTY_AMENDMENT: AmendmentProps = {
  agentSignatureLink: null,
  createdDate: '',
  description: '',
  documentType: ContractType.AMENDMENT,
  id: '',
  modifiedDate: '',
  price: 0,
  sellerFeePercentage: 0,
  signatories: [],
  signatureType: null,
  status: ContractStatus.GENERATED,
  uploadedFile: false
};

export const EMPTY_OFFER: OfferProps = {
  agentSignatureLink: null,
  createdDate: '',
  creator: 'tech@proprioo.fr',
  documentType: ContractType.OFFER,
  id: '',
  listingId: 0,
  loanAmount: 0,
  loanNeeded: FinancingMethod.NO_NEED,
  modifiedDate: '',
  price: 0,
  sellerFeePercentage: 0,
  signatories: [],
  signatureType: null,
  status: ContractStatus.GENERATED,
  uploadedFile: false,
  validityDate: null,
  viewingUuid: ''
};

export const getLastMeeting = (id: number, meetings: MeetingProps[]) =>
  [...meetings]
    .filter(
      ({ status, estimationId }) =>
        status !== AppointmentStatus.DELETED && estimationId === id
    )
    .sort((a, b) =>
      isAfter(new Date(a.createdAt), new Date(b.createdAt)) ? -1 : 1
    );

export const hasAllPrivileges = (
  agentOwner: nullOrString,
  user?: AuthUserProps,
  agentId?: number
): boolean => {
  const isAdminOrTeamLead =
    hasRole(UserRole.APP_SALES_ADMIN, user) ||
    hasRole(UserRole.MOPS, user) ||
    hasRole(UserRole.TECH, user) ||
    hasRole(UserRole.TEAM_LEADS, user);

  if (isAdminOrTeamLead || (agentOwner && agentOwner === '0')) {
    return true;
  }

  if (agentOwner && parseInt(agentOwner, 10) !== agentId) {
    return false;
  }

  return true;
};

export const hasFullAccessToProjectInfo = (
  user?: AuthUserProps,
  currentAgentId?: number,
  agentOwnerId?: number,
  viewingAgentId?: number
): boolean => {
  if (!agentOwnerId && !viewingAgentId) {
    return true;
  }

  return (
    Boolean(
      agentOwnerId &&
        hasAllPrivileges(agentOwnerId.toString(), user, currentAgentId)
    ) ||
    Boolean(
      viewingAgentId &&
        hasAllPrivileges(viewingAgentId.toString(), user, currentAgentId)
    )
  );
};

export const hasRole = (role: UserRole, user?: AuthUserProps) => {
  if (!user) return false;

  const roles = user?.roles;

  return roles?.some(value => value === role);
};

export const hasOriginalRole = (role: UserRole, user?: AuthUserProps) => {
  if (!user) return false;

  const roles = user?.original?.roles || user?.roles || [];

  return roles?.some(value => value === role);
};

export const getSquads = (agents: Agent[]): Squads =>
  agents.reduce((squads: Squads, agent) => {
    const { squads: currentAgentSquads, id: currentAgentId } = agent;

    if (!currentAgentSquads) {
      return squads;
    }

    currentAgentSquads.map(currentSquad => {
      const { id, color, name, role } = currentSquad;

      if (!squads[id]) {
        squads[id] = {
          agents: [],
          color,
          id,
          lead: undefined,
          name
        };
      }

      if (role === SquadRole.LEAD) {
        squads[id].lead = agent;
      }

      if (
        !squads[id].agents.find(squadAgent => squadAgent.id === currentAgentId)
      ) {
        squads[id].agents.push(agent);
      }
    });

    return squads;
  }, {});

export const isIDFSquad = (squadName: string): boolean =>
  IDFSquads.map(squad => squad.toLowerCase()).includes(squadName.toLowerCase());

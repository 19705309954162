import * as Sentry from '@sentry/nextjs';

export const logError = (
  message: string,
  extraLog: Record<string, unknown> = {}
) => {
  // Be sure we are sending stringified data
  const extra = Object.fromEntries(
    Object.entries(extraLog).map(([key, value]) => [
      key,
      JSON.stringify(
        value,
        value ? Object.getOwnPropertyNames(value) : undefined // To be sure we are correctly stringifying error property
      )
    ])
  );

  Sentry.captureEvent({ extra, message });
};

import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { Permission } from '@/components/state/interfaces';

import { useAuth } from '../auth/useAuth';
import { useIsTechRole } from '../auth/useIsTechRole';

export const useCurrentPathUserPermissions = () => {
  const { pathname } = useRouter();
  const { user } = useAuth();

  const isTech = useIsTechRole();

  const pathPermissions = user?.permissions.urls[pathname] || [];

  return useMemo(
    () =>
      isTech
        ? { canUserCreate: true, canUserRead: true, canUserUpdate: true }
        : {
            canUserCreate: pathPermissions.includes(Permission.CREATE),
            canUserRead: pathPermissions.includes(Permission.READ),
            canUserUpdate: pathPermissions.includes(Permission.UPDATE)
          },
    [isTech, pathPermissions]
  );
};

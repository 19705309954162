import { useMemo } from 'react';

import { Permission } from '@/components/state/interfaces';

import { useAuth } from '../auth/useAuth';
import { useIsTechRole } from '../auth/useIsTechRole';
import { FEATURE_NAME } from './interfaces';

export const useUserFeaturePermissions = (featureName: FEATURE_NAME) => {
  const { user } = useAuth();

  const isTechRole = useIsTechRole();

  return useMemo(() => {
    const userFeaturePermissions =
      user?.permissions.features[featureName] || [];

    return !isTechRole
      ? userFeaturePermissions.includes(Permission.READ)
      : true;
  }, [featureName, isTechRole, user?.permissions]);
};

import { FC } from 'react';

import LeadTag from '@/components/app/leadTag/LeadTag';
import { isCasavoSMPSource } from '@/components/app/leadTag/utils';
import MobileCard from '@/components/myCustomers/common/mobileCard/MobileCard';
import { MainContentWrapper } from '@/components/myCustomers/common/mobileCard/MobileCard.styles';
import {
  InlineItemsWrapper,
  MultiTagsWrapper
} from '@/components/myCustomers/common/styles/MyCustomers.styles';
import { FEATURE_NAME } from '@/hooks/useUserFeaturePermissions/interfaces';
import { useUserFeaturePermissions } from '@/hooks/useUserFeaturePermissions/useUserFeaturePermissions';

import { SaleOpportunity } from '../../interfaces';
import { getOpportunityLinkFeed, isClosed } from '../../LeadView.utils';
import ArchiveDate from '../common/archiveDate/ArchiveDate';
import ArchiveReason from '../common/archiveReason/ArchiveReason';
import LeadRevenueTag from '../common/leadRevenueTag/LeadRevenueTag';
import ListingAddress from '../common/listingAddress/ListingAddress';
import ListingIcon from '../common/listingIcon/ListingIcon';
import ListingSpecs from '../common/listingSpecs/ListingSpecs';
import StageTag from '../common/stageTag/StageTag';

export type MobileLeadCardProps = {
  opportunity: SaleOpportunity;
};

const MobileLeadCard: FC<MobileLeadCardProps> = ({ opportunity }) => {
  const hasAccessToRevenueForecast = useUserFeaturePermissions(
    FEATURE_NAME.MY_SELLERS_REVENUE_FORECAST
  );

  const {
    address,
    archiveDate,
    chargedFees,
    commission,
    estimationStage,
    finalSalePrice,
    firstName,
    lastAppointmentStatus,
    lastEvent,
    lastName,
    lastStage,
    leadSource,
    listingType,
    listingId,
    nextEvent,
    price,
    surface,
    roomsCount,
    transactionId
  } = opportunity;

  const link = getOpportunityLinkFeed(opportunity);

  const id =
    isCasavoSMPSource(leadSource) && transactionId ? transactionId : listingId;

  return (
    <MobileCard
      firstName={firstName}
      lastEvent={lastEvent}
      lastName={lastName}
      link={link}
      nextEvent={nextEvent}
    >
      <MainContentWrapper>
        <MultiTagsWrapper>
          <LeadTag source={leadSource} />
          <StageTag
            estimationStage={lastStage || estimationStage}
            lastAppointmentStatus={lastAppointmentStatus}
          />
          {hasAccessToRevenueForecast && (
            <LeadRevenueTag
              chargedFees={chargedFees}
              commission={commission}
              hasEmptyPlaceholder={false}
              isApproximative={!isClosed(estimationStage)}
              price={price}
            />
          )}
        </MultiTagsWrapper>
      </MainContentWrapper>
      <MainContentWrapper>
        <ListingAddress {...address} isMultiline={true} />
      </MainContentWrapper>
      <MainContentWrapper>
        <InlineItemsWrapper>
          <ListingIcon listingId={id} listingType={listingType} />
          <ListingSpecs
            finalSalePrice={finalSalePrice}
            price={price}
            roomsCount={roomsCount}
            surface={surface}
          />
        </InlineItemsWrapper>
      </MainContentWrapper>
      {archiveDate && (
        <MainContentWrapper>
          <div>
            <ArchiveDate isMobile={true} archiveDate={archiveDate} />
          </div>
          <ArchiveReason estimationStage={estimationStage} />
        </MainContentWrapper>
      )}
    </MobileCard>
  );
};
export default MobileLeadCard;

import styled, { css } from 'styled-components';

export const Layout = styled.div<{ fullScreen?: boolean }>`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  ${({ fullScreen }) =>
    fullScreen
      ? css`
          position: fixed;
          z-index: 100;
        `
      : css`
          position: absolute;
        `}
`;

import mixpanel from 'mixpanel-browser';

import { isProduction } from '@proprioo/hokkaido';

import { ArbitraryObject } from './interfaces';

export enum EVENT_NAME {
  ACT_AUTHENTIC_SIGNED = 'actAuthenticSigned',
  ACTIVITY_CREATED = 'activityCreated',
  ACTIVITY_VALIDATED = 'activityValidated',
  BUYER_LEAD_CREATED = 'buyingProjectCreated',
  COMPROMISE_SIGNED = 'compromiseSigned',
  CONTRACT_SIGNED = 'contractSigned',
  LISTING_SHARED = 'listingShared',
  LISTING_PUBLISHED = 'listingPublished',
  MANDATE_GENERATED = 'mandateGenerated',
  MEETING_VALIDATED = 'meetingValidated',
  OFFER_CREATED = 'offerCreated',
  OUTBOUND_PROSP_CREATE_ALERT = 'outboundProspCreateAlert',
  OUTBOUND_PROSP_DETAILS = 'outboundProspDetails',
  OUTBOUND_PROSP_HISTORY = 'outboundProspHistory',
  OUTBOUND_PROSP_LEAD_ASSIGNED = 'outboundProspLeadAssigned',
  OUTBOUND_PROSP_SEARCH = 'outboundProspSearch',
  OUTBOUND_PROSP_SEE_EXPIRED_PROPERTIES = 'outboundProspSeeExpiredProperties',
  PAGE_VIEWED = 'pageViewed',
  SEARCH = 'search',
  SELLER_LEAD_ARCHIVED = 'projectArchived',
  SELLER_LEAD_CREATED = 'sellingProjectCreated',
  SELLER_LEAD_MANAGEMENT = 'sellerLeadManagement',
  SELLER_PROJECT_CONVERTED = 'sellerProjectConverted',
  SIGNED_IN = 'signedIn'
}

export const identifyUser = (email: string) => {
  if (isProduction()) {
    mixpanel.identify(email);
  }
};

export const addUserProperties = (data: ArbitraryObject) => {
  if (isProduction()) {
    mixpanel.people.set_once(data);
  }
};

export const trackTimeEvent = (eventName: EVENT_NAME) => {
  if (isProduction()) {
    mixpanel.time_event(eventName);
  }
};

export const trackEvent = (eventName: EVENT_NAME, data?: ArbitraryObject) => {
  if (isProduction()) {
    mixpanel.track(eventName, data);
  }
};

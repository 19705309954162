import { ReactElement } from 'react';

import Error from '@/assets/icons/toast-error.svg';
import Version from '@/assets/icons/toast-info.svg';
import Success from '@/assets/icons/toast-success.svg';
import Warning from '@/assets/icons/toast-warning.svg';

import { ToastStatus } from './interfaces';

export const getToastIcon = (
  status: ToastStatus,
  customIcon?: ReactElement
): ReactElement => {
  if (customIcon) {
    return customIcon;
  }

  switch (status) {
    case ToastStatus.ERROR:
      return <Error data-test="error-icon" />;
    case ToastStatus.VERSION:
      return <Version data-test="version-icon" />;
    case ToastStatus.WARNING:
      return <Warning data-test="warning-icon" />;
    default:
      return <Success data-test="success-icon" />;
  }
};

import { FC, PropsWithChildren } from 'react';

import { Tooltip } from '@proprioo/salatim';

import { AvatarIconWrapper, AvatarLayout, AvatarName } from './Avatar.styles';
import { AvatarSize } from './interfaces';

export type AvatarProps = {
  name: string;
  dataTest?: string;
  isNameVisible?: boolean;
  isToolTipVisible?: boolean;
  size?: AvatarSize;
};

const Avatar: FC<PropsWithChildren<AvatarProps>> = ({
  children,
  name,
  dataTest = 'avatar',
  isNameVisible = false,
  isToolTipVisible = true,
  size
}) => (
  <AvatarLayout data-test={dataTest}>
    <Tooltip tooltip={name} isVisible={isToolTipVisible && !isNameVisible}>
      <AvatarIconWrapper size={size}>{children}</AvatarIconWrapper>
    </Tooltip>
    {isNameVisible && <AvatarName aria-hidden={true}>{name}</AvatarName>}
  </AvatarLayout>
);

export default Avatar;

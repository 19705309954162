import { useRouter } from 'next/router';
import { useRecoilState } from 'recoil';

import { historyManagerSelector } from '@/components/state/HistoryManagerState';

type UseHistoryProps = {
  isAvailable: boolean;
  lastMainPath?: string;
  goBack(): void;
};

export const useHistory = (): UseHistoryProps => {
  const { push } = useRouter();

  const [history, updateHistory] = useRecoilState(historyManagerSelector);

  const currentHistory = [...history].pop();
  const currentIndex = history.findIndex(
    ({ path }) => path === currentHistory?.path
  );

  const previousHistory = history[currentIndex - 1];

  const goBack = async () => {
    if (previousHistory) {
      const filteredHistory = [...history].slice(0, -1);

      updateHistory(filteredHistory);
      await push(previousHistory.path);
    }
  };

  const last = history[history.length - 1];

  return {
    goBack,
    isAvailable: Boolean(history.length > 1 && !currentHistory?.isMainPage),
    lastMainPath: last?.path
  };
};

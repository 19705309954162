import styled from 'styled-components';

import {
  breakpoints,
  colors,
  convertPxToRem,
  paragraphs,
  radius,
  resetCssButton,
  shadows
} from '@proprioo/salatim';

export const SquadButton = styled.button`
  ${resetCssButton};
  ${paragraphs.subtitle1};
  margin: 0 ${convertPxToRem(16)};
  width: ${convertPxToRem(40)};
  height: ${convertPxToRem(40)};
  background-color: ${colors.blue.base60};
  border-radius: ${radius.rounded};
  color: ${colors.green.base};
  cursor: pointer;
  align-self: flex-end;
`;

export const CloseButton = styled.button`
  ${resetCssButton};
  width: ${convertPxToRem(24)};
  height: ${convertPxToRem(24)};
  border-radius: ${radius.small};

  :hover {
    background-color: ${colors.grey.base10};
  }

  :active {
    background-color: ${colors.grey.base20};
  }

  > svg {
    width: ${convertPxToRem(24)};
    height: ${convertPxToRem(24)};

    path {
      fill: currentColor;
    }
  }
`;

export const MenuWrapper = styled.div`
  position: relative;
  z-index: 3;
  width: 100%;
`;

export const MenuLayout = styled.div`
  position: fixed;
  top: ${convertPxToRem(48)};
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 10;

  @media (min-width: ${breakpoints.small}px) {
    position: absolute;
    width: ${convertPxToRem(300)};
    height: ${convertPxToRem(400)};
    top: ${convertPxToRem(8)};
    right: 0;
    bottom: unset;
    left: unset;
    border-radius: ${radius.small};
    box-shadow: ${shadows.level3};
  }
`;

export const MenuHeader = styled.div`
  ${paragraphs.subtitle1};
  display: flex;
  justify-content: space-between;
  padding: ${convertPxToRem(16)};
  color: ${colors.green.base};
`;

export const MenuSearchWrapper = styled.div`
  padding: 0 ${convertPxToRem(16)};
`;

export const MenuScrollArea = styled.div`
  flex: 1;
  overflow-y: auto;
  margin-top: ${convertPxToRem(16)};
  padding: 0 ${convertPxToRem(16)};
`;

export const MenuFooter = styled.div`
  padding: ${convertPxToRem(16)};
  border-top: ${convertPxToRem(1)} solid ${colors.grey.base10};
`;

export const Divider = styled.div`
  margin: ${convertPxToRem(12)} 0;
  border-top: ${convertPxToRem(1)} solid ${colors.dark.base20};
`;

import { differenceInDays } from 'date-fns/differenceInDays';
import { differenceInMonths } from 'date-fns/differenceInMonths';
import { differenceInWeeks } from 'date-fns/differenceInWeeks';
import { isSameDay } from 'date-fns/isSameDay';
import { startOfDay } from 'date-fns/startOfDay';
import { useTranslation } from 'next-i18next';

import { nullOrString } from '@proprioo/hokkaido';

import { SinceSentenceKey } from './interfaces';
import { getSinceSentenceLabels } from './utils';

export const useSinceSentence = (
  date: nullOrString,
  key: SinceSentenceKey
): string => {
  const { t } = useTranslation();

  if (!date) return '';

  const { sinceDayLabel, sinceMonthLabel, sinceTodayLabel, sinceWeekLabel } =
    getSinceSentenceLabels(key);

  const now = new Date();
  const sinceDate = new Date(date.replace('[GMT]', ''));

  const today = isSameDay(now, sinceDate);

  const dayDifference = differenceInDays(
    startOfDay(now),
    startOfDay(sinceDate)
  );
  const monthDifference = differenceInMonths(now, sinceDate);
  const weekDifference = differenceInWeeks(now, sinceDate);

  const hasLessThanTwoMonths = monthDifference < 2;
  const hasLessThanOneWeek = weekDifference < 1;

  if (today) {
    return t(sinceTodayLabel);
  }

  if (hasLessThanOneWeek) {
    return t(sinceDayLabel, { count: dayDifference });
  }

  if (hasLessThanTwoMonths) {
    return t(sinceWeekLabel, { count: weekDifference });
  }

  return t(sinceMonthLabel, { value: monthDifference });
};

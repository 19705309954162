import { ONE_THOUSAND } from '@/constants/global';
import {
  DocumentProps,
  DocumentType,
  EstimationStage,
  Locale,
  PropertyEnum
} from '@proprioo/hokkaido';
import { TagColorsTypes } from '@proprioo/salatim';

import {
  AppointmentSubStatus,
  ValuationMeeting
} from '../qualification/sellerQualification/interfaces';
import { TagStage } from './interfaces';
import { VIRTUAL_VISIT_REGEX } from './selling/mediaPanel/virtualVisit/VirtualVisit.schema';

export const isListingSold = (estimationStage: EstimationStage) =>
  [
    EstimationStage.AUTHENTIC_ACT,
    EstimationStage.COMPROMISE,
    EstimationStage.SOLD
  ].includes(estimationStage);

export const hasListingCounterSignedOffer = (
  estimationStage: EstimationStage
) => estimationStage == EstimationStage.SOLD;

export const isListingArchived = (estimationStage: EstimationStage) =>
  [
    EstimationStage.ABANDON_PROPRIOO,
    EstimationStage.CANCELED_SALE,
    EstimationStage.CANCELLED_APPOINTMENT,
    EstimationStage.CASAVO_REJECTION,
    EstimationStage.CURIOUS,
    EstimationStage.JUST_FOR_ESTIMATION,
    EstimationStage.LEGAL_OR_URBANISTIC_ISSUES,
    EstimationStage.LOST,
    EstimationStage.LOST_MANDATE,
    EstimationStage.MANDATE_ALREADY_ASSIGNED,
    EstimationStage.NO_ANSWER,
    EstimationStage.NO_MORE_INTEREST,
    EstimationStage.NO_SHOW,
    EstimationStage.NOT_SURE_TO_SELL,
    EstimationStage.OTHER,
    EstimationStage.OUT_OF_AREA,
    EstimationStage.PRICE_TOO_HIGH,
    EstimationStage.PRICING_GAP,
    EstimationStage.REFUSE_COMMERCIAL,
    EstimationStage.SOLD_BY_AGENCY,
    EstimationStage.SOLD_BY_PARTICULAR,
    EstimationStage.WRONG_NUMBER,
    EstimationStage.WITHOUT_AGENCY
  ].includes(estimationStage);

export const isListingOnSale = (estimationStage: EstimationStage) =>
  [EstimationStage.ONLINE, EstimationStage.PAUSED].includes(estimationStage);

export const isBeforeQualification = (estimationStage: EstimationStage) =>
  [
    EstimationStage.LEAD,
    EstimationStage.PROSPECT,
    EstimationStage.BACKLOGS
  ].includes(estimationStage);

export const isListingPastSoldStage = (estimationStage: EstimationStage) =>
  [EstimationStage.COMPROMISE, EstimationStage.AUTHENTIC_ACT].includes(
    estimationStage
  );

export const convertToLeadStageTranslation = (
  lastAppointmentStatus?: AppointmentSubStatus
) => {
  switch (lastAppointmentStatus) {
    case AppointmentSubStatus.CANCELLED:
      return 'leadWithCanceledAppointment';
    case AppointmentSubStatus.TOBEDONE:
      return 'leadWithAppointmentToConfirm';
    case undefined:
    case AppointmentSubStatus.NOSHOW:
      return 'leadWithoutAppointment';
    default:
      return 'lead';
  }
};

export const convertToEstimationStageTranslation = (
  stage: EstimationStage,
  lastAppointmentStatus?: AppointmentSubStatus
): string => {
  switch (stage) {
    case EstimationStage.ABANDON_PROPRIOO:
      return 'noLongerWishToSellWithCasavo';
    case EstimationStage.APPOINTMENT_TO_DO:
      return 'scheduledEstimation';
    case EstimationStage.AUTHENTIC_ACT:
      return 'authenticAct';
    case EstimationStage.BACKLOGS:
      return 'insideSalesLead';
    case EstimationStage.CANCELLED_APPOINTMENT:
      return 'canceledMeeting';
    case EstimationStage.CANCELED_SALE:
      return 'canceledSale';
    case EstimationStage.CASAVO_REJECTION:
      return 'casavoRejection';
    case EstimationStage.COMPROMISE:
      return 'signedSaleCompromise';
    case EstimationStage.CURIOUS:
      return 'curiosity';
    case EstimationStage.JUST_FOR_ESTIMATION:
      return 'justForEstimation';
    case EstimationStage.LEAD:
      return convertToLeadStageTranslation(lastAppointmentStatus);
    case EstimationStage.LEGAL_OR_URBANISTIC_ISSUES:
      return 'legalOrUrbanisticIssues';
    case EstimationStage.LOST:
      return 'lost';
    case EstimationStage.LOST_MANDATE:
      return 'lostMandate';
    case EstimationStage.MANDATE_ALREADY_ASSIGNED:
      return 'mandateAlreadyAssigned';
    case EstimationStage.MANDATE_TO_SEND:
      return 'mandateToSend';
    case EstimationStage.NO_ANSWER:
      return 'doNotAnswer';
    case EstimationStage.NO_MORE_INTEREST:
      return 'noMoreInterest';
    case EstimationStage.NO_SHOW:
      return 'notPresent';
    case EstimationStage.NOT_SURE_TO_SELL:
      return 'notSureToSell';
    case EstimationStage.ON_HOLD:
      return 'toPublish';
    case EstimationStage.ONLINE:
      return 'online';
    case EstimationStage.OUT_OF_AREA:
      return 'outOfArea';
    case EstimationStage.OTHER:
      return 'other';
    case EstimationStage.PAUSED:
      return 'paused';
    case EstimationStage.PRICING_GAP:
      return 'pricingGap';
    case EstimationStage.PRICE_TOO_HIGH:
      return 'priceTooHigh';
    case EstimationStage.PROSPECT:
      return 'prospect';
    case EstimationStage.REFUSE_COMMERCIAL:
      return 'refuseCommercialCanvassing';
    case EstimationStage.SOLD:
      return 'countersignedOffer';
    case EstimationStage.SOLD_BY_AGENCY:
      return 'soldByOtherAgency';
    case EstimationStage.SOLD_BY_PARTICULAR:
      return 'soldByParticular';
    case EstimationStage.UNSIGNED_MANDATE:
      return 'sentMandate';
    case EstimationStage.VALUATION_TO_SEND:
      return 'valued';
    case EstimationStage.WITHOUT_AGENCY:
      return 'withoutAgency';
    case EstimationStage.WRONG_NUMBER:
      return 'phoneError';
    default:
      return 'unknownStage';
  }
};

export const getEstimationStageTagColor = (
  estimationStage: EstimationStage
): TagColorsTypes => {
  if (isListingOnSale(estimationStage)) {
    return TagColorsTypes.GREEN_LIGHT;
  }

  if (isListingSold(estimationStage)) {
    return TagColorsTypes.BLUE;
  }

  return TagColorsTypes.TERRACOTA;
};

export const getEstimationStageTagTranslation = (
  estimationStage: EstimationStage,
  lastAppointmentStatus?: AppointmentSubStatus
): string =>
  isListingArchived(estimationStage)
    ? 'archived'
    : convertToEstimationStageTranslation(
        estimationStage,
        lastAppointmentStatus
      );

export const getEstimationStageTagProps = (
  stage: EstimationStage,
  lastAppointmentStatus?: AppointmentSubStatus
): TagStage => ({
  color: getEstimationStageTagColor(stage),
  label: getEstimationStageTagTranslation(stage, lastAppointmentStatus)
});

export const getDocumentsByType = (
  type: DocumentType,
  documents?: DocumentProps[]
) => documents?.filter(({ documentType }) => documentType === type);

export const getLastAppointmentSubStatus = (
  valuationMeetings?: ValuationMeeting[]
) => {
  if (!valuationMeetings || valuationMeetings.length === 0) {
    return;
  }

  const [lastValuationMeeting] = valuationMeetings.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );

  return lastValuationMeeting.substatus;
};

export const isVirtualVisitUrl = (url: string): boolean =>
  VIRTUAL_VISIT_REGEX.test(url);

export const formatBigNumber = (value: number, locale: Locale) =>
  value >= ONE_THOUSAND
    ? `${value / ONE_THOUSAND}k`
    : value.toLocaleString(locale);

export const flatPropertyTypes: PropertyEnum[] = [
  PropertyEnum.ATTIC,
  PropertyEnum.DUPLEX,
  PropertyEnum.FLAT,
  PropertyEnum.LOFT,
  PropertyEnum.MANSARD
];

export const housePropertyTypes: PropertyEnum[] = [
  PropertyEnum.HOUSE,
  PropertyEnum.RUSTIC_HOUSE,
  PropertyEnum.TOWN_HOUSE,
  PropertyEnum.VILLA
];

import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import Email from '@/assets/icons/email.svg';
import Phone from '@/assets/icons/phone-user.svg';
import { isFakePropriooEmail } from '@/components/qualification/customerForm/utils';
import { useCustomerPermission } from '@/hooks/useCustomerPermission/useCustomerPermission';
import { nullOrString } from '@proprioo/hokkaido';
import {
  Button,
  ButtonAppearance,
  ButtonSizes,
  ButtonTheme,
  useMobileDevice
} from '@proprioo/salatim';

import { CustomerName, Layout } from './CustomerContact.styles';

export type CustomerContactProps = {
  email: string;
  phone: nullOrString;
  name: string;
};

const CustomerContact: FC<CustomerContactProps> = ({ email, phone, name }) => {
  const { t } = useTranslation();
  const isMobile = useMobileDevice();

  const isFake = isFakePropriooEmail(email);
  const isAllowed = useCustomerPermission();

  return (
    <Layout data-test="customer-contact-actions">
      {isAllowed && !isFake && email && (
        <a href={`mailto:${email}`}>
          <Button
            label={t('email')}
            rounded={true}
            icon={<Email />}
            size={isMobile ? ButtonSizes.SMALL : ButtonSizes.LARGE}
            appearance={ButtonAppearance.SECONDARY}
            theme={ButtonTheme.BLACK}
          />
        </a>
      )}
      {isAllowed && phone && (
        <a href={`tel:${phone}`}>
          <Button
            label={t('phone')}
            rounded={true}
            icon={<Phone />}
            size={isMobile ? ButtonSizes.SMALL : ButtonSizes.LARGE}
            appearance={ButtonAppearance.SECONDARY}
            theme={ButtonTheme.BLACK}
          />
        </a>
      )}
      <CustomerName>{name}</CustomerName>
    </Layout>
  );
};

export default CustomerContact;

import { useTranslation } from 'next-i18next';
import Script from 'next/script';
import { useEffect, useState } from 'react';

import { updateAgentChatId } from '@/components/admin/agentAdministration/fetch';
import { useCurrentAgent } from '@/hooks/agents/useCurrentAgent';
import { useAuth } from '@/hooks/auth/useAuth';
import { FEATURE_NAME } from '@/hooks/useUserFeaturePermissions/interfaces';
import { useUserFeaturePermissions } from '@/hooks/useUserFeaturePermissions/useUserFeaturePermissions';
import { colors } from '@proprioo/salatim';

const FreshChat = () => {
  const { t } = useTranslation();
  const { isConnected, user } = useAuth();

  const currentAgent = useCurrentAgent();
  const hasAccessToFC = useUserFeaturePermissions(
    FEATURE_NAME.WIDGET_FRESHCHAT
  );
  const [isFreshChatLoaded, setFreshChat] = useState(false);

  useEffect(() => {
    if (hasAccessToFC && isConnected && isFreshChatLoaded) {
      window.fcWidget.init({
        config: {
          content: {
            headers: {
              channel_response: { offline: t('supportNotAvailableAtTheMoment') }
            }
          },
          cssNames: { widget: 'proprioo' },
          headerProperty: {
            backgroundColor: colors.green.base,
            fontName: 'Archivo',
            fontUrl:
              'https://fonts.googleapis.com/css2?family=Archivo:wght@100;200;300;400;500;700&display=swap',
            foregroundColor: 'white'
          }
        },
        email: currentAgent?.email || user?.email,
        externalId: currentAgent?.id || user?.email,
        firstName: currentAgent?.firstname,
        host: 'https://wchat.eu.freshchat.com',
        lastName: currentAgent?.lastname,
        restoreId: currentAgent?.chatId,
        token: 'baddda3e-961a-4523-88af-67d782d802aa'
      });

      window.fcWidget.user.get(() => {
        window.fcWidget.on('user:created', resp => {
          const { status, success } = resp;
          if (status === 200 && success && resp.data?.restoreId) {
            if (currentAgent?.id) {
              updateAgentChatId({
                chat_id: resp.data.restoreId,
                id: currentAgent.id
              });
            }
          }
        });
      });

      return () => window.fcWidget.destroy();
    }
  }, [hasAccessToFC, isConnected, isFreshChatLoaded]);

  return (
    <Script
      onLoad={() => setFreshChat(true)}
      src="https://wchat.freshchat.com/js/widget.js"
      strategy="lazyOnload"
    />
  );
};

export default FreshChat;

import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import Check from '@/assets/icons/check.svg';
import Close from '@/assets/icons/close.svg';
import {
  Button,
  ButtonAppearance,
  ButtonTheme,
  IconPosition
} from '@proprioo/salatim';

import { Layout, LayoutButtons } from './ConfirmModalButtons.styles';

export type ConfirmModalButtonsProps = {
  hasAcceptIcon?: boolean;
  hasRejectIcon?: boolean;
  iconAccept?: JSX.Element;
  iconReject?: JSX.Element;
  idAccept?: string;
  idReject?: string;
  isAcceptDisabled?: boolean;
  textAccept?: string;
  textReject?: string;
  theme?: ButtonTheme;
  onAccept(): void;
  onReject(): void;
};

const ConfirmModalButtons: FC<ConfirmModalButtonsProps> = ({
  hasAcceptIcon = true,
  hasRejectIcon = true,
  iconAccept = <Check data-test="default-accept-icon" />,
  iconReject = <Close data-test="default-reject-icon" />,
  idAccept,
  idReject,
  isAcceptDisabled = false,
  textAccept = 'yes',
  textReject = 'no',
  onReject,
  onAccept,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <Layout>
      <LayoutButtons {...rest}>
        <Button
          id={idReject}
          data-test="button-reject"
          onClick={onReject}
          label={t(textReject)}
          appearance={ButtonAppearance.SECONDARY}
          icon={hasRejectIcon ? iconReject : undefined}
          iconPosition={IconPosition.LEFT}
        />
        <Button
          id={idAccept}
          data-test="button-accept"
          onClick={onAccept}
          label={t(textAccept)}
          icon={hasAcceptIcon ? iconAccept : undefined}
          disabled={isAcceptDisabled}
        />
      </LayoutButtons>
    </Layout>
  );
};

export default ConfirmModalButtons;

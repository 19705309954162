import { LeadViewSection, LeadViewTableColumns } from '../../interfaces';

export const getHiddenColumns = (
  isMobileDevice: boolean,
  isTeamLead: boolean,
  section: LeadViewSection,
  hasAccessToRevenueForecast: boolean
): LeadViewTableColumns[] => {
  const hiddenColumns: LeadViewTableColumns[] = [];

  if (isMobileDevice) {
    hiddenColumns.push(
      LeadViewTableColumns.CONTACT,
      LeadViewTableColumns.STATUS,
      LeadViewTableColumns.LISTING,
      LeadViewTableColumns.REVENUE,
      LeadViewTableColumns.AGENTS
    );
  } else {
    hiddenColumns.push(
      LeadViewTableColumns.CONTACT_STATUS,
      LeadViewTableColumns.LISTING_REVENUE
    );

    if (!hasAccessToRevenueForecast) {
      hiddenColumns.push(LeadViewTableColumns.REVENUE);
    }

    if (!isTeamLead) {
      hiddenColumns.push(LeadViewTableColumns.AGENTS);
    }
  }

  if (section === LeadViewSection.ARCHIVED) {
    hiddenColumns.push(
      LeadViewTableColumns.PAST_EVENT,
      LeadViewTableColumns.STATUS,
      LeadViewTableColumns.CONTACT_STATUS,
      isMobileDevice
        ? LeadViewTableColumns.ARCHIVED_STATUS
        : LeadViewTableColumns.ARCHIVED_CONTACT_STATUS
    );
  } else {
    hiddenColumns.push(
      LeadViewTableColumns.ARCHIVED_DATE,
      LeadViewTableColumns.ARCHIVED_REASON,
      LeadViewTableColumns.ARCHIVED_STATUS,
      LeadViewTableColumns.ARCHIVED_CONTACT_STATUS
    );
  }

  return [...new Set(hiddenColumns)];
};

import {
  ExternalLeadViewTableColumns,
  LeadViewTab
} from '@/components/myCustomers/mySellers/interfaces';

export const getHiddenColumns = (
  currentTab: LeadViewTab,
  isTeamLead: boolean
): ExternalLeadViewTableColumns[] => {
  const hiddenColumns: ExternalLeadViewTableColumns[] = [];

  if (currentTab === LeadViewTab.LEADS_ALL_EXTERNAL) {
    hiddenColumns.push(
      ExternalLeadViewTableColumns.ADVERTISER,
      ExternalLeadViewTableColumns.LAST_ACTIVITY,
      ExternalLeadViewTableColumns.UPCOMING_ACTIVITY
    );
  }

  if (currentTab === LeadViewTab.LEADS_ALL_EXTERNAL_ATTRIBUTED_PIGE) {
    hiddenColumns.push(
      ExternalLeadViewTableColumns.ADVERTISER,
      ExternalLeadViewTableColumns.ATTRIBUTION,
      ExternalLeadViewTableColumns.REMAINING_TIME
    );
  }

  if (currentTab === LeadViewTab.LEADS_ALL_EXTERNAL_ATTRIBUTED_PICKING) {
    hiddenColumns.push(
      ExternalLeadViewTableColumns.ATTRIBUTION,
      ExternalLeadViewTableColumns.NAME,
      ExternalLeadViewTableColumns.REMAINING_TIME
    );
  }

  if (currentTab === LeadViewTab.LEADS_ALL_EXTERNAL_EXPIRED) {
    hiddenColumns.push(
      ExternalLeadViewTableColumns.LAST_ACTIVITY,
      ExternalLeadViewTableColumns.NAME,
      ExternalLeadViewTableColumns.REMAINING_TIME,
      ExternalLeadViewTableColumns.UPCOMING_ACTIVITY
    );
  }

  if (!isTeamLead) {
    hiddenColumns.push(ExternalLeadViewTableColumns.AGENT);
  }

  return hiddenColumns;
};

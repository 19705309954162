import styled, { css } from 'styled-components';

import { breakpoints, colors, convertPxToRem } from '@proprioo/salatim';

import { BannerLayout } from './banner/Banner.styles';
import { Layout as ImpersonationBarLayout } from './impersonationBar/ImpersonationBar.styles';

export const OPEN_MENU_WIDTH = convertPxToRem(200);
export const CLOSED_MENU_WIDTH = convertPxToRem(48);

const COMMON_CONTAINER = css`
  margin: 0 auto;
  padding: ${convertPxToRem(16)};
`;

export const CONTAINER_MAX_WIDTH = css`
  ${COMMON_CONTAINER};

  @media (min-width: ${breakpoints.large}px) {
    max-width: calc(${convertPxToRem(1216)});
  }

  @media (min-width: ${breakpoints.xlarge}px) {
    max-width: calc(${convertPxToRem(1504)});
  }
`;

export const CONTAINER_MAX_WIDTH_MENU = css`
  ${COMMON_CONTAINER};

  @media (min-width: ${breakpoints.large}px) {
    max-width: calc(${convertPxToRem(1216)} - ${OPEN_MENU_WIDTH});
  }

  @media (min-width: ${breakpoints.xlarge}px) {
    max-width: calc(${convertPxToRem(1504)} - ${OPEN_MENU_WIDTH});
  }
`;

export const MainContainer = styled.main`
  position: relative;
  z-index: 0;
  display: flex;
  height: 100%;
  width: 100%;
`;

export const MainContent = styled.section`
  position: relative;
  z-index: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  padding-top: ${CLOSED_MENU_WIDTH};

  ${BannerLayout} + ${ImpersonationBarLayout} {
    margin-top: ${convertPxToRem(2)};
  }

  @media (min-width: ${breakpoints.small}px) {
    padding: 0 0 0 ${CLOSED_MENU_WIDTH};
  }
`;

export const MainMenu = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  width: 100%;
  height: ${CLOSED_MENU_WIDTH};
  background-color: ${colors.green.base};

  @media (min-width: ${breakpoints.small}px) {
    height: 100%;
    width: ${OPEN_MENU_WIDTH};
    transform: translateX(calc(-${OPEN_MENU_WIDTH} + ${CLOSED_MENU_WIDTH}));
    transition: all 0.2s cubic-bezier(0.175, 0.75, 0.4, 1);
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      z-index: 2;
      height: 100%;

      @media (min-width: ${breakpoints.small}px) {
        transform: translateX(0);
        box-shadow: 0 0 ${convertPxToRem(20)} rgba(51, 51, 51, 0.4);
      }
    `};
`;

export const ContentAndTopbar = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  height: 100%;
  overflow: hidden;
`;

export const Content = styled.div<{ isFullHeight: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media (min-width: ${breakpoints.small}px) {
    flex-direction: row;
  }

  ${({ isFullHeight }) =>
    isFullHeight &&
    css`
      ${ContentOverflow} {
        height: 100%;
      }
    `};
`;

export const ContentOverflow = styled.div<{ backgroundColor: string }>`
  min-height: 100%;
  width: 100%;
  padding-bottom: ${convertPxToRem(56)};
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${({ backgroundColor }) => backgroundColor};

  @media (min-width: ${breakpoints.base}px) {
    padding-bottom: 0;
  }

  @supports (scrollbar-gutter: stable) {
    scrollbar-gutter: stable;
  }
`;

export const ContentChildren = styled.div<{
  isFullHeight: boolean;
  isFullWidth: boolean;
  isWithSidebar: boolean;
}>`
  position: relative;
  z-index: 0;
  min-height: 100%;

  ${({ isFullHeight }) =>
    isFullHeight &&
    css`
      height: 100%;
    `}

  ${({ isFullWidth, isWithSidebar }) =>
    !isFullWidth &&
    isWithSidebar &&
    css`
      ${CONTAINER_MAX_WIDTH_MENU};
      margin: 0 auto;
      padding: ${convertPxToRem(16)};
    `}

  ${({ isFullWidth, isWithSidebar }) =>
    !isFullWidth &&
    !isWithSidebar &&
    css`
      ${CONTAINER_MAX_WIDTH};
      margin: 0 auto;
      padding: ${convertPxToRem(16)};
    `}
`;

export const ContentTabs = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  background: white;
`;

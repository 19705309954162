import { EstimationStage, isNotEmpty } from '@proprioo/hokkaido';

import { useCurrentAgent } from '../agents/useCurrentAgent';
import { useIsAdminRole } from '../auth/useIsAdminRole';
import { useIsSellerSuccessRole } from '../auth/useIsSellerSuccessRole';
import { useIsTeamLeadRole } from '../auth/useIsTeamLeadRole';
import { useCustomer } from '../customer/useCustomer';
import { useSquadsByAgentId } from '../squads/useSquadsByAgentId';
import {
  hasOnlyUnassignedAlerts,
  isInSameSquad,
  isOwnerOfOneAlert
} from './utils';

export const useCustomerPermission = () => {
  const { customer } = useCustomer();

  const currentAgent = useCurrentAgent();
  const currentSquads = useSquadsByAgentId(currentAgent?.id);
  const isTeamLead = useIsTeamLeadRole();
  const isAdmin = useIsAdminRole();
  const isSellerSuccess = useIsSellerSuccessRole();

  const {
    alerts,
    user: { id: customerId },
    opportunities
  } = customer;

  // Handle admin
  if (isAdmin || isSellerSuccess) {
    return true;
  }

  // Handle empty user
  if (!isNotEmpty(customerId)) {
    return false;
  }

  // Check if user has at least permissions on one of the customer's
  // opportunities
  const isOpportunityOwnerOrViewingAgent = opportunities.some(
    ({ agentId, listing, estimationStage }) =>
      Boolean(
        isTeamLead &&
          (isInSameSquad(currentSquads, agentId) ||
            isInSameSquad(currentSquads, listing?.agentViewing))
      ) ||
      (estimationStage !== EstimationStage.BACKLOGS &&
        (agentId === currentAgent?.id ||
          listing?.agentViewing === currentAgent?.id))
  );

  // Check if user has at least permission on one of the customer's buyer
  // projects (or one of its projects is not attributed)
  const isAlertOwner = isOwnerOfOneAlert(
    alerts,
    currentSquads,
    isTeamLead,
    currentAgent
  );

  const customerHasOnlyUnassignedAlerts = hasOnlyUnassignedAlerts(alerts);

  return (
    isOpportunityOwnerOrViewingAgent ||
    isAlertOwner ||
    (customer.opportunities.length === 0 && customerHasOnlyUnassignedAlerts)
  );
};

import { compareDesc } from 'date-fns/compareDesc';
import { parseISO } from 'date-fns/parseISO';
import qs from 'qs';

import {
  formatPriceHistory,
  FormattedHistory
} from '@/components/externalLead/history/utils';
import {
  Company,
  CompanyCreationFields,
  CompanyLegalForm,
  CompanyUserWithJob,
  JobPosition
} from '@/components/listing/contract/interfaces';
import { PriceHistory } from '@/components/myCustomers/mySellers/interfaces';
import { CustomerInformation } from '@/components/state/interfaces';
import { isDefined, parseGMTDate } from '@/utils/helpers';
import { numberOrString } from '@/utils/interfaces';
import {
  CustomerProfile,
  DocumentProps,
  DocumentType,
  EstimationStage,
  FinancingMethod,
  isNotEmpty,
  MandateType,
  nullOrNumber,
  OptionProps
} from '@proprioo/hokkaido';

import { ListingHistoryProps } from '../interfaces';
import { getDocumentsByType, isListingOnSale, isListingSold } from '../utils';
import {
  AmendmentCard,
  AmendmentProps,
  CommissionDefaultInfos,
  ContractFormValues,
  ContractProps,
  ContractSignatory,
  ContractSignatoryLegalStatus,
  ContractSignatoryType,
  ContractStatus,
  ContractType,
  MandateProps,
  MissingInfoType,
  MissingInfoValue,
  OfferProps,
  SaleStepsCardAnchor,
  SellingContractProps
} from './Contract.interfaces';
import { CompanyFormSchema } from './contractFormParts/contractFormSignatories/companyForm/CompanyForm.schema';
import { PhysicalSignatoryUserSchema } from './contractFormParts/contractFormSignatories/physicalSignatoryCard/PhysicalSignatoryCard.schema';
import { SignatoryFiscalCodeSchema } from './offerWrapper/offerFormWrapper/OfferFormWrapper.schema';

export const retrieveLastContract = (contracts: ContractProps[]) => {
  const [contract] = [...contracts].sort((a, b) =>
    compareDesc(parseISO(a.modifiedDate), parseISO(b.modifiedDate))
  );
  return contract;
};

export const retrieveLastSignedContract = (contracts: ContractProps[]) =>
  [...contracts]
    .sort((a, b) =>
      compareDesc(parseISO(a.modifiedDate), parseISO(b.modifiedDate))
    )
    .find(({ status }) => status === ContractStatus.SIGNED);

export const retrieveCurrentActiveAmendment = (contracts: ContractProps[]) =>
  contracts
    .filter(({ documentType }) => documentType === ContractType.AMENDMENT)
    .find(
      ({ status }) =>
        status === ContractStatus.IN_EDITION ||
        status === ContractStatus.SENT_TO_CLIENTS
    );

export const retrieveCurrentMandate = (contracts: ContractProps[]) => {
  const [contract] = contracts.filter(
    ({ documentType, status }) =>
      documentType == ContractType.FULL && status !== ContractStatus.CANCELED
  );

  return contract;
};

export const retrieveSentToClientsContract = (contracts: ContractProps[]) =>
  contracts.find(({ status }) => status === ContractStatus.SENT_TO_CLIENTS);

export const calculateAbsoluteCommission = (
  priceIncludesFees: boolean,
  amount: number,
  percentage?: nullOrNumber
): number => {
  if (!percentage) return 0;

  return priceIncludesFees
    ? calculateCommission(amount, percentage)
    : calculateClassicCommission(amount, percentage);
};

export const calculateAbsoluteCommissionPercentage = (
  priceIncludesFees: boolean,
  amount: number,
  commissionValue?: nullOrNumber
): number => {
  if (!commissionValue) return 0;

  return priceIncludesFees
    ? renderCommissionPercentage(amount, commissionValue)
    : renderClassicCommissionPercentage(amount, commissionValue);
};

export const calculateClassicCommission = (
  amountWihoutFees: number,
  percentage: number
): number => {
  if (amountWihoutFees < 0 || percentage < 0) return 0;
  return Math.floor(amountWihoutFees * (percentage / 100));
};

export const renderClassicCommissionPercentage = (
  amount: number,
  commissionValue: number
): number => {
  if (amount <= 0 || commissionValue < 0) return 0;

  const n = amount.toString().length + 1;
  const truncSize = Math.pow(10, n);
  const ceilSize = Math.pow(10, n - 1);

  return (
    Math.trunc(
      (Math.trunc((commissionValue / amount) * 100 * truncSize) / truncSize) *
        ceilSize
    ) / ceilSize
  );
};

export const calculateCommission = (
  amount: number,
  percentage: number
): number => Math.floor(amount - amount / (1 + percentage / 100));

export const renderCommissionValue = (contract: ContractProps): number => {
  const price = contract.price;
  const commission: number = contract.sellerFeePercentage;
  return calculateCommission(price, commission);
};

export const renderNetSellerPrice = ({
  price,
  sellerFeePercentage
}: SellingContractProps): number => {
  return price - calculateCommission(price, sellerFeePercentage);
};

export const renderCommissionPercentage = (
  amount: number,
  commissionValue: number
): number => {
  const n = amount.toString().length + 1;
  const truncSize = Math.pow(10, n);
  const ceilSize = Math.pow(10, n - 1);
  return (
    Math.ceil(
      (Math.trunc(
        rawCommissionPercentage(amount, commissionValue) * truncSize
      ) /
        truncSize) *
        ceilSize
    ) / ceilSize
  );
};

export const rawCommissionPercentage = (
  amount: number,
  commissionValue: number
): number => (amount / (amount - commissionValue) - 1) * 100;

export const hasAmendmentChanged = (
  card: AmendmentCard,
  lastSignedContract: SellingContractProps,
  currentAmendment: AmendmentProps
): boolean => {
  switch (card) {
    case AmendmentCard.DESIGNATION:
      return lastSignedContract.description !== currentAmendment.description;
    case AmendmentCard.PRICE:
      return (
        lastSignedContract.price !== currentAmendment.price ||
        lastSignedContract.sellerFeePercentage !==
          currentAmendment.sellerFeePercentage
      );
    default:
      return false;
  }
};

export const setNetPrice = (
  price: nullOrNumber,
  fee: nullOrNumber
): nullOrNumber => {
  if (!price) {
    return null;
  }
  if (!fee) {
    return price;
  }
  return price - fee;
};

export const getContractPdfUrl = (contractId: string): string =>
  `/api/sales/contracts/${contractId}/pdf`;

export const getContractLabel = (
  contract: ContractProps,
  isFrenchListing: boolean
) => {
  if (contract.documentType === ContractType.AMENDMENT) {
    return 'amendmentNumber';
  }

  return isFrenchListing ? 'mandateAndRegisterNumbers' : 'mandate';
};

export const getContractsURL = (
  userId: string,
  listingId: number,
  confirmation?: boolean
) =>
  `/customer/${userId}/listing/${listingId}/contracts${
    confirmation ? '?confirmation=true' : ''
  }`;

export const getAmendmentURL = (
  userId: string,
  listingId: number,
  preview?: boolean
) =>
  `${getContractsURL(userId, listingId)}/amendment${qs.stringify(
    { preview },
    { addQueryPrefix: true }
  )}`;

export const getMandateURL = (
  userId: string,
  listingId: number,
  preview?: boolean
) =>
  `${getContractsURL(userId, listingId)}/mandate${qs.stringify(
    { preview },
    { addQueryPrefix: true }
  )}`;

export const getSignURL = (userId: string, listingId: number) =>
  `${getContractsURL(userId, listingId)}/sign`;

export const isCommissionTooLow = (
  thresholds: CommissionDefaultInfos,
  contract: ContractProps
) =>
  thresholds.percentage > contract.sellerFeePercentage ||
  thresholds.minimum > renderCommissionValue(contract);

export const getContractTypeLabel = (contratType?: ContractType): string => {
  switch (contratType) {
    case ContractType.FULL:
      return 'mandate';
    case ContractType.AMENDMENT:
      return 'amendment';
    case ContractType.OFFER:
      return 'offer';
    default:
      return 'unknown';
  }
};

export const getContractApiUrl = ({ documentType, id }: ContractProps) =>
  `/api/sales/${getContractTypeLabel(documentType)}/${id}`;

export const findUpdatedSignatories = (
  originalMandate: MandateProps | OfferProps,
  updatedMandate: MandateProps | OfferProps
) =>
  updatedMandate.signatories.filter(signatory => {
    const originalSignatory = originalMandate.signatories.find(
      ({ id }) => id === signatory.id
    );

    return (
      !originalSignatory ||
      JSON.stringify(originalSignatory) !== JSON.stringify(signatory)
    );
  });

export const getUserType = (contractType: ContractType): CustomerProfile => {
  switch (contractType) {
    case ContractType.FULL:
    case ContractType.AMENDMENT:
      return CustomerProfile.SELLER;
    case ContractType.OFFER:
      return CustomerProfile.BUYER;
    default:
      return CustomerProfile.SELLER;
  }
};

export const generateEmptySignatory = (
  userId: string,
  contractType: ContractType,
  entityId?: string
): ContractSignatory => ({
  createdDate: new Date().toISOString(),
  dateOfBirth: null,
  entityId: entityId || null,
  fiscalCode: null,
  id: '',
  legalStatus: null,
  placeOfBirth: null,
  signatoryType: ContractSignatoryType.OTHER,
  signatureDate: null,
  signatureUrl: null,
  userId: userId,
  userType: getUserType(contractType)
});

export const isSignatoryNeeded = (
  contratType: ContractType,
  userType: CustomerProfile
): boolean => {
  switch (contratType) {
    case ContractType.FULL:
    case ContractType.AMENDMENT:
      return userType === CustomerProfile.SELLER;
    case ContractType.OFFER:
      return userType === CustomerProfile.BUYER;
    default:
      return true;
  }
};

export const isUserFormComplete = (user?: CustomerInformation): boolean => {
  try {
    PhysicalSignatoryUserSchema.validateSync(user, { abortEarly: false });
    return true;
  } catch {
    return false;
  }
};

export const isCompanyFormComplete = (company?: Company): boolean => {
  try {
    CompanyFormSchema.validateSync(company, { abortEarly: false });
    return true;
  } catch {
    return false;
  }
};

export const isSignatoryFormComplete = (
  signatory?: ContractSignatory
): boolean => {
  try {
    SignatoryFiscalCodeSchema.validateSync(signatory, { abortEarly: false });
    return true;
  } catch {
    return false;
  }
};

export const isCompanyComplete = (
  signatories: ContractSignatory[],
  company?: Company
): boolean => {
  const signatoryUserIds = signatories.map(({ userId }) => userId);

  return (
    !company ||
    (isCompanyFormComplete(company) &&
      Boolean(company.users.length) &&
      company.users
        .filter(({ user }) => signatoryUserIds.includes(user.id))
        .every(({ user }) => isUserFormComplete(user)))
  );
};

export const isSignatoriesFormComplete = ({
  contract: { signatories },
  users,
  entities
}: ContractFormValues<MandateProps | OfferProps>): boolean =>
  Boolean(signatories.length) &&
  (!users || users.every(isUserFormComplete)) &&
  (!entities ||
    entities.every(({ company }) => isCompanyComplete(signatories, company))) &&
  signatories.length === users?.length;

export const getIsPriceInfoComplete = (
  commission: number,
  commissionValue: number,
  price: number
): boolean =>
  isNotEmpty(commissionValue) && isNotEmpty(commission) && isNotEmpty(price);

export const getPossibleMissingInfo = (
  type: MissingInfoType
): MissingInfoValue[] => {
  const {
    AUTHENTIC_ACT_DATE,
    AUTHENTIC_ACT_UPLOAD,
    COMPROMISE_DATE,
    COMPROMISE_UPLOAD,
    DESCRIPTION,
    FEES,
    LOAN,
    MANDATE_TYPE,
    NOTARY_INVOICE_CHECKBOX,
    NOTARY_INVOICE_UPLOAD,
    OFFER_PRICE,
    PRICE,
    SIGNATORIES,
    TRACFIN_CHECKBOXES,
    TRACFIN_UPLOAD,
    PRIVACY,
    BUYER_DOWN_PAYMENT,
    BUYER_FEES_TO_PAY,
    KYC_UPLOAD,
    BUYER_FEE_PAYMENT_CONFIRMED_UPLOAD,
    SELLER_FEE_PAYMENT_CONFIRMED_UPLOAD,
    BUYER_DOWN_PAYMENT_PRELIMINARY_UPLOAD
  } = MissingInfoValue;

  switch (type) {
    case MissingInfoType.AUTHENTIC_ACT:
      return [
        AUTHENTIC_ACT_DATE,
        AUTHENTIC_ACT_UPLOAD,
        NOTARY_INVOICE_UPLOAD,
        NOTARY_INVOICE_CHECKBOX,
        PRIVACY,
        BUYER_FEE_PAYMENT_CONFIRMED_UPLOAD,
        SELLER_FEE_PAYMENT_CONFIRMED_UPLOAD
      ];
    case MissingInfoType.COMPROMISE:
      return [
        TRACFIN_UPLOAD,
        TRACFIN_CHECKBOXES,
        COMPROMISE_DATE,
        COMPROMISE_UPLOAD,
        BUYER_DOWN_PAYMENT_PRELIMINARY_UPLOAD
      ];
    case MissingInfoType.MANDATE:
      return [DESCRIPTION, PRICE, MANDATE_TYPE, SIGNATORIES];
    case MissingInfoType.OFFER:
      return [
        OFFER_PRICE,
        LOAN,
        SIGNATORIES,
        FEES,
        BUYER_DOWN_PAYMENT,
        BUYER_FEES_TO_PAY,
        KYC_UPLOAD
      ];
    default:
      return [];
  }
};

export const getMissingInfoAnchor = (
  missingInfo: MissingInfoValue
): SaleStepsCardAnchor => {
  const {
    AUTHENTIC_ACT_DATE,
    AUTHENTIC_ACT_UPLOAD,
    COMPROMISE_DATE,
    COMPROMISE_UPLOAD,
    DESCRIPTION,
    FEES,
    LOAN,
    MANDATE_TYPE,
    NOTARY_INVOICE_CHECKBOX,
    NOTARY_INVOICE_UPLOAD,
    OFFER_PRICE,
    SIGNATORIES,
    TRACFIN_CHECKBOXES,
    TRACFIN_UPLOAD,
    PRIVACY,
    BUYER_DOWN_PAYMENT,
    BUYER_FEES_TO_PAY,
    KYC_UPLOAD,
    BUYER_FEE_PAYMENT_CONFIRMED_UPLOAD,
    SELLER_FEE_PAYMENT_CONFIRMED_UPLOAD,
    BUYER_DOWN_PAYMENT_PRELIMINARY_UPLOAD
  } = MissingInfoValue;

  switch (missingInfo) {
    case AUTHENTIC_ACT_DATE:
    case AUTHENTIC_ACT_UPLOAD:
      return SaleStepsCardAnchor.AUTHENTIC_ACT;
    case COMPROMISE_DATE:
    case COMPROMISE_UPLOAD:
    case BUYER_DOWN_PAYMENT_PRELIMINARY_UPLOAD:
      return SaleStepsCardAnchor.COMPROMISE;
    case LOAN:
    case OFFER_PRICE:
    case FEES:
      return SaleStepsCardAnchor.PRICE_AND_LOAN;
    case DESCRIPTION:
      return SaleStepsCardAnchor.MANDATE_DESCRIPTION;
    case MANDATE_TYPE:
      return SaleStepsCardAnchor.MANDATE_TYPE_PICKER;
    case NOTARY_INVOICE_CHECKBOX:
    case NOTARY_INVOICE_UPLOAD:
      return SaleStepsCardAnchor.NOTARY_INVOICE;
    case SIGNATORIES:
      return SaleStepsCardAnchor.SIGNATORIES;
    case TRACFIN_CHECKBOXES:
    case TRACFIN_UPLOAD:
      return SaleStepsCardAnchor.TRACFIN;
    case PRIVACY:
      return SaleStepsCardAnchor.PRIVACY_ANCHOR;
    case BUYER_DOWN_PAYMENT:
      return SaleStepsCardAnchor.BUYER_DOWN_PAYMENT_ANCHOR;
    case BUYER_FEES_TO_PAY:
      return SaleStepsCardAnchor.BUYER_FEES_TO_PAY_ANCHOR;
    case KYC_UPLOAD:
      return SaleStepsCardAnchor.KYC;
    case BUYER_FEE_PAYMENT_CONFIRMED_UPLOAD:
    case SELLER_FEE_PAYMENT_CONFIRMED_UPLOAD:
      return SaleStepsCardAnchor.BUYER_SELLER_FEE_PAYMENT_CONFIRMED;
    default:
      return SaleStepsCardAnchor.PRICE_AND_FEES;
  }
};

export const getMandateMissingInfo = (
  values: ContractFormValues<MandateProps>,
  isFrenchListing: boolean
): MissingInfoValue[] => {
  const {
    commissionValue,
    contract: {
      sellerFeePercentage,
      price,
      description,
      mandateType,
      exclusivityExpirationDate,
      signatories
    }
  } = values;
  const { PRICE, DESCRIPTION, MANDATE_TYPE, SIGNATORIES } = MissingInfoValue;

  const missingInfo = [];

  if (!isSignatoriesFormComplete(values)) {
    missingInfo.push(SIGNATORIES);
  }

  if (
    mandateType === MandateType.FULL_EXCLUSIVITY &&
    !isNotEmpty(exclusivityExpirationDate)
  ) {
    missingInfo.push(MANDATE_TYPE);
  }

  if (isFrenchListing) {
    if (!isNotEmpty(description)) {
      missingInfo.push(DESCRIPTION);
    }

    if (!getIsPriceInfoComplete(sellerFeePercentage, commissionValue, price)) {
      missingInfo.push(PRICE);
    }
  }

  if (!isFrenchListing) {
    if (
      !signatories
        .filter(({ userType }) => userType === CustomerProfile.SELLER)
        .every(isSignatoryFormComplete)
    ) {
      missingInfo.push(MissingInfoValue.SIGNATORIES);
    }

    if (!areFeesCompleted(values)) {
      missingInfo.push(MissingInfoValue.FEES);
    }

    if (!isNotEmpty(price)) {
      missingInfo.push(MissingInfoValue.PRICE);
    }
  }

  return missingInfo;
};

export const isLoanComplete = ({
  contract: { loanNeeded, loanAmount }
}: ContractFormValues<OfferProps>): boolean =>
  [
    FinancingMethod.APPROVED,
    FinancingMethod.PENDING_APPROVAL,
    FinancingMethod.NO_PAPERWORK
  ].includes(loanNeeded)
    ? isNotEmpty(loanAmount)
    : true;

export const areFeesCompleted = ({
  contract: { sellerFeePercentage, buyerFeePercentage }
}: ContractFormValues<OfferProps | MandateProps>): boolean =>
  !buyerFeePercentage && buyerFeePercentage !== 0
    ? false
    : sellerFeePercentage >= 0 && buyerFeePercentage >= 0;

export const getOfferMissingInfoDocuments = (documents?: DocumentProps[]) => {
  const missingInfo = [];

  const kycDocument = getDocumentsByType(DocumentType.KYC, documents);
  const buyerFeesToPayDocuments = getDocumentsByType(
    DocumentType.BUYER_FEES_TO_PAY,
    documents
  );
  const buyerDownPaymentDocument = getDocumentsByType(
    DocumentType.BUYER_DOWN_PAYMENT,
    documents
  );
  if (!kycDocument?.length) {
    missingInfo.push(MissingInfoValue.KYC_UPLOAD);
  }

  if (!buyerFeesToPayDocuments?.length) {
    missingInfo.push(MissingInfoValue.BUYER_FEES_TO_PAY);
  }

  if (!buyerDownPaymentDocument?.length) {
    missingInfo.push(MissingInfoValue.BUYER_DOWN_PAYMENT);
  }

  return missingInfo;
};

export const getOfferMissingInfo = (
  values: ContractFormValues<OfferProps>,
  isFrenchListing: boolean
): MissingInfoValue[] => {
  const {
    commissionValue,
    contract: { id, price, sellerFeePercentage, signatories },
    users
  } = values;

  const missingInfo: MissingInfoValue[] = [];

  // Be sure we have all needed data before trying to create missing information (to avoid alert block display)
  if (!users?.length || !id) {
    return missingInfo;
  }

  if (!isSignatoriesFormComplete(values)) {
    missingInfo.push(MissingInfoValue.SIGNATORIES);
  }

  if (!isLoanComplete(values)) {
    missingInfo.push(MissingInfoValue.LOAN);
  }

  if (isFrenchListing) {
    if (!getIsPriceInfoComplete(sellerFeePercentage, commissionValue, price)) {
      missingInfo.push(MissingInfoValue.OFFER_PRICE);
    }
  }

  if (!isFrenchListing) {
    if (
      !signatories
        .filter(({ userType }) => userType === CustomerProfile.BUYER)
        .every(isSignatoryFormComplete)
    ) {
      missingInfo.push(MissingInfoValue.SIGNATORIES);
    }

    if (!areFeesCompleted(values)) {
      missingInfo.push(MissingInfoValue.FEES);
    }

    if (!isNotEmpty(price)) {
      missingInfo.push(MissingInfoValue.OFFER_PRICE);
    }
  }

  return missingInfo;
};

export const loanNeededOptions: OptionProps[] = [
  { label: 'loanApproved', value: FinancingMethod.APPROVED },
  { label: 'loanPendingApproval', value: FinancingMethod.PENDING_APPROVAL },
  { label: 'loanNoPaperwork', value: FinancingMethod.NO_PAPERWORK },
  { label: 'loanNoNeed', value: FinancingMethod.NO_NEED }
];

export const loanSuspensionOption: OptionProps[] = [
  { label: 'yes', value: 'true' },
  { label: 'no', value: 'false' }
];

export const normalizeSimpleBoolean = (required: boolean): string =>
  required ? 'true' : 'false';

export const getOfferURL = (
  userId: string,
  listingId: number,
  offerId: string,
  preview?: boolean
) =>
  `/customer/${userId}/listing/${listingId}/offer/${offerId}${qs.stringify(
    { preview },
    { addQueryPrefix: true }
  )}`;

export const convertContractTypeToDocumentType = (
  contractType?: ContractType
): DocumentType => {
  switch (contractType) {
    case ContractType.AMENDMENT:
      return DocumentType.AMENDMENT;
    case ContractType.OFFER:
      return DocumentType.OFFER;
    default:
      return DocumentType.MANDATE;
  }
};

export const convertContractToDocument = (
  { modifiedDate, documentType, id }: ContractProps,
  filename: string
): DocumentProps => ({
  createdBy: '',
  createdDate: modifiedDate,
  documentType: convertContractTypeToDocumentType(documentType),
  filename,
  id,
  path: '',
  referenceId: ''
});

export const isMandateSigned = (estimationStage: EstimationStage) =>
  [EstimationStage.ON_HOLD].includes(estimationStage) ||
  isListingOnSale(estimationStage) ||
  isListingSold(estimationStage);

export const isSigningContract = (status: ContractStatus) =>
  [
    ContractStatus.SENT_TO_CLIENTS,
    ContractStatus.SENT_TO_SELLERS,
    ContractStatus.VALIDATION_BY_AGENT
  ].includes(status);

export const isContractSigned = (status: ContractStatus) =>
  [ContractStatus.SIGNED, ContractStatus.WITHDRAWN].includes(status);

export const isAgentValidationNeeded = ({
  documentType,
  status
}: ContractProps) =>
  documentType === ContractType.OFFER &&
  status === ContractStatus.VALIDATION_BY_AGENT;

export const isReminderAvailable = (status: ContractStatus) =>
  status === ContractStatus.SENT_TO_CLIENTS ||
  status === ContractStatus.SENT_TO_SELLERS;

export const isContractLinkSent = (
  status: ContractStatus,
  documentType: ContractType,
  isSeller: boolean
) =>
  Boolean(isSeller && documentType !== ContractType.OFFER) ||
  Boolean(isSeller && status === ContractStatus.SENT_TO_SELLERS) ||
  Boolean(!isSeller && status === ContractStatus.SENT_TO_CLIENTS) ||
  false;

export const isContractFormAvailable = (status: ContractStatus) =>
  status === ContractStatus.IN_EDITION || status === ContractStatus.GENERATED;

export const getSelectOptions = () => {
  return {
    jobPositionOptions: [
      { label: 'manager', value: JobPosition.LEADER },
      { label: 'other', value: JobPosition.OTHER },
      { label: 'signatory', value: JobPosition.SIGNATORY }
    ] as OptionProps[],
    legalFormOptions: [
      { label: 'indivision', value: CompanyLegalForm.JOINT_OWNERSHIP },
      { label: 'sa', value: CompanyLegalForm.SA },
      { label: 'sarl', value: CompanyLegalForm.SARL },
      { label: 'sas', value: CompanyLegalForm.SAS },
      { label: 'sasu', value: CompanyLegalForm.SASU },
      { label: 'sci', value: CompanyLegalForm.SCI },
      { label: 'scpi', value: CompanyLegalForm.SCPI }
    ] as OptionProps[],
    mandateTypeOptions: [
      { label: 'simple', value: MandateType.SIMPLE },
      { label: 'exclusiveMandate', value: MandateType.FULL_EXCLUSIVITY }
    ],
    martialStatusOptions: [
      { label: 'single', value: ContractSignatoryLegalStatus.SINGLE },
      { label: 'widow', value: ContractSignatoryLegalStatus.WIDOW },
      { label: 'married', value: ContractSignatoryLegalStatus.MARRIED },
      { label: 'divorced', value: ContractSignatoryLegalStatus.DIVORCED },
      { label: 'pacsed', value: ContractSignatoryLegalStatus.PACSED }
    ] as OptionProps[]
  };
};

export const filterSignatoriesByType = (
  type: CustomerProfile,
  signatories?: ContractSignatory[]
) =>
  signatories ? signatories.filter(({ userType }) => userType === type) : [];

export const scrollElementIntoView = (id: numberOrString) => {
  const element = document.getElementById(`${id}`);

  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};

export const extractUsersWithJobFromCompany = (
  company: Company
): CompanyUserWithJob[] =>
  company.users.map(({ user, jobPosition }) => ({
    jobPosition,
    userId: user.id
  }));

export const extractCompanyCreationFields = (
  company: Company
): CompanyCreationFields => {
  const {
    city,
    cs,
    denomination,
    legalForm,
    postCode,
    rcs,
    street,
    streetNumber
  } = company;
  const usersWithJob: CompanyUserWithJob[] =
    extractUsersWithJobFromCompany(company);

  return {
    city,
    cs,
    denomination,
    legalForm,
    postalcode: postCode,
    rcs,
    street,
    streetnumber: streetNumber,
    users: usersWithJob
  };
};

const extractPricesHistory = (listing: ListingHistoryProps[]): PriceHistory[] =>
  listing
    .filter(({ prix }) => isDefined(prix))
    .map(({ prix, effective_start_date }) => ({
      date: parseGMTDate(effective_start_date),
      price: prix || 0
    }));

const removeDuplicatePrices = (
  pricesHistory: PriceHistory[]
): FormattedHistory[] =>
  formatPriceHistory(pricesHistory).filter(
    ({ price, previousPrice }) => price !== previousPrice
  );

export const cleanListingPriceHistory = (
  listings: ListingHistoryProps[]
): FormattedHistory[] =>
  listings ? removeDuplicatePrices(extractPricesHistory(listings)) : [];

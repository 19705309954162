import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { FC, Fragment, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { leadViewSelector } from '@/components/state/LeadViewState';

import { LeadViewSection, LeadViewTab } from '../../interfaces';
import { formatUrlQuery, getSection } from '../../LeadView.utils';
import Filter from '../filters/filter/Filter';
import { LeadViewFilters, LeadViewFiltersCount } from '../filters/interfaces';
import {
  getActiveFilters,
  getFiltersFromUrlParams
} from '../filters/LeadFilters.utils';
import { Layout } from './LeadFilters.styles';

export type LeadFiltersProps = {
  currentTab: LeadViewTab;
  opportunitiesCountPerFilter: LeadViewFiltersCount;
};

const LeadFilters: FC<LeadFiltersProps> = ({
  currentTab,
  opportunitiesCountPerFilter
}) => {
  const { t } = useTranslation();
  const { push, query } = useRouter();
  const [leadViewState, setLeadViewState] = useRecoilState(leadViewSelector);

  const { filter } = query;
  const { leadViewFilters } = leadViewState;
  const {
    isHighPriorityLead,
    hasPausedListing,
    isNearingExclusivityEnd,
    hasOldPrice,
    hasListingWithoutVisit,
    hasLateSignature,
    hasSignatureToSchedule
  } = leadViewFilters;
  const {
    highPriorityLeadsCount,
    signaturesToScheduleCount,
    lateSignaturesCount,
    listingsWithoutVisitCount,
    nearingExclusivityEndCount,
    oldPricesCount,
    pausedListingsCount
  } = opportunitiesCountPerFilter;

  const currentSection = useMemo(() => getSection(currentTab), [currentTab]);

  const updateFilters = (newFilters: LeadViewFilters) =>
    setLeadViewState({
      ...leadViewState,
      leadViewFilters: newFilters
    });

  const handleOnChange = async (value: LeadViewFilters) => {
    updateFilters(value);
    await push(formatUrlQuery({ ...query, filter: getActiveFilters(value) }));
  };

  useEffect(() => {
    const urlFilters = getFiltersFromUrlParams(filter);

    if (JSON.stringify(urlFilters) !== JSON.stringify(leadViewFilters)) {
      updateFilters(urlFilters);
    }
  }, [filter]);

  switch (currentSection) {
    case LeadViewSection.PROSPECTS:
      return (
        <Layout>
          <Filter
            label={t('highPriority', {
              count: highPriorityLeadsCount
            })}
            opportunitiesCount={highPriorityLeadsCount}
            checked={isHighPriorityLead}
            onChange={checked =>
              handleOnChange({
                ...leadViewFilters,
                isHighPriorityLead: checked
              })
            }
            name="high-priority-lead"
          />
        </Layout>
      );
    case LeadViewSection.SELLING:
      return (
        <Layout>
          <Filter
            label={t('listingWithoutVisit', {
              count: listingsWithoutVisitCount
            })}
            opportunitiesCount={listingsWithoutVisitCount}
            checked={hasListingWithoutVisit}
            onChange={checked =>
              handleOnChange({
                ...leadViewFilters,
                hasListingWithoutVisit: checked
              })
            }
            name="listing-without-visit"
          />
          <Filter
            label={t('extendedListingPaused', { count: pausedListingsCount })}
            checked={hasPausedListing}
            opportunitiesCount={pausedListingsCount}
            onChange={checked =>
              handleOnChange({
                ...leadViewFilters,
                hasPausedListing: checked
              })
            }
            name="listing-paused"
            tooltipLabel={t('extendedListingPausedHelper')}
          />
          <Filter
            label={t('exclusivityEndIn', {
              count: nearingExclusivityEndCount
            })}
            checked={isNearingExclusivityEnd}
            opportunitiesCount={nearingExclusivityEndCount}
            onChange={checked =>
              handleOnChange({
                ...leadViewFilters,
                isNearingExclusivityEnd: checked
              })
            }
            name="nearing-exclusivity-end"
            tooltipLabel={t('exclusivityEndInHelper')}
          />
          <Filter
            label={t('thiryDaysAtSamePrice', {
              opportunitiesCount: oldPricesCount
            })}
            checked={hasOldPrice}
            opportunitiesCount={oldPricesCount}
            onChange={checked =>
              handleOnChange({
                ...leadViewFilters,
                hasOldPrice: checked
              })
            }
            name="old-price"
            tooltipLabel={t('thiryDaysAtSamePriceHelper')}
          />
        </Layout>
      );
    case LeadViewSection.SALE:
      return (
        <Layout>
          <Filter
            label={t('signatureToSchedule', {
              count: signaturesToScheduleCount
            })}
            checked={hasSignatureToSchedule}
            opportunitiesCount={signaturesToScheduleCount}
            onChange={checked =>
              handleOnChange({
                ...leadViewFilters,
                hasSignatureToSchedule: checked
              })
            }
            name="signature-to-schedule"
            tooltipLabel={t('signatureToScheduleHelper')}
          />
          <Filter
            label={t('lateSignature', { count: lateSignaturesCount })}
            checked={hasLateSignature}
            opportunitiesCount={lateSignaturesCount}
            onChange={checked =>
              handleOnChange({
                ...leadViewFilters,
                hasLateSignature: checked
              })
            }
            name="late-signature"
            tooltipLabel={t('lateSignatureHelper')}
          />
        </Layout>
      );
    default:
      return <Fragment />;
  }
};

export default LeadFilters;

import { FC } from 'react';

import CasavoAvatar from '@/components/app/avatar/casavoAvatar/CasavoAvatar';
import SellerProjectOwnership from '@/components/app/ownership/sellerProjectOwnership/SellerProjectOwnership';
import { useAgentById } from '@/hooks/agents/useAgentById';

import { LeadViewTableCellProps } from '../interface';
import { AgentsCellLayout } from './AgentsCell.styles';

const AgentsCell: FC<LeadViewTableCellProps> = ({
  opportunity: { agentId, viewingAgent, estimationStage }
}) => {
  const ownerAgentById = useAgentById(agentId);
  const viewingAgentById = useAgentById(viewingAgent);

  return (
    <AgentsCellLayout>
      {ownerAgentById ? (
        <SellerProjectOwnership
          agentOwner={ownerAgentById}
          estimationStage={estimationStage}
          viewingAgent={viewingAgentById}
        />
      ) : (
        <CasavoAvatar />
      )}
    </AgentsCellLayout>
  );
};

export default AgentsCell;

import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useMemo } from 'react';

import Activity from '@/components/myCustomers/common/activity/Activity';
import {
  DEFAULT_TABLE_PAGE_SIZE,
  sortByDate
} from '@/components/myCustomers/myCustomers.utils';
import { FEATURE_NAME } from '@/hooks/useUserFeaturePermissions/interfaces';
import { useUserFeaturePermissions } from '@/hooks/useUserFeaturePermissions/useUserFeaturePermissions';
import { getColumnVisibility } from '@/utils/reactTable.utils';
import { useMobileDevice } from '@proprioo/salatim';
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  useReactTable
} from '@tanstack/react-table';

import {
  LeadViewSection,
  LeadViewTableColumns,
  LeadViewTableData,
  SaleOpportunity
} from '../../interfaces';
import { sortByEventDate, sortByRevenue } from '../../LeadView.utils';
import ArchiveDate from '../common/archiveDate/ArchiveDate';
import ArchiveReason from '../common/archiveReason/ArchiveReason';
import AgentsCell from './cells/agentsCell/AgentsCell';
import CustomerStatusCell from './cells/customerStatusCell/CustomerStatusCell';
import LeadCustomerCell from './cells/leadCustomerCell/LeadCustomerCell';
import ListingCell from './cells/listingCell/ListingCell';
import RevenueCell from './cells/revenueCell/RevenueCell';
import StatusCell from './cells/statusCell/StatusCell';
import { getHiddenColumns } from './DesktopLeads.utils';

export type LeadViewTableHookProps = {
  isTeamLead: boolean;
  pageIndex: number;
  opportunities: SaleOpportunity[];
  section: LeadViewSection;
};

export const useLeadViewTable = ({
  isTeamLead,
  pageIndex,
  opportunities,
  section
}: LeadViewTableHookProps) => {
  const { t } = useTranslation();

  const hasAccessToRevenueForecast = useUserFeaturePermissions(
    FEATURE_NAME.MY_SELLERS_REVENUE_FORECAST
  );
  const isMobileDevice = useMobileDevice();

  const sortPastEventColumn = useCallback(
    (rowA: Row<LeadViewTableData>, rowB: Row<LeadViewTableData>) =>
      sortByEventDate(
        rowA.original.opportunity.lastEvent,
        rowB.original.opportunity.lastEvent
      ),
    []
  );

  const sortNextEventColumn = useCallback(
    (rowA: Row<LeadViewTableData>, rowB: Row<LeadViewTableData>) =>
      sortByEventDate(
        rowA.original.opportunity.nextEvent,
        rowB.original.opportunity.nextEvent
      ),
    []
  );

  const sortArchiveDateColumn = useCallback(
    (rowA: Row<LeadViewTableData>, rowB: Row<LeadViewTableData>) =>
      sortByDate(
        rowA.original.opportunity.archiveDate,
        rowB.original.opportunity.archiveDate
      ),
    []
  );

  const sortRevenueColumn = useCallback(
    (rowA: Row<LeadViewTableData>, rowB: Row<LeadViewTableData>): number =>
      sortByRevenue(rowA.original.opportunity, rowB.original.opportunity),
    []
  );

  const columnHelper = createColumnHelper<LeadViewTableData>();

  const tableColumns = useMemo(
    () => [
      columnHelper.accessor(({ opportunity }) => ({ opportunity }), {
        cell: opportunity => <LeadCustomerCell {...opportunity.getValue()} />,
        enableSorting: false,
        header: () => t('contact'),
        id: LeadViewTableColumns.CONTACT
      }),
      columnHelper.accessor(({ opportunity }) => ({ opportunity }), {
        cell: opportunity => <StatusCell {...opportunity.getValue()} />,
        enableSorting: false,
        header: () => t('statusAndTreatmentPriority'),
        id: LeadViewTableColumns.STATUS
      }),
      columnHelper.accessor(({ opportunity }) => ({ opportunity }), {
        cell: opportunity => <StatusCell {...opportunity.getValue()} />,
        enableSorting: false,
        header: () => t('archiveStatus'),
        id: LeadViewTableColumns.ARCHIVED_STATUS
      }),
      columnHelper.accessor(({ opportunity }) => ({ opportunity }), {
        cell: opportunity => (
          <ListingCell {...opportunity.getValue()} showRevenue={false} />
        ),
        enableSorting: false,
        header: () => t('listingInformation'),
        id: LeadViewTableColumns.LISTING
      }),
      columnHelper.accessor(({ opportunity }) => ({ opportunity }), {
        cell: opportunity => <RevenueCell {...opportunity.getValue()} />,
        header: () => t('forecastSales'),
        id: LeadViewTableColumns.REVENUE,
        sortingFn: sortRevenueColumn
      }),
      columnHelper.accessor(({ opportunity }) => ({ opportunity }), {
        cell: opportunity => <AgentsCell {...opportunity.getValue()} />,
        enableSorting: false,
        header: () => t('agent'),
        id: LeadViewTableColumns.AGENTS
      }),
      columnHelper.accessor(({ opportunity }) => ({ opportunity }), {
        cell: opportunity => <CustomerStatusCell {...opportunity.getValue()} />,
        enableSorting: false,
        header: () => t('contactAndStatus'),
        id: LeadViewTableColumns.CONTACT_STATUS
      }),
      columnHelper.accessor(({ opportunity }) => ({ opportunity }), {
        cell: opportunity => <CustomerStatusCell {...opportunity.getValue()} />,
        enableSorting: false,
        header: () => t('contactAndArchiveStatus'),
        id: LeadViewTableColumns.ARCHIVED_CONTACT_STATUS
      }),
      columnHelper.accessor(({ opportunity }) => ({ opportunity }), {
        cell: opportunity => (
          <ListingCell {...opportunity.getValue()} showRevenue={true} />
        ),
        enableSorting: false,
        header: () => t('listingInfoAndSalesForecastSales'),
        id: LeadViewTableColumns.LISTING_REVENUE
      }),
      columnHelper.accessor('opportunity', {
        cell: opportunity => (
          <Activity
            event={opportunity.getValue().lastEvent}
            isDateHighlighted={false}
            isMultiline={true}
          />
        ),
        header: () => t('done_other'),
        id: LeadViewTableColumns.PAST_EVENT,
        sortDescFirst: false,
        sortingFn: sortPastEventColumn
      }),
      columnHelper.accessor('opportunity', {
        cell: opportunity => (
          <Activity
            event={opportunity.getValue().nextEvent}
            isDateHighlighted={true}
            isMultiline={true}
          />
        ),
        header: () => t('toBeDone_other'),
        id: LeadViewTableColumns.UPCOMING_EVENT,
        sortDescFirst: true,
        sortingFn: sortNextEventColumn
      }),
      columnHelper.accessor(
        ({ opportunity: { estimationStage } }) => estimationStage,
        {
          cell: estimationStage => (
            <ArchiveReason estimationStage={estimationStage.getValue()} />
          ),
          enableSorting: false,
          header: () => t('archiveReason'),
          id: LeadViewTableColumns.ARCHIVED_REASON
        }
      ),
      columnHelper.accessor('opportunity', {
        cell: opportunity => (
          <ArchiveDate archiveDate={opportunity.getValue().archiveDate} />
        ),
        header: () => t('archiveDate'),
        id: LeadViewTableColumns.ARCHIVED_DATE,
        sortingFn: sortArchiveDateColumn
      })
    ],
    []
  );

  const tableData = useMemo(
    () => opportunities.map(opportunity => ({ opportunity })),
    [opportunities]
  );

  const tableHook = useReactTable<LeadViewTableData>({
    autoResetPageIndex: false,
    columns: tableColumns,
    data: tableData,
    enableMultiSort: false,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      pagination: {
        pageIndex,
        pageSize: DEFAULT_TABLE_PAGE_SIZE
      },
      sorting: [{ desc: false, id: LeadViewTableColumns.UPCOMING_EVENT }]
    },
    state: {
      columnVisibility: getColumnVisibility(
        Object.values(LeadViewTableColumns),
        getHiddenColumns(
          isMobileDevice,
          isTeamLead,
          section,
          hasAccessToRevenueForecast
        )
      )
    }
  });

  const { getState, setPageIndex } = tableHook;
  const { pageIndex: currentPageIndex } = getState().pagination;

  useEffect(() => {
    if (pageIndex !== currentPageIndex) {
      setPageIndex(pageIndex);
    }
  }, [pageIndex]);

  return tableHook;
};

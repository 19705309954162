import styled, { css } from 'styled-components';

import {
  breakpoints,
  colors,
  convertPxToRem,
  paragraphs
} from '@proprioo/salatim';

const ACTIVE_HOVER_LINK = css`
  background-color: ${colors.blue.base};
  color: ${colors.green.base};

  svg {
    path {
      fill: ${colors.green.base};
    }
  }
`;

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${convertPxToRem(16)} auto;
  width: 100%;
  max-height: 60vh;
  overflow-y: auto;

  @media (min-width: ${breakpoints.small}px) {
    padding: 0;
    margin: ${convertPxToRem(16)} 0 0;
  }

  a {
    display: block;
    width: 100%;
  }
`;

export const ToolItem = styled.div<{ isOpen: boolean; isActive?: boolean }>`
  ${paragraphs.bodyLight2};
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: white;
  width: 100%;
  transition: all 0.2s ease-out;
  padding: ${convertPxToRem(10)} ${convertPxToRem(12)};

  :hover {
    ${ACTIVE_HOVER_LINK};
  }

  svg {
    width: ${convertPxToRem(24)};
    height: ${convertPxToRem(24)};
    transition: opacity 0.2s ease-out;

    path {
      fill: white;
    }

    > image {
      width: ${convertPxToRem(24)};
      height: ${convertPxToRem(24)};
    }
  }

  @media (min-width: ${breakpoints.small}px) {
    padding: ${convertPxToRem(10)};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      ${ACTIVE_HOVER_LINK};

      :hover {
        background-color: ${colors.blue.base60};
      }
    `};

  ${({ isOpen }) =>
    !isOpen &&
    css`
      flex-direction: row-reverse;
      padding: ${convertPxToRem(10)} ${convertPxToRem(16)};

      ${ItemLabel} {
        opacity: 0;
        transition: opacity 0;
      }

      @media (min-width: ${breakpoints.small}px) {
        padding: ${convertPxToRem(10)} ${convertPxToRem(12)};
      }
    `};
`;

export const ItemLabel = styled.span`
  margin-left: ${convertPxToRem(8)};
  transition: opacity 0.3s ease-out;
`;

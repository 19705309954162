import '../../helpers/wdyr.js'; // Must be import first
import '../css/fonts.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.min.css';
import '@/components/app/freshChat/Freshchat.css';
import '@/components/listing/listingAd/mosaic/carousel/slick-theme.css';
import '@/components/listing/listingAd/mosaic/carousel/slick.css';

import mixpanel from 'mixpanel-browser';
import { appWithTranslation } from 'next-i18next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { FC, Fragment, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { RecoilRoot } from 'recoil';
import { SWRConfig } from 'swr';

import ErrorBoundary from '@/components/app/errorBoundary/ErrorBoundary';
import FreshChat from '@/components/app/freshChat/FreshChat';
import GoogleMapsScript from '@/components/app/googleMapsScript/GoogleMapsScript';
import HistoryManager from '@/components/app/historyManager/HistoryManager';
import Loader from '@/components/app/loader/Loader';
import Routing from '@/components/app/routing/Routing';
import NotificationToast from '@/components/app/toast/NotificationToast';
import VersionManager from '@/components/app/versionManager/VersionManager';
import RecoilAuth from '@/components/state/auth/RecoilAuth';
import { COMPANY_NAME } from '@/constants/global';
import { GOOGLE_TAG_MANAGER, PAGE_VIEW_NAME } from '@/utils/gtm';
import { fetcherSwr } from '@/utils/http';
import { isProduction } from '@proprioo/hokkaido';

const CustomApp: FC<AppProps> = ({ Component, pageProps, router }) => {
  useEffect(() => {
    if (isProduction()) {
      TagManager.initialize({
        dataLayerName: PAGE_VIEW_NAME,
        gtmId: GOOGLE_TAG_MANAGER
      });

      if (window[PAGE_VIEW_NAME]) {
        TagManager.dataLayer({
          dataLayer: {
            originalLocation:
              document.location.protocol +
              '//' +
              document.location.hostname +
              document.location.pathname +
              document.location.search
          },
          dataLayerName: PAGE_VIEW_NAME
        });
      }

      mixpanel.init(`${process.env.NEXT_PUBLIC_MIX_PANEL_TOKEN}`);
    }
  }, []);

  return (
    <Fragment>
      <Head>
        <title>{COMPANY_NAME}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <SWRConfig
        value={{
          fetcher: fetcherSwr,
          focusThrottleInterval: 60000,
          revalidateOnFocus: isProduction(),
          shouldRetryOnError: false
        }}
      >
        <RecoilRoot>
          <Routing />
          <NotificationToast />
          <RecoilAuth>
            <VersionManager />
            <HistoryManager />
            <Loader />
            <ErrorBoundary key={router.pathname}>
              <GoogleMapsScript>
                <Component {...pageProps} />
              </GoogleMapsScript>
            </ErrorBoundary>
            <FreshChat />
          </RecoilAuth>
        </RecoilRoot>
      </SWRConfig>
    </Fragment>
  );
};

export default appWithTranslation(CustomApp);

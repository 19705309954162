import { nullOrNumber } from '@proprioo/hokkaido';

import { useAgentById } from '../agents/useAgentById';
import { useSquads } from './useSquads';

export const useSquadsByAgentId = (agentId?: nullOrNumber) => {
  const agent = useAgentById(agentId);
  const squads = useSquads();

  if (!agent) {
    return [];
  }

  return agent.squads
    .map(squad => ({ squadId: squad.id, squadRole: squad.role }))
    .map(({ squadId, squadRole }) => ({
      ...squads[squadId],
      currentAgentSquadRole: squadRole
    }));
};

import { SinceSentenceKey } from './interfaces';

export const getSinceSentenceLabels = (key: SinceSentenceKey) => {
  switch (key) {
    case SinceSentenceKey.MARKETED:
      return {
        sinceDayLabel: 'marketedSinceDay',
        sinceMonthLabel: 'marketedSinceMonth',
        sinceTodayLabel: 'marketedSinceToday',
        sinceWeekLabel: 'marketedSinceWeek'
      };
    case SinceSentenceKey.SENT:
      return {
        sinceDayLabel: 'sentSinceDay',
        sinceMonthLabel: 'sentSinceMonth',
        sinceTodayLabel: 'sentSinceToday',
        sinceWeekLabel: 'sentSinceWeek'
      };
    case SinceSentenceKey.SOLD:
      return {
        sinceDayLabel: 'soldSinceDay',
        sinceMonthLabel: 'soldSinceMonth',
        sinceTodayLabel: 'soldSinceToday',
        sinceWeekLabel: 'soldSinceWeek'
      };
  }
};

import styled, { css } from 'styled-components';

import { convertPxToRem } from '@proprioo/salatim';

const MARGIN = convertPxToRem(56);

export const NotificationLayout = styled.div<{ isMobile: boolean }>`
  .Toastify__toast-container {
    ${({ isMobile }) =>
      isMobile
        ? css`
            width: 100vw;
            margin-top: ${MARGIN};
          `
        : css`
            width: ${convertPxToRem(500)};
            margin-left: ${MARGIN};
          `}
  }

  .Toastify__toast-body {
    padding: 0;
  }

  .Toastify__toast {
    background: transparent;
    padding: 0;
    min-height: ${convertPxToRem(52)};
  }
`;

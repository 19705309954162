import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import qs from 'qs';
import { Fragment } from 'react';
import { useRecoilState } from 'recoil';
import useSWR from 'swr';

import CalendarCheckIcon from '@/assets/icons/calendar-check.svg';
import { ActivityNotifications } from '@/components/activity/interfaces';
import Bullet from '@/components/myCustomers/common/bullet/Bullet';
import { BulletColor } from '@/components/myCustomers/common/bullet/Bullet.interfaces';
import { menuSelector } from '@/components/state/MenuState';
import { useCurrentAgent } from '@/hooks/agents/useCurrentAgent';
import { useActiveLink } from '@/hooks/useActiveLink/useActiveLink';

import { ItemLabel, ToolItem } from '../MenuItems.styles';
import { IconLayout, IconWrapper } from './MyTasksItem.styles';

const MyTasksItems = () => {
  const { t } = useTranslation();

  const currentAgent = useCurrentAgent();
  const isActive = useActiveLink('/agent/[agentId]/task-manager');
  const [isOpen, updateMenu] = useRecoilState(menuSelector);

  const params = qs.stringify(
    { owner: currentAgent?.email || null },
    { addQueryPrefix: true, encode: true, skipNulls: true }
  );

  const { data } = useSWR<ActivityNotifications>(
    params ? `/api/activity/notifications${params}` : null,
    { refreshInterval: 1000 * 60 * 5 }
  );

  const openMenu = () => {
    if (!isOpen) {
      updateMenu(true);
    }
  };

  return (
    <Fragment>
      {currentAgent?.id && (
        <Link
          href={`/agent/${currentAgent.id}/task-manager`}
          id="sidebar-menu_task-manager"
          onMouseEnter={openMenu}
        >
          <ToolItem isOpen={isOpen} isActive={isActive}>
            <IconWrapper>
              {data && !isActive && (
                <IconLayout>
                  {Boolean(data.todo) && (
                    <Bullet
                      color={BulletColor.BLUE}
                      label="someTasksAreLateForToday"
                    />
                  )}
                  {Boolean(data.late) && (
                    <Bullet color={BulletColor.RED} label="someTasksAreLate" />
                  )}
                </IconLayout>
              )}
              <CalendarCheckIcon />
            </IconWrapper>
            <ItemLabel>{t('myActivities')}</ItemLabel>
          </ToolItem>
        </Link>
      )}
    </Fragment>
  );
};

export default MyTasksItems;

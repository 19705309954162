import { FC } from 'react';

import { useLanguage } from '@/hooks/useLanguage/useLanguage';
import { formatPrice } from '@proprioo/hokkaido';

import { Price } from './PriceCell.styles';

type PriceCellProps = {
  price: number;
};

const PriceCell: FC<PriceCellProps> = ({ price }) => {
  const { currentLocale } = useLanguage();

  return <Price>{formatPrice(price, currentLocale)}</Price>;
};

export default PriceCell;

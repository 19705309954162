import styled, { css } from 'styled-components';

import { breakpoints, convertPxToRem } from '@proprioo/salatim';

export const Layout = styled.div`
  margin-top: ${convertPxToRem(16)};
`;

export const LayoutButtons = styled.div<{ isEnd?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  width: 100%;

  button + button,
  a + div,
  a + button,
  button + a,
  div + a,
  button + span,
  span + button {
    margin: ${convertPxToRem(16)} 0 0;
  }

  & + & {
    margin-top: ${convertPxToRem(16)};
  }

  @media (min-width: ${breakpoints.xsmall}px) {
    flex-direction: row;
    justify-content: space-between;

    button + button,
    a + div,
    a + button,
    button + a,
    div + a,
    button + span,
    span + button {
      margin: 0 0 0 ${convertPxToRem(16)};
    }

    & + & {
      margin: 0;
    }

    ${({ isEnd }) =>
      isEnd &&
      css`
        justify-content: flex-end;
      `}
  }
`;

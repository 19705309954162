import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useReducer,
  useState
} from 'react';

import { SaveProps } from './ActionBarContext.interfaces';
import {
  ActionBarContextActions,
  reducerActionBarContext
} from './ActionBarContext.reducer';

type SaveActions = {
  onFail(): void;
  onReset(): void;
  onSuccess(): void;
};

type ActionsProps = {
  saveOptions?: SaveProps;
};

export type ActionBarContext = {
  actionBar: ActionsProps;
  saveActions: SaveActions;
  overwriteSaveOptions(saveOptions: SaveProps): void;
  updateSaveOptions(saveOptions: Partial<SaveProps>): void;
  updateSaveActions(saveActions: SaveActions): void;
};

const defaultActionBarContext: ActionBarContext = {
  actionBar: {},
  overwriteSaveOptions: () => {
    console.error('Please init ActionBarContext before using update function');
  },
  saveActions: {
    onFail: () => ({}),
    onReset: () => ({}),
    onSuccess: () => ({})
  },
  updateSaveActions: () => {
    console.error('Please init ActionBarContext before using update function');
  },
  updateSaveOptions: () => {
    console.error('Please init ActionBarContext before using update function');
  }
};

const ActionBarContext = createContext<ActionBarContext>(
  defaultActionBarContext
);

export const useActionBarContext = () => useContext(ActionBarContext);

const ActionBarProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(
    reducerActionBarContext,
    defaultActionBarContext.actionBar
  );
  const [saveActions, setSaveActions] = useState<SaveActions>(
    defaultActionBarContext.saveActions
  );

  const getActionBar: ActionBarContext = {
    ...defaultActionBarContext,
    actionBar: state,
    overwriteSaveOptions: (saveOptions: SaveProps) =>
      dispatch(ActionBarContextActions.initSaveOptions({ saveOptions })),
    saveActions,
    updateSaveActions: (newSaveActions: Partial<SaveActions>) =>
      setSaveActions({ ...saveActions, ...newSaveActions }),
    updateSaveOptions: (saveOptions: Partial<SaveProps>) =>
      dispatch(ActionBarContextActions.updateSaveOptions({ saveOptions }))
  };

  return (
    <ActionBarContext.Provider value={getActionBar}>
      {children}
    </ActionBarContext.Provider>
  );
};

export default ActionBarProvider;

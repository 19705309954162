import { FC } from 'react';

import { getFormatUserName } from '@/utils/user';

import { ImportantInfo, PrimaryInfo } from '../styles/MyCustomers.styles';

export type CustomerNameProps = {
  isImportant: boolean;
  firstName?: string;
  lastName?: string;
};

const CustomerName: FC<CustomerNameProps> = ({
  firstName,
  lastName,
  isImportant
}) => {
  const customerFullName = getFormatUserName(firstName || '', lastName || '');

  return isImportant ? (
    <ImportantInfo data-test="important-info">{customerFullName}</ImportantInfo>
  ) : (
    <PrimaryInfo>{customerFullName}</PrimaryInfo>
  );
};

export default CustomerName;

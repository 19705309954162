import styled, { css } from 'styled-components';

import {
  breakpoints,
  colors,
  convertPxToRem,
  paragraphs,
  radius,
  scrollbar
} from '@proprioo/salatim';

export const SelectLayout = styled.div<{ isSelectOpen: boolean }>`
  position: relative;
  z-index: 2;
  width: 100%;
  height: ${convertPxToRem(40)};
  margin-right: -${convertPxToRem(1)};

  > svg {
    position: absolute;
    z-index: 1;
    top: ${convertPxToRem(8)};
    right: ${convertPxToRem(8)};
    width: ${convertPxToRem(24)};
    height: ${convertPxToRem(24)};
    transition: transform 0.2s ease-out;
    cursor: pointer;

    ${({ isSelectOpen }) =>
      isSelectOpen &&
      css`
        transform: rotate(180deg);
      `}
  }

  @media (min-width: ${breakpoints.small}px) {
    height: auto;
    width: 40%;
  }

  @media (min-width: ${breakpoints.normal}px) {
    width: 30%;
  }
`;

export const Select = styled.input`
  ${paragraphs.body1};
  position: relative;
  width: 100%;
  height: 100%;
  padding-left: ${convertPxToRem(24)};
  border: ${convertPxToRem(1)} solid ${colors.grey.base40};
  outline: none;
  cursor: pointer;
  border-radius: ${radius.biggest};

  @media (min-width: ${breakpoints.small}px) {
    border-radius: ${radius.biggest} 0 0 ${radius.biggest};
  }

  :focus {
    border-color: ${colors.terracota.base};
  }
`;

export const OptionsLayout = styled.div`
  position: absolute;
  z-index: 1;
  top: ${convertPxToRem(50)};
  width: 100%;
  padding: ${convertPxToRem(20)};
  border: ${convertPxToRem(1)} solid ${colors.terracota.base};
  border-radius: ${radius.biggest};
  background: white;
  overflow: hidden;
`;

export const OptionsContainer = styled.div`
  ${scrollbar};
  height: 100%;
  overflow: auto;
  cursor: pointer;
`;

export const OptionLayout = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: ${convertPxToRem(32)};
  padding: 0 ${convertPxToRem(12)} 0 ${convertPxToRem(12)};
  margin-right: ${convertPxToRem(4)};
  border-radius: ${radius.biggest};
  color: ${colors.dark.base};
  cursor: pointer;
  transition: 0.2s background-color ease-out;

  & + & {
    margin-top: ${convertPxToRem(2)};
  }

  :hover {
    background-color: ${colors.terracota.base20};
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${colors.terracota.base20};
    `}
`;

export const OptionLabel = styled.span`
  ${paragraphs.body2};
  width: 100%;
  padding-right: ${convertPxToRem(32)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

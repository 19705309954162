import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import qs from 'qs';
import { Fragment } from 'react';
import { useRecoilState } from 'recoil';

import JusticeHammerIcon from '@/assets/vectors/menu/justice-hammer.svg';
import { menuSelector } from '@/components/state/MenuState';
import { useCurrentAgent } from '@/hooks/agents/useCurrentAgent';
import { useActiveLink } from '@/hooks/useActiveLink/useActiveLink';
import { QS_ARRAY_PARAMS } from '@/utils/helpers';

import { ItemLabel, ToolItem } from '../MenuItems.styles';

const BusinessStockExchangeItem = () => {
  const { t } = useTranslation();

  const [isOpen, updateMenu] = useRecoilState(menuSelector);
  const currentAgent = useCurrentAgent();
  const isActive = useActiveLink('/business-stock-exchange');

  const businessStockExchangeParams = qs.stringify(
    { agentIds: [currentAgent?.id] },
    QS_ARRAY_PARAMS
  );

  if (!currentAgent?.id) {
    return <Fragment />;
  }

  return (
    <Link
      href={`/business-stock-exchange${businessStockExchangeParams}`}
      id="sidebar-menu_business-stock-exchange"
      onMouseEnter={() => {
        if (!isOpen) {
          updateMenu(true);
        }
      }}
    >
      <ToolItem isActive={isActive} isOpen={isOpen}>
        <JusticeHammerIcon />
        <ItemLabel>{t('businessStockExchange')}</ItemLabel>
      </ToolItem>
    </Link>
  );
};

export default BusinessStockExchangeItem;

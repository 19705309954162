import { ReactElement } from 'react';

import { EmailEntryProps, SMSEntryProps } from '@/utils/interfaces';
import { nullOrDate, nullOrNumber, nullOrString } from '@proprioo/hokkaido';

import { EstimationProps, ListingProps } from '../listing/interfaces';
import { ExternalOpportunityRaw } from '../myCustomers/mySellers/interfaces';
import {
  OnlineAlert,
  OpportunityQualification
} from '../qualification/sellerQualification/interfaces';

export enum ActivityType {
  CALL = 'call',
  SMS = 'sms',
  EMAIL = 'email',
  RDV = 'rdv',
  TASK = 'task'
}

export enum ActivityStatus {
  TO_BE_DONE = 'toBeDone',
  DONE = 'done',
  DELETED = 'deleted'
}

export enum Project {
  ALL_PROJECTS = 'allProjects'
}

export enum EventType {
  SMS = 'sms',
  EMAIL = 'email',
  ACTIVITY = 'activity'
}

export enum ActivityPage {
  EXTERNAL_LEAD = 'external-lead',
  FEED = 'feed',
  TASK = 'task'
}

export type CustomerProps = {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
};

type ListingPartialProps = Pick<
  ListingProps,
  'city' | 'id' | 'street' | 'streetNumber'
>;

type ListingSellerProjectProps = ListingPartialProps & {
  postalCode: string;
};

type OpportunityQualificationPartialProps = Pick<
  OpportunityQualification,
  'city' | 'id' | 'street' | 'streetNumber'
>;

export type SellerProjectProps = OpportunityQualificationPartialProps & {
  postalCode?: string;
  listing?: ListingSellerProjectProps;
};

export type Activity = {
  activityType: ActivityType;
  buyerProject: OnlineAlert | null;
  completionDate: nullOrString;
  content: string;
  createdAt: string;
  customer: CustomerProps | null;
  failed: boolean;
  id: string;
  isAllDay: boolean;
  owner: string;
  pigeProject: ExternalOpportunityRaw | null;
  sellerProject: SellerProjectProps | null;
  status: ActivityStatus;
  targetDate: string;
  modifiedAt?: string;
};

export type ActivityState = {
  project: nullOrString;
  story: string;
  type: string;
  date: nullOrDate;
  schedule: string;
};

export type ActivityMainContent = Omit<ActivityState, 'project'>;

export type ActivityReminder = {
  reminderStory: string;
  reminderType: string;
  reminderDate: nullOrDate;
  reminderSchedule: string;
};

export type FailedCallProps = ActivityMainContent &
  ActivityReminder & {
    hasReminder: boolean;
  };

export type CreateActivityPayload = {
  activityType: string;
  buyerProject: nullOrString;
  completionDate: nullOrDate;
  content: string;
  customer: nullOrString;
  isAllDay: boolean;
  owner: string;
  sellerProject: nullOrNumber;
  status: string;
  targetDate: nullOrDate;
  pigeId?: string;
};

export type ModifyActivityPayload = CreateActivityPayload & {
  id: string;
  failed: boolean;
};

export type BuyerAndSellerProjects = {
  buyerProject: nullOrString;
  sellerProject: nullOrNumber;
};

export type AgentSellerProjectListing = Pick<
  ListingProps,
  'id' | 'city' | 'postCode' | 'status' | 'street' | 'streetNumber'
>;

export type AgentSellerProject = Omit<
  EstimationProps,
  'agentId' | 'salesforceId' | 'stage'
> & {
  user: CustomerProps;
  listing: AgentSellerProjectListing | null;
};

export type AgentProjects = {
  buyerProjects: OnlineAlert[];
  sellerProjects: AgentSellerProject[];
};

export type TaskRangeDate = {
  startDate: Date;
  endDate: Date;
  isDaily: boolean;
};

export type ActivityNotifications = {
  late: number;
  todo: number;
};

export type EmailsAndSms = {
  emails: EmailEntryProps[];
  sms: SMSEntryProps[];
};

export type BuiltElement = {
  component: ReactElement;
  completionDate: string;
};

export type DoneActivitiesSorter = {
  completionDate: nullOrString;
  targetDate: string;
};

export type ToBeDoneActivitiesSorter = {
  targetDate: string;
};

import { ComponentsMappingProps } from '@/hooks/useUserPermissionsBuilder/useUserPermissionsBuilder.interfaces';

import CreateBuyerProjectItem from './createProjectItems/createBuyerProjectItem/CreateBuyerProjectItem';
import CreateSellerProjectItem from './createProjectItems/createSellerProjectItem/CreateSellerProjectItem';

export const MENU_BAR_CREATE_ITEMS: ComponentsMappingProps = [
  {
    Component: CreateSellerProjectItem,
    position: 1,
    url: '/qualification/seller'
  },
  {
    Component: CreateBuyerProjectItem,
    position: 2,
    url: '/qualification/buyer'
  }
];

import styled, { css } from 'styled-components';

import { colors, convertPxToRem } from '@proprioo/salatim';

export const BuyerActivitiesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: fit-content;
  color: ${colors.dark.base};
`;

export const Activity = styled.div<{ hasNoActivity: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  & + & {
    padding-left: ${convertPxToRem(32)};
  }

  svg {
    height: ${convertPxToRem(18)};
    width: ${convertPxToRem(18)};
    margin-right: ${convertPxToRem(12)};
    fill: ${colors.dark.base80};
    fill-rule: evenodd;
  }

  ${({ hasNoActivity }) =>
    hasNoActivity &&
    css`
      color: ${colors.dark.base40};

      svg {
        fill: ${colors.dark.base40};
      }
    `}
`;

import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { SecondaryInfo } from '@/components/myCustomers/common/styles/MyCustomers.styles';
import { useLanguage } from '@/hooks/useLanguage/useLanguage';
import { formatCompactPrice } from '@/utils/price';
import { formatNumber, isNotEmpty, listToString } from '@proprioo/hokkaido';

export type ListingSpecsProps = {
  surface: number;
  finalSalePrice?: number;
  price?: number;
  roomsCount?: number;
};

const ListingSpecs: FC<ListingSpecsProps> = ({
  finalSalePrice,
  price,
  roomsCount,
  surface
}) => {
  const { t } = useTranslation();
  const { currentLocale } = useLanguage();

  const formattedPrice = formatCompactPrice(
    finalSalePrice || price,
    currentLocale
  );

  const surfaceString =
    surface !== undefined
      ? formatNumber(surface, currentLocale, t('squareMeterWithSpace'))
      : '';
  const roomsCountString = roomsCount
    ? t('roomsTagCount', { count: roomsCount })
    : '';
  const priceString =
    (price !== undefined || finalSalePrice !== undefined) && formattedPrice
      ? formattedPrice
      : '';

  const renderedInfos = listToString(
    [surfaceString, roomsCountString, priceString],
    ' • '
  );

  return (
    <SecondaryInfo>
      {isNotEmpty(renderedInfos) ? renderedInfos : t('noInfo')}
    </SecondaryInfo>
  );
};

export default ListingSpecs;

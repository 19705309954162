import { atom, selector } from 'recoil';

type LoaderProps = {
  isLoading: boolean;
  ref?: HTMLElement | null;
  text?: string;
};

const loaderAtom = atom<LoaderProps>({
  default: {
    isLoading: false,
    ref: null
  },
  key: 'loaderAtom'
});

export const loaderSelector = selector<LoaderProps>({
  get: ({ get }) => get(loaderAtom),
  key: 'loaderSelector',
  set: ({ set }, loaderValues) => set(loaderAtom, loaderValues)
});

import Image from 'next/image';
import { FC } from 'react';

import Placeholder from '@/assets/icons/avatar-placeholder.svg';
import { useAgentAvatarById } from '@/hooks/agents/useAgentAvatarById';

import Avatar, { AvatarProps } from '../Avatar';
import { AvatarSize } from '../interfaces';
import { PlaceholderWrapper } from './AgentAvatar.styles';

export type AgentAvatarProps = AvatarProps & {
  agentId?: number;
};

const AgentAvatar: FC<AgentAvatarProps> = ({
  agentId,
  name,
  dataTest,
  isNameVisible,
  isToolTipVisible,
  size = AvatarSize.NORMAL
}) => {
  const avatarPath = useAgentAvatarById(agentId || undefined);

  return (
    <Avatar
      dataTest={dataTest}
      name={name}
      isNameVisible={isNameVisible}
      isToolTipVisible={isToolTipVisible}
      size={size}
    >
      {avatarPath ? (
        <Image src={avatarPath} alt={name} width={size} height={size} />
      ) : (
        <PlaceholderWrapper>
          <Placeholder alt={name} data-test="avatar-placeholder" />
        </PlaceholderWrapper>
      )}
    </Avatar>
  );
};

export default AgentAvatar;

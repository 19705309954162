import styled from 'styled-components';

import { convertPxToRem, InterFont, paragraphs } from '@proprioo/salatim';

export const TableHeadCell = styled.th`
  ${paragraphs.body2};
  ${InterFont};
  text-align: left;
  background-color: white;
  padding: ${convertPxToRem(14)};
`;

export const TableHeadInner = styled.div`
  display: flex;
  align-items: center;
`;

import { useTranslation } from 'next-i18next';
import { FC, isValidElement, ReactElement } from 'react';
import Skeleton from 'react-loading-skeleton';

import { PAGE_VIEW_TITLE } from '@/utils/gtm';
import { useMobileDevice } from '@proprioo/salatim';

import { ActivityBulletsProps } from '../activityBullets/ActivityBullets';
import {
  IconWrapper,
  Layout,
  SkeletonWrapper,
  TitleWrapper
} from './Section.styles';

export type SectionParams = {
  color: string;
  icon: JSX.Element;
  label: string;
};

export type SectionProps = {
  hasTabs: boolean;
  isActive: boolean;
  isLoading: boolean;
  sectionParams: SectionParams;
  sectionUrl: string;
  tableContent: string;
  pageViewTitle: PAGE_VIEW_TITLE;
  bullet?: ReactElement<ActivityBulletsProps>;
  onClick?(): void;
};

const Section: FC<SectionProps> = ({
  bullet,
  hasTabs,
  isActive,
  isLoading,
  sectionParams,
  sectionUrl,
  onClick,
  pageViewTitle,
  tableContent
}) => {
  const { t } = useTranslation();
  const isMobile = useMobileDevice();

  const { color, icon, label } = sectionParams;

  const labelTranslation = t(label);

  return isLoading ? (
    <SkeletonWrapper>
      <Skeleton width={isMobile ? 64 : 180} height={64} />
    </SkeletonWrapper>
  ) : (
    <Layout
      aria-controls={hasTabs ? 'tabs' : tableContent}
      aria-label={labelTranslation}
      aria-selected={isActive}
      hasTabs={hasTabs}
      href={sectionUrl}
      id={`${pageViewTitle}_button_${label}`}
      isActive={isActive}
      onClick={onClick}
      role="tab"
      tabIndex={isActive ? 0 : -1}
      shallow={true}
    >
      <IconWrapper color={color} isActive={isActive}>
        {!isActive && bullet && isValidElement(bullet) && bullet}
        {icon}
      </IconWrapper>
      <TitleWrapper isActive={isActive}>{labelTranslation}</TitleWrapper>
    </Layout>
  );
};

export default Section;

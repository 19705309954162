import React from 'react';

import { Checkbox } from '@proprioo/salatim';

import { CheckboxWrapper, LabelWrapper, Layout } from './CheckboxItem.styles';

export type CheckboxItemProps = {
  checked: boolean;
  icon: JSX.Element;
  label: string;
  indeterminate?: boolean;
  onChange(checked: boolean): void;
};

const CheckboxItem = ({
  checked,
  icon,
  indeterminate = false,
  label,
  onChange
}: CheckboxItemProps) => (
  <Layout>
    <Checkbox
      name={label}
      label=""
      id={label}
      indeterminate={indeterminate}
      checked={checked}
      disabled={false}
      onChange={onChange}
    >
      <CheckboxWrapper>
        {icon}
        <LabelWrapper>{label}</LabelWrapper>
      </CheckboxWrapper>
    </Checkbox>
  </Layout>
);

export default CheckboxItem;

import {
  BuyersResult,
  SellersResult,
  UsersResult
} from '../app/layout/topBar/search/interfaces';

export enum SearchResultsTab {
  ALL = 'allResults',
  BUYER_PROJECTS = 'buyerProjects',
  BUYERS_WITHOUT_PROJECT = 'buyersWithoutProject',
  SELLER_PROJECTS = 'sellerProjects'
}

export type SearchAPIResults = {
  buyerProjects: BuyersResult;
  buyersWithoutProject: UsersResult;
  sellerProjects: SellersResult;
};

import { PREFIX_EMAIL } from '@/constants/global';
import { formatName } from '@/utils/user';
import {
  Dictionary,
  normalizePhoneNumber,
  nullOrString,
  replaceSingleQuotionMark,
  trimName
} from '@proprioo/hokkaido';

import { LoanStage, PurchaseStage } from '../buyerQualification/interfaces';
import { CustomerInformation } from '../state/interfaces';
import { EMPTY_PROJECT, EMPTY_USER } from '../state/utils';
import { CreateQualificationValues } from './createQualification/CreateQualification';

export const generateDefaultFormProps = (): CreateQualificationValues => ({
  customer: EMPTY_USER,
  project: EMPTY_PROJECT
});

export const dictionnaryIsEmpty = (dictionnary: Dictionary) =>
  Object.keys(dictionnary).length === 0;

export const normalizeUser = ({
  firstName,
  lastName,
  phone,
  email,
  ...rest
}: CustomerInformation): CustomerInformation => ({
  ...rest,
  email: trimName(email),
  firstName: replaceSingleQuotionMark(trimName(firstName)),
  lastName: replaceSingleQuotionMark(trimName(lastName)),
  phone: phone && normalizePhoneNumber(phone)
});

export const getDefaultEmail = (firstName: string, lastName: string) =>
  `${PREFIX_EMAIL}${formatName(firstName)}-${formatName(
    lastName
  )}-${Date.now()}@proprioo.fr`;

export const convertToLoanStage = (stage: nullOrString) => {
  switch (stage) {
    case 'noNeed':
      return LoanStage.NO_NEED;
    case 'noButINeedOne':
      return LoanStage.NO_BUT_I_NEED_ONE;
    case 'inProgress':
      return LoanStage.IN_PROGRESS;
    case 'accepted':
      return LoanStage.ACCEPTED;
    default:
      return undefined;
  }
};

export const convertToPurchaseStage = (stage: nullOrString) => {
  switch (stage) {
    case 'kickOff':
      return PurchaseStage.KICK_OFF;
    case 'visiting':
      return PurchaseStage.VISITING;
    case 'alreadyMadeOffer':
      return PurchaseStage.ALREADY_MADE_OFFER;
    default:
      return undefined;
  }
};

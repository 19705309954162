import * as yup from 'yup';

import { isInternalEmail } from '@proprioo/hokkaido';
import { UserSchema } from '@proprioo/salatim';

import { isAFakeOrRealEmail, validateEmail } from './utils';

export const ExistingEmailSchema = yup
  .string()
  .required()
  .test('email', 'email error', validateEmail)
  .test('email', 'email domain error', value => isAFakeOrRealEmail(value));

export const ExistingBuyerEmailSchema = yup
  .string()
  .required()
  .test('email', 'email error', validateEmail);

export const NewEmailSchema = yup
  .string()
  .optional()
  .test('email', 'email error', validateEmail)
  .test('email', 'email domain error', value => !isInternalEmail(value));

export const ExtendedCustomerSchema = UserSchema.shape({
  email: NewEmailSchema,
  id: yup.string().optional()
});

export const ExistingCustomerSchema = UserSchema.shape({
  email: ExistingEmailSchema,
  id: yup.string().required()
});

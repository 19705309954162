import { useTranslation } from 'next-i18next';
import { FC, Fragment } from 'react';

import { PrimaryInfo } from '@/components/myCustomers/common/styles/MyCustomers.styles';
import { listToString } from '@proprioo/hokkaido';

export type ListingAddressProps = {
  city: string;
  isMultiline: boolean;
  postCode: string;
  street: string;
  streetNumber: string;
};

const ListingAddress: FC<ListingAddressProps> = ({
  city,
  isMultiline,
  postCode,
  street,
  streetNumber
}) => {
  const { t } = useTranslation();

  const firstLine = t('formattedAddress', {
    number: streetNumber || '',
    street
  }).trim();
  const secondLine = listToString([postCode, city]);

  return (
    <PrimaryInfo>
      {isMultiline ? (
        <Fragment>
          {firstLine},<br />
          {secondLine}
        </Fragment>
      ) : (
        listToString([firstLine, secondLine], t('comma'))
      )}
    </PrimaryInfo>
  );
};

export default ListingAddress;

import { Dispatch, FC, useEffect, useRef } from 'react';

import ArrowIcon from '@/assets/icons/chevron-bottom.svg';
import { useTranslatedOptions } from '@/hooks/useTranslatedOptions/useTranslatedOptions';
import { OptionProps, useOnClickOutside } from '@proprioo/salatim';

import { SearchState, SearchType } from '../interfaces';
import {
  OptionLabel,
  OptionLayout,
  OptionsContainer,
  OptionsLayout,
  Select,
  SelectLayout
} from './SearchSelect.styles';

export const SELECT_OPTIONS: OptionProps[] = [
  { label: 'allTypes', value: SearchType.ALL_TYPES },
  { label: 'firstNameAndLastName', value: SearchType.NAME },
  { label: 'email', value: SearchType.EMAIL },
  { label: 'phone', value: SearchType.PHONE },
  { label: 'listingAddress', value: SearchType.ADDRESS },
  { label: 'listingId', value: SearchType.ID }
];

export type SearchSelectProps = {
  isSelectOpen: boolean;
  selectedType: string;
  updateReducer: Dispatch<Partial<SearchState>>;
};

const SearchSelect: FC<SearchSelectProps> = ({
  isSelectOpen,
  selectedType,
  updateReducer
}) => {
  const translatedOptions = useTranslatedOptions(SELECT_OPTIONS);

  const selectLayoutRef = useRef<HTMLDivElement>(null);
  const selectedOptionRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(selectLayoutRef, ({ target }) => {
    if (isSelectOpen && !selectLayoutRef.current?.contains(target as Node)) {
      updateReducer({ isSelectOpen: false });
    }
  });

  useEffect(() => {
    if (isSelectOpen && selectedOptionRef?.current) {
      selectedOptionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'end'
      });
    }
  }, [isSelectOpen, selectedOptionRef]);

  const selectedValue = translatedOptions.find(
    ({ value }) => value === selectedType
  )?.label;

  return (
    <SelectLayout
      data-test="select-wrapper-search-layout"
      isSelectOpen={isSelectOpen}
      onClick={() => updateReducer({ isSelectOpen: !isSelectOpen })}
      ref={selectLayoutRef}
    >
      <Select
        aria-label={selectedValue}
        data-test="search-select-input"
        name="search-select"
        readOnly={true}
        type="text"
        value={selectedValue}
      />
      <ArrowIcon aria-hidden={true} />
      {isSelectOpen && (
        <OptionsLayout data-test="select-options">
          <OptionsContainer role="list">
            {translatedOptions.map(({ label, value }) => {
              const isOptionSelected = value === selectedType;

              return (
                <OptionLayout
                  aria-selected={isOptionSelected}
                  isSelected={isOptionSelected}
                  key={`option-${value}`}
                  onClick={() =>
                    updateReducer({ isSelectOpen: false, selectedType: value })
                  }
                  ref={isOptionSelected ? selectedOptionRef : undefined}
                  role="option"
                >
                  <OptionLabel data-test={`option-label-${value}`}>
                    {label}
                  </OptionLabel>
                </OptionLayout>
              );
            })}
          </OptionsContainer>
        </OptionsLayout>
      )}
    </SelectLayout>
  );
};

export default SearchSelect;

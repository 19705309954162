import { DatabaseStatus } from '@/utils/interfaces';
import { nullOrDate, nullOrNumber, nullOrString } from '@proprioo/hokkaido';

import {
  CustomerInformation,
  FullAddressInformation
} from '../../state/interfaces';

export enum EntityCategory {
  CORPORATION = 'Company',
  MULTI_USER = 'MultiUser',
  PERSON = 'Person',
  SINGLE_USER = 'SingleUser'
}

export enum JobPosition {
  LEADER = 'leader',
  OTHER = 'other',
  SIGNATORY = 'signatory'
}

export enum CompanyLegalForm {
  JOINT_OWNERSHIP = 'JointOwnership',
  SA = 'Sa',
  SARL = 'Sarl',
  SAS = 'Sas',
  SASU = 'Sasu',
  SCI = 'Sci',
  SCPI = 'Scpi'
}

export type Entity = {
  category: EntityCategory;
  companyId: nullOrString;
  createdBy: string;
  hasDashboardAccess: boolean;
  id: string;
  modifiedBy: nullOrString;
  projectId: string;
  status: DatabaseStatus;
  userId: nullOrString;
  willBeNotified: boolean;
  company?: Company;
  quota?: number;
};

export type CompanyUser = {
  jobPosition: JobPosition;
  user: CustomerInformation;
};

export type CompanyUserWithJob = {
  userId: string;
  jobPosition: JobPosition | null;
};

export type Company = FullAddressInformation & {
  createdBy: string;
  cs: nullOrNumber;
  denomination: string;
  endDate: nullOrDate;
  id: string;
  legalForm: CompanyLegalForm;
  modifiedBy: nullOrString;
  rcs: nullOrString;
  status: DatabaseStatus;
  users: CompanyUser[];
};

export type CompanyCreationFields = {
  cs: nullOrNumber;
  denomination: string;
  legalForm: CompanyLegalForm;
  rcs: nullOrString;
  users: CompanyUserWithJob[];
  city?: string;
  postalcode?: string;
  street?: string;
  streetnumber?: string;
};

import { FC } from 'react';

import SquadIcon from '@/assets/icons/activity-rdv.svg';

import CheckboxItem from '../checkboxItem/CheckboxItem';
import { Avatar } from './SquadCheckbox.styles';

export type SquadCheckboxProps = {
  agentsCount: number;
  label: string;
  selectedAgentsCount: number;
  onChange(checked: boolean): void;
};

const SquadCheckbox: FC<SquadCheckboxProps> = ({
  agentsCount,
  label,
  selectedAgentsCount,
  onChange
}) => (
  <CheckboxItem
    checked={selectedAgentsCount === agentsCount}
    indeterminate={
      Boolean(selectedAgentsCount) && selectedAgentsCount < agentsCount
    }
    icon={
      <Avatar>
        <SquadIcon />
      </Avatar>
    }
    label={label}
    onChange={onChange}
  />
);

export default SquadCheckbox;

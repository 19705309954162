import styled, { css } from 'styled-components';

import {
  breakpoints,
  colors,
  convertPxToRem,
  paragraphs
} from '@proprioo/salatim';

export const Layout = styled.nav`
  position: sticky;
  bottom: 0;
  z-index: 0;
  background-color: ${colors.green.base5};
  display: flex;
  justify-content: center;
`;

export const Wrapper = styled.div<{
  isOpen: boolean;
  isSidebarVisible: boolean;
}>`
  background-color: inherit;
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  ${({ isOpen, isSidebarVisible }) =>
    isOpen
      ? css`
          @media (min-width: ${breakpoints.small}px) {
            padding-left: ${convertPxToRem(300)};

            ${!isSidebarVisible &&
            css`
              padding-left: 0;
            `};
          }

          @media (min-width: ${breakpoints.normal}px) {
            padding-left: ${convertPxToRem(360)};

            ${!isSidebarVisible &&
            css`
              padding-left: 0;
            `};
          }
        `
      : css`
          @media (min-width: ${breakpoints.small}px) {
            padding-left: ${convertPxToRem(64)};
          }

          ${!isSidebarVisible &&
          css`
            padding-left: 0;
          `};
        `};
`;

export const Box = styled.div<{ showOnMobile?: boolean }>`
  height: ${convertPxToRem(48)};

  ${({ showOnMobile = true }) =>
    !showOnMobile &&
    css`
      display: none;
    `}
`;

export const ButtonBox = styled.div<{ isVisible?: boolean }>`
  transform: ${({ isVisible = true }) =>
    isVisible ? 'translateY(-100px)' : 'translateY(200px)'};
  transition: transform 0.35s ease-in-out;
`;

export const BasicBoxButton = styled.button`
  display: flex;
  justify-content: center;
  border: unset;
  width: 100%;
  height: 100%;
  padding: 0;

  :active,
  :focus {
    outline: none;
  }
`;

export const primaryStyle = css`
  background: ${colors.green.base};
  color: white;

  path {
    fill: white;
    opacity: 0.64;
  }

  :hover {
    background: ${colors.green.base80};

    path {
      opacity: 1;
    }
  }

  :active {
    color: ${colors.green.base};
    background: ${colors.green.base20};

    path {
      fill: ${colors.green.base};
    }
  }

  :disabled {
    background: ${colors.grey.base20};
    color: ${colors.grey.base};
    cursor: not-allowed;

    path {
      fill: currentColor;
      opacity: 1;
    }
  }
`;

export const BoxButtonTextStyle = css`
  ${paragraphs.body1};
  cursor: pointer;

  :active,
  :focus {
    outline: none;
  }

  svg {
    width: ${convertPxToRem(32)};
    height: ${convertPxToRem(32)};
  }
`;

import styled from 'styled-components';

import { colors, convertPxToRem, paragraphs, radius } from '@proprioo/salatim';

export const Avatar = styled.span`
  ${paragraphs.subtitle2};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${convertPxToRem(24)};
  height: ${convertPxToRem(24)};
  background-color: ${colors.blue.base60};
  border-radius: ${radius.rounded};
  color: ${colors.green.base};

  svg {
    width: 100%;
    height: 100%;
  }
`;

import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import AgentAvatar from '@/components/app/avatar/agentAvatar/AgentAvatar';
import { AvatarSize } from '@/components/app/avatar/interfaces';
import { useAgentById } from '@/hooks/agents/useAgentById';
import { getFullName } from '@proprioo/hokkaido';
import { useSmartphoneDevice } from '@proprioo/salatim';

type AgentCellProps = {
  agentId: number;
};

const AgentCell: FC<AgentCellProps> = ({ agentId }) => {
  const { t } = useTranslation();

  const agentOwner = useAgentById(agentId);
  const isSmartphone = useSmartphoneDevice();

  return (
    <div onClick={event => event.preventDefault()}>
      {agentOwner?.id && (
        <AgentAvatar
          agentId={agentOwner.id}
          name={t('ownershipName', {
            name: getFullName(agentOwner?.firstname, agentOwner?.lastname)
          })}
          size={isSmartphone ? AvatarSize.SMALL : AvatarSize.NORMAL}
        />
      )}
    </div>
  );
};

export default AgentCell;

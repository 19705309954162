import { useTranslation } from 'next-i18next';
import Link from 'next/link';

import BuyerProjectIcon from '@/assets/icons/buyer-project.svg';
import { PAGE_VIEW_TITLE } from '@/utils/gtm';
import { generateButtonId } from '@/utils/tracking';

const CreateBuyerProjectItem = () => {
  const { t } = useTranslation();

  return (
    <Link
      data-test="new-buyer"
      href="/qualification/buyer"
      id={generateButtonId(PAGE_VIEW_TITLE.MENU, 'projectBuyerCreate')}
    >
      <BuyerProjectIcon />
      {t('createBuyerProject')}
    </Link>
  );
};

export default CreateBuyerProjectItem;

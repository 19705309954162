import Skeleton from 'react-loading-skeleton';

import { getArrayOfLength } from '@proprioo/hokkaido';
import { useSmartphoneDevice } from '@proprioo/salatim';

import { SkeletonWrapper } from './Loaders.styles';

export const MobileCardsLoader = () => (
  <SkeletonWrapper>
    <Skeleton count={3} height={180} />;
  </SkeletonWrapper>
);

export const DesktopTableLoader = () => (
  <SkeletonWrapper data-test="desktop-table-loader">
    <Skeleton height={40} />
    {getArrayOfLength(5).map((_, index) => (
      <Skeleton key={index} height={80} />
    ))}
  </SkeletonWrapper>
);

export const CustomersLoader = () => {
  const isSmartphone = useSmartphoneDevice();

  return isSmartphone ? <MobileCardsLoader /> : <DesktopTableLoader />;
};

import { add } from 'date-fns/add';
import { differenceInHours } from 'date-fns/differenceInHours';
import { isBefore } from 'date-fns/isBefore';

import { nullOrString } from '@proprioo/hokkaido';

export const getRemainingTimeToCreateActivity = (
  assignedDate: nullOrString
): number => {
  if (!assignedDate) {
    return 0;
  }

  const today = new Date();
  const assignedEndDate = add(new Date(assignedDate), { hours: 72 });

  return isBefore(today, assignedEndDate)
    ? differenceInHours(assignedEndDate, today, { roundingMethod: 'round' })
    : 0;
};

import { FC, Fragment } from 'react';
import { useRecoilValue } from 'recoil';

import TablePagination from '@/components/app/tablePagination/TablePagination';
import TableHeader from '@/components/myCustomers/common/desktop/tableHeader/TableHeader';
import TableRow from '@/components/myCustomers/common/desktop/tableRow/TableRow';
import { DesktopTableLoader } from '@/components/myCustomers/common/loaders/Loaders';
import {
  PaginationWrapper,
  Table,
  TableWrapper
} from '@/components/myCustomers/common/styles/Table.styles';
import { leadViewSelector } from '@/components/state/LeadViewState';
import { PAGE_VIEW_TITLE } from '@/utils/gtm';
import { generateProjectId } from '@/utils/tracking';

import {
  LeadViewTab,
  LeadViewTableData,
  SaleOpportunity
} from '../../interfaces';
import {
  getOpportunityLinkFeed,
  getSection,
  getTabUrl
} from '../../LeadView.utils';
import {
  getActiveFilters,
  getSectionFilters
} from '../filters/LeadFilters.utils';
import { useLeadViewTable } from './DesktopLeads.hook';

type DesktopLeadsProps = {
  currentPage: number;
  currentTab: LeadViewTab;
  isLoading: boolean;
  isTeamLead: boolean;
  opportunities: SaleOpportunity[];
  agentIds?: number[];
};

const DesktopLeads: FC<DesktopLeadsProps> = ({
  agentIds,
  currentPage,
  currentTab,
  isLoading,
  isTeamLead,
  opportunities
}) => {
  const { leadViewFilters } = useRecoilValue(leadViewSelector);

  const section = getSection(currentTab);

  const { getHeaderGroups, getRowModel, getState, getPageCount } =
    useLeadViewTable({
      isTeamLead,
      opportunities,
      pageIndex: currentPage - 1,
      section
    });

  const { pageIndex } = getState().pagination;

  const sectionFilters = getActiveFilters(
    getSectionFilters(section, leadViewFilters)
  );
  const headerGroups = getHeaderGroups();
  const rows = getRowModel().rows;

  return (
    <Fragment>
      <div>
        {isLoading ? (
          <DesktopTableLoader />
        ) : (
          <TableWrapper>
            <Table>
              <TableHeader<LeadViewTableData> headerGroups={headerGroups} />
              <tbody>
                {rows.map((row, index) => (
                  <TableRow<LeadViewTableData>
                    key={`${row.id}-${index}`}
                    link={getOpportunityLinkFeed(row.original.opportunity)}
                    linkPageView={generateProjectId(
                      PAGE_VIEW_TITLE.LEADS,
                      `${row.original.opportunity.estimationId}`
                    )}
                    row={row}
                  />
                ))}
              </tbody>
            </Table>
          </TableWrapper>
        )}
      </div>
      {!isLoading && (
        <PaginationWrapper>
          <TablePagination
            idTitle={PAGE_VIEW_TITLE.LEADS}
            pageCount={getPageCount()}
            pageIndex={pageIndex}
            pageLink={page =>
              getTabUrl(currentTab, page, agentIds, sectionFilters)
            }
          />
        </PaginationWrapper>
      )}
    </Fragment>
  );
};

export default DesktopLeads;

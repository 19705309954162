import { FC, PropsWithChildren } from 'react';

import { InnerLink, Layout } from './NavigationButton.styles';

export type NavigationButtonProps = {
  href: string;
  idTitle: string;
  label: string;
  isActive?: boolean;
};

const NavigationButton: FC<PropsWithChildren<NavigationButtonProps>> = ({
  children,
  idTitle,
  href,
  isActive,
  label
}) => {
  return (
    <Layout>
      <InnerLink
        aria-label={label}
        href={href}
        id={idTitle}
        isActive={isActive}
        shallow={true}
        {...(isActive && { 'aria-current': 'page' })}
      >
        {children}
      </InnerLink>
    </Layout>
  );
};

export default NavigationButton;

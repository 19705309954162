import { atom, selector } from 'recoil';

const sidebarAtom = atom<boolean>({
  default: true,
  key: 'sidebarAtom'
});

export const sidebarSelector = selector<boolean>({
  get: ({ get }) => get(sidebarAtom),
  key: 'siderbarSelector',
  set: ({ set }, newValues) => set(sidebarAtom, newValues)
});

import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { FC, Fragment, useMemo } from 'react';

import CloseIcon from '@/assets/icons/close.svg';
import YanportIcon from '@/assets/icons/yanport.svg';
import ConfirmModalButtons from '@/components/app/confirmModalButtons/ConfirmModalButtons';
import { useLeads } from '@/hooks/fetch/leads';
import { EventPageView, PAGE_VIEW_TITLE, sendPageView } from '@/utils/gtm';
import { logError } from '@/utils/log';
import { toastError, toastSuccess } from '@/utils/notification';
import { generateButtonId } from '@/utils/tracking';
import { Button, ButtonSizes, useModal } from '@proprioo/salatim';

import {
  ExternalOpportunityNormalized,
  ExternalOpportunityStatus
} from '../../../interfaces';
import { updateExternalLead } from '../fetch';
import { Message } from './AttributionCell.styles';

export type AttributionCellProps = Pick<
  ExternalOpportunityNormalized,
  'pigeId' | 'status'
>;

const AttributionCell: FC<AttributionCellProps> = ({ pigeId, status }) => {
  const { t } = useTranslation();
  const { asPath } = useRouter();
  const { refreshArchivedExternalOpportunities, refreshExternalOpportunities } =
    useLeads();

  const isAutomaticAttributionStatus =
    status === ExternalOpportunityStatus.AUTOMATIC_ATTRIBUTION;

  const {
    buttonLabel,
    buttonModalLabel,
    errorMessage,
    leadStatusToUpdate,
    modalMessage,
    modalTitle,
    successMessage,
    pageViewTitle
  } = useMemo(
    () =>
      isAutomaticAttributionStatus
        ? {
            buttonLabel: 'refuseLead',
            buttonModalLabel: 'refuseListing',
            errorMessage: 'refuseLeadError',
            leadStatusToUpdate: ExternalOpportunityStatus.REFUSED,
            modalMessage: 'aboutToRefuseExternalLead',
            modalTitle: 'refuseExternalLead',
            pageViewTitle: PAGE_VIEW_TITLE.MODAL_EXTERNAL_LEAD_REFUSE,
            successMessage: 'refuseLeadSuccess'
          }
        : {
            buttonLabel: 'unassignThisLead',
            buttonModalLabel: 'unassignLead',
            errorMessage: 'unassignLeadError',
            leadStatusToUpdate: ExternalOpportunityStatus.DELISTED,
            modalMessage: 'aboutToUnassignLead',
            modalTitle: 'unassignLead',
            pageViewTitle: PAGE_VIEW_TITLE.MODAL_EXTERNAL_LEAD_UNASSIGN,
            successMessage: 'unassignLeadSuccess'
          },
    [isAutomaticAttributionStatus]
  );

  const { closeModal, createModal, openModal, toggleLoader } = useModal({
    onOpen: () =>
      sendPageView(
        { path: asPath, title: pageViewTitle },
        EventPageView.MODAL_OPENING
      )
  });

  const handleRefuseLead = async () => {
    const payload = {
      id: pigeId,
      status: leadStatusToUpdate
    };

    try {
      toggleLoader();
      await updateExternalLead(payload);
      toastSuccess(successMessage);

      if (isAutomaticAttributionStatus) {
        await refreshExternalOpportunities();
      } else {
        await refreshArchivedExternalOpportunities();
      }

      closeModal();
    } catch (error) {
      logError(`Failed to refuse lead from seller board: ${pigeId}`, {
        error,
        payload
      });
      toggleLoader();
      toastError(errorMessage);
    }
  };

  return (
    <div onClick={event => event.preventDefault()}>
      <Button
        icon={<CloseIcon />}
        label={t(buttonLabel)}
        size={ButtonSizes.SMALL}
        onClick={openModal}
      />
      {createModal({
        children: (
          <Fragment>
            <Message>{t(modalMessage)}</Message>
            <ConfirmModalButtons
              idAccept={generateButtonId(pageViewTitle, 'validate')}
              idReject={generateButtonId(pageViewTitle, 'cancel')}
              textAccept={buttonModalLabel}
              textReject="cancel"
              onAccept={handleRefuseLead}
              onReject={closeModal}
            />
          </Fragment>
        ),
        icon: <YanportIcon />,
        isAlternative: true,
        title: t(modalTitle)
      })}
    </div>
  );
};

export default AttributionCell;

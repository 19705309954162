import { useMemo } from 'react';

import { Permission } from '@/components/state/interfaces';
import { DictionaryBoolean } from '@/utils/interfaces';
import { Dictionary } from '@proprioo/hokkaido';

import { useAuth } from '../auth/useAuth';
import { useIsTechRole } from '../auth/useIsTechRole';

export const useUserSubsectionsPermissions = (subsections: Dictionary) => {
  const { user } = useAuth();

  const isTechRole = useIsTechRole();

  return useMemo(
    () =>
      Object.values(subsections).reduce<DictionaryBoolean>(
        (acc, subsection) => {
          const userSectionPermissions =
            user?.permissions.subsections[subsection] || [];

          acc[subsection] = !isTechRole
            ? userSectionPermissions.includes(Permission.READ)
            : true;

          return acc;
        },
        {}
      ),
    [isTechRole, subsections, user?.permissions]
  );
};

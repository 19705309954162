import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import ArrowLeft from '@/assets/icons/chevron-left.svg';
import ArrowRight from '@/assets/icons/chevron-right.svg';
import { PAGE_VIEW_TITLE } from '@/utils/gtm';
import { generateButtonId } from '@/utils/tracking';
import { getArrayOfLength } from '@proprioo/hokkaido';

import NavigationButton from './navigationButton/NavigationButton';
import { Dots, LayoutPagination } from './TablePagination.styles';

export type TablePaginationProps = {
  idTitle: PAGE_VIEW_TITLE;
  pageCount: number;
  pageIndex: number;
  label?: string;
  pageLink(page: number): string;
};

const CONSECUTIVE_PAGES = 4;

const TablePagination: FC<TablePaginationProps> = ({
  idTitle,
  label = 'tablePagination',
  pageCount,
  pageIndex,
  pageLink
}) => {
  const { t } = useTranslation();

  const currentPage = pageIndex + 1;

  const hasNextButton = currentPage < pageCount;
  const hasPreviousButton = currentPage > 1;

  const hasDotsBefore =
    pageCount > CONSECUTIVE_PAGES + 1 && currentPage >= CONSECUTIVE_PAGES;
  const hasDotsAfter =
    pageCount > CONSECUTIVE_PAGES + 1 &&
    pageCount - currentPage >= CONSECUTIVE_PAGES - 1;

  const leftPages = getArrayOfLength(
    hasDotsBefore ? 1 : Math.min(CONSECUTIVE_PAGES + 1, Math.max(pageCount, 1))
  ).map((_, index) => index + 1);

  const middlePages = getArrayOfLength(
    hasDotsBefore && hasDotsAfter ? 3 : 0
  ).map(index => currentPage - 2 + index);

  const rightPages = getArrayOfLength(
    hasDotsAfter
      ? 1
      : hasDotsBefore
        ? Math.min(CONSECUTIVE_PAGES, pageCount)
        : 0
  )
    .map(index => pageCount - index + 1)
    .reverse();

  const renderPages = (pages: number[]) =>
    pages.map(page => (
      <NavigationButton
        idTitle={generateButtonId(idTitle, `page-${page}`)}
        key={page}
        isActive={currentPage === page}
        href={pageLink(page)}
        label={t(currentPage === page ? 'currentPage' : 'goToPage', { page })}
      >
        {page}
      </NavigationButton>
    ));

  return (
    <nav aria-label={t(label)}>
      <LayoutPagination>
        {hasPreviousButton && (
          <NavigationButton
            idTitle={generateButtonId(idTitle, 'goToPreviousPage')}
            href={pageLink(currentPage - 1)}
            label={t('goToPreviousPage')}
          >
            <ArrowLeft />
          </NavigationButton>
        )}
        {renderPages(leftPages)}
        {hasDotsBefore && <Dots>...</Dots>}
        {renderPages(middlePages)}
        {hasDotsAfter && <Dots>...</Dots>}
        {renderPages(rightPages)}
        {hasNextButton && (
          <NavigationButton
            idTitle={generateButtonId(idTitle, 'goToNextPage')}
            href={pageLink(currentPage + 1)}
            label={t('goToNextPage')}
          >
            <ArrowRight />
          </NavigationButton>
        )}
      </LayoutPagination>
    </nav>
  );
};

export default TablePagination;

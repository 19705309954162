import { Country, Dictionary, Language } from '@proprioo/hokkaido';

// Constants
export const COMPANY_NAME = 'Casavo';
export const COOKIE_KEY_COUNTRY = 'countryBusiness';
export const COOKIE_KEY_LNG = 'i18nextLng';
export const COUNTRY_FRANCE = 'FR';
export const COUNTRY_ITALY = 'IT';
export const COUNTRY_SPAIN = 'ES';
export const COUNTRIES_GEOCODE_BY_COUNTRY: Record<Country, string[]> = {
  france: [COUNTRY_FRANCE, 'RE'],
  italy: [COUNTRY_ITALY],
  spain: [COUNTRY_SPAIN]
};
export const COUNTRIES_GEOCODE_BY_LANGUAGE: Record<Language, string[]> = {
  en: [COUNTRY_FRANCE, 'RE', COUNTRY_ITALY, COUNTRY_SPAIN],
  es: [COUNTRY_SPAIN],
  fr: [COUNTRY_FRANCE, 'RE'],
  it: [COUNTRY_ITALY]
};
export const DEFAULT_TIMEZONE = 'Europe/Paris';
export const FRENCH_LANGUAGE = Language.FRENCH;
export const IDFSquads = ['Paris Nord', 'Paris Ouest', 'Paris Est', '92', '94'];
export const LAST_IMPERSONATED_AGENTS_STORAGE_KEY = 'lastImpersonatedAgents';
export const MAX_NOTIFICATIONS = 3;
export const MIME_TYPES =
  'image/jpg,image/jpeg,image/png,image/webp,image/heic,image/heif';
export const NOTIFICATION_BLUE_COLOR = '#2AA6EC';
export const ONE_MILLION = 1000000;
export const ONE_THOUSAND = 1000;
export const PREFIX_EMAIL = 'noemail+';
export const PROPRIOO_SOURCE = 'proprioo.fr';
export const SEARCH_AUTOCOMPLETE_RESULTS_PER_PAGE = 3;
export const X_REQUESTED_WITH = 'backoffice';

// Translation keys (to avoid changing one by one in each file)
export const CARD_CLOSE_TOGGLE_ARIA_LABEL = 'hideContent';
export const CARD_OPEN_TOGGLE_ARIA_LABEL = 'viewContent';
export const TAG_CLOSE_ARIA_LABEL = 'delete';

// Date helpers
export const DATE_DAY_HOURS_FORMAT = 'dateDayHoursFormat';
export const DATE_DAY_MONTH_FORMAT = 'dateDayMonthFormat';
export const DATE_DISTRIBUTIONS_PANEL_FORMAT = 'dateDistributionsPanelFormat';
export const DATE_FULL_DAY_FORMAT = 'dateFullDayFormat';
export const DATE_ISO_FORMAT = 'dateIsoFormat';
export const DATE_SCHEDULE_SECONDS_FORMAT = 'dateScheduleSecondsFormat';
export const DATE_SCHEDULE_SIMPLE_FORMAT = 'dateScheduleSimpleFormat';
export const DATE_SIMPLE_FORMAT = 'dateSimpleFormat';
export const DATE_SHORT_FORMAT = 'dateShortFormat';
export const DATE_SLASH_FORMAT = 'dateSlashFormat';

// Pictures
export const DEFAULT_AGENT_PICTURE =
  'https://cdn.proprioo.fr/assets/images/defaultAgent.png';
export const UNAVAILABLE_PLACEHOLDER =
  'https://cdn.proprioo.fr/assets/images/unavailablePlaceholder.jpg';

// External links
export const EXTERNAL_TOOLS: Dictionary = {
  AGENT_INVOICE_DOCUMENTATION:
    'https://proprioo.freshdesk.com/support/solutions/articles/80000961590',
  CHERCHE_MON_NID_SUPPORTS_URL:
    'https://docs.google.com/presentation/d/1z7orRhT69uD8u6tMDrPbJs55MWtPtDIaGz_NoFbIcLE/present?slide=id.g258c182fd8b_0_0',
  CHERCHE_MON_NID_URL:
    'https://www.cherchemonnid.com/mon-compte/professionnel/',
  FEE_DISTRIBUTION_DOCUMENTATION:
    'https://proprioo.freshdesk.com/support/solutions/articles/80000961595',
  GOOGLE_CALENDAR: 'https://calendar.google.com/calendar/r',
  GOOGLE_DRIVE_MANDATE_TEMPATE_IT:
    'https://drive.google.com/drive/u/0/folders/1Q-IR05bU2xrlOPbuhkic6l0IXYnoJUf1?pli=1',
  GOOGLE_DRIVE_URL: 'https://drive.google.com/drive/folders/',
  GOOGLE_MAPS: 'https://www.google.com/maps',
  INVOICE_FORM:
    'https://docs.google.com/forms/d/11kv6Ti5sjFzK-viWVkkarLKLCLCw6Cr9zJQIxiBUyi0/viewform',
  MON_CHASSEUR_IMMO_OFF_MARKET:
    'https://docs.google.com/forms/d/e/1FAIpQLSehrihaRaQpeLFIW0d9yckEUBPaLzkxi9e8em8IvrhbM9ObPQ/viewform',
  MY_CONTACTS: 'https://contacts.google.com/directory',
  PORTAL_PRODUCT: 'https://portal.productboard.com/manomcb6t7rrwaqa5fs2z9f2',
  RESEARCH_MANDATE_DOCUMENTATION:
    'https://docs.google.com/presentation/d/1NwITkTKbj9My8m-YA0sISi82Z_e4t7AvYtqiznLGQuA',
  RESEARCH_MANDATE_STEP_BY_STEP:
    'https://docs.google.com/presentation/d/1-THaeWH99S-glCL39OVsOxMmNqOXTSVvAgWn27amjaY',
  RESEARCH_MANDATE_TEMPLATE:
    'https://docs.google.com/document/d/1sOTZl2kRJ06gFCVy7xKWniqJ1aRBohV01JMPWHUL9FM',
  SUBMIT_IDEA_FORM:
    'https://docs.google.com/forms/d/e/1FAIpQLSdnXcoEP57DwdivXXTMAAfVZwM5WOq3w6N7AvpOaR30iCXu4Q/viewform',
  WIKI_CASA_URL: 'https://www.wikicasa.it/pro',
  YANPORT_API_URL: 'https://api.yanport.com',
  YANPORT_URL: 'https://app.yanport.com'
};

// Environment variables
export const ALGOLIA_APP_ID = `${process.env.NEXT_PUBLIC_ALGOLIA_APP_ID}`;
export const ALGOLIA_API_KEY = `${process.env.NEXT_PUBLIC_ALGOLIA_API_KEY}`;
export const APP_SALES_URL = `${process.env.NEXT_PUBLIC_APP_SALES_URL}`;
export const BACKOFFICE_URL = `${process.env.NEXT_PUBLIC_BACKOFFICE_URL}`;
export const BIGQUERY_BUYER_ID = `${process.env.BIGQUERY_BUYER_ID}`;
export const BIGQUERY_EVENT_ID = `${process.env.BIGQUERY_EVENT_ID}`;
export const BIGQUERY_GS_ID = `${process.env.BIGQUERY_GS_ID}`;
export const BIGQUERY_PROJECT_ID = `${process.env.BIGQUERY_PROJECT_ID}`;
export const BIGQUERY_RAW_BUYER_ID = `${process.env.BIGQUERY_RAW_BUYER_ID}`;
export const BIGQUERY_RAW_GEO_ID = `${process.env.BIGQUERY_RAW_GEO_ID}`;
export const BIGQUERY_REPORT_ID = `${process.env.BIGQUERY_REPORT_ID}`;
export const BIGQUERY_SNAP_ID = `${process.env.BIGQUERY_SNAP_ID}`;
export const BUYER_URL = `${process.env.BUYER_URL}`;
export const DATA_URL = `${process.env.DATA_URL}`;
export const ENV_TYPE = `${process.env.NEXT_PUBLIC_ENV_TYPE}` || 'unknown';
export const EVENT_BUS_TOKEN = `${process.env.EVENT_BUS_TOKEN}`;
export const EVENT_BUS_URL = `${process.env.EVENT_BUS_URL}`;
export const GOOGLE_MAPS_KEY = `${process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY}`;
export const GRAPH_TOKEN = `${process.env.GRAPH_TOKEN}`;
export const LIGHTSHOP_CLIENT_ID = `${process.env.NEXT_PUBLIC_LIGHTSHOP_CLIENT_ID}`;
export const LIGHTSHOP_CLIENT_SECRET = `${process.env.LIGHTSHOP_CLIENT_SECRET}`;
export const LIGHTSHOP_ENV = `${process.env.NEXT_PUBLIC_LIGHTSHOP_ENV}`;
export const LIGHTSHOP_URL = `${process.env.LIGHTSHOP_URL}`;
export const LOCAL_API = `http://${process.env.HOSTNAME || 'localhost'}:3000`;
export const METABASE_AGENT_DASHBOARD_ID = 165;
export const METABASE_PERFORMANCE_DASHBOARD_ID = 112;
export const METABASE_SECRET_KEY = `${process.env.METABASE_SECRET_KEY}`;
export const METABASE_URL = `${process.env.METABASE_URL}`;
export const OAUTH_API_TOKEN_URL = `${process.env.OAUTH_API_TOKEN_URL}`;
export const OAUTH_AUTHORIZE_URL = `${process.env.NEXT_PUBLIC_OAUTH_AUTHORIZE_URL}`;
export const OAUTH_CLIENT_ID = `${process.env.NEXT_PUBLIC_OAUTH_CLIENT_ID}`;
export const OAUTH_CLIENT_SECRET = `${process.env.OAUTH_CLIENT_SECRET}`;
export const OAUTH_JWT_SIGNING_CERTIFICATE = `${process.env.OAUTH_JWT_SIGNING_CERTIFICATE}`;
export const PICTURE_API = `${process.env.NEXT_PUBLIC_PICTURE_API_URL}`;
export const PICTURE_API_FULL_URL = `${process.env.NEXT_PUBLIC_PICTURE_API_URL}/casavo`;
export const SSO_COOKIE_NAME = `${process.env.SSO_COOKIE_NAME}`;
export const SSO_SIGNING_KEY = `${process.env.SSO_SIGNING_KEY}`;
export const WEBSITE_URL = `${process.env.NEXT_PUBLIC_WEBSITE_URL}`;

// Feature flags
export const FEATURE_FLAG_BRK_9008 = `${process.env.NEXT_PUBLIC_FEATURE_FLAG_BRK_9008}`; // Offer management
export const FEATURE_FLAG_BRK_9218 = `${process.env.NEXT_PUBLIC_FEATURE_FLAG_BRK_9218}`; // Brokered channel

import { FC } from 'react';

import ErrorIcon from '@/assets/icons/exclamation-circle.svg';
import InfoIcon from '@/assets/icons/info-circle.svg';
import SuccessIcon from '@/assets/icons/success-circle.svg';

import { AlertCardStatus } from '../AlertCard.utils';

const AlertCardIcon: FC<{ status: AlertCardStatus }> = ({ status }) => {
  switch (status) {
    case AlertCardStatus.DISPLAY:
      return <InfoIcon aria-hidden={true} data-test="display-icon" />;
    case AlertCardStatus.SUCCESS:
      return <SuccessIcon aria-hidden={true} data-test="success-icon" />;
    case AlertCardStatus.WARNING:
      return <InfoIcon aria-hidden={true} data-test="warning-icon" />;
    case AlertCardStatus.ERROR:
      return <ErrorIcon aria-hidden={true} data-test="error-icon" />;
    case AlertCardStatus.INFO:
      return <InfoIcon aria-hidden={true} data-test="info-icon" />;
  }
};

export default AlertCardIcon;

import styled from 'styled-components';

import { colors, convertPxToRem, fonts, radius } from '@proprioo/salatim';

export const RadiosWrapper = styled.div`
  margin-left: ${convertPxToRem(48)};
`;

export const RadioLayout = styled.div`
  padding: ${convertPxToRem(4)} ${convertPxToRem(8)} ${convertPxToRem(4)} 0;
`;

export const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  max-width: fit-content;
  font-size: ${fonts.small};
  line-height: ${convertPxToRem(18)};
  color: ${colors.dark.base};
  cursor: pointer;

  svg,
  svg > image {
    height: ${convertPxToRem(24)};
    width: ${convertPxToRem(24)};
  }

  svg {
    margin-right: ${convertPxToRem(8)};
  }

  ::selection {
    background-color: transparent;
  }
`;

export const RadioButton = styled.input`
  margin-right: ${convertPxToRem(16)};
  opacity: 0;

  + ${RadioLabel} {
    position: relative;

    ::before,
    ::after {
      content: '';
      display: inline-block;
      position: absolute;
      border-radius: ${radius.rounded};
      transition: all 250ms ease;
    }

    ::before {
      top: 0;
      left: -${convertPxToRem(32)};
      height: ${convertPxToRem(24)};
      width: ${convertPxToRem(24)};
      border: ${convertPxToRem(2)} solid ${colors.dark.base20};
      background-color: white;
    }

    ::after {
      left: -${convertPxToRem(26)};
      top: ${convertPxToRem(6)};
      height: ${convertPxToRem(12)};
      width: ${convertPxToRem(12)};
      opacity: 0;
      transform: scale(0);
    }
  }

  &:focus,
  &:hover {
    + ${RadioLabel}::before {
      border-color: ${colors.terracota.base};
    }
  }

  &:checked {
    + ${RadioLabel} {
      ::before {
        border-color: ${colors.terracota.base20};
      }

      ::after {
        background-color: ${colors.terracota.base};
        opacity: 1;
        transform: scale(1);
      }
    }
  }
`;

import { RichText } from 'prismic-reactjs';
import { Fragment } from 'react';
import useSWR from 'swr';

import { useLanguage } from '@/hooks/useLanguage/useLanguage';
import {
  TextToggle,
  useMobileDevice,
  useSmartphoneDevice
} from '@proprioo/salatim';

import { BannerLayout } from './Banner.styles';
import { PrismicBanner } from './interfaces';
import { getIconByLevel, getTextMaxLength } from './utils';

const Banner = () => {
  const { currentLocale } = useLanguage();

  const { data } = useSWR<PrismicBanner>(
    `/api/prismic/banner?locale=${currentLocale}`
  );

  const isSmartphone = useSmartphoneDevice();
  const isMobile = useMobileDevice();

  const text =
    data && Boolean(data.text && data.text.length)
      ? RichText.asText(data.text)
      : '';

  return data?.level && text ? (
    <BannerLayout id="banner-alert" data-test="banner" level={data.level}>
      {getIconByLevel(data.level)}
      <TextToggle
        maxLength={getTextMaxLength(isSmartphone, isMobile)}
        text={text}
      />
    </BannerLayout>
  ) : (
    <Fragment />
  );
};

export default Banner;

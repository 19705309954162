import { FC } from 'react';

import LeadRevenueTag from '@/components/myCustomers/mySellers/leads/common/leadRevenueTag/LeadRevenueTag';
import { isClosed } from '@/components/myCustomers/mySellers/LeadView.utils';

import { LeadViewTableCellProps } from '../interface';

const RevenueCell: FC<LeadViewTableCellProps> = ({ opportunity }) => {
  const { chargedFees, commission, estimationStage, price } = opportunity;

  const isApproximative = !isClosed(estimationStage);

  return (
    <LeadRevenueTag
      chargedFees={chargedFees}
      commission={commission}
      hasEmptyPlaceholder={true}
      isApproximative={isApproximative}
      price={price}
    />
  );
};

export default RevenueCell;

import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { FC, Fragment } from 'react';
import { useRecoilValue } from 'recoil';

import FilterIcon from '@/assets/icons/filter.svg';
import { leadViewSelector } from '@/components/state/LeadViewState';
import { EventPageView, PAGE_VIEW_TITLE, sendPageView } from '@/utils/gtm';
import { Button, ButtonAppearance, useModal } from '@proprioo/salatim';

import { LeadViewSection, LeadViewTab } from '../../interfaces';
import { getSection } from '../../LeadView.utils';
import { LeadViewFiltersCount } from '../filters/interfaces';
import LeadFilters from '../filters/LeadFilters';
import { getActiveFilters } from '../filters/LeadFilters.utils';
import { FilterButtonWrapper } from './MobileLeadFilters.styles';

export type MobileLeadFiltersProps = {
  currentTab: LeadViewTab;
  opportunitiesCountPerFilter: LeadViewFiltersCount;
};

const MobileLeadFilters: FC<MobileLeadFiltersProps> = ({
  currentTab,
  opportunitiesCountPerFilter
}) => {
  const { asPath } = useRouter();
  const { t } = useTranslation();

  const { createModal, openModal } = useModal({
    onOpen: () =>
      sendPageView(
        { path: asPath, title: PAGE_VIEW_TITLE.MODAL_LEAD_FILTERS },
        EventPageView.MODAL_OPENING
      )
  });
  const { leadViewFilters } = useRecoilValue(leadViewSelector);

  const activeFilterCount = getActiveFilters(leadViewFilters).length;
  const currentSection = getSection(currentTab);

  const hasNoFilters = [
    LeadViewSection.LEADS,
    LeadViewSection.ARCHIVED
  ].includes(currentSection);

  return (
    <Fragment>
      <FilterButtonWrapper>
        <Button
          {...(!activeFilterCount && {
            appearance: ButtonAppearance.SECONDARY
          })}
          label={
            activeFilterCount
              ? t('activeFilterCount', { count: activeFilterCount })
              : t('filter')
          }
          onClick={openModal}
          disabled={hasNoFilters}
          icon={<FilterIcon data-test="filter-icon" />}
        />
      </FilterButtonWrapper>
      {createModal({
        children: (
          <LeadFilters
            currentTab={currentTab}
            opportunitiesCountPerFilter={opportunitiesCountPerFilter}
          />
        ),
        isAlternative: true,
        isFullScreen: true,
        title: t('filter')
      })}
    </Fragment>
  );
};

export default MobileLeadFilters;

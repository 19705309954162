import { capitalizeEachWord } from '@/components/buyerbase/utils';
import {
  getFullName,
  normalizeString,
  nullOrString,
  trimName
} from '@proprioo/hokkaido';

export const formatName = (value: string) =>
  trimName(normalizeString(value).replace(/\s/g, ''));

export const getFormatUserName = (
  firstname: nullOrString,
  lastname: nullOrString,
  isComplete = true
): string => {
  const capitalizedFirstname = capitalizeEachWord(firstname);
  const capitalizedLastname = capitalizeEachWord(lastname);

  return capitalizedFirstname && capitalizedLastname
    ? isComplete
      ? getFullName(capitalizedFirstname, capitalizedLastname)
      : getFullName(
          `${capitalizedFirstname.slice(0, 1)}.`,
          capitalizedLastname.length > 12
            ? capitalizedLastname.slice(0, 12) + '...'
            : capitalizedLastname
        )
    : '';
};

export const getCustomerUrl = (customerId: string) => `/customer/${customerId}`;

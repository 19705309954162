import * as yup from 'yup';

import { CustomerProfile, FinancingMethod } from '@proprioo/hokkaido';

import { CompanyFormSchema } from '../../contractFormParts/contractFormSignatories/companyForm/CompanyForm.schema';
import { PhysicalSignatoryUserSchema } from '../../contractFormParts/contractFormSignatories/physicalSignatoryCard/PhysicalSignatoryCard.schema';

const fiscalCodeRegex = /.{11}|.{16}/;

const BaseContractSchema = yup.object({
  loanAmount: yup.number().when('loanNeeded', {
    is: (loan: FinancingMethod) =>
      [
        FinancingMethod.APPROVED,
        FinancingMethod.PENDING_APPROVAL,
        FinancingMethod.NO_PAPERWORK
      ].includes(loan),
    otherwise: () => yup.number(),
    then: () => yup.number().positive().required()
  }),
  loanNeeded: yup.string().oneOf(Object.values(FinancingMethod)).required(),
  price: yup.number().min(1).required(),
  validityDate: yup.date().required()
});

export const SignatoryFiscalCodeSchema = yup.object({
  dateOfBirth: yup.date().nullable(),
  fiscalCode: yup
    .string()
    .matches(fiscalCodeRegex, {
      excludeEmptyString: true
    })
    .required(),
  legalStatus: yup.string().nullable(),
  placeOfBirth: yup.string().nullable()
});

export const ConditionalSignatoryFiscalCodeSchema = yup.object({
  fiscalCode: yup.string().when('userType', {
    is: CustomerProfile.BUYER,
    otherwise: () => yup.string().nullable(),
    then: () =>
      yup
        .string()
        .matches(fiscalCodeRegex, {
          excludeEmptyString: true
        })
        .required()
  })
});

const ContractSchemaOtherCountry = BaseContractSchema.shape({
  buyerFeePercentage: yup.number().max(100).required(),
  loanSuspension: yup.boolean().required(),
  loanSuspensionDate: yup.date().when('loanSuspension', {
    is: true,
    otherwise: () => yup.date().nullable(),
    then: () => yup.date().required()
  }),
  sellerFeePercentage: yup.number().max(100).required(),
  signatories: yup.array().of(ConditionalSignatoryFiscalCodeSchema)
});

export const OfferFormWrapperSchema = yup
  .object({
    contract: BaseContractSchema,
    entities: yup
      .array()
      .of(yup.object({ company: CompanyFormSchema }))
      .nullable(),
    netSellerPriceValue: yup.number().required(),
    users: yup.array().of(PhysicalSignatoryUserSchema)
  })
  .test('representative', 'representative', ({ entities }) => {
    // We should at least have 1 representative signatory in company
    const representativeCount = entities?.filter(
      ({ company }) => !company || company.users?.length === 0
    );

    return !representativeCount?.length;
  });

export const OfferFormWrapperSchemaOtherCountry = OfferFormWrapperSchema.shape({
  contract: ContractSchemaOtherCountry
});

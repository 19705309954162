import { Language } from '@proprioo/hokkaido';

export const LANGUAGES_OPTIONS: Record<
  Language,
  { flagUrl: string; text: string }
> = {
  en: {
    flagUrl: 'https://cdn.proprioo.fr/assets/flags/GB.svg',
    text: 'english'
  },
  es: {
    flagUrl: 'https://cdn.proprioo.fr/assets/flags/ES.svg',
    text: 'spanish'
  },
  fr: {
    flagUrl: 'https://cdn.proprioo.fr/assets/flags/FR.svg',
    text: 'french'
  },
  it: {
    flagUrl: 'https://cdn.proprioo.fr/assets/flags/IT.svg',
    text: 'italian'
  }
};

import { useTranslation } from 'next-i18next';
import { useRecoilValue } from 'recoil';

import MyContactsIcon from '@/assets/vectors/menu/account-card-details.svg';
import { menuSelector } from '@/components/state/MenuState';
import { EXTERNAL_TOOLS } from '@/constants/global';

import { ItemLabel, ToolItem } from '../../menuItems/MenuItems.styles';

const MyContactsItem = () => {
  const { t } = useTranslation();

  const isOpen = useRecoilValue(menuSelector);

  return (
    <a
      href={EXTERNAL_TOOLS.MY_CONTACTS}
      id="sidebar-menu_my-contacts"
      rel="noopener noreferrer"
      target="_blank"
    >
      <ToolItem isOpen={isOpen}>
        <MyContactsIcon />
        <ItemLabel>{t('myPhoneBook')}</ItemLabel>
      </ToolItem>
    </a>
  );
};

export default MyContactsItem;

import qs from 'qs';
import { FC, useReducer } from 'react';
import useSWR from 'swr';

import { useCurrentAgent } from '@/hooks/agents/useCurrentAgent';
import { useDebounce } from '@proprioo/salatim';

import { SearchState, SearchType } from './interfaces';
import { Layout } from './Search.styles';
import { MIN_CHARACTERS_VALUE } from './Search.utils';
import SearchInput from './searchInput/SearchInput';
import SearchSelect from './searchSelect/SearchSelect';

type SearchProps = {
  onCallback?(): void;
};

const Search: FC<SearchProps> = ({ onCallback }) => {
  const [{ isSelectOpen, searchValue, selectedType }, updateReducer] =
    useReducer(
      (prev: SearchState, next: Partial<SearchState>) => ({ ...prev, ...next }),
      {
        isSelectOpen: false,
        searchValue: '',
        selectedType: SearchType.ALL_TYPES
      }
    );

  const currentAgent = useCurrentAgent();
  const debouncedSearchValue = useDebounce<string>(searchValue, 500);

  const params = qs.stringify(
    {
      limitMax: 5,
      searchType: selectedType,
      textSearch: debouncedSearchValue,
      ...(currentAgent?.id && { requestingAgent: currentAgent.id })
    },
    { addQueryPrefix: true, encode: true }
  );

  const { data: suggestions } = useSWR<string[]>(
    debouncedSearchValue.length > MIN_CHARACTERS_VALUE &&
      selectedType !== SearchType.ALL_TYPES
      ? `/api/search/autocompletion${params}`
      : null
  );

  return (
    <Layout>
      <SearchSelect
        isSelectOpen={isSelectOpen}
        selectedType={selectedType}
        updateReducer={updateReducer}
      />
      <SearchInput
        searchValue={searchValue}
        selectedType={selectedType}
        suggestions={suggestions}
        onCallback={onCallback}
        updateReducer={updateReducer}
      />
    </Layout>
  );
};

export default Search;

import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import { Fragment, useEffect } from 'react';
import { useResetRecoilState } from 'recoil';

import * as Sentry from '@sentry/nextjs';

import { menuSelector } from '../../state/MenuState';

const Routing = () => {
  const { events } = useRouter();

  const resetMenu = useResetRecoilState(menuSelector);

  const handleRouteChangeStart = () => {
    resetMenu();
    NProgress.start();
  };

  const handleRouteChangeComplete = () => {
    NProgress.done();
    window.scrollTo(0, 0);
  };

  const handleRouteChangeError = () => NProgress.done();

  const handleBeforeHistoryChange = () =>
    // We clear breadcrumb here to keep user actions history clear after each route change.
    Sentry.getCurrentScope().clearBreadcrumbs();

  useEffect(() => {
    NProgress.configure({
      minimum: 0.2,
      showSpinner: false,
      trickleSpeed: 100
    });

    events.on('routeChangeStart', handleRouteChangeStart);
    events.on('routeChangeComplete', handleRouteChangeComplete);
    events.on('routeChangeError', handleRouteChangeError);
    events.on('beforeHistoryChange', handleBeforeHistoryChange);

    return () => {
      events.off('routeChangeStart', handleRouteChangeStart);
      events.off('routeChangeComplete', handleRouteChangeComplete);
      events.off('routeChangeError', handleRouteChangeError);
      events.off('beforeHistoryChange', handleBeforeHistoryChange);
    };
  }, []);

  return <Fragment />;
};

export default Routing;

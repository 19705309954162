import styled from 'styled-components';

import { convertPxToRem, fonts } from '@proprioo/salatim';

export const ScoreTag = styled.span<{
  backgroundColor: string;
  textColor: string;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${convertPxToRem(4)} ${convertPxToRem(8)};
  border-radius: ${convertPxToRem(6)};
  font-weight: bold;
  font-size: ${fonts.smallest};
  line-height: ${convertPxToRem(16)};
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ textColor }) => textColor};

  :not(:only-of-type) {
    margin: ${convertPxToRem(2)} ${convertPxToRem(4)} ${convertPxToRem(2)} 0;
  }
`;

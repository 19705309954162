import { useTranslation } from 'next-i18next';

import { OptionProps } from '@proprioo/hokkaido';

export const useTranslatedOptions = (options: OptionProps[]): OptionProps[] => {
  const { t } = useTranslation();

  return options.map(({ value, label }) =>
    label ? { label: t(label), value } : { label, value }
  );
};

import { useTranslation } from 'next-i18next';
import { FC, useMemo, useRef, useState } from 'react';

import CloseIcon from '@/assets/icons/close.svg';
import { Squad } from '@/components/state/interfaces';
import { useCurrentAgent } from '@/hooks/agents/useCurrentAgent';
import { useAgentsByAgentId } from '@/hooks/squads/useAgentsByTeamLeadId';
import { useSquadsByAgentId } from '@/hooks/squads/useSquadsByAgentId';
import { PAGE_VIEW_TITLE } from '@/utils/gtm';
import { generateButtonId } from '@/utils/tracking';
import { isNotEmpty } from '@proprioo/hokkaido';
import {
  Button,
  ButtonAppearance,
  Input,
  useOnClickOutside
} from '@proprioo/salatim';

import { sortAndFilterAgents } from '../LeadView.utils';
import AgentCheckbox from './agentCheckbox/AgentCheckbox';
import {
  CloseButton,
  Divider,
  MenuFooter,
  MenuHeader,
  MenuLayout,
  MenuScrollArea,
  MenuSearchWrapper,
  MenuWrapper,
  SquadButton
} from './AgentsSquadsSelector.styles';
import SquadCheckbox from './squadCheckbox/SquadCheckbox';

type AgentsSquadsSelectorProps = {
  agentIds?: number[];
  setAgentIds(agentIds: number[]): void;
};

const AgentsSquadsSelector: FC<AgentsSquadsSelectorProps> = ({
  agentIds = [],
  setAgentIds
}) => {
  const { t } = useTranslation();

  const currentAgent = useCurrentAgent();
  const allSquads = useSquadsByAgentId(currentAgent?.id);
  const agents = useAgentsByAgentId(currentAgent?.id);

  const layoutRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const agentIdsFromSquad = useMemo(() => agents.map(({ id }) => id), [agents]);

  const selectedAgentIds = [
    ...new Set(agentIdsFromSquad.filter(id => agentIds.includes(id)))
  ];

  const dedupeAgents = agents.filter(
    (agent, index, self) =>
      index === self.findIndex(t => t.email === agent.email)
  );

  const orderedAgents = dedupeAgents.length
    ? sortAndFilterAgents(dedupeAgents, search).filter(
        agent => agent.id !== currentAgent?.id
      )
    : [];

  useOnClickOutside(layoutRef, event => {
    if (isOpen && !layoutRef.current?.contains(event.target as Node)) {
      handleToggleMenu();
    }
  });

  const getAgentsFromSquad = (squad: Squad) => squad.agents.map(({ id }) => id);

  const selectedAgentsCount = (squad: Squad) =>
    getAgentsFromSquad(squad).filter(id => agentIds.includes(id)).length;

  const handleAgentSelection = (agentId: number, isChecked: boolean) => {
    setAgentIds(
      isChecked ? [...agentIds, agentId] : agentIds.filter(id => id !== agentId)
    );
    setSearch('');
  };

  const handleSquadSelection = (squad: Squad) => {
    const agentsFromSquad = getAgentsFromSquad(squad);
    const hasSelectedAgents = agentsFromSquad
      .map(id => agentIds.includes(id))
      .filter(Boolean).length;

    if (hasSelectedAgents) {
      const removeAgents = agentIds.filter(id => !agentsFromSquad.includes(id));

      setAgentIds(removeAgents);
    } else {
      const addSquadAgents = new Set([...agentIds, ...agentsFromSquad]);

      setAgentIds([...addSquadAgents]);
    }
  };

  const handleToggleMenu = () => {
    if (isOpen) {
      setSearch('');
    }

    setIsOpen(!isOpen);
  };

  const handleToggleAll = () => {
    setAgentIds(selectedAgentIds.length ? [] : agentIdsFromSquad);
  };

  return (
    <div ref={layoutRef}>
      <SquadButton
        id={generateButtonId(
          PAGE_VIEW_TITLE.LEADS,
          'agentsSquadsSelectorLabel'
        )}
        type="button"
        aria-label={t('agentsSquadsSelector')}
        onClick={handleToggleMenu}
      >
        {selectedAgentIds.length}
      </SquadButton>
      {isOpen && (
        <MenuWrapper>
          <MenuLayout>
            <MenuHeader>
              <span>{t('agentPlural')}</span>
              <CloseButton
                aria-label={t('close')}
                title={t('close')}
                onClick={handleToggleMenu}
              >
                <CloseIcon />
              </CloseButton>
            </MenuHeader>
            <MenuSearchWrapper>
              <Input
                dataTest="agent-selector-search"
                label={t('search')}
                disabled={false}
                error={false}
                value={search}
                onChange={setSearch}
              />
            </MenuSearchWrapper>
            <MenuScrollArea>
              {currentAgent && (
                <AgentCheckbox
                  key={currentAgent.id}
                  agentId={currentAgent.id}
                  checked={selectedAgentIds?.includes(currentAgent.id) ?? false}
                  onChange={checked =>
                    handleAgentSelection(currentAgent.id, checked)
                  }
                />
              )}
              {allSquads?.map(squad => (
                <SquadCheckbox
                  key={squad.id}
                  agentsCount={squad.agents.length}
                  label={squad.name}
                  selectedAgentsCount={selectedAgentsCount(squad)}
                  onChange={() => handleSquadSelection(squad)}
                />
              ))}
              <Divider />
              {orderedAgents.map(agent => (
                <AgentCheckbox
                  key={agent.id}
                  agentId={agent.id}
                  checked={selectedAgentIds?.includes(agent.id) ?? false}
                  onChange={checked => handleAgentSelection(agent.id, checked)}
                />
              ))}
              {orderedAgents.length === 0 && isNotEmpty(search) && (
                <div>{t('unknownAgent')}</div>
              )}
            </MenuScrollArea>
            <MenuFooter>
              <Button
                label={t(selectedAgentIds.length ? 'unselectAll' : 'selectAll')}
                appearance={ButtonAppearance.NO_APPEARANCE}
                onClick={handleToggleAll}
              />
            </MenuFooter>
          </MenuLayout>
        </MenuWrapper>
      )}
    </div>
  );
};

export default AgentsSquadsSelector;

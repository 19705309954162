import { OnlineAlert } from '@/components/qualification/sellerQualification/interfaces';
import {
  Agent,
  agentOrUndefined,
  Squad,
  SquadRole
} from '@/components/state/interfaces';
import { nullOrNumber } from '@proprioo/hokkaido';

export type CurrentSquads = {
  currentAgentSquadRole: SquadRole;
  id: string;
  name: string;
  color: string;
  lead: agentOrUndefined;
  agents: Agent[];
};

export const isInSameSquad = (squads: Squad[], agentId?: nullOrNumber) =>
  squads.filter(
    ({ agents }) => agents.filter(({ id }) => id === agentId).length > 0
  ).length > 0;

export const isOwnerOfOneAlert = (
  alerts: OnlineAlert[],
  currentSquads: CurrentSquads[],
  isTeamLead: boolean,
  currentAgent?: Agent
): boolean =>
  alerts.some(
    ({ project }) =>
      Boolean(
        isTeamLead && isInSameSquad(currentSquads, project?.agentOwner)
      ) || currentAgent?.id === project.agentOwner
  );

export const hasOnlyUnassignedAlerts = (alerts: OnlineAlert[]) =>
  alerts.every(({ project }) => !project.agentOwner);

import { useTranslation } from 'next-i18next';

import { nullOrNumber } from '@proprioo/hokkaido';

export type PropertyInformation = {
  nbBedrooms: nullOrNumber;
  nbRooms: nullOrNumber;
  surface: nullOrNumber;
};

export const usePropertyInformation = ({
  nbBedrooms,
  nbRooms,
  surface
}: PropertyInformation): string[] => {
  const { t } = useTranslation();

  return [
    nbRooms ? t('roomsTagCount', { count: nbRooms }) : '',
    nbBedrooms ? t('bedroomsTagCount', { count: nbBedrooms }) : '',
    surface ? t('surfaceSquareMeterWithValue', { value: surface }) : ''
  ];
};

import { FC } from 'react';

import ActivityIndicator from '@/components/myCustomers/common/activityIndicator/ActivityIndicator';
import CustomerName from '@/components/myCustomers/common/customerName/CustomerName';
import {
  ActivityCellWrapper,
  ActivityIndicatorWrapper,
  MultilineCell
} from '@/components/myCustomers/common/styles/Table.styles';

import { LeadViewTableCellProps } from '../interface';
import StatusCell from '../statusCell/StatusCell';

const CustomerStatusCell: FC<LeadViewTableCellProps> = ({ opportunity }) => {
  const { firstName, lastName, nextEvent } = opportunity;

  return (
    <ActivityCellWrapper>
      <ActivityIndicatorWrapper>
        <ActivityIndicator targetDate={nextEvent?.targetDate} />
      </ActivityIndicatorWrapper>
      <MultilineCell>
        <CustomerName
          firstName={firstName}
          isImportant={false}
          lastName={lastName}
        />
        <StatusCell opportunity={opportunity} />
      </MultilineCell>
    </ActivityCellWrapper>
  );
};

export default CustomerStatusCell;

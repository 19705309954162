import { ListingPictureProps } from '@/components/listing/interfaces';
import { IMAGE_RATIO } from '@/components/listing/listingAd/mosaic/carousel/Carousel.styles';
import { PICTURE_API_FULL_URL } from '@/constants/global';
import {
  computeArrayOfPictures,
  computeNbVisiblePictures,
  computePictureFromAssets,
  computePictures,
  getOnlyPictures
} from '@proprioo/hokkaido';
import { useMobileDevice } from '@proprioo/salatim';

export const IMAGE_WIDTH = 400;
export const BIG_IMAGE_WIDTH = 800;

export type MosaicPicturesProps = {
  bigFirstPicture: string;
  computedMobilePictures: string[];
  computedPictures: string[];
  computedPicturesForSlideshow: string[];
  nbPicturesWithoutPlaceholder: number;
  nbRemainingPictures: number;
  smallFirstPicture: string;
};

export const useListingAdMosaicPictures = (
  pictures: ListingPictureProps[],
  virtualVisit?: string
): MosaicPicturesProps => {
  const isMobile = useMobileDevice();

  const { computedPictures, computedPicturesForSlideshow } = computePictures(
    pictures,
    virtualVisit
  );
  const [firstPicture, ...rest] = computedPictures;

  const nbVisiblePictures = computeNbVisiblePictures(isMobile, virtualVisit);
  const othersPictures = getOnlyPictures(rest, nbVisiblePictures);
  const nbPicturesWithoutPlaceholder = othersPictures.length;

  return {
    bigFirstPicture: computePictureFromAssets(
      firstPicture,
      BIG_IMAGE_WIDTH,
      IMAGE_RATIO,
      PICTURE_API_FULL_URL
    ),
    computedMobilePictures: computedPictures.map(picture =>
      computePictureFromAssets(
        picture,
        IMAGE_WIDTH,
        IMAGE_RATIO,
        PICTURE_API_FULL_URL
      )
    ),
    computedPictures: computeArrayOfPictures(
      nbVisiblePictures - nbPicturesWithoutPlaceholder,
      othersPictures
    ).map(picture =>
      computePictureFromAssets(
        picture,
        IMAGE_WIDTH,
        IMAGE_RATIO,
        PICTURE_API_FULL_URL
      )
    ),
    computedPicturesForSlideshow: computedPicturesForSlideshow.map(picture =>
      picture.virtualVisitLink
        ? picture.virtualVisitLink
        : computePictureFromAssets(
            picture,
            BIG_IMAGE_WIDTH,
            IMAGE_RATIO,
            PICTURE_API_FULL_URL
          )
    ),
    nbPicturesWithoutPlaceholder,
    nbRemainingPictures: rest.length,
    smallFirstPicture: computePictureFromAssets(
      firstPicture,
      IMAGE_WIDTH,
      IMAGE_RATIO,
      PICTURE_API_FULL_URL
    )
  };
};

import Link from 'next/link';
import { transparentize } from 'polished';
import styled from 'styled-components';

import {
  breakpoints,
  colors,
  convertPxToRem,
  paragraphs
} from '@proprioo/salatim';

export const Layout = styled(Link)<{ color: string; isSelected: boolean }>`
  display: inline-block;
  padding: ${convertPxToRem(16)} ${convertPxToRem(24)};
  margin-bottom: -${convertPxToRem(3)};
  background-color: white;
  border-bottom: ${convertPxToRem(3)} solid
    ${({ color, isSelected }) =>
      isSelected ? color : transparentize(0.85, color)};
  transition: all 0.2s ease-out;
  outline: 0;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;

export const BadgesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${convertPxToRem(8)};
  gap: ${convertPxToRem(8)};

  @media (min-width: ${breakpoints.xsmall}px) {
    flex-direction: row;
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  column-gap: ${convertPxToRem(12)};
  padding-left: ${convertPxToRem(8)};
`;

export const TitleWrapper = styled.span<{ isSelected: boolean }>`
  ${paragraphs.body1}
  color: ${({ isSelected }) =>
    isSelected ? colors.dark.base : colors.dark.base60};
  margin-right: ${convertPxToRem(8)};
  transition: color 0.2s ease-out;
  white-space: nowrap;
`;

export const TooltipIconWrapper = styled.div`
  > svg {
    height: ${convertPxToRem(32)};
    width: ${convertPxToRem(32)};
    margin-bottom: -${convertPxToRem(4)};

    path {
      fill: ${colors.grey.base};
    }
  }

  @media (hover: hover) {
    > svg:hover {
      path {
        fill: ${colors.dark.base};
      }
    }
  }
`;

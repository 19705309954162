import { darken } from 'polished';
import styled, { css } from 'styled-components';

import { Layout as ConfirmModalButtonsLayout } from '@/components/app/confirmModalButtons/ConfirmModalButtons.styles';
import {
  breakpoints,
  colors,
  convertPxToRem,
  paragraphs,
  radius
} from '@proprioo/salatim';

import { AlertCardStatus } from './AlertCard.utils';

export const AlertCardContent = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;

  > svg {
    margin-right: ${convertPxToRem(16)};
    min-width: ${convertPxToRem(24)};
    min-height: ${convertPxToRem(24)};
  }
`;

export const AlertCardWrapper = styled.div`
  width: 100%;
`;

const getLayoutStyle = (status: AlertCardStatus) => {
  switch (status) {
    case AlertCardStatus.DISPLAY:
      return css`
        background-color: ${colors.grey.base5};

        & ${AlertCardContent} > svg {
          color: ${colors.grey.base};
        }
      `;
    case AlertCardStatus.ERROR:
      return css`
        background-color: ${colors.red.base10};

        & ${AlertCardContent} > svg {
          color: ${colors.red.base};
        }
      `;
    case AlertCardStatus.INFO:
      return css`
        background-color: ${colors.blue.base60};

        & ${AlertCardContent} > svg {
          color: ${darken(0.4, colors.blue.base)};
        }
      `;
    case AlertCardStatus.SUCCESS:
      return css`
        background-color: ${colors.greenLight.base20};

        & ${AlertCardContent} > svg {
          color: ${colors.green.base};
        }
      `;
    case AlertCardStatus.WARNING:
      return css`
        background-color: ${colors.terracota.base10};

        & ${AlertCardContent} > svg {
          color: ${colors.dark.base};
        }
      `;
  }
};

export const AlertCardLayout = styled.div<{
  status: AlertCardStatus;
}>`
  ${paragraphs.bodyLight1};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  padding: ${convertPxToRem(16)};
  border-radius: ${radius.small};

  @media (min-width: ${breakpoints.xsmall}px) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  & + & {
    margin-top: ${convertPxToRem(8)};
  }

  ${({ status }) => getLayoutStyle(status)}
`;

export const AlertCardMain = styled.div`
  display: flex;
`;

export const AlertCardTitle = styled.span`
  ${paragraphs.subtitle1};
  display: block;
  margin-bottom: ${convertPxToRem(8)};
`;

export const AlertCardActions = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: center;
  padding-top: ${convertPxToRem(16)};

  > button + button,
  > button + a {
    margin-top: ${convertPxToRem(16)};
  }

  ${ConfirmModalButtonsLayout} {
    margin-top: ${convertPxToRem(0)};
  }

  @media (min-width: ${breakpoints.xsmall}px) {
    padding: 0 0 0 ${convertPxToRem(16)};
  }
`;

import { ComponentsMappingProps } from '@/hooks/useUserPermissionsBuilder/useUserPermissionsBuilder.interfaces';

import BusinessStockExchangeItem from './businessStockExchangeItem/BusinessStockExchangeItem';
import BuyerbaseItem from './buyerbaseItem/BuyerbaseItem';
import MyBuyersItem from './myBuyersItem/MyBuyersItem';
import MyPerformancesItem from './myPerformancesItem/MyPerformancesItem';
import MySellersItem from './mySellersItem/MySellersItem';
import MyTasksItems from './myTasksItem/MyTasksItem';
import OpportunitiesItem from './opportunitiesItem/OpportunitiesItem';
import ServicesItem from './servicesItem/ServicesItem';
import ZoningItem from './zoningItem/ZoningItem';

export const MENU_ITEMS_MAPPING: ComponentsMappingProps = [
  {
    Component: MyTasksItems,
    position: 1,
    url: '/agent/[agentId]/task-manager'
  },
  {
    Component: MyPerformancesItem,
    position: 2,
    url: '/agent/dashboard'
  },
  {
    Component: MySellersItem,
    position: 3,
    url: '/my-sellers'
  },
  {
    Component: BuyerbaseItem,
    position: 4,
    url: '/buyer'
  },
  {
    Component: MyBuyersItem,
    position: 5,
    url: '/my-buyers'
  },
  {
    Component: BusinessStockExchangeItem,
    position: 6,
    url: '/business-stock-exchange'
  },
  {
    Component: OpportunitiesItem,
    position: 7,
    url: '/map-properties'
  },
  {
    Component: ZoningItem,
    position: 8,
    url: '/zoning'
  },
  {
    Component: ServicesItem,
    position: 9,
    url: '/services'
  }
];

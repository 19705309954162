import styled, { css } from 'styled-components';

import { breakpoints, colors, convertPxToRem } from '@proprioo/salatim';

export const Layout = styled.nav`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;

  > svg {
    width: ${convertPxToRem(100)};
    height: ${convertPxToRem(25)};
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: white;
  width: 100%;
  align-items: center;
  min-height: ${convertPxToRem(48)};
  flex-shrink: 0;

  > svg {
    width: ${convertPxToRem(24)};
    height: ${convertPxToRem(24)};
  }

  @media (min-width: ${breakpoints.xsmall}px) {
    min-height: auto;
    margin-left: auto;
    justify-content: space-between;
  }
`;

export const MenuHeader = styled.div<{ isOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: ${convertPxToRem(8)} ${convertPxToRem(16)};

  @media (min-width: ${breakpoints.small}px) {
    height: auto;
    padding: ${convertPxToRem(8)};

    ${({ isOpen }) =>
      isOpen &&
      css`
        padding: ${convertPxToRem(8)} ${convertPxToRem(16)};
      `};
  }
`;

export const MenuCreate = styled.div<{ isOpen: boolean }>`
  display: none;
  margin: ${convertPxToRem(16)} 0;
  padding: 0 ${convertPxToRem(8)};
  width: 100%;

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: block;
      padding: 0 ${convertPxToRem(16)};
    `};

  @media (min-width: ${breakpoints.small}px) {
    display: block;
    text-align: right;
    padding: 0 ${convertPxToRem(8)};

    ${({ isOpen }) =>
      isOpen &&
      css`
        text-align: left;
        padding: 0 ${convertPxToRem(16)};
      `};
  }
`;

export const CreateActions = styled.div`
  width: ${convertPxToRem(224)};

  a {
    display: flex;
    align-items: center;
    padding: ${convertPxToRem(8)};
    color: ${colors.dark.base};

    :hover {
      color: ${colors.terracota.base};

      > svg {
        color: ${colors.terracota.base};
      }
    }
  }

  svg {
    color: ${colors.grey.base};
    margin-right: ${convertPxToRem(8)};
    width: ${convertPxToRem(24)};
    height: ${convertPxToRem(24)};
  }
`;

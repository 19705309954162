import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Fragment } from 'react';

import AdminSettingsIcon from '@/assets/icons/settings-admin.svg';
import AdminSettings from '@/components/app/layout/adminSettings/AdminSettings';
import { useCurrentAgent } from '@/hooks/agents/useCurrentAgent';
import { useAuth } from '@/hooks/auth/useAuth';
import { useCurrentCountry } from '@/hooks/useCurrentCountry/useCurrentCountry';
import { EventPageView, PAGE_VIEW_TITLE, sendPageView } from '@/utils/gtm';
import { getFormatUserName } from '@/utils/user';
import { listToString } from '@proprioo/hokkaido';
import { useModal, useSmartphoneDevice } from '@proprioo/salatim';

import AgentAvatar from '../../avatar/agentAvatar/AgentAvatar';
import { AvatarAndText, Layout, Modify, Text } from './ImpersonationBar.styles';

const ImpersonationBar = () => {
  const { t } = useTranslation();
  const { asPath } = useRouter();
  const { user } = useAuth();

  const { createModal, openModal, closeModal, toggleLoader } = useModal({
    onOpen: () =>
      sendPageView(
        { path: asPath, title: PAGE_VIEW_TITLE.MODAL_ADMIN_SETTINGS },
        EventPageView.MODAL_OPENING
      )
  });

  const isSmartphone = useSmartphoneDevice();
  const currentAgent = useCurrentAgent();
  const currentCountry = useCurrentCountry();

  const formattedAgent = getFormatUserName(
    currentAgent?.firstname || null,
    currentAgent?.lastname || null,
    !isSmartphone
  );
  const identity = formattedAgent || user?.email;
  const translatedCountry = t(currentCountry);

  const impersonationSentence = isSmartphone
    ? listToString([identity, translatedCountry], t('join'))
    : t('loggedInAs', { country: translatedCountry, identity });

  if (!user?.original?.email) {
    return <Fragment />;
  }

  return (
    <Layout id="impersonated-user" data-test="impersonated-user">
      <AvatarAndText>
        {!isSmartphone && (
          <AgentAvatar
            agentId={currentAgent?.id}
            dataTest="agent-avatar"
            isToolTipVisible={false}
            name={formattedAgent || user.email}
          />
        )}
        <Text dangerouslySetInnerHTML={{ __html: impersonationSentence }} />
      </AvatarAndText>
      <Modify onClick={openModal}>{t('edit')}</Modify>
      {createModal({
        children: (
          <AdminSettings closeModal={closeModal} toggleLoader={toggleLoader} />
        ),
        icon: <AdminSettingsIcon />,
        isAlternative: true,
        title: t('adminSettings')
      })}
    </Layout>
  );
};

export default ImpersonationBar;

import { ReactElement } from 'react';

import ErrorIcon from '@/assets/icons/exclamation-circle.svg';
import InfoIcon from '@/assets/icons/info-circle.svg';
import ResolvedIcon from '@/assets/icons/success-circle.svg';

import { BannerLevel } from './interfaces';

export const getIconByLevel = (level: BannerLevel): ReactElement => {
  switch (level) {
    case BannerLevel.ERROR:
      return <ErrorIcon data-test="error-icon" />;
    case BannerLevel.RESOLVED:
      return <ResolvedIcon data-test="resolved-icon" />;
    default:
      return <InfoIcon data-test="info-icon" />;
  }
};

export const getTextMaxLength = (
  isSmartphone: boolean,
  isMobile: boolean
): number => {
  if (isSmartphone) return 20;
  if (isMobile) return 150;
  return 300;
};

import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';

import Replay from '@/assets/icons/replay.svg';
import { ArbitraryObject } from '@/utils/interfaces';
import { logError } from '@/utils/log';
import { Button, H3 } from '@proprioo/salatim';

import {
  Layout,
  Paragraph,
  TitleImage,
  TitleWrapper
} from './GlobalError.styles';

type GlobalErrorProps = {
  errorCode?: string;
  extraLog?: ArbitraryObject;
  text?: string;
};

const GlobalError: FC<GlobalErrorProps> = ({
  errorCode = '404',
  extraLog,
  text = 'errorHappenedTechTeamReceivedMessage'
}) => {
  const { t } = useTranslation();
  const { pathname } = useRouter();

  useEffect(() => {
    logError(`Global error ${errorCode} occured visiting ${pathname}`, {
      ...extraLog,
      releaseLog: `${process.env.COMMIT_HASH}`
    });
  }, []);

  return (
    <Layout data-test="error-page">
      <TitleImage>
        <span>{errorCode}</span>
      </TitleImage>
      <TitleWrapper>
        <H3 title={t(text)} isAlternative={true} />
      </TitleWrapper>
      <Paragraph>{t('notFoundPage')}</Paragraph>
      <Link href="/">
        <Button label={t('backToHomePage')} icon={<Replay />} />
      </Link>
    </Layout>
  );
};

export default GlobalError;

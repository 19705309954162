import { useTranslation } from 'next-i18next';
import { FC, Fragment } from 'react';

import SharesIcon from '@/assets/icons/shares.svg';
import { SecondaryInfo } from '@/components/myCustomers/common/styles/MyCustomers.styles';
import { getLeadRevenue } from '@/components/myCustomers/mySellers/LeadView.utils';
import { TAG_CLOSE_ARIA_LABEL } from '@/constants/global';
import { useLanguage } from '@/hooks/useLanguage/useLanguage';
import { formatCompactPrice } from '@/utils/price';
import { Tag, TagAppearance, TagColorsTypes } from '@proprioo/salatim';

import { RevenueTagWrapper } from '../../Leads.styles';

export type LeadRevenueTagProps = {
  commission: number;
  hasEmptyPlaceholder: boolean;
  chargedFees?: number;
  isApproximative?: boolean;
  price?: number;
};

const LeadRevenueTag: FC<LeadRevenueTagProps> = ({
  chargedFees,
  commission,
  hasEmptyPlaceholder,
  isApproximative,
  price
}) => {
  const { t } = useTranslation();
  const { currentLocale } = useLanguage();

  const leadRevenue = getLeadRevenue(commission, price, chargedFees);
  const formattedPrice = formatCompactPrice(leadRevenue, currentLocale);

  return leadRevenue > 0 ? (
    <RevenueTagWrapper>
      <Tag
        appearance={TagAppearance.BUSINESS}
        closeAriaLabel={t(TAG_CLOSE_ARIA_LABEL)}
        color={TagColorsTypes.GREY_LIGHT}
        icon={<SharesIcon />}
        labels={[`${isApproximative ? t('tilde') : ''}${formattedPrice || ''}`]}
      />
    </RevenueTagWrapper>
  ) : hasEmptyPlaceholder ? (
    <SecondaryInfo>{t('hyphen')}</SecondaryInfo>
  ) : (
    <Fragment />
  );
};

export default LeadRevenueTag;

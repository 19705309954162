import styled from 'styled-components';

import { colors, convertPxToRem, paragraphs, radius } from '@proprioo/salatim';

export const Layout = styled.div`
  display: flex;
  align-items: center;
  max-width: ${convertPxToRem(300)};
`;

export const PictureWrapper = styled.div`
  min-width: ${convertPxToRem(80)};

  img {
    border-radius: ${radius.small};
    object-fit: cover;
  }
`;

export const SinceDate = styled.span`
  ${paragraphs.caption};
  color: ${colors.dark.base60};
`;

export const Address = styled.address`
  ${paragraphs.bodyLight2};
  color: ${colors.dark.base};
  font-style: normal;
`;

export const OtherInformation = styled.span`
  ${paragraphs.bodyLight2};
  color: ${colors.dark.base60};
  display: flex;
`;

export const ListingDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${convertPxToRem(8)};

  ${Address} {
    margin: ${convertPxToRem(4)} 0;
  }
`;

export const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${convertPxToRem(16)};
  width: ${convertPxToRem(16)};
  margin-left: ${convertPxToRem(4)};
  background-color: ${colors.green.base};
  border-radius: ${radius.rounded};

  > svg {
    color: white;
  }
`;

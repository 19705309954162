import { fetcher } from '@/utils/http';
import { HttpMethod } from '@proprioo/hokkaido';

import { UpdateExternalLeadPayload } from './interfaces';

export const updateExternalLead = (
  payload: UpdateExternalLeadPayload
): Promise<unknown> =>
  fetcher('/api/external-lead/update', {
    body: JSON.stringify(payload),
    method: HttpMethod.PUT
  });

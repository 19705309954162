import styled, { css } from 'styled-components';

import {
  colors,
  convertPxToRem,
  fonts,
  paragraphs,
  radius
} from '@proprioo/salatim';

import { AvatarSize } from './interfaces';

export const AvatarLayout = styled.div`
  line-height: initial;
  display: flex;
  align-items: center;
  column-gap: ${convertPxToRem(8)};
`;

export const AvatarName = styled.span`
  ${paragraphs.bodyLight2}
`;

export const AvatarIconWrapper = styled.span<{ size?: number }>`
  display: block;
  border-radius: ${radius.rounded};
  background-color: ${colors.green.base};
  color: ${colors.terracota.base20};
  text-align: center;
  font-size: ${fonts.small};
  font-weight: bold;

  ${({ size }) => css`
    width: ${convertPxToRem(size || AvatarSize.NORMAL)};
    height: ${convertPxToRem(size || AvatarSize.NORMAL)};
    line-height: ${convertPxToRem(size || AvatarSize.NORMAL)};
  `}

  img {
    width: 100%;
    height: 100%;
    border-radius: ${radius.rounded};
    object-fit: cover;
  }

  svg {
    height: 100%;
    color: white;
  }
`;

import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { FC, Fragment } from 'react';

import AlertCard from '@/components/app/alertCard/AlertCard';
import { AlertCardStatus } from '@/components/app/alertCard/AlertCard.utils';
import { MobileCardsLoader } from '@/components/myCustomers/common/loaders/Loaders';
import { PAGE_VIEW_TITLE } from '@/utils/gtm';
import { generateProjectId } from '@/utils/tracking';

import { LeadViewTab } from '../../../interfaces';
import { ExternalLeadsProps } from '../interfaces';
import ExternalLeadCard from './externalLeadCard/ExternalLeadCard';
import { CardsContainer, Wrapper } from './ExternalLeadsMobile.styles';

const ExternalLeadsMobile: FC<ExternalLeadsProps> = ({
  currentTab,
  isLoading,
  isTeamLead = false,
  opportunities
}) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <Wrapper data-test="mobile-loader">
        <MobileCardsLoader />
      </Wrapper>
    );
  }

  return (
    <Fragment>
      {currentTab === LeadViewTab.LEADS_ALL_EXTERNAL && (
        <Wrapper>
          <AlertCard
            status={AlertCardStatus.WARNING}
            text={t('externalLeadsAutomaticAttributionTabInformation')}
          />
        </Wrapper>
      )}
      {currentTab === LeadViewTab.LEADS_ALL_EXTERNAL_EXPIRED && (
        <Wrapper>
          <AlertCard
            status={AlertCardStatus.WARNING}
            text={t('externalLeadsExpiredTabInformation')}
          />
        </Wrapper>
      )}
      <CardsContainer data-test="external-leads-mobile-container">
        {opportunities.map(opportunity => (
          <Link
            href={`/external-lead/${opportunity.pigeExternalId}`}
            id={generateProjectId(
              PAGE_VIEW_TITLE.LEADS_EXTERNAL,
              `${opportunity.pigeExternalId}`
            )}
            key={opportunity.pigeExternalId}
          >
            <ExternalLeadCard
              isTeamLead={isTeamLead}
              opportunity={opportunity}
            />
          </Link>
        ))}
      </CardsContainer>
    </Fragment>
  );
};

export default ExternalLeadsMobile;

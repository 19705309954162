import { useMemo } from 'react';

import { Squads } from '@/components/state/interfaces';
import { getSquads } from '@/components/state/utils';

import { useActiveAgents } from '../agents/useActiveAgents';

export const useSquads = (): Squads => {
  const agents = useActiveAgents();

  const squads = useMemo(() => getSquads(agents), [agents]);

  return squads;
};

import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { convertToEstimationStageTranslation } from '@/components/listing/utils';
import { SecondaryInfo } from '@/components/myCustomers/common/styles/MyCustomers.styles';
import { EstimationStage } from '@proprioo/hokkaido';

export type ArchiveReasonProps = {
  estimationStage: EstimationStage;
};

const ArchiveReason: FC<ArchiveReasonProps> = ({ estimationStage }) => {
  const { t } = useTranslation();

  return (
    <SecondaryInfo>
      {t(convertToEstimationStageTranslation(estimationStage))}
    </SecondaryInfo>
  );
};

export default ArchiveReason;

import { darken } from 'polished';
import styled, { css } from 'styled-components';

import { colors, convertPxToRem, paragraphs } from '@proprioo/salatim';

import { BannerLevel } from './interfaces';

export const BannerLayout = styled.div<{ level: BannerLevel }>`
  ${paragraphs.bodyLight1};
  display: flex;
  align-items: flex-start;
  padding: ${convertPxToRem(16)};

  > svg {
    height: ${convertPxToRem(24)};
    width: ${convertPxToRem(24)};
    min-width: ${convertPxToRem(24)};
    margin-right: ${convertPxToRem(8)};
  }

  ${({ level }) =>
    level === BannerLevel.ERROR
      ? css`
          background-color: ${colors.red.base10};

          > svg {
            color: ${colors.red.base};
          }
        `
      : level === BannerLevel.INFO
      ? css`
          background-color: ${colors.blue.base60};

          > svg {
            color: ${darken(0.4, colors.blue.base)};
          }
        `
      : level === BannerLevel.RESOLVED
      ? css`
          background-color: ${colors.greenLight.base20};

          > svg {
            color: ${colors.green.base};
          }
        `
      : css`
          background-color: ${colors.terracota.base10};

          > svg {
            color: ${colors.terracota.base};
          }
        `}
`;

import { FC } from 'react';

import InfoIcon from '@/assets/icons/inform.svg';
import { PAGE_VIEW_TITLE } from '@/utils/gtm';
import { generateButtonId } from '@/utils/tracking';
import { Checkbox, Tooltip, useSmartphoneDevice } from '@proprioo/salatim';

import { FilterWrapper, Helper, HelpIconWrapper } from './Filter.styles';

export type FilterProps = {
  checked: boolean;
  label: string;
  name: string;
  opportunitiesCount: number;
  tooltipLabel?: string;
  onChange(checked: boolean): void;
};

const Filter: FC<FilterProps> = ({
  label,
  checked,
  name,
  opportunitiesCount,
  onChange,
  tooltipLabel
}) => {
  const isSmartphoneDevice = useSmartphoneDevice();

  return tooltipLabel ? (
    <FilterWrapper>
      <Checkbox
        checked={checked}
        disabled={!opportunitiesCount}
        id={generateButtonId(
          PAGE_VIEW_TITLE.LEADS,
          'agentsSquadsSelectorLabel'
        )}
        label={label}
        name={name}
        onChange={onChange}
      />
      {isSmartphoneDevice ? (
        <Helper>{tooltipLabel}</Helper>
      ) : (
        <Tooltip tooltip={tooltipLabel}>
          <HelpIconWrapper>
            <InfoIcon data-test="info-icon" />
          </HelpIconWrapper>
        </Tooltip>
      )}
    </FilterWrapper>
  ) : (
    <Checkbox
      checked={checked}
      disabled={!opportunitiesCount}
      id={name}
      label={label}
      name={name}
      onChange={onChange}
    />
  );
};

export default Filter;

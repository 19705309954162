import { LeadSource } from '@proprioo/hokkaido';

export const isCasavoSMPSource = (source?: LeadSource): boolean => {
  if (!source) return false;

  return [
    LeadSource.CASAVO_SMP_IBUYER_MANDATE,
    LeadSource.CASAVO_SMP_MANDATE
  ].includes(source);
};

export const isCasavoLeadSource = (source?: LeadSource): boolean => {
  if (!source) return false;

  return [
    LeadSource.CASAVO_DELEGATION,
    LeadSource.CASAVO_LEAD,
    LeadSource.CASAVO_PROSPECT,
    LeadSource.CASAVO_SMP_IBUYER_MANDATE,
    LeadSource.CASAVO_SMP_MANDATE,
    LeadSource.CASAVO_VALUATION
  ].includes(source);
};

import { isBefore } from 'date-fns/isBefore';
import { isSameDay } from 'date-fns/isSameDay';
import { parseISO } from 'date-fns/parseISO';
import { useTranslation } from 'next-i18next';
import { FC, Fragment } from 'react';

import { CustomerEvent } from '../../myCustomers.interfaces';
import RelativeDate from '../relativeDate/RelativeDate';
import {
  DangerInfo,
  ImportantInfo,
  PrimaryInfo,
  SecondaryInfo
} from '../styles/MyCustomers.styles';
import { getActivityEventLabel } from './Activity.utils';

export type ActivityProps = {
  event?: CustomerEvent;
  isDateHighlighted?: boolean;
  isMobile?: boolean;
  isMultiline?: boolean;
};

const Activity: FC<ActivityProps> = ({
  event,
  isDateHighlighted,
  isMobile,
  isMultiline
}) => {
  const { t } = useTranslation();

  if (!event) {
    return (
      <PrimaryInfo>
        {t(isMobile ? 'noActivityPlanned' : 'noActivity')}
      </PrimaryInfo>
    );
  }

  const { activityType, targetDate } = event;
  const parsedTargetDate = parseISO(targetDate);

  const now = new Date();
  const isLate = isBefore(parsedTargetDate, now);
  const isToday = isSameDay(parsedTargetDate, now);

  const DateContainer =
    isDateHighlighted && isLate
      ? DangerInfo
      : isDateHighlighted && isToday
        ? ImportantInfo
        : SecondaryInfo;

  const renderedDate = (
    <DateContainer>
      <RelativeDate
        date={parsedTargetDate}
        isCapitalized={isMultiline || false}
      />
    </DateContainer>
  );

  return (
    <Fragment>
      {t(getActivityEventLabel(activityType))}
      {isMultiline ? <br /> : ' '}
      {renderedDate}
    </Fragment>
  );
};

export default Activity;

import { useRouter } from 'next/router';
import useSWR from 'swr';

import { UserInformations } from '@/components/state/interfaces';
import { EMPTY_CUSTOMER } from '@/components/state/utils';

export const useCustomer = () => {
  const {
    query: { customerId }
  } = useRouter();

  const {
    data: customer,
    isLoading,
    mutate: refreshCustomer,
    error
  } = useSWR<UserInformations>(customerId ? `/api/user/${customerId}` : null);

  return {
    customer: customer || EMPTY_CUSTOMER,
    error: !customer && error,
    isFetchingCustomer: isLoading,
    refreshCustomer
  };
};

import styled from 'styled-components';

import { convertPxToRem, radius } from '@proprioo/salatim';

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${convertPxToRem(4)};
  margin-top: ${convertPxToRem(16)};
`;

export const Inner = styled.div`
  background-color: white;
  padding: ${convertPxToRem(8)};
  border-radius: ${radius.big};
  overflow: hidden;
`;

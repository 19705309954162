import React from 'react';
import { ToastContainer } from 'react-toastify';

import { useMobileDevice } from '@proprioo/salatim';

import { NotificationLayout } from './NotificationToast.styles';

const NotificationToast = () => {
  const isMobile = useMobileDevice();

  return (
    <NotificationLayout isMobile={isMobile}>
      <ToastContainer
        autoClose={2000}
        closeButton={false}
        hideProgressBar={true}
        icon={false}
        newestOnTop={true}
        position={isMobile ? 'top-center' : 'bottom-left'}
      />
    </NotificationLayout>
  );
};

export default NotificationToast;

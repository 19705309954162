import useSWR from 'swr';

import { Agent } from '@/components/state/interfaces';

import { useAuth } from '../auth/useAuth';

export const useAgents = () => {
  const { isConnected } = useAuth();

  const {
    data: agents,
    mutate: refreshAgents,
    isLoading
  } = useSWR<Agent[]>(isConnected ? `/api/sales/agents` : null);

  return {
    agents: agents && agents.length ? agents : [],
    isLoading,
    refreshAgents
  };
};

import { useMemo } from 'react';

import { useAgents } from './useAgents';

export const useActiveAgents = () => {
  const { agents } = useAgents();

  const activeAgents = useMemo(
    () => agents.filter(({ isBlocked }) => !isBlocked),
    [agents]
  );

  return activeAgents;
};

import { validate } from 'email-validator';

import { PREFIX_EMAIL } from '@/constants/global';
import { isInternalEmail, nullOrString } from '@proprioo/hokkaido';

export const validateEmail = (email?: nullOrString) =>
  email ? validate(email) : true;

export const isFakePropriooEmail = (email?: nullOrString) =>
  Boolean(email?.includes(PREFIX_EMAIL));

export const isAFakeOrRealEmail = (email?: nullOrString) =>
  isFakePropriooEmail(email) || !isInternalEmail(email);

import Link from 'next/link';
import styled, { css } from 'styled-components';

import {
  colors,
  convertPxToRem,
  paragraphs,
  radius,
  shadows
} from '@proprioo/salatim';

export const Layout = styled.li`
  list-style: none;
`;

export const InnerLink = styled(Link)<{ isActive?: boolean }>`
  ${paragraphs.label};
  min-width: ${convertPxToRem(32)};
  height: ${convertPxToRem(32)};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.dark.base60};
  text-decoration: unset;
  border-radius: ${radius.normal};
  border: ${convertPxToRem(2)} solid transparent;
  padding: ${convertPxToRem(4)};
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
  }

  svg,
  path {
    fill: ${colors.dark.base};
  }

  &:hover {
    background-color: ${colors.terracota.base};
    color: white;

    svg,
    path {
      fill: white;
    }
  }

  &:active {
    border: ${convertPxToRem(2)} solid ${colors.grey.base20};
    color: white;

    svg,
    path {
      fill: white;
    }
  }

  &:hover,
  &:active {
    box-shadow: ${shadows.level3};
  }

  &:active,
  &:focus {
    outline: unset;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background: ${colors.grey.base20};
      color: ${colors.dark.base};

      &:hover,
      &:active {
        background: ${colors.grey.base20};
        color: ${colors.dark.base};
        border: ${convertPxToRem(2)} solid transparent;
      }
    `};
`;

import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { Layout } from './Counter.styles';

export type CounterProps = {
  isActive: boolean;
  count: number;
};

const Counter: FC<CounterProps> = ({ isActive, count }) => {
  const { t } = useTranslation();

  return (
    <Layout aria-label={t('sellerProjectCount', { count })} isActive={isActive}>
      {count}
    </Layout>
  );
};

export default Counter;

import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { FC } from 'react';

import PhoneIcon from '@/assets/icons/activity-call.svg';
import ClockIcon from '@/assets/icons/clock.svg';
import { getUserName } from '@/components/app/layout/sidebarItems/externalLeadSidebar/utils';
import { DealerType } from '@/components/buyersMatching/interfaces';
import { getDealerTypeTranslationKey } from '@/components/buyersMatching/utils';
import {
  ExternalOpportunityNormalized,
  ExternalOpportunityStatus
} from '@/components/myCustomers/mySellers/interfaces';
import { getRemainingTimeToAttributeLead } from '@/components/myCustomers/mySellers/LeadView.utils';
import {
  TAG_CLOSE_ARIA_LABEL,
  UNAVAILABLE_PLACEHOLDER
} from '@/constants/global';
import { useLanguage } from '@/hooks/useLanguage/useLanguage';
import { usePropertyInformation } from '@/hooks/usePropertyInformation/usePropertyInformation';
import { formatCompactPrice } from '@/utils/price';
import { listToString } from '@proprioo/hokkaido';
import { Tag, TagAppearance, TagColorsTypes } from '@proprioo/salatim';

import ActivityCell from '../../activityCell/ActivityCell';
import AgentCell from '../../agentCell/AgentCell';
import AttributionCell from '../../attributionCell/AttributionCell';
import {
  Address,
  IconWrapper,
  OtherInformation,
  PictureWrapper
} from '../../listingDataCell/ListingDataCell.styles';
import ScoresCell from '../../scoresCell/ScoresCell';
import {
  AdvertiserAndRemainingTimeContainer,
  AttributionButtonWrapper,
  CardLayout,
  DefaultContainer,
  PictureAndAddressContainer,
  PriceAndBuyersContainer,
  PriceAndBuyersWraper
} from './ExternalLeadCard.styles';

export type ExternalLeadCardProps = {
  isTeamLead: boolean;
  opportunity: ExternalOpportunityNormalized;
};

const ExternalLeads: FC<ExternalLeadCardProps> = ({
  isTeamLead,
  opportunity
}) => {
  const { t } = useTranslation();
  const { currentLocale } = useLanguage();

  const {
    agentId,
    assignedDate,
    assignedEndDate,
    city,
    dealerTypes,
    firstname,
    isOnline,
    lastActivity,
    lastname,
    nbBedrooms,
    nbRooms,
    phone,
    pictures,
    pigeCreatedAt,
    pigeId,
    postCode,
    price,
    scores,
    status,
    street,
    streetNumber,
    surface,
    upcomingActivity
  } = opportunity;

  const hasOwnerDealerType = dealerTypes.includes(DealerType.OWNER);
  const isLeadAttributed = [
    ExternalOpportunityStatus.ACCEPTED,
    ExternalOpportunityStatus.PERMANENTLY_ATTRIBUTED
  ].includes(status);

  const userName = getUserName(firstname, lastname);
  const dealerTypesTags = dealerTypes.map(type =>
    t(getDealerTypeTranslationKey(type))
  );
  const hoursRemaining = getRemainingTimeToAttributeLead(assignedEndDate);
  const formattedPrice = formatCompactPrice(price, currentLocale);
  const address = t('formattedFullAddress', {
    address: listToString([street, postCode, city]),
    number: streetNumber || ''
  }).trim();
  const otherInformation = listToString(
    usePropertyInformation({ nbBedrooms, nbRooms, surface })
  );
  const [firstPicture] = pictures;

  return (
    <CardLayout>
      <AdvertiserAndRemainingTimeContainer>
        {hasOwnerDealerType ? (
          <span>{userName || t('hyphen')}</span>
        ) : (
          <Tag
            appearance={TagAppearance.BUSINESS}
            closeAriaLabel={t(TAG_CLOSE_ARIA_LABEL)}
            color={TagColorsTypes.BLUE}
            labels={dealerTypesTags}
          />
        )}
        {!isLeadAttributed && (
          <Tag
            appearance={TagAppearance.BUSINESS}
            closeAriaLabel={t(TAG_CLOSE_ARIA_LABEL)}
            color={TagColorsTypes.RED_LIGHT}
            icon={<ClockIcon aria-hidden={true} />}
            labels={[
              t('valueWithUnitNoSpacing', {
                unit: t('hour'),
                value: hoursRemaining
              })
            ]}
          />
        )}
      </AdvertiserAndRemainingTimeContainer>
      <PriceAndBuyersContainer>
        <PriceAndBuyersWraper>
          {formattedPrice && (
            <Tag
              appearance={TagAppearance.BUSINESS}
              closeAriaLabel={t(TAG_CLOSE_ARIA_LABEL)}
              color={TagColorsTypes.GREY}
              labels={[formattedPrice]}
            />
          )}
          <ScoresCell pigeCreatedAt={pigeCreatedAt} scores={scores} />
        </PriceAndBuyersWraper>
        {isTeamLead && agentId && <AgentCell agentId={agentId} />}
      </PriceAndBuyersContainer>
      <PictureAndAddressContainer>
        <PictureWrapper>
          <Image
            alt=""
            data-test="listing-picture"
            height={60}
            loading="lazy"
            src={firstPicture || UNAVAILABLE_PLACEHOLDER}
            width={80}
          />
        </PictureWrapper>
        <Address>{address}</Address>
      </PictureAndAddressContainer>
      <DefaultContainer>
        <OtherInformation>
          {otherInformation}
          {phone && (
            <IconWrapper>
              <PhoneIcon aria-hidden={true} data-test="phone-icon" />
            </IconWrapper>
          )}
        </OtherInformation>
      </DefaultContainer>
      {isLeadAttributed && isOnline ? (
        <DefaultContainer>
          <ActivityCell
            activity={lastActivity}
            assignedDate={assignedDate}
            pigeStatus={status}
          />
          <ActivityCell
            activity={upcomingActivity}
            assignedDate={assignedDate}
            isUpcomingActivity={true}
            pigeStatus={status}
          />
        </DefaultContainer>
      ) : (
        <AttributionButtonWrapper>
          <AttributionCell pigeId={pigeId} status={status} />
        </AttributionButtonWrapper>
      )}
    </CardLayout>
  );
};

export default ExternalLeads;

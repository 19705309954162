import useSWR from 'swr';

import {
  ExternalOpportunityNormalized,
  LeadViewTab,
  SaleOpportunity
} from '@/components/myCustomers/mySellers/interfaces';

import { useCurrentAgent } from '../agents/useCurrentAgent';
import { useIsTeamLeadRole } from '../auth/useIsTeamLeadRole';
import { useAgentIdsFromTeamLead } from '../squads/useAgentIdsFromTeamLead';
import { useUserSubsectionsPermissions } from '../useUserSubsectionsPermissions/useUserSubsectionsPermissions';
import { getLeadsApiUrls } from './utils';

export type LeadsProps = {
  externalOpportunities: ExternalOpportunityNormalized[];
  isArchivedExternalOpportunitiesLoading: boolean;
  isArchivedOpportunitiesLoading: boolean;
  isCurrentExternalOpportunitiesLoading: boolean;
  isCurrentOpportunitiesLoading: boolean;
  opportunities: SaleOpportunity[];
  refreshArchivedExternalOpportunities(): Promise<
    ExternalOpportunityNormalized[] | undefined
  >;
  refreshExternalOpportunities(): Promise<
    ExternalOpportunityNormalized[] | undefined
  >;
};

export const useLeads = (): LeadsProps => {
  const agentIds = useAgentIdsFromTeamLead();
  const currentAgent = useCurrentAgent();
  const isTeamLead = useIsTeamLeadRole();
  const userSubsectionsPermissions = useUserSubsectionsPermissions(LeadViewTab);

  const {
    archivedExternalOpportunitiesUrl,
    archivedOpportunitiesUrl,
    currentExternalOpportunitiesUrl,
    currentOpportunitiesUrl
  } = getLeadsApiUrls(isTeamLead, agentIds, currentAgent?.id);

  const {
    data: currentOpportunities,
    isLoading: isCurrentOpportunitiesLoading
  } = useSWR<SaleOpportunity[]>(currentOpportunitiesUrl, {
    revalidateOnFocus: false
  });

  const {
    data: archivedOpportunities,
    isLoading: isArchivedOpportunitiesLoading
  } = useSWR<SaleOpportunity[]>(
    userSubsectionsPermissions[LeadViewTab.ARCHIVED_ALL]
      ? archivedOpportunitiesUrl
      : null,
    { revalidateOnFocus: false }
  );

  const {
    data: currentExternalOpportunities,
    isLoading: isCurrentExternalOpportunitiesLoading,
    mutate: refreshExternalOpportunities
  } = useSWR<ExternalOpportunityNormalized[]>(
    userSubsectionsPermissions[LeadViewTab.LEADS_ALL_EXTERNAL]
      ? currentExternalOpportunitiesUrl
      : null,
    { revalidateOnFocus: false }
  );

  const {
    data: archivedExternalOpportunities,
    isLoading: isArchivedExternalOpportunitiesLoading,
    mutate: refreshArchivedExternalOpportunities
  } = useSWR<ExternalOpportunityNormalized[]>(
    userSubsectionsPermissions[LeadViewTab.LEADS_ALL_EXTERNAL_EXPIRED]
      ? archivedExternalOpportunitiesUrl
      : null,
    { revalidateOnFocus: false }
  );

  const opportunities = [
    ...(currentOpportunities || []),
    ...(archivedOpportunities || [])
  ].filter(Boolean);

  const externalOpportunities = [
    ...(currentExternalOpportunities || []),
    ...(archivedExternalOpportunities || [])
  ].filter(Boolean);

  return {
    externalOpportunities,
    isArchivedExternalOpportunitiesLoading,
    isArchivedOpportunitiesLoading,
    isCurrentExternalOpportunitiesLoading,
    isCurrentOpportunitiesLoading,
    opportunities,
    refreshArchivedExternalOpportunities,
    refreshExternalOpportunities
  };
};

import { useMemo } from 'react';

import { nullOrNumber } from '@proprioo/hokkaido';

import { useAgents } from './useAgents';

export const useAgentById = (agentId?: nullOrNumber) => {
  const { agents } = useAgents();

  const agentById = useMemo(
    () => agents.find(({ id }) => id === agentId),
    [agents, agentId]
  );

  return agentById;
};

import { FC, Fragment, useEffect, useMemo } from 'react';

import { useUserSectionsPermissions } from '@/hooks/useUserSectionsPermissions/useUserSectionsPermissions';
import { EVENT_NAME, trackEvent } from '@/utils/mixPanel';
import { useSmartphoneDevice } from '@proprioo/salatim';

import { SectionsLayout } from '../../common/styles/NavBar.styles';
import {
  ExternalOpportunitiesPerTab,
  LeadViewSection,
  LeadViewTab,
  SaleOpportunitiesPerTab
} from '../interfaces';
import LeadFilters from '../leads/filters/LeadFilters';
import { countOpportunitiesPerFilter } from '../leads/filters/LeadFilters.utils';
import MobileLeadFilters from '../leads/mobileLeadFilters/MobileLeadFilters';
import LeadSection from '../leadSection/LeadSection';
import { getSection, getTabs } from '../LeadView.utils';
import NavBarSubSection from '../navBarSubSection/NavBarSubSection';

export type NavBarProps = {
  currentTab: LeadViewTab;
  externalOpportunities: ExternalOpportunitiesPerTab;
  isLoading: boolean;
  opportunities: SaleOpportunitiesPerTab;
  agentIds?: number[];
  hasOpportunities?: boolean;
  isTeamLead?: boolean;
};

const NavBar: FC<NavBarProps> = ({
  agentIds,
  currentTab,
  hasOpportunities,
  isLoading,
  isTeamLead,
  opportunities,
  externalOpportunities
}) => {
  const isSmartphone = useSmartphoneDevice();
  const userSectionsPermissions = useUserSectionsPermissions(LeadViewSection);

  const currentSection = useMemo(() => getSection(currentTab), [currentTab]);

  const currentSectionTabs = useMemo(
    () => getTabs(currentSection),
    [currentSection]
  );

  const leadViewSectionsFiltered = useMemo(
    () =>
      Object.values(LeadViewSection).filter(
        section => userSectionsPermissions[section]
      ),
    [userSectionsPermissions]
  );

  const hasMultipleTabs = currentSectionTabs.length > 1;
  const showFilters = !isLoading && hasOpportunities && isTeamLead;

  const opportunitiesCountPerFilter =
    countOpportunitiesPerFilter(opportunities);

  useEffect(() => {
    if (currentSection === LeadViewSection.LEADS) {
      trackEvent(EVENT_NAME.SELLER_LEAD_MANAGEMENT, {
        currentSection,
        currentTab
      });
    }
  }, [currentTab, currentSection]);

  return (
    <Fragment>
      {showFilters && isSmartphone && (
        <MobileLeadFilters
          currentTab={currentTab}
          opportunitiesCountPerFilter={opportunitiesCountPerFilter}
        />
      )}
      <SectionsLayout {...(!isLoading && { role: 'tablist' })}>
        {leadViewSectionsFiltered.map(section => (
          <LeadSection
            agentIds={agentIds}
            isActive={currentSection === section}
            isLoading={isLoading}
            key={section}
            kind={section}
            opportunities={opportunities}
          />
        ))}
      </SectionsLayout>
      {showFilters && !isSmartphone && (
        <LeadFilters
          currentTab={currentTab}
          opportunitiesCountPerFilter={opportunitiesCountPerFilter}
        />
      )}
      {hasMultipleTabs && !isLoading && (
        <NavBarSubSection
          agentIds={agentIds}
          currentTab={currentTab}
          externalOpportunities={externalOpportunities}
          opportunities={opportunities}
        />
      )}
    </Fragment>
  );
};

export default NavBar;

import qs from 'qs';

import { BuyerScore } from '@/components/buyer/interfaces';
import {
  ActivitiesBuyer,
  Buyer,
  BuyerActivity,
  BuyerBaseColumns,
  BuyerBaseField,
  Data,
  ScoreColor
} from '@/components/buyerbase/interfaces';
import { Agent } from '@/components/state/interfaces';
import { DEFAULT_OTHER_CRITERIAS } from '@/utils/criterias';
import { QsArrayFormat } from '@/utils/helpers';
import { ArbitraryObject } from '@/utils/interfaces';
import { EstimationStage, nullOrString } from '@proprioo/hokkaido';
import { colors } from '@proprioo/salatim';

import { DictionaryBoolean } from '../../utils/interfaces';
import { ActivityStatus } from '../activity/interfaces';
import {
  getLastContactActivity,
  getNextPlannedActivity
} from '../activity/utils';
import { OpportunityQualification } from '../qualification/sellerQualification/interfaces';
import { OtherCriteriasProps } from './filters/otherCriterias/OtherCriterias';
import { BuyerbaseFiltersState } from './interfaces';

export const MAX_LINES = 100;

export const generateEmptyBuyerbaseFilters = (): BuyerbaseFiltersState => ({
  agentOwners: [],
  bedrooms: null,
  field: null,
  landingArea: null,
  limit: MAX_LINES,
  offset: 0,
  orderBy: null,
  other: DEFAULT_OTHER_CRITERIAS,
  price: null,
  propertyTypes: null,
  rooms: null,
  score: null,
  surface: null,
  withPropriooOwner: false,
  zonesIds: []
});

export const extractOtherCriterias = (
  other?: OtherCriteriasProps
): string[] => {
  const criterias =
    other &&
    Object.entries(other).reduce((acc: string[], [key, value]) => {
      if (
        value && typeof value === 'string' ? Boolean(JSON.parse(value)) : value
      ) {
        acc.push(key);
      }

      return acc;
    }, []);

  return criterias || [];
};

export const getTruthyCriteria = (other: OtherCriteriasProps) =>
  Object.entries(other).reduce((acc: DictionaryBoolean, [key, value]) => {
    if (
      value && typeof value === 'string' ? Boolean(JSON.parse(value)) : value
    ) {
      acc[key] = true;
    }

    return acc;
  }, {});

export const getOtherFromQuery = (query: ArbitraryObject) => {
  const otherCriterias = Object.keys(DEFAULT_OTHER_CRITERIAS);
  const other = Object.entries(query).reduce(
    (acc: OtherCriteriasProps, [key, value]) => {
      if (otherCriterias.includes(key)) {
        return {
          ...acc,
          [key]: JSON.parse(value)
        };
      }
      return acc;
    },
    DEFAULT_OTHER_CRITERIAS
  );

  return other;
};

export const queryObjectToSearchState = (
  query: ArbitraryObject
): BuyerbaseFiltersState => {
  const state = generateEmptyBuyerbaseFilters();
  const other = getOtherFromQuery(query);

  for (const [key, value] of Object.entries(query)) {
    if (key === 'zonesIds' || key === 'agentOwners') {
      state[key] = [value].flat();
    } else if (
      Object.prototype.hasOwnProperty.call(state, key) &&
      value !== null
    ) {
      Object.defineProperties(state, {
        [key]: { value }
      });
    }
  }

  return { ...state, other };
};

export const searchStateToQueryObject = (
  state: BuyerbaseFiltersState
): ArbitraryObject => {
  const {
    zonesIds,
    score,
    propertyTypes,
    surface,
    rooms,
    bedrooms,
    landingArea,
    price,
    agentOwners,
    field,
    orderBy,
    offset,
    other,
    limit,
    withPropriooOwner
  } = { ...state };

  const otherCriterias = getTruthyCriteria(other);

  return {
    ...(zonesIds && Boolean(zonesIds.length) && { zonesIds }),
    ...(score && { score }),
    ...(propertyTypes && { propertyTypes }),
    ...(surface && { surface }),
    ...(rooms && { rooms }),
    ...(bedrooms && { bedrooms }),
    ...(landingArea && { landingArea }),
    ...(price && { price }),
    ...(agentOwners && { agentOwners }),
    ...(otherCriterias && { ...otherCriterias }),
    ...(field && { field }),
    ...(orderBy && { orderBy }),
    ...(offset && { offset }),
    ...(limit && { limit }),
    ...(withPropriooOwner && { withPropriooOwner })
  };
};

export const queryObjectToString = (query: ArbitraryObject): string =>
  qs.stringify(query, {
    arrayFormat: QsArrayFormat.COMMA,
    encode: false
  });

export const queryStringToObject = (query: string): ArbitraryObject =>
  qs.parse(query, {
    comma: true,
    parseArrays: true,
    strictNullHandling: true
  });

export const isAllowedToSendMail = (
  opportunity?: OpportunityQualification
): boolean =>
  Boolean(
    opportunity &&
      [
        EstimationStage.ON_HOLD,
        EstimationStage.ONLINE,
        EstimationStage.PAUSED,
        EstimationStage.SOLD
      ].includes(opportunity.estimationStage)
  );

export const getScoreCellColor = (score: BuyerScore): ScoreColor => {
  switch (score) {
    case BuyerScore.A_PLUS:
    case BuyerScore.A:
      return {
        backgroundColor: colors.greenLight.base40,
        textColor: colors.dark.base
      };
    case BuyerScore.B:
      return {
        backgroundColor: colors.greenLight.base10,
        textColor: colors.dark.base
      };
    default:
      return {
        backgroundColor: colors.grey.base10,
        textColor: colors.dark.base60
      };
  }
};

export const capitalizeEachWord = (sentence: nullOrString): string => {
  if (sentence) {
    if (/^[a-zÀ-ú]+(-[a-zÀ-ú]+){1,}$/gim.test(sentence.trim())) {
      const sentenceSplit = sentence.toLowerCase().split(/-/).filter(Boolean);

      if (sentenceSplit.length) {
        return sentenceSplit
          .map(word => word[0].toUpperCase() + word.substring(1))
          .join('-');
      }

      return '';
    } else {
      const sentenceReplace = sentence
        .toLowerCase()
        .replace(/-+/g, ' ')
        .trim()
        .split(/\s+/)
        .filter(Boolean);

      if (sentenceReplace.length) {
        return sentenceReplace
          .map(word => word[0].toUpperCase() + word.substring(1))
          .join(' ');
      }
      return '';
    }
  }

  return '';
};

export const formatData = (data: Buyer[], agents: Agent[]): Data[] =>
  data.map(el => {
    const {
      activities,
      agentOwner,
      firstname,
      lastname,
      idAlert,
      userId,
      email,
      phone,
      isSeller,
      loanStage,
      purchaseStage,
      notification,
      notificationNb,
      zones,
      modifiedAt,
      score,
      searchMandatesNb,
      listingRequestsNb,
      viewingsNb,
      offersNb,
      forListing,
      minPrice,
      maxPrice,
      minRooms,
      maxRooms,
      minLandingArea,
      maxLandingArea,
      maxBedrooms,
      minBedrooms,
      propertyTypes,
      minSurface,
      maxSurface,
      projectsNb,
      estimationRequestsNb,
      hasCellarOrAnnex,
      hasLift,
      hasOutdoorSpace,
      hasParking,
      hasPool,
      withoutRenovationWork,
      groundFloor
    } = el;

    const { activitiesFuture, activitiesPast } = activities.reduce(
      (acc: ActivitiesBuyer, activity) => {
        if (activity.status === ActivityStatus.DONE) {
          acc.activitiesPast.push(activity);
        } else {
          acc.activitiesFuture.push(activity);
        }
        return acc;
      },
      {
        activitiesFuture: [],
        activitiesPast: []
      }
    );

    const nextActivity =
      getNextPlannedActivity<BuyerActivity>(activitiesFuture);
    const lastContact = getLastContactActivity(activitiesPast);

    return {
      activitiesFuture,
      activitiesPast,
      agents,
      bedrooms: { max: maxBedrooms, min: minBedrooms },
      buyer: { firstname, lastname },
      buyerActivities: {
        listingRequestsCount: listingRequestsNb,
        offersCount: offersNb,
        visitsCount: viewingsNb
      },
      email: {
        agentOwner,
        alertId: idAlert,
        customerId: userId,
        notification,
        notificationNb,
        notified: forListing?.notified
      },
      interested: {
        customer: {
          email,
          firstname,
          id: userId,
          lastname,
          phone
        },
        interested:
          forListing?.interested !== undefined ? forListing.interested : null
      },
      landingArea: { max: maxLandingArea, min: minLandingArea },
      modifiedAt,
      other: {
        ...DEFAULT_OTHER_CRITERIAS,
        groundFloor: Boolean(groundFloor && JSON.parse(`${groundFloor}`)),
        hasCellarOrAnnex: Boolean(
          hasCellarOrAnnex && JSON.parse(`${hasCellarOrAnnex}`)
        ),
        hasLift: Boolean(hasLift && JSON.parse(`${hasLift}`)),
        hasOutdoorSpace: Boolean(
          hasOutdoorSpace && JSON.parse(`${hasOutdoorSpace}`)
        ),
        hasParking: Boolean(hasParking && JSON.parse(`${hasParking}`)),
        hasPool: Boolean(hasPool && JSON.parse(`${hasPool}`)),
        withoutRenovationWork: Boolean(
          withoutRenovationWork && JSON.parse(`${withoutRenovationWork}`)
        )
      },
      preview: {
        agentOwner,
        email,
        idAlert,
        lastContact,
        nextActivity,
        phone,
        userId,
        userName: { firstname, lastname }
      },
      price: { max: maxPrice, min: minPrice },
      propertyTypes: propertyTypes,
      rooms: { max: maxRooms, min: minRooms },
      score,
      scoreColor: getScoreCellColor(score),
      sellerBuyer: {
        agentOwnerId: agentOwner,
        buyerProjectCount: projectsNb,
        sellerProjectCount: estimationRequestsNb
      },
      status: {
        hasResearchMandate: Boolean(searchMandatesNb),
        isSeller,
        loanStage,
        notification,
        purchaseStage
      },
      surface: { max: maxSurface, min: minSurface },
      zones
    };
  });

export const getHiddenColumns = (
  isInterestAvailable: boolean,
  isEnabledToSendMail: boolean
): BuyerBaseColumns[] => {
  const columns = [];

  if (!isInterestAvailable && !isEnabledToSendMail) {
    columns.push(BuyerBaseColumns.BUYER_INTEREST, BuyerBaseColumns.EMAIL);
  }

  if (isInterestAvailable && !isEnabledToSendMail) {
    columns.push(BuyerBaseColumns.EMAIL);
  }

  return [...new Set(columns)];
};

export const getTableHeadCellField = (column: string) => {
  switch (column) {
    case BuyerBaseColumns.SCORE:
      return BuyerBaseField.SCORING;
    case BuyerBaseColumns.BUYER:
      return BuyerBaseField.LASTNAME;
    default:
      return column;
  }
};

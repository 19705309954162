import { ActivityType } from '@/components/activity/interfaces';

import { EventType } from '../../mySellers/interfaces';

export const getActivityEventLabel = (
  eventType: ActivityType | EventType
): string => {
  switch (eventType) {
    case ActivityType.CALL:
      return 'call';
    case ActivityType.EMAIL:
      return 'email';
    case ActivityType.RDV:
      return 'rdv';
    case ActivityType.SMS:
      return 'sms';
    case ActivityType.TASK:
      return 'task';
    case EventType.CONTACT_REQUEST:
      return 'contactRequest';
    case EventType.CREATION:
      return 'created';
    case EventType.LEAD:
      return 'toQualify';
    case EventType.VALUATION:
      return 'valuation';
    case EventType.VISIT:
      return 'visit';
  }
};

import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRecoilState } from 'recoil';

import ServicesIcon from '@/assets/vectors/menu/services.svg';
import { menuSelector } from '@/components/state/MenuState';
import { useActiveLink } from '@/hooks/useActiveLink/useActiveLink';

import { ItemLabel, ToolItem } from '../MenuItems.styles';

const ServicesItem = () => {
  const { t } = useTranslation();

  const [isOpen, updateMenu] = useRecoilState(menuSelector);
  const isActive = useActiveLink('/services');

  return (
    <Link
      href="/services"
      id="sidebar-menu_services-catalogue"
      onMouseEnter={() => {
        if (!isOpen) {
          updateMenu(true);
        }
      }}
    >
      <ToolItem isActive={isActive} isOpen={isOpen}>
        <ServicesIcon />
        <ItemLabel>{t('services')}</ItemLabel>
      </ToolItem>
    </Link>
  );
};

export default ServicesItem;

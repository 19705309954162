import { formatDistanceToNow } from 'date-fns/formatDistanceToNow';
import { FC } from 'react';

import { formatLocaleDay } from '@/components/qualification/sellerQualification/utils';
import { DATE_DAY_HOURS_FORMAT } from '@/constants/global';
import { useLanguage } from '@/hooks/useLanguage/useLanguage';
import { capitalize, LANGUAGE_DATE_LOCALES } from '@proprioo/hokkaido';
import { Tooltip } from '@proprioo/salatim';

export type RelativeDateProps = {
  date: Date;
  isCapitalized: boolean;
};

const RelativeDate: FC<RelativeDateProps> = ({ date, isCapitalized }) => {
  const { currentLanguage } = useLanguage();

  const localeDate = LANGUAGE_DATE_LOCALES[currentLanguage];

  const relativeDate = formatDistanceToNow(date, {
    addSuffix: true,
    locale: localeDate
  });

  return (
    <Tooltip
      placement="bottom"
      tooltip={formatLocaleDay(date, DATE_DAY_HOURS_FORMAT, localeDate)}
    >
      {isCapitalized ? capitalize(relativeDate) : relativeDate}
    </Tooltip>
  );
};

export default RelativeDate;

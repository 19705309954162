import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { FC, Fragment } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Cookies from 'universal-cookie';
import * as yup from 'yup';

import CheckIcon from '@/assets/icons/check.svg';
import ResetIcon from '@/assets/icons/replay.svg';
import { countrySelector } from '@/components/state/CountryState';
import { COOKIE_KEY_COUNTRY } from '@/constants/global';
import { useActiveAgents } from '@/hooks/agents/useActiveAgents';
import { useAuth } from '@/hooks/auth/useAuth';
import { useIsAdminOriginalRole } from '@/hooks/auth/useIsAdminOriginalRole';
import { useCurrentCountry } from '@/hooks/useCurrentCountry/useCurrentCountry';
import { useForm } from '@/utils/form';
import { PAGE_VIEW_TITLE } from '@/utils/gtm';
import { fetcher } from '@/utils/http';
import { toastError, toastSuccess } from '@/utils/notification';
import { generateButtonId } from '@/utils/tracking';
import {
  Country,
  HttpMethod,
  listToString,
  OptionProps
} from '@proprioo/hokkaido';
import {
  Autocomplete,
  Button,
  ButtonAppearance,
  Input,
  Line,
  Row,
  Select,
  useMobileDevice
} from '@proprioo/salatim';

import { getOptionPropsFromAgent } from '../../../qualification/projectForm/ProjectForm.utils';
import { authSelector } from '../../../state/auth/AuthState';
import { StatusEndpointProps, UserRole } from '../../../state/interfaces';
import { LayoutButtons } from '../../confirmModalButtons/ConfirmModalButtons.styles';
import { Title } from './AdminSettings.styles';
import {
  getLastImpersonatedAgentsFromStorage,
  getRoleLabel,
  setLastImpersonatedAgentsToStorage
} from './AdminSettings.utils';

export type AdminSettingsProps = {
  closeModal(): void;
  toggleLoader(): void;
};

const AdminSettings: FC<AdminSettingsProps> = ({
  closeModal,
  toggleLoader
}) => {
  const { t } = useTranslation();
  const { push } = useRouter();
  const { user } = useAuth();

  const isAdmin = useIsAdminOriginalRole();
  const activeAgents = useActiveAgents();
  const isMobile = useMobileDevice();
  const currentCountry = useCurrentCountry();
  const setCurrentCountry = useSetRecoilState(countrySelector);
  const [auth, updateAuth] = useRecoilState(authSelector);

  const lastImpersonatedAgents = getLastImpersonatedAgentsFromStorage();

  const { hasChanged, submitForm, updateValuesFromInput, values } = useForm<{
    country: OptionProps;
    email: string;
    roles: UserRole[];
  }>({
    initialValues: {
      country: { label: t(currentCountry), value: currentCountry },
      email: user?.email || '',
      roles: user?.roles || []
    },
    onValidationSuccess: async ({ updatedValues }) => {
      try {
        toggleLoader();

        const { user, userPermissions } = await fetcher<StatusEndpointProps>(
          `/api/auth/impersonate`,
          {
            body: JSON.stringify({
              email: updatedValues.email,
              roles: updatedValues.roles
            }),
            method: HttpMethod.POST
          }
        );

        updateAuth({
          ...auth,
          ...(user &&
            userPermissions && {
              user: { ...user, permissions: userPermissions }
            })
        });

        if (updatedValues.country.value !== currentCountry) {
          const cookies = new Cookies();
          const newCurrentCountry = updatedValues.country.value as Country;

          // Cookie is only used to not lose country impersonation on app reload
          cookies.set(COOKIE_KEY_COUNTRY, newCurrentCountry, {
            maxAge: 3 * 31 * 24 * 60 * 60,
            path: '/'
          });
          setCurrentCountry(newCurrentCountry);
        }

        const impersonatedAgent = activeAgents.find(
          ({ email }) => email === updatedValues.email
        );

        if (impersonatedAgent) {
          setLastImpersonatedAgentsToStorage(
            impersonatedAgent,
            lastImpersonatedAgents
          );
        }

        return async () => {
          // This push must be done in the useForm callback to avoid alert box popping
          if (updatedValues.country.value !== currentCountry) {
            await push('/my-sellers');
          }

          closeModal();
        };
      } catch (error) {
        toggleLoader();
        throw error;
      }
    },
    options: {
      confirmationMessage: 'savedParameters',
      errorMessage: 'anErrorOccured',
      useActionBar: false,
      useLoader: false
    },
    pageViewTitle: PAGE_VIEW_TITLE.MODAL_ADMIN_SETTINGS,
    validationSchema: yup.object()
  });

  const handleReset = async () => {
    try {
      toggleLoader();

      const { user, userPermissions } = await fetcher<StatusEndpointProps>(
        '/api/auth/impersonate',
        { method: HttpMethod.DELETE }
      );

      const cookies = new Cookies();
      // Cookie is only used to not lose country impersonation on app reload
      cookies.set(COOKIE_KEY_COUNTRY, Country.FRANCE, {
        maxAge: 3 * 31 * 24 * 60 * 60,
        path: '/'
      });

      setCurrentCountry(Country.FRANCE);
      updateAuth({
        ...auth,
        ...(user &&
          userPermissions && {
            user: { ...user, permissions: userPermissions }
          })
      });
      toastSuccess('savedParameters');
      closeModal();
    } catch {
      toggleLoader();
      toastError('anErrorOccured');
    }
  };

  const agentsList = activeAgents
    .filter(({ country }) => country === values.country.value)
    .map(getOptionPropsFromAgent);
  const activeAgent = activeAgents.find(({ email }) => email === values.email);

  return (
    <Fragment>
      {isAdmin && (
        <Fragment>
          <Line>
            <Title isAlternative={true}>{t('country')}</Title>
          </Line>
          <Line>
            <Row>
              <Select
                disabled={false}
                error={false}
                label={t('country')}
                onSelect={country => {
                  if (country.value !== values.country.value) {
                    updateValuesFromInput({
                      country,
                      email: '',
                      roles: user?.original?.roles || user?.roles || []
                    });
                  }
                }}
                option={values.country}
                options={Object.values(Country).map(country => ({
                  label: t(country),
                  value: country
                }))}
              />
            </Row>
          </Line>
        </Fragment>
      )}
      <Line>
        <Title isAlternative={true}>{t('loginAs')}</Title>
      </Line>
      <Line>
        <Row>
          <Select
            disabled={!lastImpersonatedAgents.length}
            error={false}
            label={t('lastUsedAgents')}
            native={isMobile}
            onSelect={option => {
              const foundAgent = activeAgents.find(
                ({ id }) => `${id}` === option?.value
              );

              const agentCountry = foundAgent?.country || Country.FRANCE;

              updateValuesFromInput({
                country: { label: t(agentCountry), value: agentCountry },
                email: foundAgent?.email || '',
                roles: foundAgent?.userRoles || []
              });
            }}
            option={null}
            options={lastImpersonatedAgents.map(getOptionPropsFromAgent)}
          />
        </Row>
      </Line>
      <Line>
        <Row>
          <Autocomplete
            dataTest="agent"
            disabled={false}
            error={false}
            label={t('agent')}
            list={agentsList}
            native={isMobile}
            onSelect={option => {
              const foundAgent = activeAgents.find(
                ({ id }) => `${id}` === option?.value
              );

              updateValuesFromInput({
                email: foundAgent?.email || '',
                roles: foundAgent?.userRoles || []
              });
            }}
            option={activeAgent ? getOptionPropsFromAgent(activeAgent) : null}
          />
        </Row>
      </Line>
      <Line>
        <Row>
          <Input
            dataTest="agent-user-role"
            disabled={true}
            error={false}
            label={t('userRole')}
            value={listToString(
              activeAgent?.userRoles.map(role => t(getRoleLabel(role))) || [],
              t('comma')
            )}
          />
          <Input
            dataTest="agent-squad"
            disabled={true}
            error={false}
            label={t('squad')}
            value={listToString(
              activeAgent?.squads.map(({ name }) => name) || [],
              t('comma')
            )}
          />
        </Row>
      </Line>
      <Line>
        <LayoutButtons isEnd={true}>
          <Button
            appearance={ButtonAppearance.SECONDARY}
            icon={<ResetIcon />}
            id={generateButtonId(
              PAGE_VIEW_TITLE.MODAL_ADMIN_SETTINGS,
              'impersonationReset'
            )}
            label={t('resetParameters')}
            onClick={handleReset}
          />
          <Button
            disabled={!hasChanged}
            icon={<CheckIcon />}
            id={generateButtonId(
              PAGE_VIEW_TITLE.MODAL_ADMIN_SETTINGS,
              'saveAction'
            )}
            label={t('save')}
            onClick={submitForm}
          />
        </LayoutButtons>
      </Line>
    </Fragment>
  );
};

export default AdminSettings;

import { Entity } from '@/components/listing/contract/interfaces';
import { CustomerInformation } from '@/components/state/interfaces';
import {
  CustomerProfile,
  Dictionary,
  FinancingMethod,
  MandateChargedToType,
  MandateType,
  nullOrBoolean,
  nullOrDate,
  nullOrNumber,
  nullOrString
} from '@proprioo/hokkaido';

export enum ContractType {
  FULL = 'full',
  AMENDMENT = 'amendment',
  OFFER = 'offer'
}

export enum ContractSubtype {
  GUARANTEED_SALE = 'guaranteedSales',
  REGULAR_SALE = 'regularSale'
}

export enum SignatureType {
  IMPORT = 'import',
  PAPER = 'paper',
  YOUSIGN = 'yousign'
}

export enum ContractStatus {
  CANCELED = 'canceled',
  DELETED = 'deleted',
  DONE = 'done',
  GENERATED = 'generated',
  IN_EDITION = 'inEdition',
  RESERVED = 'reserved',
  SENT_TO_CLIENTS = 'sentToClients',
  SENT_TO_SELLERS = 'sentToSellers',
  SIGNED = 'signed',
  VALIDATION_BY_AGENT = 'validationByAgent',
  WITHDRAWN = 'withdrawn'
}

export enum AmendmentCard {
  DESIGNATION,
  PRICE
}

export enum MissingInfoType {
  AUTHENTIC_ACT = 'authenticAct',
  COMPROMISE = 'compromise',
  MANDATE = 'mandate',
  OFFER = 'offer'
}

export enum MissingInfoValue {
  AUTHENTIC_ACT_DATE = 'authenticActDateError',
  AUTHENTIC_ACT_UPLOAD = 'authenticActUploadError',
  COMPROMISE_DATE = 'compromiseDateError',
  COMPROMISE_UPLOAD = 'compromiseUploadError',
  DESCRIPTION = 'missingDescriptionError',
  FEES = 'fees',
  LOAN = 'theFunding',
  MANDATE_TYPE = 'mandateTypeError',
  NOTARY_INVOICE_CHECKBOX = 'sentInvoiceToNotaryCheckboxError',
  NOTARY_INVOICE_UPLOAD = 'notaryInvoiceUploadError',
  OFFER_PRICE = 'offerAmountError',
  PRICE = 'priceAndCommissionError',
  SIGNATORIES = 'signatoriesInformationError',
  TRACFIN_UPLOAD = 'tracfinDocumentsUploadError',
  TRACFIN_CHECKBOXES = 'tracfinCheckboxesError',
  PRIVACY = 'privacyError',
  BUYER_DOWN_PAYMENT = 'buyerDownPaymentError',
  BUYER_FEES_TO_PAY = 'buyerFeesToPayError',
  KYC_UPLOAD = 'kycError',
  BUYER_FEE_PAYMENT_CONFIRMED_UPLOAD = 'buyerFeePaymentConfirmedError',
  SELLER_FEE_PAYMENT_CONFIRMED_UPLOAD = 'sellerFeePaymentConfirmedError',
  BUYER_DOWN_PAYMENT_PRELIMINARY_UPLOAD = 'buyerDownPaymentPreliminaryUploadError'
}

export enum SaleStepsCardAnchor {
  AUTHENTIC_ACT = 'authenticAct',
  COMPROMISE = 'compromise',
  MANDATE_DESCRIPTION = 'mandateDescription',
  MANDATE_TYPE_PICKER = 'mandateTypePicker',
  NOTARY_INVOICE = 'notaryInvoce',
  PRICE_AND_FEES = 'priceAndFees',
  PRICE_AND_LOAN = 'priceAndLoan',
  SIGNATORIES = 'signatoriesSection',
  TRACFIN = 'tracfin',
  BUYER_DOWN_PAYMENT_ANCHOR = 'buyerDownPayment',
  BUYER_FEES_TO_PAY_ANCHOR = 'buyerFeesToPay',
  PRIVACY_ANCHOR = 'privacy',
  KYC = 'kyc',
  BUYER_SELLER_FEE_PAYMENT_CONFIRMED = 'buyerFeePaymentConfirmed'
}

export type ContractTermProps = {
  key: string;
  text: string;
  checked: boolean;
};

export enum ContractSignatoryType {
  MAIN = 'main',
  OTHER = 'other'
}

export enum ContractSignatoryLegalStatus {
  SINGLE = 'single',
  WIDOW = 'widow',
  MARRIED = 'married',
  DIVORCED = 'divorced',
  PACSED = 'pacsed'
}

export type ContractSignatory = {
  createdDate: string;
  dateOfBirth: nullOrString;
  entityId: nullOrString;
  id: string;
  legalStatus: ContractSignatoryLegalStatus | null;
  placeOfBirth: nullOrString;
  signatoryType: ContractSignatoryType;
  signatureDate: nullOrString;
  signatureUrl: nullOrString;
  userId: string;
  userType: CustomerProfile;
  fiscalCode?: nullOrString;
};

export type CommissionDefaultInfos = {
  minimum: number;
  value: number;
  percentage: number;
};

export type ExistingContract = {
  documentType: ContractType;
  id: string;
  listingId: number;
  status: ContractStatus;
};

export type ContractProps = {
  agentSignatureLink: nullOrString;
  createdDate: string;
  documentType: ContractType;
  id: string;
  modifiedDate: string;
  price: number;
  signatories: ContractSignatory[];
  signatureType: SignatureType | null;
  status: ContractStatus;
  uploadedFile: boolean;
  contractType?: null | ContractSubtype;
  percentage?: number;
  sellerFeePercentage: number;
  sellingPrice?: number;
};

export type AmendmentProps = ContractProps & {
  description: string;
  documentType: ContractType.AMENDMENT;
};

export type MandateProps = ContractProps & {
  buyerFeePercentage?: nullOrNumber;
  contractTerms: ContractTermProps[];
  contractTermsCustom: ContractTermProps[];
  description: string;
  documentType: ContractType.FULL;
  mandateType: MandateType;
  rentingInfo: string;
  priceIncludesFees?: nullOrBoolean;
  exclusivityExpirationDate: nullOrString;
  chargedTo?: MandateChargedToType;
  validityDate: nullOrDate;
};

export type OfferProps = ContractProps & {
  buyerFeePercentage?: nullOrNumber;
  comment?: string;
  creator?: string;
  documentType: ContractType.OFFER;
  loanNeeded: FinancingMethod;
  loanAmount: number;
  loanSuspension?: boolean;
  loanSuspensionDate?: nullOrDate;
  listingId: number;
  priceIncludesFees?: nullOrBoolean;
  viewingUuid: string;
  validityDate: nullOrDate;
};

export type MandateRegister = {
  createdAt: string;
  createdBy: string;
  id: number;
  listingId: number;
  mandateLogId: string;
  modifiedAt: nullOrDate;
};

export type SellingContractProps = AmendmentProps | MandateProps;

export type ContractFormValues<T> = {
  contract: T;
  commissionValue: number;
  netSellerPriceValue: number;
  entities?: Entity[];
  users?: CustomerInformation[];
  sellerFee?: number;
  buyerFee?: number;
};

export type ContractFormProps<T = OfferProps | SellingContractProps> = {
  errors: Dictionary;
  isValidationFailing?: boolean;
  updateValues(values: Partial<ContractFormValues<T>>): void;
  values: ContractFormValues<T>;
};

export type ContractFormOfferProps = ContractFormProps<OfferProps> & {
  isFrenchListing: boolean;
};

export type ContractFormSignatoriesProps<
  T = OfferProps | SellingContractProps
> = ContractFormProps<T> & {
  isFrenchListing: boolean;
  isLoading: boolean;
  refreshContract(): Promise<T | undefined>;
};

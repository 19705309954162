import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import Logo from '@/assets/icons/casavo-icon.svg';

import Avatar, { AvatarProps } from '../Avatar';

const CasavoAvatar: FC<Partial<AvatarProps>> = ({ name, dataTest, size }) => {
  const { t } = useTranslation();

  return (
    <Avatar
      name={name || t('casavoOwnership')}
      dataTest={dataTest || 'avatar-proprioo'}
      size={size}
    >
      <Logo alt={name || t('casavoOwnership')} data-test="avatar-logo" />
    </Avatar>
  );
};

export default CasavoAvatar;

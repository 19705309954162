import styled from 'styled-components';

import { NOTIFICATION_BLUE_COLOR } from '@/constants/global';
import { colors, convertPxToRem, radius } from '@proprioo/salatim';

import { BulletColor } from './Bullet.interfaces';

export const Layout = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Inner = styled.span<{ color: BulletColor }>`
  display: block;
  width: ${convertPxToRem(6)};
  height: ${convertPxToRem(6)};
  border-radius: ${radius.rounded};

  background-color: ${({ color }) =>
    color === BulletColor.BLUE
      ? NOTIFICATION_BLUE_COLOR
      : color === BulletColor.RED
      ? colors.red.base
      : color === BulletColor.ORANGE
      ? colors.terracota.base60
      : colors.grey.base};
`;

import styled from 'styled-components';

import { breakpoints, colors, convertPxToRem } from '@proprioo/salatim';

export const Layout = styled.div`
  color: ${colors.dark.base};

  & + & {
    margin-top: ${convertPxToRem(8)};
  }
`;

export const DangerInfoWrapper = styled.div`
  @media (min-width: ${breakpoints.xsmall}px) {
    max-width: ${convertPxToRem(120)};
  }
`;

import { Fragment } from 'react';

import ClockAlertIcon from '@/assets/icons/clock-alert.svg';
import ClockIcon from '@/assets/icons/clock.svg';
import { colors } from '@proprioo/salatim';

export type ActivityIndicatorParams = {
  color: string;
  icon: JSX.Element;
  label: string;
};

export function getActivityIndicatorParams(
  isToday: boolean,
  isLate: boolean,
  isComing: boolean
): ActivityIndicatorParams {
  const color = isToday
    ? colors.blue.base
    : isLate
      ? colors.red.base
      : colors.grey.base;

  const label = isToday
    ? 'todayTask'
    : isLate
      ? 'lateTask'
      : isComing
        ? 'upcomingTask'
        : 'hyphen';

  const icon =
    isToday || isComing ? (
      <ClockIcon data-test="future-activity-icon" aria-hidden={true} />
    ) : isLate ? (
      <ClockAlertIcon data-test="late-activity-icon" aria-hidden={true} />
    ) : (
      <Fragment />
    );

  return { color, icon, label };
}

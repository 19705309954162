import { UserRole } from '@/components/state/interfaces';
import { hasRole } from '@/components/state/utils';

import { useCurrentAgent } from '../agents/useCurrentAgent';
import { useAuth } from './useAuth';

export const useIsTeamLeadRole = (): boolean => {
  const { user } = useAuth();

  const currentAgent = useCurrentAgent();

  return (
    (hasRole(UserRole.TEAM_LEADS, user) || hasRole(UserRole.MOPS, user)) &&
    Boolean(currentAgent?.id)
  );
};

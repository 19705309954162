import { useRouter } from 'next/router';
import qs from 'qs';

export const useBasePath = (): string => {
  const { asPath } = useRouter();

  const [basePath] = Object.keys(qs.parse(asPath, { delimiter: '?' }));

  return basePath;
};

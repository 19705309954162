import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';

import Arrow from '@/assets/icons/dropdown.svg';
import Exit from '@/assets/icons/exit.svg';
import AdminSettingsIcon from '@/assets/icons/settings-admin.svg';
import AgentAvatar from '@/components/app/avatar/agentAvatar/AgentAvatar';
import AdminSettings from '@/components/app/layout/adminSettings/AdminSettings';
import { authSelector } from '@/components/state/auth/AuthState';
import { UserRole } from '@/components/state/interfaces';
import { menuSelector } from '@/components/state/MenuState';
import { hasOriginalRole } from '@/components/state/utils';
import { LANGUAGES_OPTIONS } from '@/constants/languages';
import { useCurrentAgent } from '@/hooks/agents/useCurrentAgent';
import { useAuth } from '@/hooks/auth/useAuth';
import { useLanguage } from '@/hooks/useLanguage/useLanguage';
import { useUserPermissionsBuilder } from '@/hooks/useUserPermissionsBuilder/useUserPermissionsBuilder';
import { EventPageView, PAGE_VIEW_TITLE, sendPageView } from '@/utils/gtm';
import { fetcher } from '@/utils/http';
import { generateButtonId } from '@/utils/tracking';
import { getFormatUserName } from '@/utils/user';
import { HttpMethod } from '@proprioo/hokkaido';
import { useModal } from '@proprioo/salatim';

import { ItemLabel, ToolItem } from '../menuItems/MenuItems.styles';
import LanguageModal from './languageModal/LanguageModal';
import {
  AdminLinksWrapper,
  Agent,
  AgentName,
  AgentToggle,
  Layout,
  Links
} from './MenuAgent.styles';
import { MENU_AGENT_ITEMS_MAPPING } from './MenuAgent.utils';

const MenuAgent = () => {
  const { t } = useTranslation();
  const { currentLanguage } = useLanguage();
  const { asPath } = useRouter();
  const { user } = useAuth();

  const menuAgentItems = useUserPermissionsBuilder(MENU_AGENT_ITEMS_MAPPING);
  const [isLinksOpen, toggleLinks] = useState<boolean>(false);
  const [isOpen, toggleMenu] = useRecoilState(menuSelector);
  const resetAuth = useResetRecoilState(authSelector);

  const currentAgent = useCurrentAgent();
  const canAccessAdminSettings =
    hasOriginalRole(UserRole.APP_SALES_ADMIN, user) ||
    hasOriginalRole(UserRole.MOPS, user) ||
    hasOriginalRole(UserRole.TEAM_LEADS, user) ||
    hasOriginalRole(UserRole.TECH, user);

  const {
    closeModal: closeAdminSettingsModal,
    createModal: createAdminSettingsModal,
    openModal: openAdminSettingsModal,
    toggleLoader: toggleAdminSettingsLoader
  } = useModal({
    onOpen: () => {
      sendPageView(
        {
          path: asPath,
          title: PAGE_VIEW_TITLE.MODAL_ADMIN_SETTINGS
        },
        EventPageView.MODAL_OPENING
      );
      toggleMenu(false);
    }
  });

  const {
    closeModal: closeLanguageModal,
    createModal: createLanguageModal,
    openModal: openLanguageModal,
    toggleLoader: toggleLanguageModalLoader
  } = useModal({
    onOpen: () => {
      sendPageView(
        { path: asPath, title: PAGE_VIEW_TITLE.MODAL_LANGUAGES },
        EventPageView.MODAL_OPENING
      );
      toggleMenu(false);
    }
  });

  useEffect(() => {
    if (!isOpen) {
      toggleLinks(false);
    }
  }, [isOpen]);

  const handleClick = () => {
    if (!isOpen) {
      toggleLinks(!isLinksOpen);
      toggleMenu(true);
    } else {
      toggleLinks(!isLinksOpen);
    }
  };

  const handleHover = () => {
    if (!isOpen) {
      toggleMenu(true);
    }
  };

  const handleLogout = async () => {
    await fetcher('/api/auth/logout', { method: HttpMethod.POST });
    resetAuth();
  };

  const firstname = currentAgent?.firstname || user?.email;
  const name = getFormatUserName(
    currentAgent?.firstname || null,
    currentAgent?.lastname || null,
    false
  );

  return (
    <Layout isOpen={isLinksOpen}>
      {canAccessAdminSettings && (
        <AdminLinksWrapper>
          <span
            id={generateButtonId(PAGE_VIEW_TITLE.MENU, 'adminSettings')}
            onClick={openAdminSettingsModal}
            onMouseEnter={handleHover}
            role="button"
          >
            <ToolItem isOpen={isOpen} data-test="settings">
              <AdminSettingsIcon />
              <ItemLabel>{t('adminSettings')}</ItemLabel>
            </ToolItem>
          </span>
          <span
            id={generateButtonId(PAGE_VIEW_TITLE.MENU, 'languages')}
            onClick={openLanguageModal}
            onMouseEnter={handleHover}
            role="button"
          >
            <ToolItem isOpen={isOpen} data-test="languages">
              <svg>
                <image xlinkHref={LANGUAGES_OPTIONS[currentLanguage].flagUrl} />
              </svg>
              <ItemLabel>
                {t(LANGUAGES_OPTIONS[currentLanguage].text)}
              </ItemLabel>
            </ToolItem>
          </span>
        </AdminLinksWrapper>
      )}
      <Agent isOpen={isOpen} onClick={handleClick} onMouseEnter={handleHover}>
        {user && (
          <AgentAvatar
            agentId={currentAgent?.id}
            name={name || user.email}
            isToolTipVisible={false}
          />
        )}
        <AgentName>{firstname}</AgentName>
        <AgentToggle>
          <Arrow />
        </AgentToggle>
      </Agent>
      {isLinksOpen && (
        <Links>
          {menuAgentItems}
          <ToolItem
            data-test="disconnect"
            isOpen={isOpen}
            onClick={handleLogout}
          >
            <Exit />
            <ItemLabel> {t('disconnect')}</ItemLabel>
          </ToolItem>
        </Links>
      )}
      {createAdminSettingsModal({
        children: (
          <AdminSettings
            closeModal={closeAdminSettingsModal}
            toggleLoader={toggleAdminSettingsLoader}
          />
        ),
        icon: <AdminSettingsIcon />,
        isAlternative: true,
        title: t('adminSettings')
      })}
      {createLanguageModal({
        children: (
          <LanguageModal
            closeModal={closeLanguageModal}
            toggleLoader={toggleLanguageModalLoader}
          />
        ),
        isAlternative: true,
        title: t('changeLanguage')
      })}
    </Layout>
  );
};

export default MenuAgent;

import { FC, Fragment, useMemo } from 'react';

import { useLeads } from '@/hooks/fetch/leads';

import { LeadViewTab } from './interfaces';
import ExternalLeads from './leads/externalLeads/ExternalLeads';
import Leads from './leads/Leads';
import {
  getExternalOpportunitiesPerTab,
  getIsLoadingByCurrentTab,
  isExternalLeadTab,
  reduceOpportunities
} from './LeadView.utils';
import NavBar from './navBar/NavBar';

type LeadViewProps = {
  currentPage: number;
  currentTab: LeadViewTab;
};

const LeadView: FC<LeadViewProps> = ({ currentPage, currentTab }) => {
  const {
    externalOpportunities,
    isArchivedExternalOpportunitiesLoading,
    isArchivedOpportunitiesLoading,
    isCurrentExternalOpportunitiesLoading,
    isCurrentOpportunitiesLoading,
    opportunities
  } = useLeads();

  const isLoading = getIsLoadingByCurrentTab(
    currentTab,
    isArchivedExternalOpportunitiesLoading,
    isArchivedOpportunitiesLoading,
    isCurrentExternalOpportunitiesLoading,
    isCurrentOpportunitiesLoading
  );

  const opportunitiesPerTab = useMemo(
    () => reduceOpportunities(opportunities),
    [opportunities]
  );
  const externalOpportunitiesPerTab = useMemo(
    () => getExternalOpportunitiesPerTab(externalOpportunities),
    [externalOpportunities]
  );

  return (
    <Fragment>
      <NavBar
        currentTab={currentTab}
        externalOpportunities={externalOpportunitiesPerTab}
        isLoading={isLoading}
        opportunities={opportunitiesPerTab}
      />
      {isExternalLeadTab(currentTab) ? (
        <ExternalLeads
          currentPage={currentPage}
          currentTab={currentTab}
          isLoading={isLoading}
          opportunities={externalOpportunitiesPerTab[currentTab]}
        />
      ) : (
        <Leads
          currentPage={currentPage}
          currentTab={currentTab}
          isLoading={isLoading}
          opportunities={opportunitiesPerTab[currentTab]}
        />
      )}
    </Fragment>
  );
};

export default LeadView;

import {
  nullOrBoolean,
  nullOrNumber,
  nullOrString,
  PropertyEnum
} from '@proprioo/hokkaido';

import { Activity } from '../activity/interfaces';
import { InitialProject } from '../app/layout/topBar/search/interfaces';
import { BuyerScore } from '../buyer/interfaces';
import {
  LoanStage,
  LocationState,
  MinMax,
  PurchaseStage
} from '../buyerQualification/interfaces';
import { InterestType } from '../buyersMatching/interfaces';
import { Agent } from '../state/interfaces';
import { OtherCriteriasProps } from './filters/otherCriterias/OtherCriterias';
import { OrderBy } from './sortingToggle/enum';

type CustomerName = {
  firstname: string;
  lastname: string;
};

export type BuyerActivity = Pick<
  Activity,
  | 'activityType'
  | 'completionDate'
  | 'id'
  | 'isAllDay'
  | 'status'
  | 'targetDate'
>;

export enum BuyerBaseColumns {
  ACTIVITIES_FUTURE = 'activitiesFuture',
  ACTIVITIES_PAST = 'activitiesPast',
  AGENT_OWNER = 'agentOwner',
  BEDROOMS = 'bedrooms',
  BUYER = 'buyer',
  BUYER_ACTIVITIES = 'buyerActivities',
  BUYER_INTEREST = 'interested',
  EMAIL = 'email',
  LANDING_AREA = 'landingArea',
  MODIFIED_AT = 'modifiedAt',
  OTHER = 'other',
  PRICE = 'price',
  PROPERTY_TYPES = 'propertyTypes',
  ROOMS = 'rooms',
  SELLER_BUYER = 'sellerBuyer',
  SCORE = 'score',
  STATUS = 'status',
  SURFACE = 'surface',
  ZONES = 'zones'
}

export enum BuyerBaseField {
  BEDROOMS = 'bedrooms',
  INTERESTED = 'interested',
  LANDING_AREA = 'landingArea',
  LASTNAME = 'lastName',
  MODIFIED_AT = 'modifiedAt',
  PRICE = 'price',
  ROOMS = 'rooms',
  SCORING = 'scoring',
  SURFACE = 'surface'
}

export type ScoreColor = {
  backgroundColor: string;
  textColor: string;
};

export type BuyerListingData = {
  interested?: boolean;
  notified?: string;
};

export type Buyer = InitialProject & {
  activities: BuyerActivity[];
  createdAt: string;
  idAlert: string;
  internalComment: string;
  isSeller: boolean;
  loanStage: LoanStage;
  modifiedAt: string;
  notification: boolean;
  notificationNb: number;
  propertyTypes: PropertyEnum[];
  purchaseStage: PurchaseStage;
  score: BuyerScore;
  searchMandatesNb: number;
  userId: string;
  agentOwner?: number;
  forListing?: BuyerListingData;
  groundFloor?: boolean;
  hasCellarOrAnnex?: boolean;
  hasLift?: boolean;
  hasParking?: boolean;
  hasPool?: boolean;
  hasOutdoorSpace?: boolean;
  minBedrooms?: number;
  maxBedrooms?: number;
  minLandingArea?: number;
  maxLandingArea?: number;
  minPrice?: number;
  maxPrice?: number;
  minRooms?: number;
  maxRooms?: number;
  minSurface?: number;
  maxSurface?: number;
  withoutRenovationWork?: boolean;
  zones?: LocationState[];
};

export type BuyerBaseProps = {
  buyers: Buyer[];
  totalProjectsCount: number;
};

export type PreviewData = {
  email: string;
  idAlert: string;
  phone: string;
  userId: string;
  userName: CustomerName;
  agentOwner?: number;
  lastContact?: BuyerActivity;
  nextActivity?: BuyerActivity;
};

export type PreviewState = {
  isPreviewVisible: boolean;
  previewData: PreviewData;
  selectedRow: nullOrString;
};

export type BuyerInterestData = CustomerName & {
  id: string;
  email: string;
  phone: string;
};

export type CustomerEmailData = {
  alertId: string;
  customerId: string;
  notification: boolean;
  notificationNb: number;
  agentOwner?: number;
  notified?: string;
};

export type BuyerActivitiesData = {
  listingRequestsCount: number;
  offersCount: number;
  visitsCount: number;
};

export type BuyerData = {
  firstname: string;
  lastname: string;
};

export type InterestedData = {
  customer: BuyerInterestData;
  interested: nullOrBoolean;
};

export type StatusData = {
  hasResearchMandate: boolean;
  isSeller: boolean;
  loanStage: LoanStage;
  notification: boolean;
  purchaseStage: PurchaseStage;
};

export type SellerBuyer = {
  buyerProjectCount: number;
  sellerProjectCount: number;
  agentOwnerId?: number;
};

export type ActivitiesBuyer = {
  activitiesFuture: BuyerActivity[];
  activitiesPast: BuyerActivity[];
};

export type Data = ActivitiesBuyer & {
  agents: Agent[];
  buyerActivities: BuyerActivitiesData;
  bedrooms: MinMax;
  buyer: BuyerData;
  email: CustomerEmailData;
  interested: InterestedData;
  landingArea: MinMax;
  modifiedAt: string;
  other: OtherCriteriasProps;
  preview: PreviewData;
  price: MinMax;
  propertyTypes: PropertyEnum[];
  rooms: MinMax;
  score: BuyerScore;
  scoreColor: ScoreColor;
  sellerBuyer: SellerBuyer;
  status: StatusData;
  surface: MinMax;
  zones?: LocationState[];
};

export type BuyerbaseFiltersState = {
  agentOwners: string[];
  bedrooms: nullOrNumber;
  field: nullOrString;
  landingArea: nullOrNumber;
  limit: number;
  offset: number;
  orderBy: OrderBy | null;
  other: OtherCriteriasProps;
  price: nullOrNumber;
  propertyTypes: nullOrString;
  rooms: nullOrNumber;
  score: nullOrString;
  surface: nullOrNumber;
  withPropriooOwner: nullOrBoolean;
  zonesIds: string[];
};

export type BuyerbaseSearchState = {
  data?: BuyerBaseProps;
  lastQuery?: string;
};

export type TableDataAndColumns = {
  agents: Agent[];
  data: Buyer[];
  filters: BuyerbaseFiltersState;
  applyFilters(filters: BuyerbaseFiltersState): void;
};

export type UpdateBuyerInterestPayload = {
  customerId: string;
  id: string;
  value: nullOrBoolean;
  interestType?: InterestType;
};

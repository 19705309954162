import { DictionaryBoolean } from '@/utils/interfaces';

export const getColumnVisibility = (
  allColumns: string[],
  hiddenColumns: string[]
): DictionaryBoolean =>
  allColumns.reduce<DictionaryBoolean>(
    (acc, column) => ({ ...acc, [column]: !hiddenColumns.includes(column) }),
    {}
  );

export enum SearchType {
  ADDRESS = 'address',
  ALL_TYPES = 'allTypes',
  EMAIL = 'email',
  ID = 'id',
  NAME = 'name',
  PHONE = 'phone'
}

import {
  AlertObjectPayload,
  LoanStage,
  PurchaseStage
} from '@/components/buyerQualification/interfaces';
import { Company } from '@/components/listing/contract/interfaces';
import { AppointmentSubStatus } from '@/components/qualification/sellerQualification/interfaces';
import { EstimationStage, LeadSource, PropertyEnum } from '@proprioo/hokkaido';

export type SearchState = {
  isSelectOpen: boolean;
  searchValue: string;
  selectedType: string;
};

export type InitialProject = {
  email: string;
  estimationRequestsNb: number;
  listingRequestsNb: number;
  lastname: string;
  offersNb: number;
  firstname: string;
  projectsNb: number;
  phone: string;
  viewingsNb: number;
};

export type UserWithoutProject = InitialProject & {
  id: string;
  matchingScoreUsername: number;
  modified: string;
};

export type BuyerProject = InitialProject &
  AlertObjectPayload & {
    idAlert: string;
    userId: string;
    createdAt: string;
    matchingScoreUsername: number;
    modifiedAt: string;
    notification: true;
    score: number;
    agentOwner?: number;
    internalComment?: string;
    isSeller?: boolean;
    loanStage?: LoanStage;
    purchaseStage?: PurchaseStage;
  };

export type SellerProject = InitialProject & {
  agentId: number;
  codePostal: string;
  estimationStage: EstimationStage;
  id: number;
  lastAppointmentStatus: AppointmentSubStatus;
  leadSource: LeadSource;
  matchingScoreUsername: number;
  matchingScoreStreet: number;
  modified: string;
  numero: string;
  propertyType: PropertyEnum;
  rue: string;
  userId: string;
  ville: string;
  agentOwner?: number;
  annonceId?: number;
  expectedPrice?: number;
  lastStage?: EstimationStage;
  listingId?: number;
  nbBedrooms?: number;
  nbPieces?: number;
  pics?: string[];
  surfaceCarrez?: number;
  viewingAgent?: number;
};

export type CompanyResult = Pick<
  Company,
  | 'id'
  | 'denomination'
  | 'city'
  | 'street'
  | 'legalForm'
  | 'streetNumber'
  | 'postCode'
> & {
  entityId: string;
  firstnameLeader: string;
  lastnameLeader: string;
};

export type BuyersResult = {
  results: BuyerProject[];
  total: number;
};

export type SellersResult = {
  results: SellerProject[];
  total: number;
};

export type UsersResult = {
  results: UserWithoutProject[];
  total: number;
};

export type CompaniesResults = {
  results: CompanyResult[];
  total: number;
};

export type SearchAPIResults = {
  oppBuyer: BuyersResult;
  oppSeller: SellersResult;
  usersWithoutProjects: UsersResult;
  companyResults: CompaniesResults;
};

import { formatUrlQuery } from '@/components/myCustomers/mySellers/LeadView.utils';

export const getLeadsApiUrls = (
  isTeamLead: boolean,
  agentIds: number[],
  agentId?: number
) => {
  if (!agentId) {
    return {
      archivedExternalOpportunitiesUrl: null,
      archivedOpportunitiesUrl: null,
      currentExternalOpportunitiesUrl: null,
      currentOpportunitiesUrl: null
    };
  }

  const params = formatUrlQuery({ requestingAgents: agentIds });

  return isTeamLead
    ? {
        archivedExternalOpportunitiesUrl: `/api/external-lead/archived-tl-leads${params}`,
        archivedOpportunitiesUrl: `/api/data/tl-leads-archived${params}`,
        currentExternalOpportunitiesUrl: `/api/external-lead/tl-leads${params}`,
        currentOpportunitiesUrl: `/api/data/tl-leads${params}`
      }
    : {
        archivedExternalOpportunitiesUrl: `/api/external-lead/archived-leads?agentId=${agentId}`,
        archivedOpportunitiesUrl: `/api/data/agent-leads-archived?requestingAgent=${agentId}`,
        currentExternalOpportunitiesUrl: `/api/external-lead/leads?agentId=${agentId}`,
        currentOpportunitiesUrl: `/api/data/agent-leads?requestingAgent=${agentId}`
      };
};

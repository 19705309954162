import { FC, Fragment, useMemo } from 'react';

import { useLeads } from '@/hooks/fetch/leads';

import { LeadViewTab } from './interfaces';
import ExternalLeads from './leads/externalLeads/ExternalLeads';
import Leads from './leads/Leads';
import {
  getExternalOpportunitiesPerTab,
  getIsLoadingByCurrentTab,
  isExternalLeadTab,
  reduceOpportunities
} from './LeadView.utils';
import NavBar from './navBar/NavBar';

export type TeamLeadViewProps = {
  currentAgentIds: number[];
  currentPage: number;
  currentTab: LeadViewTab;
};

const TeamLeadView: FC<TeamLeadViewProps> = ({
  currentAgentIds,
  currentPage,
  currentTab
}) => {
  const {
    externalOpportunities,
    isArchivedExternalOpportunitiesLoading,
    isArchivedOpportunitiesLoading,
    isCurrentExternalOpportunitiesLoading,
    isCurrentOpportunitiesLoading,
    opportunities
  } = useLeads();

  const isLoading = getIsLoadingByCurrentTab(
    currentTab,
    isArchivedExternalOpportunitiesLoading,
    isArchivedOpportunitiesLoading,
    isCurrentExternalOpportunitiesLoading,
    isCurrentOpportunitiesLoading
  );

  const opportunitiesPerTab = useMemo(() => {
    const filteredOpportunities = opportunities.filter(
      ({ agentId, viewingAgent }) =>
        currentAgentIds.includes(agentId) ||
        (viewingAgent && currentAgentIds.includes(viewingAgent))
    );

    return reduceOpportunities(filteredOpportunities);
  }, [currentAgentIds, opportunities]);

  const externalOpportunitiesPerTab = useMemo(() => {
    const filteredExternalOpportunities = externalOpportunities.filter(
      ({ agentId }) => agentId && currentAgentIds.includes(agentId)
    );

    return getExternalOpportunitiesPerTab(filteredExternalOpportunities);
  }, [currentAgentIds, externalOpportunities]);

  return (
    <Fragment>
      <NavBar
        agentIds={currentAgentIds}
        currentTab={currentTab}
        externalOpportunities={externalOpportunitiesPerTab}
        hasOpportunities={Boolean(opportunities.length)}
        isLoading={isLoading}
        isTeamLead={true}
        opportunities={opportunitiesPerTab}
      />
      {isExternalLeadTab(currentTab) ? (
        <ExternalLeads
          agentIds={currentAgentIds}
          currentPage={currentPage}
          currentTab={currentTab}
          isLoading={isLoading}
          isTeamLead={true}
          opportunities={externalOpportunitiesPerTab[currentTab]}
        />
      ) : (
        <Leads
          agentIds={currentAgentIds}
          currentPage={currentPage}
          currentTab={currentTab}
          isLoading={isLoading}
          isTeamLead={true}
          opportunities={opportunitiesPerTab[currentTab]}
        />
      )}
    </Fragment>
  );
};

export default TeamLeadView;

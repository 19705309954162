import { useTranslation } from 'next-i18next';
import { FC, useEffect, useState } from 'react';

import { Loader, LoaderProps } from '@proprioo/salatim';

import { Layout } from './GlobalLoader.styles';

type GlobalLoaderProps = LoaderProps & {
  fullScreen?: boolean;
};

const GlobalLoader: FC<GlobalLoaderProps> = ({
  fullScreen,
  text = 'loading',
  ...rest
}) => {
  const { t } = useTranslation();
  const [isWaiting, setIsWaiting] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsWaiting(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Layout fullScreen={fullScreen} data-test="global-loader">
      <Loader
        {...rest}
        text={
          text &&
          (isWaiting ? t('justAMomentLonger', { text: t(text) }) : t(text))
        }
      />
    </Layout>
  );
};

export default GlobalLoader;

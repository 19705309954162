import styled from 'styled-components';

import { colors, convertPxToRem } from '@proprioo/salatim';

/***
 * Generic wrappers
 **/

export const IconPrefixedInfo = styled.span`
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;

  svg + * {
    margin-left: ${convertPxToRem(8)};
  }

  path {
    fill: ${colors.dark.base};
  }
`;

/***
 * Specific wrappers styles
 **/
export const RevenueTagWrapper = styled.span`
  span {
    color: ${colors.dark.base80};
    font-weight: normal;
    white-space: nowrap;
  }

  path {
    fill: ${colors.dark.base80};
  }
`;

export const TreatmentPriorityTagWrapper = styled.div`
  span > span {
    margin-right: 0;
  }
`;

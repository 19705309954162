import { UserRole } from '@/components/state/interfaces';
import { nullOrBoolean } from '@proprioo/hokkaido';

export enum DatabaseStatus {
  ACTIVATED = 'ACTIVATED',
  BLOCKED = 'BLOCKED',
  DELETED = 'DELETED',
  PENDING = 'PENDING'
}

export enum EnvLabels {
  STAGING = 'preprod',
  TEST = 'test',
  SANDBOX = 'sandbox',
  LOCAL = 'local',
  PRODUCTION = 'prod'
}

export enum FEATURE_FLAG_STATE {
  DEACTIVATED = 'deactivated',
  ACTIVATED = 'activated'
}

export enum FileFormat {
  PDF = 'application/pdf',
  PNG = 'image/png',
  JPG = 'image/jpg',
  JPEG = 'image/jpeg'
}

export enum IconOrientation {
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ArbitraryObject = Record<string, any>;
export type DictionaryBoolean = Record<string, boolean>;
export type DictionaryNullOrBoolean = Record<string, nullOrBoolean>;
export type numberOrString = number | string;

export type AuthTokens = {
  accessToken: string;
  refreshToken: string;
  impersonation?: {
    email?: string;
    roles?: UserRole[];
  };
};

type EntryProps = {
  uuid: string;
  completionDate: string;
};

export type EmailEntryProps = EntryProps & {
  email: string;
  subject: string;
};

export type SMSEntryProps = EntryProps & {
  phoneNumber: string;
  message: string;
};

import { PartialAgent } from '@/components/qualification/projectForm/ProjectForm.interfaces';
import {
  ENV_TYPE,
  LAST_IMPERSONATED_AGENTS_STORAGE_KEY
} from '@/constants/global';
import { EnvLabels } from '@/utils/interfaces';

import { Agent, UserRole } from '../../../state/interfaces';

export const getRoleLabel = (role: UserRole) => {
  switch (role) {
    case UserRole.APP_SALES_ADMIN:
      return 'appsalesadmin';
    case UserRole.IREC:
      return 'irec';
    case UserRole.MOPS:
      return 'mops';
    case UserRole.PREMIUM_PARTNER:
      return 'premiumPartner';
    case UserRole.SALES:
      return 'salesRole';
    case UserRole.SELLER_SUCCESS:
      return 'sellerSuccess';
    case UserRole.TEAM_LEADS:
      return 'teamleads';
    case UserRole.TECH:
      return 'tech';
  }
};

export const getLastImpersonatedAgentsStorageKey = () =>
  ENV_TYPE === EnvLabels.PRODUCTION
    ? `${LAST_IMPERSONATED_AGENTS_STORAGE_KEY}_prod`
    : `${LAST_IMPERSONATED_AGENTS_STORAGE_KEY}_staging`;

export const getLastImpersonatedAgentsFromStorage = (): PartialAgent[] => {
  const lastImpersonatedAgentsStorageKey =
    getLastImpersonatedAgentsStorageKey();

  if (
    window !== undefined &&
    lastImpersonatedAgentsStorageKey in localStorage
  ) {
    const lastImpersonatedAgentsStringified = localStorage.getItem(
      lastImpersonatedAgentsStorageKey
    );

    return lastImpersonatedAgentsStringified
      ? JSON.parse(lastImpersonatedAgentsStringified)
      : [];
  }

  return [];
};

export const setLastImpersonatedAgentsToStorage = (
  impersonatedAgent: Agent,
  lastImpersonatedAgents: PartialAgent[]
) => {
  const lastImpersonatedAgentsStorageKey =
    getLastImpersonatedAgentsStorageKey();

  const unduplicatedAgents = [
    impersonatedAgent,
    ...lastImpersonatedAgents
  ].reduce<PartialAgent[]>((acc, item) => {
    const foundAgent = acc.find(({ id }) => id === item.id);

    if (!foundAgent) {
      return [
        ...acc,
        {
          firstname: item.firstname,
          id: item.id,
          isDuplicated: item.isDuplicated,
          lastname: item.lastname,
          squads: item.squads
        }
      ];
    }

    return acc;
  }, []);

  if (unduplicatedAgents.length > 5) {
    unduplicatedAgents.pop();
  }

  localStorage.setItem(
    lastImpersonatedAgentsStorageKey,
    JSON.stringify(unduplicatedAgents)
  );
};

import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRecoilState } from 'recoil';

import ZoningIcon from '@/assets/vectors/menu/map-marker.svg';
import { menuSelector } from '@/components/state/MenuState';
import { useActiveLink } from '@/hooks/useActiveLink/useActiveLink';

import { ItemLabel, ToolItem } from '../MenuItems.styles';

const ZoningItem = () => {
  const { t } = useTranslation();

  const [isOpen, updateMenu] = useRecoilState(menuSelector);
  const isActive = useActiveLink('/zoning');

  return (
    <Link
      href="/zoning"
      id="sidebar-menu_zoning"
      onMouseEnter={() => {
        if (!isOpen) {
          updateMenu(true);
        }
      }}
    >
      <ToolItem isActive={isActive} isOpen={isOpen}>
        <ZoningIcon />
        <ItemLabel>{t('zoning')}</ItemLabel>
      </ToolItem>
    </Link>
  );
};

export default ZoningItem;

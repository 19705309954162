import * as yup from 'yup';

import {
  ExtendedCustomerSchema,
  NewEmailSchema
} from '@/components/qualification/customerForm/CustomerForm.schema';

export const PhysicalSignatoryUserSchema = ExtendedCustomerSchema.shape({
  city: yup.string().required(),
  email: NewEmailSchema.required(),
  postCode: yup.string().required(),
  street: yup.string().required()
});

export const PhysicalSignatoryUserEmailSchema = ExtendedCustomerSchema.shape({
  email: NewEmailSchema.required()
});

import { eachMinuteOfInterval } from 'date-fns/eachMinuteOfInterval';
import { format } from 'date-fns/format';
import { isBefore } from 'date-fns/isBefore';
import { isToday } from 'date-fns/isToday';
import { parseISO } from 'date-fns/parseISO';
import { i18n } from 'next-i18next';
import { Fragment, ReactElement } from 'react';

import Call from '@/assets/icons/activity-call.svg';
import Email from '@/assets/icons/activity-email.svg';
import FailedCall from '@/assets/icons/activity-failed-call.svg';
import RDV from '@/assets/icons/activity-rdv.svg';
import SMS from '@/assets/icons/activity-sms.svg';
import Task from '@/assets/icons/activity-task.svg';
import {
  DATE_SCHEDULE_SECONDS_FORMAT,
  DATE_SCHEDULE_SIMPLE_FORMAT
} from '@/constants/global';
import {
  EstimationStage,
  listToString,
  nullOrString,
  OptionProps
} from '@proprioo/hokkaido';
import { colors } from '@proprioo/salatim';

import { BuyerActivity } from '../buyerbase/interfaces';
import { LocationState } from '../buyerQualification/interfaces';
import { formatAreas } from '../buyerQualification/utils';
import { OpportunityQualification } from '../qualification/sellerQualification/interfaces';
import {
  Activity,
  ActivityStatus,
  ActivityType,
  AgentProjects,
  BuiltElement,
  BuyerAndSellerProjects,
  DoneActivitiesSorter,
  Project,
  ToBeDoneActivitiesSorter
} from './interfaces';

export const START_OF_DAY_SCHEDULE = '09:00:00';

export const getSelectOptions = () => ({
  activityTypeOptions: Object.entries(ActivityType).map(([_, value]) => ({
    label: value,
    value
  })) as OptionProps[],
  scheduleOptions: [
    { label: '', value: '' },
    ...eachMinuteOfInterval(
      { end: new Date(2020, 1, 1, 22), start: new Date(2020, 1, 1, 6) },
      { step: 15 }
    ).map((scheduleTime: Date) => ({
      label: format(
        scheduleTime,
        i18n?.t(DATE_SCHEDULE_SIMPLE_FORMAT, { ns: 'date' }) || ''
      ),
      value: format(
        scheduleTime,
        i18n?.t(DATE_SCHEDULE_SECONDS_FORMAT, { ns: 'date' }) || ''
      )
    }))
  ] as OptionProps[]
});

export const getLocationsSentence = (
  locationState: LocationState[]
): string => {
  const [first, second, ...rest] = formatAreas(locationState);

  if (rest.length === 0) return listToString([first, second], ', ');
  if (rest.length > 0) return `${first}, ${second} + ${rest.length}`;
  return '';
};

export const getActivityIcon = (
  type: ActivityType,
  failed: boolean
): ReactElement => {
  switch (type) {
    case ActivityType.TASK:
      return <Task data-test="task" />;
    case ActivityType.EMAIL:
      return <Email data-test="email" />;
    case ActivityType.SMS:
      return <SMS data-test="sms" />;
    case ActivityType.CALL:
      return failed ? (
        <FailedCall data-test="failed-call" />
      ) : (
        <Call data-test="call" />
      );
    case ActivityType.RDV:
      return <RDV data-test="rdv" />;
    default:
      return <Fragment />;
  }
};

export const getIconColors = (
  isToday: boolean,
  isPast: boolean,
  isDone: boolean,
  isAllDay: boolean
) => {
  if ((isToday && isAllDay && !isDone) || (isToday && !isPast)) {
    return { circleColor: colors.green.base, color: 'white' };
  } else if (isPast && !isDone) {
    return { circleColor: colors.red.base80, color: 'white' };
  } else if (isPast && isDone) {
    return { circleColor: colors.grey.base60, color: colors.dark.base };
  } else {
    return { circleColor: colors.blue.base, color: colors.green.base };
  }
};

export const getActivitiesByStatus = (
  activities: Activity[] = [],
  filteredStatus: ActivityStatus
) => activities.filter(({ status }) => status === filteredStatus);

export const getToBeDoneActivitiesSorted = <T extends ToBeDoneActivitiesSorter>(
  activities: T[] = []
) =>
  [...activities].sort(
    (a, b) =>
      parseISO(a.targetDate).getTime() - parseISO(b.targetDate).getTime()
  );

export const getDoneActivitiesSorted = <T extends DoneActivitiesSorter>(
  activities: T[]
) =>
  [...activities].sort(
    (a, b) =>
      parseISO(b.completionDate || b.targetDate).getTime() -
      parseISO(a.completionDate || a.targetDate).getTime()
  );

export const getBuyerAndSellerProjects = (
  project: nullOrString
): BuyerAndSellerProjects => {
  if (!project || project === Project.ALL_PROJECTS) {
    return { buyerProject: null, sellerProject: null };
  }

  const isMatchingRegex = /[a-z]/.test(project);

  const buyerProject = isMatchingRegex ? project : null;
  const sellerProject = !isMatchingRegex ? parseInt(project, 10) : null;

  return { buyerProject, sellerProject };
};

export const getFilteredCustomerActivities = (activities: Activity[]) =>
  activities.filter(
    ({ buyerProject, sellerProject }) => !buyerProject && !sellerProject
  );

export const getFilteredProjectActivities = (activities: Activity[]) =>
  activities.filter(
    ({ buyerProject, sellerProject }) => buyerProject || sellerProject
  );

export const getProjectsActivities = (activities: Activity[][]) =>
  activities.reduce((a, b) => a.concat(b));

export const getBuyerProjectsActivities = (
  projectsActivitiesArray: Activity[][],
  customerActivities: Activity[]
): Activity[] => {
  const projectsActivities = getProjectsActivities(projectsActivitiesArray);
  const filteredCustomerActivities =
    getFilteredCustomerActivities(customerActivities);

  return [...projectsActivities, ...filteredCustomerActivities];
};

export const getSellerProjectActivities = (
  projectActivities: Activity[],
  customerActivities: Activity[]
): Activity[] => {
  const filteredCustomerActivities =
    getFilteredCustomerActivities(customerActivities);
  const filteredProjectActivities =
    getFilteredProjectActivities(projectActivities);

  return [...filteredProjectActivities, ...filteredCustomerActivities];
};

export const getNextPlannedActivity = <T extends BuyerActivity>(
  activities: T[]
): T | undefined => {
  if (!activities.length) return;

  const [first] = getToBeDoneActivitiesSorted<T>(activities);

  return first;
};

export const getLastPlannedActivity = <T extends BuyerActivity>(
  activities: T[]
): T | undefined => {
  if (!activities.length) return;

  const [first] = getDoneActivitiesSorted<T>(activities);

  return first;
};

export const getLastContactActivity = (
  activities: BuyerActivity[]
): BuyerActivity | undefined => {
  if (!activities.length) return;

  const filteredActivities = activities.filter(
    ({ activityType }) => activityType !== ActivityType.TASK
  );

  const [first] = getDoneActivitiesSorted<BuyerActivity>(filteredActivities);

  return first;
};

export const getScheduleRoundedDownToNearestQuarter = (date: Date): string => {
  const coeff = 1000 * 60 * 15;

  return format(
    new Date(Math.floor(date.getTime() / coeff) * coeff),
    i18n?.t(DATE_SCHEDULE_SECONDS_FORMAT, { ns: 'date' }) || ''
  );
};

export const getProjectCustomerId = (
  projectId: string,
  projects: AgentProjects
): string | undefined => {
  const { sellerProjects, buyerProjects } = projects;

  return (
    sellerProjects.find(({ id }) => id.toString() === projectId)?.user.id ||
    buyerProjects.find(({ alertID }) => alertID === projectId)?.userID
  );
};

export const getFilteredActivities = (activities: Activity[]) =>
  activities.reduce((acc: Activity[], item) => {
    const foundActivity = acc.find(({ id }) => id === item.id);

    if (!foundActivity) {
      acc.push(item);
    }

    return acc;
  }, []);

export const formatActivityContent = (content?: nullOrString) => content || '-';

export const getActivitiesAndEventsSorted = (
  activitiesAndEvents: BuiltElement[]
) =>
  [...activitiesAndEvents].sort(
    (a, b) =>
      parseISO(new Date(b.completionDate).toISOString()).getTime() -
      parseISO(new Date(a.completionDate).toISOString()).getTime()
  );

export const getErrorMessage = (
  activitiesError?: string,
  emailsAndSmsError?: string,
  notificationsError?: string
): string => {
  if (activitiesError) {
    return 'activitiesFetchError';
  }

  if (emailsAndSmsError) {
    return 'emailsAndSmsFetchError';
  }

  if (notificationsError) {
    return 'notificationsFetchError';
  }

  return '';
};

export const hasHandleLeadCard = (
  isAllowed: boolean,
  isLoading: boolean,
  activities: Activity[],
  activitiesError?: string,
  opportunity?: OpportunityQualification
) =>
  isAllowed &&
  !isLoading &&
  !activitiesError &&
  !activities.length &&
  Boolean(opportunity?.visibleByAgent) &&
  opportunity?.estimationStage === EstimationStage.LEAD;

export const isLateActivity = (
  targetDate: string,
  isDone: boolean,
  isAllDay: boolean
) => {
  const isPastTargetDate = isBefore(parseISO(targetDate), new Date());

  return (
    !isDone &&
    (isAllDay
      ? !isToday(parseISO(targetDate)) && isPastTargetDate
      : isPastTargetDate)
  );
};

export const getActivityTypeLabel = (activityType: ActivityType) => {
  switch (activityType) {
    case ActivityType.CALL:
      return 'call';
    case ActivityType.EMAIL:
      return 'email';
    case ActivityType.RDV:
      return 'rdv';
    case ActivityType.SMS:
      return 'sms';
    case ActivityType.TASK:
      return 'task';
  }
};

export const isUserAuthorized = (owner: string, user?: string) => {
  const regex = /^([^@]+)@/;
  const ownerPrefix = owner.match(regex);
  const userPrefix = user?.match(regex);

  if (ownerPrefix && userPrefix) {
    return ownerPrefix[1] === userPrefix[1];
  }

  return false;
};

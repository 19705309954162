import { useRouter } from 'next/router';
import { FC, Fragment, useEffect } from 'react';

import { useCurrentAgent } from '@/hooks/agents/useCurrentAgent';
import { useAuth } from '@/hooks/auth/useAuth';
import { PAGE_VIEW_TITLE, sendPageView } from '@/utils/gtm';
import { ArbitraryObject, numberOrString } from '@/utils/interfaces';
import { EVENT_NAME, trackEvent } from '@/utils/mixPanel';

type PageViewTrackerProps = {
  title: PAGE_VIEW_TITLE;
  [key: string]: numberOrString | ArbitraryObject | undefined | null | boolean;
};

const PageViewTracker: FC<PageViewTrackerProps> = props => {
  const { asPath } = useRouter();
  const { user } = useAuth();

  const currentAgent = useCurrentAgent();

  useEffect(() => {
    if (asPath && (user?.original?.email || user?.email)) {
      sendPageView({
        ...props,
        path: asPath,
        url: window.location.hostname,
        user_id: user.original?.email || user.email,
        ...(currentAgent && { agent_id: currentAgent.id })
      });

      trackEvent(EVENT_NAME.PAGE_VIEWED, {
        connectedAs: user.email,
        connectedAsRole: user.roles,
        pagePath: asPath,
        pageTitle: props.title
      });
    }
  }, [asPath, user?.email, user?.original?.email]);

  return <Fragment />;
};

export default PageViewTracker;

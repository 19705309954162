import { useTranslation } from 'next-i18next';
import { FC, PropsWithChildren, useEffect } from 'react';
import { useRecoilState } from 'recoil';

import ArrowLeft from '@/assets/icons/chevron-left.svg';
import ArrowRight from '@/assets/icons/chevron-right.svg';
import { sidebarSelector } from '@/components/state/SidebarState';
import { PAGE_VIEW_TITLE } from '@/utils/gtm';
import { generateButtonId } from '@/utils/tracking';
import { Button, ButtonSizes, useMobileDevice } from '@proprioo/salatim';

import {
  ContentSidebar,
  LayoutContactButton,
  LayoutSidebar,
  OpenButton
} from './Sidebar.styles';

export type ISidebar = {
  mobileSidebarTitle: FC;
};

const Sidebar: FC<PropsWithChildren<ISidebar>> = ({
  children,
  mobileSidebarTitle: MobileSidebarTitle
}) => {
  const { t } = useTranslation();

  const isMobile = useMobileDevice();
  const [isOpen, toggleOpen] = useRecoilState(sidebarSelector);

  useEffect(() => {
    if (isMobile) {
      toggleOpen(false);
    }
  }, [isMobile]);

  const label = isOpen ? 'closeSidebar' : 'openSidebar';

  return (
    <LayoutSidebar id="sidebar" isOpen={isOpen}>
      <LayoutContactButton>
        {isMobile && !isOpen && <MobileSidebarTitle />}
        <OpenButton>
          <Button
            onClick={() => toggleOpen(!isOpen)}
            data-test="arrow-click"
            rounded={true}
            size={ButtonSizes.SMALL}
            label={t(label)}
            id={generateButtonId(PAGE_VIEW_TITLE.SHORTCUT, label)}
            icon={
              isOpen ? (
                <ArrowLeft data-test="arrow-left" />
              ) : (
                <ArrowRight data-test="arrow-right" />
              )
            }
          />
        </OpenButton>
      </LayoutContactButton>
      <ContentSidebar>{children}</ContentSidebar>
    </LayoutSidebar>
  );
};

export default Sidebar;

import { useTranslation } from 'next-i18next';
import { FC, Fragment } from 'react';

import RelativeDate from '@/components/myCustomers/common/relativeDate/RelativeDate';
import {
  ImportantInfo,
  SecondaryInfo
} from '@/components/myCustomers/common/styles/MyCustomers.styles';
import { getDateWithoutTimezone } from '@/components/myCustomers/myCustomers.utils';
import { formatLocaleDay } from '@/components/qualification/sellerQualification/utils';
import { DATE_SHORT_FORMAT } from '@/constants/global';
import { useLanguage } from '@/hooks/useLanguage/useLanguage';
import { LANGUAGE_DATE_LOCALES } from '@proprioo/hokkaido';

export type ArchiveDateProps = {
  archiveDate?: string;
  isMobile?: boolean;
};

const ArchiveDate: FC<ArchiveDateProps> = ({
  archiveDate,
  isMobile = false
}) => {
  const { t } = useTranslation();
  const { currentLanguage } = useLanguage();

  if (!archiveDate) {
    return <Fragment />;
  }

  const parsedDate = getDateWithoutTimezone(archiveDate);

  return isMobile ? (
    <ImportantInfo>
      {t('archivedAt', {
        date: formatLocaleDay(
          parsedDate,
          DATE_SHORT_FORMAT,
          LANGUAGE_DATE_LOCALES[currentLanguage]
        )
      })}
    </ImportantInfo>
  ) : (
    <SecondaryInfo>
      <RelativeDate date={parsedDate} isCapitalized={true} />
    </SecondaryInfo>
  );
};

export default ArchiveDate;

import { Fragment } from 'react';

import { useCustomer } from '@/hooks/customer/useCustomer';
import { useCustomerPermission } from '@/hooks/useCustomerPermission/useCustomerPermission';
import { getFormatUserName } from '@/utils/user';

import CustomerContact from '../../../customerSidebar/customerContact/CustomerContact';

const CustomerMobileSidebarTitle = () => {
  const {
    customer: { user }
  } = useCustomer();

  const { phone, email, firstName, lastName } = user;

  const customerName = getFormatUserName(firstName, lastName);

  const hasCustomerPermissions = useCustomerPermission();

  return hasCustomerPermissions ? (
    <CustomerContact phone={phone} email={email} name={customerName} />
  ) : (
    <Fragment>{customerName}</Fragment>
  );
};

export default CustomerMobileSidebarTitle;

import Link from 'next/link';

import { flexRender, Row } from '@tanstack/react-table';

import { TableBodyCell } from '../../styles/Table.styles';
import { Tr } from './TableRow.styles';

export type TableRowProps<T> = {
  link: string;
  linkPageView: string;
  row: Row<T>;
};

const TableRow = <T,>({ link, linkPageView, row }: TableRowProps<T>) => {
  const { getVisibleCells } = row;

  return (
    <Tr data-test="table-body-row">
      {getVisibleCells().map((cell, key) => (
        <TableBodyCell key={`${cell.row}-${key}`} data-test="table-body-cell">
          <Link href={link} id={linkPageView}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Link>
        </TableBodyCell>
      ))}
    </Tr>
  );
};

export default TableRow;

import { EXTERNAL_TOOLS } from '@/constants/global';
import { ComponentsMappingProps } from '@/hooks/useUserPermissionsBuilder/useUserPermissionsBuilder.interfaces';

import AdminItem from './adminItem/AdminItem';
import CalendarItem from './calendarItem/CalendarItem';
import MyContactsItem from './myContactsItem/MyContactsItem';
import PersonalParametersItem from './personalParametersItem/PersonalParametersItem';

export const MENU_AGENT_ITEMS_MAPPING: ComponentsMappingProps = [
  { Component: MyContactsItem, position: 1, url: EXTERNAL_TOOLS.MY_CONTACTS },
  { Component: CalendarItem, position: 2, url: EXTERNAL_TOOLS.GOOGLE_CALENDAR },
  { Component: AdminItem, position: 3, url: '/admin' },
  {
    Component: PersonalParametersItem,
    position: 4,
    url: '/agent/[agentId]/personal-parameters'
  }
];

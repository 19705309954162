import styled from 'styled-components';

import { colors, convertPxToRem, paragraphs } from '@proprioo/salatim';

import { Address } from '../../listingDataCell/ListingDataCell.styles';

export const CardLayout = styled.div`
  padding: ${convertPxToRem(16)} 0;
`;

export const DefaultContainer = styled.div`
  margin-top: ${convertPxToRem(8)};
`;

export const AdvertiserAndRemainingTimeContainer = styled.div`
  ${paragraphs.body2};
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${colors.dark.base};
`;

export const PriceAndBuyersContainer = styled(DefaultContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PriceAndBuyersWraper = styled.div`
  display: flex;
  align-items: center;
`;

export const PictureAndAddressContainer = styled(DefaultContainer)`
  display: flex;
  align-items: center;
  margin-top: ${convertPxToRem(8)};

  ${Address} {
    margin-left: ${convertPxToRem(8)};
  }
`;

export const AttributionButtonWrapper = styled(DefaultContainer)`
  display: flex;
  justify-content: flex-end;
`;

export enum FEATURE_NAME {
  CUSTOMER_ACTIVITY_SUMMARY_OFFERS = 'CUSTOMER_ACTIVITY_SUMMARY_OFFERS',
  CUSTOMER_BUYER_INTERESTS = 'CUSTOMER_BUYER_INTERESTS',
  CUSTOMER_PROJECTS_CREATION = 'CUSTOMER_PROJECTS_CREATION',
  CUSTOMER_LANGUAGE_PREFERENCES = 'CUSTOMER_LANGUAGE_PREFERENCES',
  MY_SELLERS_REVENUE_FORECAST = 'MY_SELLERS_REVENUE_FORECAST',
  PERSONAL_PARAMETERS_FULL_PAGE = 'PERSONAL_PARAMETERS_FULL_PAGE',
  TOPBAR_SEARCH = 'TOPBAR_SEARCH',
  WIDGET_FRESHCHAT = 'WIDGET_FRESHCHAT'
}

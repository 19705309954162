import { Dictionary } from '@proprioo/hokkaido';

import {
  ActionsUnion,
  ActionsWithPayload,
  createAction,
  createActionPayload
} from './actions';

type ReducerDefaultState = {
  hasAccepted: boolean;
  hasChanged: boolean;
  hasErrors: boolean;
  errors: Dictionary;
  isLoading: boolean;
  isSubmitting: boolean;
  isSucceeding: boolean;
  refreshErrors: boolean;
};

export type ReducerFormState<T> = ReducerDefaultState & {
  values: T;
};

export const defaultValues: ReducerDefaultState = {
  errors: {},
  hasAccepted: false,
  hasChanged: false,
  hasErrors: false,
  isLoading: false,
  isSubmitting: false,
  isSucceeding: false,
  refreshErrors: false
};

export const FORM_ACCEPT = 'formAccept';
export const FORM_REJECT = 'formReject';
export const RESET = 'reset';
export const SUBMIT_CANCEL = 'submitCancel';
export const SUBMIT_ERROR = 'submitError';
export const SUBMIT_ERROR_WITHOUT_SUBMITTING = 'submitErrorWithoutSubmitting';
export const SUBMIT_START = 'submitStart';
export const SUBMIT_SUCCESS = 'submitSuccess';
export const UPDATE_VALUES = 'updateValues';
export const UPDATE_VALUES_FROM_INPUT = 'updateValuesFromInput';
export const TOGGLE_LOADING = 'toggleLoading';
export const UPDATE_ERRORS = 'updateErrors';
export const UPDATE_HAS_ERRORS = 'updateHasErrors';

export const FormActions = {
  acceptForm: createAction<typeof FORM_ACCEPT>(FORM_ACCEPT),
  rejectForm: createAction<typeof FORM_REJECT>(FORM_REJECT),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reset: createActionPayload<typeof RESET, { values: any }>(RESET),
  submitCancel: createAction<typeof SUBMIT_CANCEL>(SUBMIT_CANCEL),
  submitError: createActionPayload<typeof SUBMIT_ERROR, { errors: Dictionary }>(
    SUBMIT_ERROR
  ),
  submitErrorWithoutSubmitting: createActionPayload<
    typeof SUBMIT_ERROR_WITHOUT_SUBMITTING,
    { errors: Dictionary }
  >(SUBMIT_ERROR_WITHOUT_SUBMITTING),
  submitStart: createAction<typeof SUBMIT_START>(SUBMIT_START),
  submitSuccess: createAction<typeof SUBMIT_SUCCESS>(SUBMIT_SUCCESS),
  toggleLoading: createAction<typeof TOGGLE_LOADING>(TOGGLE_LOADING),
  updateErrors: createActionPayload<
    typeof UPDATE_ERRORS,
    { errors: Dictionary }
  >(UPDATE_ERRORS),
  updateHasErrors: createAction<typeof UPDATE_HAS_ERRORS>(UPDATE_HAS_ERRORS),
  updateValues: createActionPayload<
    typeof UPDATE_VALUES,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { values: any }
  >(UPDATE_VALUES),
  updateValuesFromInput: createActionPayload<
    typeof UPDATE_VALUES_FROM_INPUT,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { values: any }
  >(UPDATE_VALUES_FROM_INPUT)
};

export type Actions<T> = ActionsWithPayload<string, ReducerFormState<T>>;

export type AcceptedActions = ActionsUnion<typeof FormActions>;

export function reducerForm<T>(
  state: ReducerFormState<T>,
  action: AcceptedActions
): ReducerFormState<T> {
  const { type, payload } = action as Actions<T>;
  switch (type) {
    case FORM_ACCEPT:
      return {
        ...state,
        hasAccepted: true,
        isLoading: true,
        isSubmitting: true
      };
    case FORM_REJECT:
      return {
        ...state,
        hasAccepted: false,
        isLoading: false,
        isSubmitting: false,
        refreshErrors: false
      };
    case RESET:
      return { values: payload.values, ...defaultValues };
    case SUBMIT_START:
      return {
        ...state,
        isLoading: true,
        isSubmitting: true,
        refreshErrors: true
      };
    case SUBMIT_ERROR:
      return {
        ...state,
        errors: payload.errors,
        isLoading: false,
        isSubmitting: false
      };
    case SUBMIT_ERROR_WITHOUT_SUBMITTING:
      return {
        ...state,
        errors: payload.errors,
        isLoading: false,
        isSubmitting: false,
        refreshErrors: true
      };
    case SUBMIT_CANCEL:
      return { ...state, isLoading: false, isSubmitting: false };
    case SUBMIT_SUCCESS:
      return {
        ...state,
        hasAccepted: false,
        hasChanged: false,
        isLoading: false,
        isSubmitting: false,
        isSucceeding: true,
        refreshErrors: false
      };
    case TOGGLE_LOADING:
      return { ...state, isLoading: !state.isLoading };
    case UPDATE_VALUES:
      return {
        ...state,
        values: { ...state.values, ...payload.values }
      };
    case UPDATE_VALUES_FROM_INPUT:
      return {
        ...state,
        hasChanged:
          JSON.stringify(state.values) !== JSON.stringify(payload.values),
        values: { ...state.values, ...payload.values }
      };
    case UPDATE_ERRORS:
      return {
        ...state,
        errors: payload.errors
      };
    case UPDATE_HAS_ERRORS:
      return {
        ...state,
        hasErrors: true
      };
    default:
      throw new Error();
  }
}

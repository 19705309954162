import { FC, Fragment } from 'react';
import { useRecoilValue } from 'recoil';

import { useActionBarContext } from '@/components/app/actionBar/ActionBarContext';
import { sidebarSelector } from '@/components/state/SidebarState';

import { Layout, Wrapper } from './ActionBar.styles';
import SaveBox from './SaveBox';

type ActionBarProps = {
  isSidebarVisible: boolean;
};

const ActionBar: FC<ActionBarProps> = ({ isSidebarVisible }) => {
  const { actionBar } = useActionBarContext();

  const isOpen = useRecoilValue(sidebarSelector);

  const { saveOptions } = actionBar;
  const nbBox = [saveOptions].filter(Boolean).length + 1;

  return (
    <Fragment>
      {nbBox > 1 && (
        <Layout data-test="action-bar-layout">
          <Wrapper isOpen={isOpen} isSidebarVisible={isSidebarVisible}>
            {saveOptions && <SaveBox {...saveOptions} />}
          </Wrapper>
        </Layout>
      )}
    </Fragment>
  );
};

export default ActionBar;

import { capitalizeEachWord } from '@/components/buyerbase/utils';
import { getFullName } from '@proprioo/hokkaido';
import { formatInternationalPhone } from '@proprioo/salatim';

export const getUserName = (firstname: string, lastname: string): string => {
  if (firstname || lastname) {
    return getFullName(
      capitalizeEachWord(firstname),
      capitalizeEachWord(lastname)
    );
  }

  return '';
};

export const getPhone = (hasAccess: boolean, phone: string) => {
  if (!phone) return '';

  const formattedPhone = formatInternationalPhone(phone);

  if (hasAccess) {
    return formattedPhone;
  } else {
    return formattedPhone.replace(/[^\s]*.{6}$/, '** ** **');
  }
};

export const getEmail = (hasAccess: boolean, email: string) => {
  if (!email) return '';

  if (hasAccess) {
    return email;
  } else {
    return email.replace(/^.+(?=@)/, '**********');
  }
};

import { atom, selector } from 'recoil';

const googleMapsAtom = atom<boolean>({
  default: false,
  key: 'googleMapsAtom'
});

export const googleMapsSelector = selector<boolean>({
  get: ({ get }) => get(googleMapsAtom),
  key: 'googleMapsSelector',
  set: ({ set }, menuValue) => set(googleMapsAtom, menuValue)
});

import { useMemo } from 'react';

import { Permission } from '@/components/state/interfaces';
import { DictionaryBoolean } from '@/utils/interfaces';
import { Dictionary } from '@proprioo/hokkaido';

import { useAuth } from '../auth/useAuth';
import { useIsTechRole } from '../auth/useIsTechRole';

export const useUserSectionsPermissions = (sections: Dictionary) => {
  const { user } = useAuth();

  const isTechRole = useIsTechRole();

  return useMemo(
    () =>
      Object.values(sections).reduce<DictionaryBoolean>((acc, section) => {
        const userSectionPermissions =
          user?.permissions.sections[section] || [];

        acc[section] = !isTechRole
          ? userSectionPermissions.includes(Permission.READ)
          : true;

        return acc;
      }, {}),
    [isTechRole, sections, user?.permissions]
  );
};

import styled from 'styled-components';

import { breakpoints, convertPxToRem } from '@proprioo/salatim';

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${breakpoints.base}px) {
    flex-direction: row;
    max-width: 80%;
    padding: 0 ${convertPxToRem(12)};
    height: ${convertPxToRem(40)};
  }

  @media (min-width: ${breakpoints.normal}px) {
    padding: 0 ${convertPxToRem(24)};
    max-width: 70%;
  }
`;

import { TFunction, withTranslation } from 'next-i18next';
import Link from 'next/link';
import { Component, ErrorInfo, ReactNode } from 'react';

import ReplayIcon from '@/assets/icons/replay.svg';
import { logError } from '@/utils/log';
import { Button, H3 } from '@proprioo/salatim';

import {
  Layout,
  Paragraph,
  TitleImage,
  TitleWrapper
} from '../globalError/GlobalError.styles';

type Props = {
  t: TFunction;
  children?: ReactNode;
};

type State = {
  hasError: boolean;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log({ error, errorInfo });
    logError(error.message, { error, errorInfo });
    this.setState({ hasError: true });
  }

  render() {
    const { children, t } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <Layout data-test="error-page">
          <TitleImage>
            <span>{404}</span>
          </TitleImage>
          <TitleWrapper>
            <H3 title={t('anErrorOccured')} isAlternative={true} />
          </TitleWrapper>
          <Paragraph>{t('notFoundPage')}</Paragraph>
          <Link href="/">
            <Button label={t('backToHomePage')} icon={<ReplayIcon />} />
          </Link>
        </Layout>
      );
    }

    return children;
  }
}

export default withTranslation('global')(ErrorBoundary);

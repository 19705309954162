import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import qs from 'qs';
import {
  Dispatch,
  FC,
  Fragment,
  KeyboardEvent,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import SearchIcon from '@/assets/icons/search.svg';
import { capitalizeEachWord } from '@/components/buyerbase/utils';
import { SearchResultsTab } from '@/components/searchResults/interfaces';
import { EVENT_NAME, trackEvent } from '@/utils/mixPanel';
import { toastWarning } from '@/utils/notification';
import {
  Button,
  ButtonSizes,
  useMobileDevice,
  useOnClickOutside
} from '@proprioo/salatim';

import { SearchState, SearchType } from '../interfaces';
import {
  getSelectedTypePlaceHolderLabel,
  MIN_CHARACTERS_VALUE
} from '../Search.utils';
import {
  OptionLabel,
  OptionLayout,
  OptionsContainer,
  OptionsLayout
} from '../searchSelect/SearchSelect.styles';
import { ButtonLayout, Input, InputLayout } from './SearchInput.styles';

export type SearchInputProps = {
  searchValue: string;
  selectedType: string;
  updateReducer: Dispatch<Partial<SearchState>>;
  onCallback?(): void;
  suggestions?: string[];
};

const SearchInput: FC<SearchInputProps> = ({
  searchValue,
  selectedType,
  suggestions,
  onCallback,
  updateReducer
}) => {
  const { t } = useTranslation();
  const { pathname, push } = useRouter();

  const isMobile = useMobileDevice();

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const searchInputLayout = useRef<HTMLDivElement>(null);

  useOnClickOutside(searchInputLayout, ({ target }) => {
    if (isFocused && !searchInputLayout.current?.contains(target as Node)) {
      setIsFocused(false);
      document.getElementById('search-bar-input')?.blur();
    }
  });

  const isSearchResultPage = pathname === '/search-results';

  const resetInput = () => {
    setIsFocused(false);
    updateReducer({ searchValue: '', selectedType: SearchType.ALL_TYPES });
  };

  const handlePushOnResultsPage = async (
    text: string,
    isAutocompleted = false
  ) => {
    const searchParams = {
      autocompleted: isAutocompleted,
      isMobile,
      searchType: selectedType,
      tab: SearchResultsTab.ALL,
      textSearch: text
    };

    const params = qs.stringify(searchParams, {
      addQueryPrefix: true,
      encode: false
    });

    trackEvent(EVENT_NAME.SEARCH, searchParams);

    await push(`/search-results${params}`, undefined, {
      shallow: isSearchResultPage
    });

    if (isSearchResultPage) {
      resetInput();
    }

    if (onCallback) {
      onCallback();
    }
  };

  const handleSearch = () => {
    if (searchValue.length <= MIN_CHARACTERS_VALUE) {
      return toastWarning('minCharactersToSearch');
    }

    handlePushOnResultsPage(searchValue);
    document.getElementById('search-bar-input')?.blur(); // Do not forget to unfocus input field in this case
  };

  const handleOnEnterKeyPress = async ({ code }: KeyboardEvent) => {
    if (code === 'Enter') {
      if (searchValue.length <= MIN_CHARACTERS_VALUE) {
        return toastWarning('minCharactersToSearch');
      }

      handleSearch();
    }
  };

  useEffect(() => {
    return () => {
      if (!isSearchResultPage) {
        resetInput();
      }
    };
  }, []);

  const suggestionsComponents = useMemo(
    () => (
      <Fragment>
        {isFocused &&
          suggestions &&
          Boolean(suggestions?.length) &&
          searchValue.length > MIN_CHARACTERS_VALUE && (
            <OptionsLayout data-test="search-suggestions">
              <OptionsContainer>
                {suggestions.map((suggestion, index) => (
                  <OptionLayout
                    isSelected={false}
                    key={`suggestion-${index}-${suggestion}`}
                    role="option"
                    onClick={() => handlePushOnResultsPage(suggestion, true)}
                  >
                    <OptionLabel>
                      {selectedType === SearchType.NAME
                        ? capitalizeEachWord(suggestion)
                        : suggestion}
                    </OptionLabel>
                  </OptionLayout>
                ))}
              </OptionsContainer>
            </OptionsLayout>
          )}
      </Fragment>
    ),
    [isFocused, searchValue, suggestions]
  );

  return (
    <Fragment>
      <InputLayout ref={searchInputLayout}>
        <SearchIcon aria-hidden={true} />
        <Input
          aria-label={t(
            getSelectedTypePlaceHolderLabel(selectedType as SearchType)
          )}
          autoComplete="off"
          data-test="search-bar-input"
          id="search-bar-input"
          name="search-bar-input"
          onChange={({ target }) =>
            updateReducer({ searchValue: target.value })
          }
          onFocus={() => setIsFocused(true)}
          onKeyDown={handleOnEnterKeyPress}
          placeholder={t(
            getSelectedTypePlaceHolderLabel(selectedType as SearchType)
          )}
          type="search"
          value={searchValue}
        />
        {suggestionsComponents}
      </InputLayout>
      {isMobile && (
        <ButtonLayout>
          <Button
            label={t('search')}
            icon={<SearchIcon />}
            size={ButtonSizes.SMALL}
            onClick={handleSearch}
          />
        </ButtonLayout>
      )}
    </Fragment>
  );
};

export default SearchInput;

import styled from 'styled-components';

import { convertPxToRem } from '@proprioo/salatim';

export const Layout = styled.div`
  display: flex;
  align-items: start;
  flex-wrap: wrap;
  padding: 0 0 ${convertPxToRem(16)} ${convertPxToRem(16)};
  background-color: white;

  > div {
    margin: ${convertPxToRem(16)} ${convertPxToRem(32)} 0 0;
  }
`;

import { isToday } from 'date-fns/isToday';
import { useTranslation } from 'next-i18next';
import { FC, Fragment } from 'react';

import { getScoreTranslation } from '@/components/buyer/utils';
import { getScoreCellColor } from '@/components/buyerbase/utils';
import { colors } from '@proprioo/salatim';

import { Scores } from '../../../interfaces';
import { ScoreTag } from './ScoresCell.styles';

export type BuyersCellProps = {
  pigeCreatedAt: string;
  scores: Scores[];
};

const ScoresCell: FC<BuyersCellProps> = ({ pigeCreatedAt, scores }) => {
  const { t } = useTranslation();

  return scores.length ? (
    <Fragment>
      {scores
        .filter(({ count }) => Boolean(count))
        .map(({ count, score }, index) => (
          <ScoreTag key={index} {...getScoreCellColor(score)}>
            {t('valueWithUnit', {
              unit: t(getScoreTranslation(score)),
              value: count
            })}
          </ScoreTag>
        ))}
    </Fragment>
  ) : (
    <ScoreTag
      backgroundColor={colors.grey.base10}
      textColor={colors.dark.base60}
    >
      {isToday(new Date(pigeCreatedAt))
        ? t('buyersCalculationAvailableTomorrow')
        : t('buyersCalculationError')}
    </ScoreTag>
  );
};

export default ScoresCell;

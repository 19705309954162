import { differenceInDays } from 'date-fns/differenceInDays';
import { isPast } from 'date-fns/isPast';

import { queryParamToArray } from '@/utils/helpers';
import {
  EstimationStage,
  getArraysIntersection,
  PriorityEnum
} from '@proprioo/hokkaido';

import {
  LeadViewSection,
  QueryParam,
  SaleOpportunitiesPerTab,
  SaleOpportunity
} from '../../interfaces';
import {
  LeadViewFilters,
  LeadViewFiltersCount,
  LeadViewFilterType
} from './interfaces';

export const DEFAULT_LEAD_VIEW_FILTERS: LeadViewFilters = {
  hasLateSignature: false,
  hasListingWithoutVisit: false,
  hasOldPrice: false,
  hasPausedListing: false,
  hasSignatureToSchedule: false,
  isHighPriorityLead: false,
  isNearingExclusivityEnd: false
};

export const isHighPriorityLead = ({
  treatmentPriority
}: SaleOpportunity): boolean => treatmentPriority === PriorityEnum.IMMEDIATE;

export const hasListingWithoutVisits = ({
  nbFuturVisitsAndPrevious7Days
}: SaleOpportunity): boolean => !nbFuturVisitsAndPrevious7Days;

export const hasPausedListing = ({
  estimationStage,
  currentStatusPassageDate
}: SaleOpportunity): boolean =>
  // 15 days since the ad is offline
  Boolean(
    estimationStage === EstimationStage.PAUSED &&
      currentStatusPassageDate &&
      differenceInDays(new Date(), new Date(currentStatusPassageDate)) > 15
  );

export const isNearingExclusivityEnd = ({
  exclusivityDate
}: SaleOpportunity): boolean => {
  // less than 15 days until exclusivity ends - still exclusive
  if (!exclusivityDate) return false;

  const timeToExclusivityEnd = differenceInDays(
    new Date(exclusivityDate),
    new Date()
  );

  return Boolean(timeToExclusivityEnd < 15 && timeToExclusivityEnd > 0);
};

export const hasOldPrice = ({
  firstPublicationDate,
  latestContractSignatureDate
}: SaleOpportunity): boolean => {
  //at least 30 days since the last price update
  const latestPriceUpdateDate =
    latestContractSignatureDate || firstPublicationDate;

  return Boolean(
    latestPriceUpdateDate &&
      differenceInDays(new Date(), new Date(latestPriceUpdateDate)) >= 30
  );
};

export const hasSignatureToSchedule = ({
  estimationStage,
  finalSaleDate,
  deedSalesDate
}: SaleOpportunity): boolean =>
  (estimationStage === EstimationStage.SOLD && !deedSalesDate) ||
  (estimationStage === EstimationStage.COMPROMISE && !finalSaleDate);

export const hasLateSignature = ({
  estimationStage,
  finalSaleDate,
  deedSalesDate
}: SaleOpportunity): boolean =>
  Boolean(
    estimationStage === EstimationStage.SOLD &&
      deedSalesDate &&
      isPast(new Date(deedSalesDate))
  ) ||
  Boolean(
    estimationStage === EstimationStage.COMPROMISE &&
      finalSaleDate &&
      isPast(new Date(finalSaleDate))
  );

export const getMatchingFilterMethod = (filter: LeadViewFilterType) => {
  switch (filter) {
    case LeadViewFilterType.IS_HIGH_PRIORITY_LEAD:
      return isHighPriorityLead;
    case LeadViewFilterType.IS_NEARING_EXCLUSIVITY_END:
      return isNearingExclusivityEnd;
    case LeadViewFilterType.HAS_LATE_SIGNATURE:
      return hasLateSignature;
    case LeadViewFilterType.HAS_LISTING_WITHOUT_VISIT:
      return hasListingWithoutVisits;
    case LeadViewFilterType.HAS_OLD_PRICE:
      return hasOldPrice;
    case LeadViewFilterType.HAS_PAUSED_LISTING:
      return hasPausedListing;
    case LeadViewFilterType.HAS_SIGNATURE_TO_SCHEDULE:
      return hasSignatureToSchedule;
    default:
      return () => true;
  }
};

export const hasNoActiveFilter = (filters: LeadViewFilters) =>
  Object.values(filters).filter(Boolean).length === 0;

export const filterLeadOpportunities = (
  opportunities: SaleOpportunity[],
  currentFilters: LeadViewFilters
): SaleOpportunity[] => {
  if (hasNoActiveFilter(currentFilters)) return opportunities;

  const filteredOpportunities = Object.entries(currentFilters)
    .filter(([, isActive]) => isActive)
    .map(([filter]) => {
      const filterFunction = getMatchingFilterMethod(
        filter as LeadViewFilterType
      );

      return opportunities.filter(opportunity => filterFunction(opportunity));
    });

  return getArraysIntersection<SaleOpportunity>(filteredOpportunities);
};

export const getActiveFilters = (filters: LeadViewFilters) =>
  Object.entries(filters)
    .filter(([, isActive]) => isActive)
    .map(([filterName]) => filterName.toString());

export const getFiltersFromUrlParams = (
  urlFilters?: QueryParam
): LeadViewFilters => {
  if (!urlFilters) return DEFAULT_LEAD_VIEW_FILTERS;

  const filterNames = queryParamToArray(urlFilters);

  const validFilters = filterNames.filter(filter =>
    Object.values(LeadViewFilterType).includes(filter as LeadViewFilterType)
  );

  return {
    ...DEFAULT_LEAD_VIEW_FILTERS,
    ...Object.fromEntries(validFilters.map(filter => [filter, true]))
  };
};

export const getSectionFilters = (
  section: LeadViewSection,
  filters: LeadViewFilters,
  originalFilters = DEFAULT_LEAD_VIEW_FILTERS
) => {
  const {
    isHighPriorityLead,
    hasListingWithoutVisit,
    hasPausedListing,
    isNearingExclusivityEnd,
    hasOldPrice,
    hasSignatureToSchedule,
    hasLateSignature
  } = filters;

  switch (section) {
    case LeadViewSection.PROSPECTS:
      return { ...originalFilters, isHighPriorityLead };
    case LeadViewSection.SELLING:
      return {
        ...originalFilters,
        hasListingWithoutVisit,
        hasOldPrice,
        hasPausedListing,
        isNearingExclusivityEnd
      };
    case LeadViewSection.SALE:
      return {
        ...originalFilters,
        hasLateSignature,
        hasSignatureToSchedule
      };
    default:
      return originalFilters;
  }
};

export const countOpportunitiesPerFilter = ({
  PROSPECTS_ALL,
  SELLING_ALL,
  SALE_ALL
}: SaleOpportunitiesPerTab): LeadViewFiltersCount => ({
  highPriorityLeadsCount: PROSPECTS_ALL.filter(opportunity =>
    isHighPriorityLead(opportunity)
  ).length,
  lateSignaturesCount: SALE_ALL.filter(opportunity =>
    hasLateSignature(opportunity)
  ).length,
  listingsWithoutVisitCount: SELLING_ALL.filter(opportunity =>
    hasListingWithoutVisits(opportunity)
  ).length,
  nearingExclusivityEndCount: SELLING_ALL.filter(opportunity =>
    isNearingExclusivityEnd(opportunity)
  ).length,
  oldPricesCount: SELLING_ALL.filter(opportunity => hasOldPrice(opportunity))
    .length,
  pausedListingsCount: SELLING_ALL.filter(opportunity =>
    hasPausedListing(opportunity)
  ).length,
  signaturesToScheduleCount: SALE_ALL.filter(opportunity =>
    hasSignatureToSchedule(opportunity)
  ).length
});

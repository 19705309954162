import { RichTextBlock } from 'prismic-reactjs';

export enum BannerLevel {
  ERROR = 'Error',
  INFO = 'Info',
  RESOLVED = 'Resolved',
  WARNING = 'Warning'
}

export type PrismicBanner = {
  level: BannerLevel;
  text: RichTextBlock[];
};

import { useTranslation } from 'next-i18next';
import { useRecoilValue } from 'recoil';

import CalendarIcon from '@/assets/vectors/menu/calendar-today.svg';
import { menuSelector } from '@/components/state/MenuState';
import { EXTERNAL_TOOLS } from '@/constants/global';

import { ItemLabel, ToolItem } from '../../menuItems/MenuItems.styles';

const CalendarItem = () => {
  const { t } = useTranslation();

  const isOpen = useRecoilValue(menuSelector);

  return (
    <a
      href={EXTERNAL_TOOLS.GOOGLE_CALENDAR}
      id="sidebar-menu_calendar"
      rel="noopener noreferrer"
      target="_blank"
    >
      <ToolItem isOpen={isOpen}>
        <CalendarIcon />
        <ItemLabel>{t('myCalendar')}</ItemLabel>
      </ToolItem>
    </a>
  );
};

export default CalendarItem;

import { useAgentById } from '@/hooks/agents/useAgentById';

export const useAgentAvatarById = (agentId?: number) => {
  const agent = useAgentById(agentId);

  if (!agent?.id) {
    return null;
  }

  return agent.picture;
};

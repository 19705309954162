import { isBefore } from 'date-fns/isBefore';
import { isToday } from 'date-fns/isToday';
import { parseISO } from 'date-fns/parseISO';
import { startOfDay } from 'date-fns/startOfDay';

import { CustomerEvent } from './myCustomers.interfaces';
import { QueryParam } from './mySellers/interfaces';

export const DEFAULT_TABLE_PAGE_SIZE = 30;

export const getPageIndexFromUrlParam = (page?: QueryParam): number => {
  const pageIndex = parseInt(`${page}`, 10);

  return !isNaN(pageIndex) ? pageIndex : 1;
};

export const sortByDate = (dateA?: string, dateB?: string): number => {
  const now = new Date();

  return isBefore(dateA ? parseISO(dateA) : now, dateB ? parseISO(dateB) : now)
    ? -1
    : 1;
};

export const getDateWithoutTimezone = (dateValue: string): Date => {
  if (dateValue.endsWith('Z')) {
    return new Date(dateValue.substring(0, dateValue.length - 1));
  }

  return parseISO(dateValue);
};

export const isEventLate = (event?: CustomerEvent): boolean =>
  !!event && isBefore(parseISO(event.targetDate), startOfDay(new Date()));

export const isEventToday = (event?: CustomerEvent): boolean =>
  !!event && isToday(parseISO(event.targetDate));

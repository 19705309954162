import { SearchType } from './interfaces';

export const MIN_CHARACTERS_VALUE = 2;

export const getSelectedTypePlaceHolderLabel = (selectedType: SearchType) => {
  switch (selectedType) {
    case SearchType.ADDRESS:
      return 'addressPlaceholder';
    case SearchType.ALL_TYPES:
      return 'allTypesPlaceholder';
    case SearchType.EMAIL:
      return 'searchEmailPlaceholder';
    case SearchType.ID:
      return 'idPlaceholder';
    case SearchType.NAME:
      return 'namePlaceholder';
    case SearchType.PHONE:
      return 'phonePlaceholder';
  }
};

import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import qs from 'qs';
import { FC, Fragment, useEffect } from 'react';

import { DEFAULT_TABLE_PAGE_SIZE } from '@/components/myCustomers/myCustomers.utils';
import { useCurrentAgent } from '@/hooks/agents/useCurrentAgent';
import { PAGE_VIEW_TITLE } from '@/utils/gtm';
import { QS_ARRAY_PARAMS } from '@/utils/helpers';
import { EVENT_NAME, trackEvent } from '@/utils/mixPanel';
import { generateButtonId } from '@/utils/tracking';
import { Button, useSmartphoneDevice } from '@proprioo/salatim';

import { LeadViewTab } from '../../interfaces';
import { ButtonWrapper, NoOpportunitiesLayout } from './ExternalLeads.styles';
import ExternalLeadsDesktop from './externalLeadsDesktop/ExternalLeadsDesktop';
import ExternalLeadsMobile from './externalLeadsMobile/ExternalLeadsMobile';
import { ExternalLeadsProps } from './interfaces';

const ExternalLeads: FC<ExternalLeadsProps> = props => {
  const { t } = useTranslation();
  const { asPath } = useRouter();

  const isSmartphone = useSmartphoneDevice();
  const currentAgent = useCurrentAgent();

  const params = qs.stringify(
    { agentIds: [currentAgent?.id] },
    QS_ARRAY_PARAMS
  );

  const { currentTab, isLoading, opportunities } = props;
  const nbOfOpportunities = opportunities.length;

  useEffect(() => {
    if (currentTab === LeadViewTab.LEADS_ALL_EXTERNAL) {
      const desktopImpressions =
        nbOfOpportunities > DEFAULT_TABLE_PAGE_SIZE
          ? DEFAULT_TABLE_PAGE_SIZE
          : nbOfOpportunities;

      trackEvent(EVENT_NAME.OUTBOUND_PROSP_SEARCH, {
        impressions: isSmartphone ? nbOfOpportunities : desktopImpressions,
        pagePage: asPath,
        pageTitle: PAGE_VIEW_TITLE.LEADS
      });
    }
  }, [currentTab, nbOfOpportunities]);

  if (!opportunities.length && !isLoading) {
    return (
      <NoOpportunitiesLayout>
        <div>{t('noExternalLeadsConsultBusinessStockExchange')}</div>
        {currentAgent?.id && (
          <ButtonWrapper>
            <Link
              href={`/business-stock-exchange${params}`}
              id={generateButtonId(
                PAGE_VIEW_TITLE.LEADS_EXTERNAL,
                'goToBusinessStockExchange'
              )}
            >
              <Button label={t('businessStockExchange')} />
            </Link>
          </ButtonWrapper>
        )}
      </NoOpportunitiesLayout>
    );
  }

  return (
    <Fragment>
      {isSmartphone ? (
        <ExternalLeadsMobile {...props} />
      ) : (
        <ExternalLeadsDesktop {...props} />
      )}
    </Fragment>
  );
};

export default ExternalLeads;

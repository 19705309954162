import { FC, PropsWithChildren } from 'react';

import { CustomerEvent } from '../../myCustomers.interfaces';
import Activity from '../activity/Activity';
import ActivityIndicator from '../activityIndicator/ActivityIndicator';
import CustomerName from '../customerName/CustomerName';
import { PrimaryInfo, SecondaryInfo } from '../styles/MyCustomers.styles';
import {
  ActivityWrapper,
  Layout,
  MainContentWrapper,
  NextEventWrapper
} from './MobileCard.styles';

export type MobileCardProps = {
  link: string;
  firstName?: string;
  isNextEventVisible?: boolean;
  isLastEventVisible?: boolean;
  lastEvent?: CustomerEvent;
  lastName?: string;
  nextEvent?: CustomerEvent;
};

const MobileCard: FC<PropsWithChildren<MobileCardProps>> = ({
  children,
  firstName,
  isLastEventVisible = true,
  isNextEventVisible = true,
  lastEvent,
  lastName,
  link,
  nextEvent
}) => (
  <Layout data-test="mobile-card" href={link}>
    {nextEvent?.targetDate && (
      <ActivityWrapper>
        <ActivityIndicator targetDate={nextEvent?.targetDate} />
      </ActivityWrapper>
    )}
    <MainContentWrapper>
      <CustomerName
        firstName={firstName}
        isImportant={true}
        lastName={lastName}
      />
      {isLastEventVisible && (
        <div>
          <SecondaryInfo data-test="last-event">
            <Activity event={lastEvent} isMobile={true} />
          </SecondaryInfo>
        </div>
      )}
    </MainContentWrapper>
    {children}
    <MainContentWrapper>
      {isNextEventVisible && (
        <NextEventWrapper>
          <PrimaryInfo data-test="next-event">
            <Activity
              event={nextEvent}
              isDateHighlighted={true}
              isMobile={true}
            />
          </PrimaryInfo>
        </NextEventWrapper>
      )}
    </MainContentWrapper>
  </Layout>
);

export default MobileCard;

import styled from 'styled-components';

import { TableWrapper } from '@/components/myCustomers/common/styles/Table.styles';
import { convertPxToRem } from '@proprioo/salatim';

export const AlertCardWrapper = styled.div`
  margin-top: ${convertPxToRem(24)};
`;

export const NoOpportunitiesLayout = styled(TableWrapper)`
  padding: ${convertPxToRem(40)} ${convertPxToRem(16)};
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  margin-top: ${convertPxToRem(12)};
`;

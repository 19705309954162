import { ReactElement } from 'react';

import Cave from '@/assets/icons/cave.svg';
import Elevator from '@/assets/icons/elevator.svg';
import Ground from '@/assets/icons/ground.svg';
import Parking from '@/assets/icons/parking.svg';
import Pool from '@/assets/icons/pool.svg';
import Renovation from '@/assets/icons/renovation.svg';
import Terrace from '@/assets/icons/terrace.svg';
import { OtherCriteriasProps } from '@/components/buyerbase/filters/otherCriterias/OtherCriterias';

type Criteria = {
  name: string;
  text: string;
  dataTest: string;
  image: ReactElement;
};

export const DEFAULT_OTHER_CRITERIAS: OtherCriteriasProps = {
  groundFloor: false,
  hasCellarOrAnnex: false,
  hasLift: false,
  hasOutdoorSpace: false,
  hasParking: false,
  hasPool: false,
  withoutRenovationWork: false
};

export const CRITERIAS: Record<string, Criteria> = {
  groundFloor: {
    dataTest: 'criteriaGroundFloor',
    image: <Ground />,
    name: 'groundFloor',
    text: 'groundFloor'
  },
  hasCellarOrAnnex: {
    dataTest: 'criteriaCaveAnnex',
    image: <Cave />,
    name: 'cellarOrAnnex',
    text: 'cellarOrAnnex'
  },
  hasLift: {
    dataTest: 'criteriaElevator',
    image: <Elevator />,
    name: 'elevator',
    text: 'elevator'
  },
  hasOutdoorSpace: {
    dataTest: 'criteriaBalconySurface',
    image: <Terrace />,
    name: 'balconyTerrace',
    text: 'balconyTerrace'
  },
  hasParking: {
    dataTest: 'criteriaParkingBox',
    image: <Parking />,
    name: 'parkingOrBox',
    text: 'parkingOrBox'
  },
  hasPool: {
    dataTest: 'criteriaPool',
    image: <Pool />,
    name: 'pool',
    text: 'pool'
  },
  withoutRenovationWork: {
    dataTest: 'criteriaNoRenovation',
    image: <Renovation />,
    name: 'noWork',
    text: 'noWork'
  }
};

import { UserRole } from '@/components/state/interfaces';
import { hasRole } from '@/components/state/utils';

import { useAuth } from './useAuth';

export const useIsSellerSuccessRole = (): boolean => {
  const { user } = useAuth();

  return hasRole(UserRole.SELLER_SUCCESS, user);
};

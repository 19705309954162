import { isBefore } from 'date-fns/isBefore';
import { isToday } from 'date-fns/isToday';

import {
  DangerInfo,
  ImportantInfo,
  SecondaryInfo
} from '@/components/myCustomers/common/styles/MyCustomers.styles';

export const getDateWrapper = (
  isAllDay: boolean,
  isToBeDone: boolean,
  targetDate: Date
) => {
  const isDateToday = isToday(targetDate);
  const isDatePast = isBefore(targetDate, new Date());
  const isDateLate = isAllDay ? !isDateToday && isDatePast : isDatePast;

  if (isToBeDone) {
    if (isDateLate) {
      return DangerInfo;
    }

    return isDateToday ? ImportantInfo : SecondaryInfo;
  }

  return SecondaryInfo;
};

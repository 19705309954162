import styled from 'styled-components';

import { breakpoints, colors, convertPxToRem } from '@proprioo/salatim';

export const TOPBAR_HEIGHT = convertPxToRem(64);
export const TOPBAR_MOBILE_HEIGHT = convertPxToRem(48);

export const NavigationContainer = styled.aside`
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: white;
  justify-content: space-between;
  padding: ${convertPxToRem(16)};
  border-bottom: ${convertPxToRem(1)} solid ${colors.dark.base10};

  @media (min-width: ${breakpoints.xsmall}px) {
    padding: ${convertPxToRem(16)} ${convertPxToRem(24)};
  }
`;

export const NavigationTitleTag = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  max-width: 50%;

  @media (min-width: ${breakpoints.xsmall}px) {
    max-width: unset;
  }

  h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

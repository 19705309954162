import { ReactElement } from 'react';

import {
  ActionsUnion,
  ActionsWithPayload,
  createActionPayload
} from '@/utils/actions';

import { SaveProps } from './ActionBarContext.interfaces';

export type ReducerActionBarState = {
  saveOptions?: SaveProps;
  commentBar?: ReactElement;
  showReminder?: boolean;
  showComment?: boolean;
};

const INIT_SAVE_OPTIONS = 'initSaveOptions';
const UPDATE_SAVE_OPTIONS = 'updateSaveOptions';

export const ActionBarContextActions = {
  initSaveOptions: createActionPayload<
    typeof INIT_SAVE_OPTIONS,
    { saveOptions: SaveProps }
  >(INIT_SAVE_OPTIONS),
  updateSaveOptions: createActionPayload<
    typeof UPDATE_SAVE_OPTIONS,
    { saveOptions: Partial<SaveProps> }
  >(UPDATE_SAVE_OPTIONS)
};

export type Actions = ActionsWithPayload<string, ReducerActionBarState>;

export type AcceptedActions = ActionsUnion<typeof ActionBarContextActions>;

export function reducerActionBarContext(
  state: ReducerActionBarState,
  action: AcceptedActions
): ReducerActionBarState {
  const { type, payload } = action as Actions;
  switch (type) {
    case INIT_SAVE_OPTIONS:
      return {
        ...state,
        saveOptions: payload.saveOptions
      };
    case UPDATE_SAVE_OPTIONS:
      if (state.saveOptions) {
        return {
          ...state,
          saveOptions: { ...state.saveOptions, ...payload.saveOptions }
        };
      }
      return state;
    default:
      throw new Error();
  }
}

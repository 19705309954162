import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { FC, Fragment, useState } from 'react';
import Cookies from 'universal-cookie';

import ConfirmModalButtons from '@/components/app/confirmModalButtons/ConfirmModalButtons';
import { COOKIE_KEY_LNG } from '@/constants/global';
import { LANGUAGES_OPTIONS } from '@/constants/languages';
import { useLanguage } from '@/hooks/useLanguage/useLanguage';
import { Language } from '@proprioo/hokkaido';

import {
  RadioButton,
  RadioLabel,
  RadioLayout,
  RadiosWrapper
} from './LanguageModal.styles';

export type LanguageModalProps = {
  closeModal(): void;
  toggleLoader(): void;
};

const LanguageModal: FC<LanguageModalProps> = ({
  closeModal,
  toggleLoader
}) => {
  const { t } = useTranslation();
  const { currentLanguage } = useLanguage();
  const { asPath, pathname, push, query } = useRouter();

  const [language, setLanguage] = useState<Language>(currentLanguage);

  const cookies = new Cookies();

  return (
    <Fragment>
      <RadiosWrapper>
        {Object.entries(LANGUAGES_OPTIONS).map(([key, { flagUrl, text }]) => (
          <RadioLayout key={key}>
            <RadioButton
              checked={language === key}
              data-test={`radio-${key}`}
              id={key}
              name="languages"
              onChange={() => setLanguage(key as Language)}
              type="radio"
              value={key}
            />
            <RadioLabel htmlFor={key}>
              <svg>
                <image xlinkHref={flagUrl} />
              </svg>
              {t(text)}
            </RadioLabel>
          </RadioLayout>
        ))}
      </RadiosWrapper>
      <ConfirmModalButtons
        onAccept={async () => {
          toggleLoader();
          cookies.set(COOKIE_KEY_LNG, language, { path: '/' });
          await push({ pathname, query }, asPath, { locale: language });
          closeModal();
        }}
        onReject={closeModal}
        textAccept="save"
        textReject="close"
      />
    </Fragment>
  );
};

export default LanguageModal;

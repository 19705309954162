import { EnvLabels } from '@/utils/interfaces';

export const getEnvTranslationKey = (envLabels: EnvLabels) => {
  switch (envLabels) {
    case EnvLabels.LOCAL:
      return 'env-local';
    case EnvLabels.PRODUCTION:
      return 'env-prod';
    case EnvLabels.SANDBOX:
      return 'env-sandbox';
    case EnvLabels.STAGING:
      return 'env-preprod';
    case EnvLabels.TEST:
      return 'env-test';
  }
};

import { FC } from 'react';

import { isCasavoSMPSource } from '@/components/app/leadTag/utils';
import { InlineItemsWrapper } from '@/components/myCustomers/common/styles/MyCustomers.styles';
import { MultilineCell } from '@/components/myCustomers/common/styles/Table.styles';
import LeadRevenueTag from '@/components/myCustomers/mySellers/leads/common/leadRevenueTag/LeadRevenueTag';
import ListingAddress from '@/components/myCustomers/mySellers/leads/common/listingAddress/ListingAddress';
import ListingIcon from '@/components/myCustomers/mySellers/leads/common/listingIcon/ListingIcon';
import ListingSpecs from '@/components/myCustomers/mySellers/leads/common/listingSpecs/ListingSpecs';
import { isClosed } from '@/components/myCustomers/mySellers/LeadView.utils';
import { FEATURE_NAME } from '@/hooks/useUserFeaturePermissions/interfaces';
import { useUserFeaturePermissions } from '@/hooks/useUserFeaturePermissions/useUserFeaturePermissions';

import { LeadViewTableCellProps } from '../interface';

export type ListingCellProps = LeadViewTableCellProps & {
  showRevenue: boolean;
};

const ListingCell: FC<ListingCellProps> = ({ opportunity, showRevenue }) => {
  const hasAccessToRevenueForecast = useUserFeaturePermissions(
    FEATURE_NAME.MY_SELLERS_REVENUE_FORECAST
  );

  const {
    address,
    chargedFees,
    commission,
    estimationStage,
    finalSalePrice,
    leadSource,
    listingType,
    listingId,
    price,
    roomsCount,
    surface,
    transactionId
  } = opportunity;

  const isApproximativeRevenue = !isClosed(estimationStage);

  const id =
    isCasavoSMPSource(leadSource) && transactionId ? transactionId : listingId;

  return (
    <MultilineCell>
      <ListingAddress {...address} isMultiline={false} />
      <InlineItemsWrapper>
        <ListingIcon listingId={id} listingType={listingType} />
        <ListingSpecs
          finalSalePrice={finalSalePrice}
          price={price}
          roomsCount={roomsCount}
          surface={surface}
        />
        {showRevenue && hasAccessToRevenueForecast && (
          <LeadRevenueTag
            chargedFees={chargedFees}
            commission={commission}
            hasEmptyPlaceholder={false}
            isApproximative={isApproximativeRevenue}
            price={price}
          />
        )}
      </InlineItemsWrapper>
    </MultilineCell>
  );
};

export default ListingCell;

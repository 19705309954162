import styled from 'styled-components';

import {
  breakpoints,
  colors,
  convertPxToRem,
  paragraphs
} from '@proprioo/salatim';

export const Layout = styled.div`
  text-align: center;
  height: 100%;
`;

export const TitleWrapper = styled.div`
  margin: ${convertPxToRem(18)} 0 ${convertPxToRem(8)};
  text-align: center;
`;

export const Paragraph = styled.p`
  ${paragraphs.body1}
  color: ${colors.dark.base};
  margin-bottom: ${convertPxToRem(32)};
`;

export const TitleImage = styled.h1`
  display: block;
  background-clip: padding-box;
  font-size: ${convertPxToRem(125)};
  color: ${colors.dark.base};
  text-shadow: 0 0 0 rgba(51, 51, 51, 0.15);
  transition: font-size 0.2s;
  background: url(/static/pictures/paris.jpg) center center;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;

  @media (min-width: ${breakpoints.small}px) {
    font-size: ${convertPxToRem(300)};
    line-height: ${convertPxToRem(250)};
  }
`;

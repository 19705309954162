import { useRouter } from 'next/router';

import { Language, LANGUAGE_LOCALES } from '@proprioo/hokkaido';

export const useLanguage = () => {
  const { locale = Language.ENGLISH } = useRouter();

  return {
    currentLanguage: locale as Language,
    currentLocale: LANGUAGE_LOCALES[locale as Language]
  };
};

import { Activity, ActivityType } from '@/components/activity/interfaces';
import { BuyerScore } from '@/components/buyer/interfaces';
import { DealerType } from '@/components/buyersMatching/interfaces';
import { FormattedHistory } from '@/components/externalLead/history/utils';
import { ListingQuality } from '@/components/listing/interfaces';
import { YanportAds } from '@/components/marketingPoint/interfaces';
import { AppointmentSubStatus } from '@/components/qualification/sellerQualification/interfaces';
import {
  EstimationStage,
  LeadSource,
  nullOrBoolean,
  nullOrNumber,
  nullOrString,
  PriorityEnum,
  PropertyEnum
} from '@proprioo/hokkaido';

export enum LeadViewSection {
  LEADS = 'LEADS',
  PROSPECTS = 'PROSPECTS',
  SELLING = 'SELLING',
  SALE = 'SALE',
  ARCHIVED = 'ARCHIVED'
}

export enum LeadViewTab {
  ARCHIVED_ALL = 'ARCHIVED_ALL',
  LEADS_ALL = 'LEADS_ALL',
  LEADS_ALL_EXTERNAL = 'LEADS_ALL_EXTERNAL',
  LEADS_ALL_EXTERNAL_ATTRIBUTED_PICKING = 'LEADS_ALL_EXTERNAL_ATTRIBUTED_PICKING',
  LEADS_ALL_EXTERNAL_ATTRIBUTED_PIGE = 'LEADS_ALL_EXTERNAL_ATTRIBUTED_PIGE',
  LEADS_ALL_EXTERNAL_EXPIRED = 'LEADS_ALL_EXTERNAL_EXPIRED',
  PROSPECTS_ALL = 'PROSPECTS_ALL',
  PROSPECTS_ESTIMATED = 'PROSPECTS_ESTIMATED',
  PROSPECTS_QUALIFIED = 'PROSPECTS_QUALIFIED',
  PROSPECTS_TO_QUALIFY = 'PROSPECTS_TO_QUALIFY',
  PROSPECTS_WITHOUT_MEETING = 'PROSPECTS_WITHOUT_MEETING',
  SALE_ALL = 'SALE_ALL',
  SALE_AUTHENTIC_ACT = 'SALE_AUTHENTIC_ACT',
  SALE_COMPROMISE = 'SALE_COMPROMISE',
  SALE_COUNTERSIGNED_OFFER = 'SALE_COUNTERSIGNED_OFFER',
  SELLING_ALL = 'SELLING_ALL',
  SELLING_ONLINE = 'SELLING_ONLINE',
  SELLING_ON_HOLD = 'SELLING_ON_HOLD',
  SELLING_TO_PUBLISH = 'SELLING_TO_PUBLISH'
}

export type PriceHistory = {
  date: string;
  price: number;
};

export enum MarketingDealerType {
  AGENCY = 'AGENCY',
  MANDATARY = 'MANDATARY',
  PRIVATE = 'PRIVATE'
}

export enum EventType {
  CREATION = 'creation',
  CONTACT_REQUEST = 'contactRequest',
  LEAD = 'lead',
  VALUATION = 'valuation',
  VISIT = 'visit'
}

export const TABS_MAPPING = {
  [LeadViewSection.LEADS]: [
    LeadViewTab.LEADS_ALL,
    LeadViewTab.LEADS_ALL_EXTERNAL,
    LeadViewTab.LEADS_ALL_EXTERNAL_ATTRIBUTED_PIGE,
    LeadViewTab.LEADS_ALL_EXTERNAL_ATTRIBUTED_PICKING,
    LeadViewTab.LEADS_ALL_EXTERNAL_EXPIRED
  ],
  [LeadViewSection.PROSPECTS]: [
    LeadViewTab.PROSPECTS_ALL,
    LeadViewTab.PROSPECTS_WITHOUT_MEETING,
    LeadViewTab.PROSPECTS_TO_QUALIFY,
    LeadViewTab.PROSPECTS_QUALIFIED,
    LeadViewTab.PROSPECTS_ESTIMATED
  ],
  [LeadViewSection.SALE]: [
    LeadViewTab.SALE_ALL,
    LeadViewTab.SALE_COUNTERSIGNED_OFFER,
    LeadViewTab.SALE_COMPROMISE,
    LeadViewTab.SALE_AUTHENTIC_ACT
  ],
  [LeadViewSection.SELLING]: [
    LeadViewTab.SELLING_ALL,
    LeadViewTab.SELLING_TO_PUBLISH,
    LeadViewTab.SELLING_ONLINE,
    LeadViewTab.SELLING_ON_HOLD
  ],
  [LeadViewSection.ARCHIVED]: [LeadViewTab.ARCHIVED_ALL]
};

export type SaleOpportunityEvent = {
  activityType: EventType | ActivityType;
  targetDate: string;
};

export type SaleOpportunity = {
  address: {
    streetNumber: string;
    street: string;
    postCode: string;
    city: string;
  };
  agentId: number;
  archiveDate?: string;
  chargedFees?: number;
  commission: number;
  currentStatusPassageDate?: string;
  estimationId: number;
  estimationStage: EstimationStage;
  exclusivityDate: nullOrString;
  deedSalesDate: nullOrString;
  finalSaleDate: nullOrString;
  finalSalePrice?: number;
  firstName?: string;
  firstPublicationDate: nullOrString;
  latestContractSignatureDate: nullOrString;
  lastAppointmentStatus: AppointmentSubStatus;
  lastEvent: SaleOpportunityEvent;
  lastName?: string;
  lastStage?: EstimationStage;
  leadSource: LeadSource;
  listingId?: string;
  listingType: PropertyEnum;
  nbFuturVisitsAndPrevious7Days: number;
  nextEvent?: SaleOpportunityEvent;
  price?: number;
  roomsCount?: number;
  surface: number;
  treatmentPriority?: PriorityEnum;
  transactionId: nullOrString;
  userId: string;
  viewingAgent: nullOrNumber;
};

export type SaleOpportunitiesPerTab = {
  [key in LeadViewTab]: SaleOpportunity[];
};

export enum LeadViewTableColumns {
  // Common columns
  PAST_EVENT = 'pastEvent',
  UPCOMING_EVENT = 'upcomingEvent',

  // Tablet specific columns
  CONTACT_STATUS = 'contactStatus',
  LISTING_REVENUE = 'listingRevenue',

  // Desktop specific columns
  AGENTS = 'agents',
  CONTACT = 'contact',
  LISTING = 'listing',
  REVENUE = 'revenue',
  STATUS = 'status',

  // Archived specific columns
  ARCHIVED_CONTACT_STATUS = 'archivedContactStatus',
  ARCHIVED_DATE = 'archivedDate',
  ARCHIVED_REASON = 'archivedRevenue',
  ARCHIVED_STATUS = 'archivedStatus'
}

export type LeadViewTableData = {
  opportunity: SaleOpportunity;
};

export type ExternalLeadViewTableData = {
  opportunity: ExternalOpportunityNormalized;
};

export type QueryParam = string | string[] | undefined;

export type LeadViewNavigation = {
  page: number;
  tab: LeadViewTab;
  agents?: number[];
};

export enum ExternalOpportunityStatus {
  ACCEPTED = 'accepted',
  AUTOMATIC_ATTRIBUTION = 'automaticAttribution',
  DELISTED = 'delisted',
  LOST = 'lost',
  NOT_ATTRIBUTED = 'notAttributed',
  OUT_OF_ZONE = 'outOfZone',
  PERMANENTLY_ATTRIBUTED = 'permanentlyAttributed',
  PROSPECT = 'prospect',
  REFUSED = 'refused'
}

export type ExternalOpportunity = {
  agentId: nullOrNumber;
  assignedDate: nullOrString;
  assignedEndDate: nullOrString;
  createdAt: string;
  createdBy: string;
  email: nullOrString;
  firstname: nullOrString;
  id: string;
  lastname: nullOrString;
  modifiedAt: nullOrString;
  modifiedBy: nullOrString;
  pigeExternalId: string;
  phone: nullOrString;
  refusalReason: nullOrString;
  status: ExternalOpportunityStatus;
};

export type ExternalPropertyRaw = {
  adresse: nullOrString;
  adresse_numero: nullOrString;
  ads: nullOrString;
  appartement_balcon_surface: nullOrNumber;
  appartement_cave: nullOrBoolean;
  appartement_nb_chambres: nullOrNumber;
  appartement_etage_bien: nullOrNumber;
  appartement_nb_pieces: nullOrNumber;
  appartement_nb_sdb: nullOrNumber;
  appartement_nb_sde: nullOrNumber;
  appartement_parking: nullOrBoolean;
  appartement_qualite: nullOrNumber;
  appartement_surface_carrez: nullOrNumber;
  appartement_surface_habitable: nullOrNumber;
  ascenseur: nullOrBoolean;
  balcony_terrace: nullOrBoolean;
  bloctel: nullOrString;
  charge_copropriete_mois: nullOrNumber;
  code_postal: nullOrString;
  cuisine_equipee: nullOrBoolean;
  description: string;
  dpe: nullOrNumber;
  geo_location_ids: string;
  ges: nullOrNumber;
  images: nullOrString;
  immeuble_annee_construction: nullOrNumber;
  latitude: number;
  longitude: number;
  marketing_dealers: nullOrString;
  marketing_publication_start_date: string;
  modified_at: string;
  nb_floor_listing: nullOrNumber;
  number: nullOrString;
  orientation_est: nullOrBoolean;
  orientation_nord: nullOrBoolean;
  orientation_ouest: nullOrBoolean;
  orientation_sud: nullOrBoolean;
  prix: number;
  prix_historique: nullOrString;
  sold_by_agency: boolean;
  sold_by_owner: boolean;
  sold_by_representative: boolean;
  swimming_pool: nullOrBoolean;
  type_bien: PropertyEnum;
  valide: number;
  ville: nullOrString;
  yanport_id: string;
};

export type MarketingDealer = {
  agenciesUnwanted?: boolean;
  id?: number;
  bloctel?: { checkDate?: string; status?: string };
  email?: string;
  name?: string;
  organizationId?: number;
  phoneNumber?: string;
  phoneNumberHash?: string;
  subType?: string;
  type?: MarketingDealerType;
};

type ExternalOpportunityActivity = {
  activities: Activity[];
  count: number;
};

export type ExternalOpportunityRaw = {
  activities: ExternalOpportunityActivity | null;
  pige: ExternalOpportunity;
  pigeProperties: ExternalPropertyRaw;
  potentialBuyer: BuyerbaseScores;
};

export type ExternalOpportunityNormalized = {
  ads: YanportAds[];
  agentId: nullOrNumber;
  assignedDate: nullOrString;
  assignedEndDate: nullOrString;
  balconySurfaceArea: nullOrNumber;
  city: nullOrString;
  constructionYear: nullOrNumber;
  dealerTypes: DealerType[];
  description: string;
  dpe: number;
  electricityFees: nullOrNumber;
  email: string;
  firstname: string;
  floor: nullOrNumber;
  floorsCount: nullOrNumber;
  ges: number;
  hasBloctel: boolean;
  hasCellar: boolean;
  hasElevator: boolean;
  hasFurnishedKitchen: boolean;
  hasOutdoorSpace: boolean;
  hasParking: boolean;
  hasPool: boolean;
  isInterested: nullOrBoolean;
  isInternal: boolean;
  isOnline: boolean;
  isProspectLead: boolean;
  lastname: string;
  latitude: number;
  longitude: number;
  nbBathrooms: nullOrNumber;
  nbBedrooms: nullOrNumber;
  nbRooms: nullOrNumber;
  nbShowerRooms: nullOrNumber;
  modifiedAt: string;
  orientationEast: nullOrBoolean;
  orientationNorth: nullOrBoolean;
  orientationSouth: nullOrBoolean;
  orientationWest: nullOrBoolean;
  phone: string;
  pictures: string[];
  pigeCreatedAt: string;
  pigeCreatedBy: string;
  pigeId: string;
  pigeModifiedAt: nullOrString;
  pigeModifiedBy: nullOrString;
  pigeExternalId: string;
  postCode: nullOrString;
  price: number;
  priceHistory: FormattedHistory[];
  propertyType: PropertyEnum;
  publicationDate: string;
  quality: ListingQuality | null;
  refusalReason: nullOrString;
  scores: Scores[];
  status: ExternalOpportunityStatus;
  street: nullOrString;
  streetNumber: nullOrString;
  surface: nullOrNumber;
  surfaceCarrez: nullOrNumber;
  zonesId: string;
  lastActivity?: Activity;
  marketingDealerType?: MarketingDealerType;
  upcomingActivity?: Activity;
};

export type Scores = {
  count: number;
  score: BuyerScore;
};

export type BuyerbaseScores = {
  id: string;
  scores: Scores[];
};

export type ExternalOpportunitiesPerTab = {
  [key in LeadViewTab]: ExternalOpportunityNormalized[];
};

export enum ExternalLeadViewTableColumns {
  ADVERTISER = 'advertiser',
  AGENT = 'agent',
  ATTRIBUTION = 'attribution',
  BUYERS = 'buyers',
  LAST_ACTIVITY = 'lastActivity',
  LISTING = 'listing',
  NAME = 'name',
  PRICE = 'price',
  REMAINING_TIME = 'remainingTime',
  UPCOMING_ACTIVITY = 'upcomingActivity'
}

import { atom, selector } from 'recoil';

export type History = {
  isMainPage: boolean;
  path: string;
  route: string;
  customerId?: string;
  leadId?: string;
};

const historyManagerAtom = atom<History[]>({
  default: [],
  key: 'HistoryManagerAtom'
});

export const historyManagerSelector = selector<History[]>({
  get: ({ get }) => get(historyManagerAtom),
  key: 'historyManagerSelector',
  set: ({ set }, historyManagerValues) =>
    set(historyManagerAtom, historyManagerValues)
});

import styled from 'styled-components';

import {
  breakpoints,
  colors,
  convertPxToRem,
  fonts,
  InterFont,
  paragraphs,
  radius
} from '@proprioo/salatim';

const SEARCH_CANCEL_BUTTON_COLOR = colors.dark.base80;
const SEARCH_CANCEL_BUTTON_SIZE = convertPxToRem(12);

export const InputLayout = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  margin-top: ${convertPxToRem(16)};
  height: ${convertPxToRem(40)};

  > svg {
    position: absolute;
    z-index: 1;
    top: ${convertPxToRem(8)};
    left: ${convertPxToRem(8)};
    width: ${convertPxToRem(24)};
    height: ${convertPxToRem(24)};
  }

  :focus-within {
    z-index: 2;
  }

  @media (min-width: ${breakpoints.small}px) {
    margin-top: 0;
    height: auto;
    width: 60%;
  }

  @media (min-width: ${breakpoints.normal}px) {
    width: 70%;
  }
`;

export const Input = styled.input`
  ${paragraphs.body1};
  width: 100%;
  height: 100%;
  padding-left: ${convertPxToRem(36)};
  border: ${convertPxToRem(1)} solid ${colors.grey.base40};
  border-radius: ${radius.biggest};
  outline: none;
  color: ${colors.dark.base};
  font-size: ${fonts.base};
  text-overflow: ellipsis;

  :focus {
    border-color: ${colors.terracota.base};
  }

  ::placeholder {
    ${InterFont};
    color: ${colors.dark.base};
    font-size: ${fonts.smaller};
  }

  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
    position: relative;
    z-index: 0;
    right: ${convertPxToRem(8)};
    height: ${SEARCH_CANCEL_BUTTON_SIZE};
    width: ${SEARCH_CANCEL_BUTTON_SIZE};
    background-color: transparent;
    background-image: linear-gradient(
        to left top,
        transparent,
        transparent 45%,
        ${SEARCH_CANCEL_BUTTON_COLOR} 45%,
        ${SEARCH_CANCEL_BUTTON_COLOR} 55%,
        transparent 55%
      ),
      linear-gradient(
        to right top,
        transparent,
        transparent 45%,
        ${SEARCH_CANCEL_BUTTON_COLOR} 45%,
        ${SEARCH_CANCEL_BUTTON_COLOR} 55%,
        transparent 55%
      );
    cursor: pointer;
  }

  @media (min-width: ${breakpoints.small}px) {
    border-radius: 0 ${radius.biggest} ${radius.biggest} 0;
  }
`;

export const ButtonLayout = styled.div`
  text-align: center;
  margin-top: ${convertPxToRem(24)};
`;

import styled from 'styled-components';

import {
  breakpoints,
  colors,
  convertPxToRem,
  paragraphs
} from '@proprioo/salatim';

export const FilterWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (min-width: ${breakpoints.xsmall}px) {
    flex-direction: row;
  }
`;

export const HelpIconWrapper = styled.div`
  > svg {
    margin-top: ${convertPxToRem(1)};
    width: ${convertPxToRem(24)};
    height: ${convertPxToRem(24)};
  }

  path {
    fill: currentColor;
  }
`;

export const Helper = styled.div`
  ${paragraphs.breadcrumb};
  margin: ${convertPxToRem(4)} 0 0 ${convertPxToRem(32)};
  color: ${colors.dark.base60};
`;

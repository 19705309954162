import { atom, selector } from 'recoil';
import Cookies from 'universal-cookie';

import { COOKIE_KEY_COUNTRY } from '@/constants/global';
import { Country } from '@proprioo/hokkaido';

type nullOrCountry = Country | null;

const countryAtom = atom<nullOrCountry>({
  default: null,
  effects: [
    ({ setSelf }) => {
      const cookies = new Cookies();
      const cookieCountry = cookies.get(COOKIE_KEY_COUNTRY) as Country;

      if (cookieCountry) {
        setSelf(cookieCountry);
      }
    }
  ],
  key: 'countryAtom'
});

export const countrySelector = selector<nullOrCountry>({
  get: ({ get }) => get(countryAtom),
  key: 'countrySelector',
  set: ({ set }, newValue) => set(countryAtom, newValue)
});

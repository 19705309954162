import { atom, selector } from 'recoil';

const menuAtom = atom<boolean>({
  default: false,
  key: 'menuAtom'
});

export const menuSelector = selector<boolean>({
  get: ({ get }) => get(menuAtom),
  key: 'menuSelector',
  set: ({ set }, menuValue) => set(menuAtom, menuValue)
});

import styled from 'styled-components';

import { colors, convertPxToRem } from '@proprioo/salatim';

import { CardLayout } from './externalLeadCard/ExternalLeadCard.styles';

export const Wrapper = styled.div`
  margin-top: ${convertPxToRem(16)};
`;

export const CardsContainer = styled(Wrapper)`
  padding: 0 ${convertPxToRem(16)};
  background: white;

  a + a {
    ${CardLayout} {
      border-top: ${convertPxToRem(1)} solid ${colors.grey.base20};
    }
  }
`;

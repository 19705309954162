import {
  DEFAULT_CURRENCY_FORMAT,
  formatPrice,
  Locale,
  nullOrNumber
} from '@proprioo/hokkaido';

export const formatCompactPrice = (
  price?: nullOrNumber,
  locale = Locale.ENGLISH
) => {
  if (!price && price !== 0) {
    return;
  }

  return formatPrice(price, locale, {
    ...DEFAULT_CURRENCY_FORMAT,
    maximumSignificantDigits: 3,
    notation: 'compact'
  });
};

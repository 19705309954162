import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { FC } from 'react';

import PhoneIcon from '@/assets/icons/activity-call.svg';
import { UNAVAILABLE_PLACEHOLDER } from '@/constants/global';
import { usePropertyInformation } from '@/hooks/usePropertyInformation/usePropertyInformation';
import { SinceSentenceKey } from '@/hooks/useSinceSentence/interfaces';
import { useSinceSentence } from '@/hooks/useSinceSentence/useSinceSentence';
import { listToString } from '@proprioo/hokkaido';

import { ExternalOpportunityNormalized } from '../../../interfaces';
import {
  Address,
  IconWrapper,
  Layout,
  ListingDataWrapper,
  OtherInformation,
  PictureWrapper,
  SinceDate
} from './ListingDataCell.styles';

export type ListingDataCellProps = Pick<
  ExternalOpportunityNormalized,
  | 'city'
  | 'nbBedrooms'
  | 'nbRooms'
  | 'phone'
  | 'pictures'
  | 'postCode'
  | 'publicationDate'
  | 'street'
  | 'streetNumber'
  | 'surface'
>;

const ListingDataCell: FC<ListingDataCellProps> = ({
  city,
  nbBedrooms,
  nbRooms,
  phone,
  pictures,
  postCode,
  publicationDate,
  street,
  streetNumber,
  surface
}) => {
  const { t } = useTranslation();
  const sinceSentence = useSinceSentence(
    publicationDate,
    SinceSentenceKey.MARKETED
  );
  const address = t('formattedFullAddress', {
    address: listToString([street, postCode, city]),
    number: streetNumber || ''
  }).trim();
  const otherInformation = listToString(
    usePropertyInformation({ nbBedrooms, nbRooms, surface })
  );
  const [firstPicture] = pictures;

  return (
    <Layout>
      <PictureWrapper>
        <Image
          alt=""
          data-test="listing-picture"
          height={60}
          loading="lazy"
          src={firstPicture || UNAVAILABLE_PLACEHOLDER}
          width={80}
        />
      </PictureWrapper>
      <ListingDataWrapper>
        <SinceDate>{sinceSentence}</SinceDate>
        <Address>{address}</Address>
        <OtherInformation>
          {otherInformation}
          {phone && (
            <IconWrapper>
              <PhoneIcon aria-hidden={true} data-test="phone-icon" />
            </IconWrapper>
          )}
        </OtherInformation>
      </ListingDataWrapper>
    </Layout>
  );
};

export default ListingDataCell;

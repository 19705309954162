import { FC, Fragment } from 'react';

import AgentAvatar from '@/components/app/avatar/agentAvatar/AgentAvatar';
import { useAgentById } from '@/hooks/agents/useAgentById';
import { getFullName } from '@proprioo/hokkaido';

import CheckboxItem from '../checkboxItem/CheckboxItem';

export type AgentCheckboxProps = {
  agentId: number;
  checked: boolean;
  onChange(checked: boolean): void;
};

const AgentCheckbox: FC<AgentCheckboxProps> = ({
  agentId,
  checked,
  onChange
}) => {
  const agent = useAgentById(agentId);

  if (!agent) {
    return <Fragment />;
  }

  const fullName = getFullName(agent.firstname, agent.lastname);

  return (
    <CheckboxItem
      checked={checked}
      icon={
        <AgentAvatar
          agentId={agent.id}
          isToolTipVisible={false}
          name={fullName}
          size={24}
        />
      }
      label={fullName}
      onChange={onChange}
    />
  );
};

export default AgentCheckbox;

import { useTranslation } from 'next-i18next';
import { FC, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import ClockAlertIcon from '@/assets/icons/clock-alert.svg';
import ClockIcon from '@/assets/icons/clock.svg';
import InformIcon from '@/assets/icons/inform.svg';
import SharesIcon from '@/assets/icons/shares.svg';
import { leadViewAtom } from '@/components/state/LeadViewState';
import { TAG_CLOSE_ARIA_LABEL } from '@/constants/global';
import { useRevenues } from '@/hooks/useRevenues/useRevenues';
import { FEATURE_NAME } from '@/hooks/useUserFeaturePermissions/interfaces';
import { useUserFeaturePermissions } from '@/hooks/useUserFeaturePermissions/useUserFeaturePermissions';
import { PAGE_VIEW_TITLE } from '@/utils/gtm';
import { generateButtonId } from '@/utils/tracking';
import {
  colors,
  Tag,
  TagAppearance,
  TagColorsTypes,
  Tooltip
} from '@proprioo/salatim';

import Badge from '../../common/badge/Badge';
import Counter from '../../common/counter/Counter';
import { TagWrapper } from '../../common/styles/MyCustomers.styles';
import {
  ExternalOpportunityNormalized,
  LeadViewSection,
  LeadViewTab,
  SaleOpportunity
} from '../interfaces';
import { filterLeadOpportunities } from '../leads/filters/LeadFilters.utils';
import {
  countLateEvents,
  countTodayEvents,
  getSection,
  getSectionParams,
  getTabLabel,
  getTabTooltipTranslationKey,
  getTabUrl,
  isExternalLeadTab
} from '../LeadView.utils';
import {
  BadgesWrapper,
  HeadingWrapper,
  IconsWrapper,
  Layout,
  TitleWrapper,
  TooltipIconWrapper
} from './Tab.styles';

export type TabProps = {
  currentTab: LeadViewTab;
  externalOpportunities: ExternalOpportunityNormalized[];
  kind: LeadViewTab;
  opportunities: SaleOpportunity[];
  agentIds?: number[];
};

const Tab: FC<TabProps> = ({
  agentIds,
  currentTab,
  externalOpportunities,
  kind,
  opportunities
}) => {
  const { t } = useTranslation();
  const { leadViewFilters } = useRecoilValue(leadViewAtom);

  const section = getSection(kind);

  const { color } = useMemo(() => getSectionParams(section), [section]);
  const count = useMemo(
    () =>
      isExternalLeadTab(kind)
        ? externalOpportunities.length
        : filterLeadOpportunities(opportunities, leadViewFilters).length,
    [kind, leadViewFilters, opportunities]
  );

  const hasAccessToRevenueForecast = useUserFeaturePermissions(
    FEATURE_NAME.MY_SELLERS_REVENUE_FORECAST
  );

  const isLeadSection = section === LeadViewSection.LEADS;
  const isSelected = currentTab === kind;
  const lateEvents = countLateEvents(opportunities);
  const todayEvents = countTodayEvents(opportunities);
  const tabLabel = getTabLabel(kind);
  const tooltipMessage = getTabTooltipTranslationKey(kind);
  const revenuesTag = useRevenues(section, opportunities);

  return (
    <Layout
      aria-label={t(tabLabel)}
      aria-selected={isSelected}
      color={color}
      href={getTabUrl(kind, 1, agentIds)}
      id={generateButtonId(PAGE_VIEW_TITLE.LEADS, tabLabel)}
      isSelected={isSelected}
      role="tab"
      shallow={true}
      tabIndex={isSelected ? 0 : -1}
    >
      <HeadingWrapper>
        <TitleWrapper isSelected={isSelected}>{t(tabLabel)}</TitleWrapper>
        <Counter count={count} isActive={isSelected} />
        {isLeadSection && (
          <div onClick={event => event.preventDefault()}>
            <Tooltip tooltip={t(tooltipMessage)}>
              <TooltipIconWrapper>
                <InformIcon aria-label="tooltip" />
              </TooltipIconWrapper>
            </Tooltip>
          </div>
        )}
      </HeadingWrapper>
      <BadgesWrapper>
        {!isLeadSection && hasAccessToRevenueForecast && (
          <TagWrapper>
            <Tag
              appearance={TagAppearance.BUSINESS}
              closeAriaLabel={t(TAG_CLOSE_ARIA_LABEL)}
              color={TagColorsTypes.GREY_LIGHT}
              dataTest="revenue-tag"
              icon={<SharesIcon />}
              labels={[revenuesTag]}
            />
          </TagWrapper>
        )}
        {(Boolean(todayEvents) || Boolean(lateEvents)) && (
          <IconsWrapper>
            {Boolean(todayEvents) && (
              <Badge
                color={colors.blue.base}
                count={todayEvents}
                icon={<ClockIcon />}
                label="todayTasksCount"
              />
            )}
            {Boolean(lateEvents) && (
              <Badge
                color={colors.red.base}
                count={lateEvents}
                icon={<ClockAlertIcon />}
                label="lateTaskCount"
              />
            )}
          </IconsWrapper>
        )}
      </BadgesWrapper>
    </Layout>
  );
};

export default Tab;

import Link from 'next/link';
import styled from 'styled-components';

import { BuyerActivitiesWrapper } from '@/components/buyer/buyerActivities/BuyerActivities.styles';
import { colors, convertPxToRem } from '@proprioo/salatim';

export const Layout = styled(Link)`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-row-gap: ${convertPxToRem(8)};
  padding: ${convertPxToRem(8)};
  height: 100%;

  :not(:last-child) {
    border-bottom: ${convertPxToRem(1)} solid ${colors.grey.base40};
  }
`;

export const ActivityWrapper = styled.div`
  grid-column: 1;
  grid-row: 1;
`;

export const MainContentWrapper = styled.div`
  grid-column: 2;
`;

export const NextEventWrapper = styled.div`
  text-align: right;
`;

export const ActivitiesWrapper = styled(MainContentWrapper)`
  margin: ${convertPxToRem(8)} 0;

  ${BuyerActivitiesWrapper} {
    justify-content: flex-start;
  }
`;

export const OwnerWrapper = styled.div`
  margin-top: ${convertPxToRem(16)};
`;

import { useMemo } from 'react';

import { useAuth } from '../auth/useAuth';
import { useAgents } from './useAgents';

export const useCurrentAgent = () => {
  const { agents } = useAgents();
  const { user } = useAuth();

  const currentAgent = useMemo(
    () => agents.find(({ email }) => email === user?.email),
    [agents, user?.email]
  );

  return currentAgent;
};

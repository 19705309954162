import { isAfter } from 'date-fns/isAfter';
import { isBefore } from 'date-fns/isBefore';
import { parseISO } from 'date-fns/parseISO';

import { PROPRIOO_SOURCE } from '@/constants/global';
import { isDefined } from '@/utils/helpers';
import { logError } from '@/utils/log';
import { compareOfferDates } from '@/utils/offer.utils';
import { nullOrNumber } from '@proprioo/hokkaido';

import { ActivityStatus } from '../activity/interfaces';
import { BuyerActivity } from '../buyerbase/interfaces';
import { OfferProps } from '../listing/contract/Contract.interfaces';
import { Visit } from '../visit/interfaces';
import {
  extractUserIdFromNewOffer,
  oldOfferStatusFromNewOffer,
  removeOffersWithoutBuyers
} from '../visit/utils';
import {
  Activities,
  ActivitiesOffer,
  ActivitiesVisit,
  ActivityType,
  BuyerInterest,
  BuyerOffer,
  BuyerScore,
  CleanedBuyerOffer,
  CleanedListingRequest,
  CleanedVisit,
  ListingRequest,
  OfferStatus
} from './interfaces';

const CHRONOLOGICAL_OFFER_STATUS_ORDER = [
  OfferStatus.SIGNED,
  OfferStatus.SENT_TO_SELLERS,
  OfferStatus.VALIDATION_BY_AGENT,
  OfferStatus.SENT_TO_CLIENTS,
  OfferStatus.WITHDRAWN,
  OfferStatus.PENDING,
  OfferStatus.CREATED,
  OfferStatus.CANCELED,
  OfferStatus.DELETED,
  null
];

export const convertInterestToRating = (
  interest: BuyerInterest | null
): nullOrNumber => {
  switch (interest) {
    case BuyerInterest.LOW:
      return 1;
    case BuyerInterest.MEDIUM:
      return 2;
    case BuyerInterest.HIGH:
      return 3;
    default:
      return null;
  }
};

export const convertOffersPropsToBuyerOffers = (
  offers: OfferProps[]
): BuyerOffer[] =>
  removeOffersWithoutBuyers(offers).map(
    ({
      creator,
      id,
      listingId,
      loanAmount,
      loanNeeded,
      modifiedDate,
      price,
      signatories,
      status,
      validityDate
    }) => ({
      amount: price,
      contactId: null,
      createdAt: modifiedDate,
      /**
       * TODO: at the moment, new Date(validityDate).toISOString() is the best solution to avoid
       * an issue emerged when migrating date-fns from v2 to v3. We should update the typings of the
       * data coming from the API, but that is out of the scope of this task and require some more changes.
       */
      expiry: validityDate ? new Date(validityDate).toISOString() : '',
      id,
      isFromWebsite: !isDefined(creator),
      isNewOffer: true,
      loanAmount,
      loanNeeded,
      mainUserId: extractUserIdFromNewOffer(signatories),
      modifiedDate,
      propertyId: listingId,
      status: oldOfferStatusFromNewOffer(status),
      yousignBuyer: null,
      yousignSeller: null
    })
  );

export const formatListingRequests = (
  listingRequests: ListingRequest[]
): CleanedListingRequest[] =>
  listingRequests
    .map(
      ({
        archived,
        id,
        createdAt,
        mainUserId,
        message,
        propertyId,
        source
      }) => ({
        archived,
        id,
        message,
        propertyId,
        source,
        sourceCreatedAt: createdAt,
        userId: mainUserId
      })
    )
    .filter(({ source }) => !source?.includes(PROPRIOO_SOURCE))
    .sort(
      (a, b) =>
        new Date(b.sourceCreatedAt).getTime() -
        new Date(a.sourceCreatedAt).getTime()
    )
    .reverse();

export const formatVisits = (visits: Visit[]): CleanedVisit[] =>
  visits
    .map(
      ({
        uuid,
        annonce_id,
        visit_datetime_utc,
        status,
        visitor,
        appointment_id,
        recap_visite,
        interest
      }) => ({
        appointmentId: appointment_id,
        id: uuid,
        interest,
        propertyId: annonce_id,
        status,
        userId: visitor,
        visitDate: [visit_datetime_utc],
        visitRecap: recap_visite
      })
    )
    .reduce((acc: CleanedVisit[], visit) => {
      const { propertyId, userId, visitDate } = visit;

      const visitedIndex = acc.findIndex(
        item => item.userId === userId && item.propertyId === propertyId
      );

      if (visitedIndex > -1) {
        acc.push({
          ...visit,
          visitDate: [...acc[visitedIndex].visitDate, ...visitDate].sort(
            (a, b) => new Date(b).getTime() - new Date(a).getTime()
          )
        });
        acc.splice(visitedIndex, 1);
      } else {
        acc.push(visit);
      }

      return acc;
    }, []);

const logOfferStatusError = (offer: CleanedBuyerOffer) =>
  logError(`Unknown offer status: ${offer.id}`, { offer });

export const hasKnownOfferStatus = (status: OfferStatus | null) =>
  CHRONOLOGICAL_OFFER_STATUS_ORDER.includes(status) ||
  (status &&
    CHRONOLOGICAL_OFFER_STATUS_ORDER.includes(
      status.toLowerCase() as OfferStatus
    ));

export const compareOfferStatus = (
  first: CleanedBuyerOffer,
  second: CleanedBuyerOffer
): CleanedBuyerOffer => {
  const orderedOffers = [first, second].sort((a, b) =>
    compareOfferDates(a, b, false)
  );

  const [newestOffer, oldestOffer] = orderedOffers;

  if (!hasKnownOfferStatus(oldestOffer.status)) {
    logOfferStatusError(oldestOffer);
    return newestOffer;
  } else if (!hasKnownOfferStatus(newestOffer.status)) {
    logOfferStatusError(newestOffer);
    return oldestOffer;
  }

  const [offer] = CHRONOLOGICAL_OFFER_STATUS_ORDER.map(currentStatus => {
    const [matchingOffer] = orderedOffers.filter(({ status }) =>
      status && currentStatus
        ? status.toLowerCase() === currentStatus.toLowerCase()
        : status === currentStatus
    );

    return matchingOffer;
  }).filter(Boolean);

  return offer;
};

export const cleanBuyerOffer = ({
  amount,
  createdAt,
  expiry,
  id,
  isFromWebsite,
  isNewOffer,
  loanAmount,
  loanNeeded,
  mainUserId,
  modifiedDate,
  propertyId,
  status
}: BuyerOffer): CleanedBuyerOffer => ({
  amount,
  createdAt,
  expiry,
  id,
  isFromWebsite: !!isFromWebsite,
  isNewOffer: !!isNewOffer,
  loanAmount,
  loanNeeded,
  modifiedDate,
  propertyId,
  status,
  userId: mainUserId
});

export const formatOffers = (offers: BuyerOffer[]): CleanedBuyerOffer[] =>
  offers.map(cleanBuyerOffer).reduce((acc: CleanedBuyerOffer[], offer) => {
    const { propertyId, userId } = offer;

    const offerIndex = acc.findIndex(
      item => item.userId === userId && item.propertyId === propertyId
    );

    if (offerIndex > -1) {
      acc.splice(offerIndex, 1, compareOfferStatus(acc[offerIndex], offer));
    } else {
      acc.push(offer);
    }

    return acc;
  }, []);

const filterActivities = (
  requests: CleanedListingRequest[],
  visits: CleanedVisit[],
  offers: CleanedBuyerOffer[]
): Activities => {
  let temporaryRequests = [...requests];

  const temporaryVisits: ActivitiesVisit[] = visits.map(visit => {
    const { userId, propertyId } = visit;

    const indexRequests: number = requests.findIndex(
      req => req.userId === userId && req.propertyId === propertyId
    );

    const itemRequests = requests[indexRequests];

    temporaryRequests = temporaryRequests.filter(
      req => req.propertyId !== propertyId || req.userId !== userId
    );

    return {
      ...itemRequests,
      ...visit
    };
  });

  const activitiesOffers: ActivitiesOffer[] = offers.map(offer => {
    const { userId, propertyId } = offer;

    const indexRequests: number = temporaryRequests.findIndex(
      req => req.userId === userId && req.propertyId === propertyId
    );

    const indexVisits: number = temporaryVisits.findIndex(
      req => req.userId === userId && req.propertyId === propertyId
    );

    const itemRequests = temporaryRequests[indexRequests];
    const itemVisits = temporaryVisits[indexVisits];

    temporaryRequests = temporaryRequests.filter(
      req => req.propertyId !== propertyId || req.userId !== userId
    );

    if (indexVisits > -1) {
      temporaryVisits.splice(indexVisits, 1);
    }

    return {
      ...itemRequests,
      ...itemVisits,
      ...offer
    };
  });

  return {
    activitiesOffers,
    activitiesRequests: temporaryRequests,
    activitiesVisits: temporaryVisits
  };
};
export const orderFutureVisitDates = (
  visit: ActivitiesVisit
): ActivitiesVisit => {
  const currentDate = new Date();

  const pastBuffer = visit.visitDate.filter(date =>
    isBefore(parseISO(date), currentDate)
  );
  const futureBuffer = visit.visitDate
    .filter(date => isAfter(parseISO(date), currentDate))
    .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
    .reverse();

  visit.visitDate =
    pastBuffer.length > 0
      ? (visit.visitDate = futureBuffer.concat(pastBuffer))
      : (visit.visitDate = visit.visitDate.reverse());

  return visit;
};

export const getBuyerActivities = (
  listingRequests: ListingRequest[],
  visits: Visit[],
  offers: BuyerOffer[]
): ActivityType[] => {
  const currentDate = new Date();

  const formattedRequests = formatListingRequests(listingRequests);
  const formattedVisits = formatVisits(visits);
  const formattedOffers = formatOffers(offers);

  const { activitiesRequests, activitiesVisits, activitiesOffers } =
    filterActivities(formattedRequests, formattedVisits, formattedOffers);

  const pastVisits = activitiesVisits.filter(({ visitDate }) =>
    isBefore(parseISO(visitDate[0]), currentDate)
  );
  const futureVisits = activitiesVisits
    .filter(({ visitDate }) => isAfter(parseISO(visitDate[0]), currentDate))
    .map(visit => orderFutureVisitDates(visit));

  return [
    { items: activitiesRequests.reverse(), title: 'contactRequest' },
    {
      items: futureVisits
        .sort(
          (a, b) =>
            new Date(b.visitDate[0]).getTime() -
            new Date(a.visitDate[0]).getTime()
        )
        .reverse(),
      title: 'upcomingVisit'
    },
    {
      items: pastVisits.sort(
        (a, b) =>
          new Date(b.visitDate[0]).getTime() -
          new Date(a.visitDate[0]).getTime()
      ),
      title: 'pastVisit'
    },
    {
      items: activitiesOffers.sort((a, b) => compareOfferDates(a, b, false)),
      title: 'offer'
    }
  ];
};

export const getScoreTranslation = (score: BuyerScore): string => {
  switch (score) {
    case BuyerScore.A_PLUS:
      return 'scoreAPlus';
    case BuyerScore.A:
      return 'scoreA';
    case BuyerScore.B:
      return 'scoreB';
    case BuyerScore.C:
      return 'scoreC';
    default:
      return 'unknownScore';
  }
};

export const getBuyerActivitiesByStatus = (
  activities: BuyerActivity[],
  filteredStatus: ActivityStatus
) => activities.filter(({ status }) => status === filteredStatus);

import SortUnset from '@/assets/icons/arrow-down-up.svg';
import ArrowDown from '@/assets/icons/arrow-down.svg';
import ArrowUp from '@/assets/icons/arrow-up.svg';
import { OrderBy } from '@/components/buyerbase/sortingToggle/enum';
import { SortDirection } from '@tanstack/react-table';

export type SortedState = false | SortDirection;

export const getSortIcon = (sortState: SortedState) => {
  switch (sortState) {
    case OrderBy.ASC:
      return <ArrowUp data-test="sort-asc-icon" aria-hidden={true} />;
    case OrderBy.DESC:
      return <ArrowDown data-test="sort-desc-icon" aria-hidden={true} />;
    default:
      return <SortUnset data-test="unset-sort-icon" aria-hidden={true} />;
  }
};

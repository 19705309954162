import {
  BuyerSource,
  Language,
  nullOrBoolean,
  nullOrNumber,
  nullOrString,
  OptionProps,
  UserProps
} from '@proprioo/hokkaido';

import { OtherCriteriasProps } from '../buyerbase/filters/otherCriterias/OtherCriterias';

export enum CreatedFrom {
  ALERT = 'alertOnline',
  TOOL = 'projectCall'
}

export type MinMax = {
  min?: nullOrNumber;
  max?: nullOrNumber;
};

export enum CriteriaTypesCheck {
  HOUSE = 'criteria.propertyTypes.house',
  FLAT = 'criteria.propertyTypes.flat'
}

export enum RangeType {
  MIN = 'min',
  MAX = 'max'
}

export enum CriteriaRangeTypes {
  NB_BEDROOMS = 'nbBedrooms',
  ROOMS = 'rooms',
  SURFACE = 'surface',
  BUDGET = 'budget',
  LAND = 'land'
}

export enum LoanStage {
  NO_NEED = 'noNeed',
  NO_BUT_I_NEED_ONE = 'noButINeedOne',
  IN_PROGRESS = 'inProgress',
  ACCEPTED = 'accepted'
}

export enum PurchaseStage {
  KICK_OFF = 'kickOff',
  VISITING = 'visiting',
  ALREADY_MADE_OFFER = 'alreadyMadeOffer'
}

export type SelectedProperties = {
  flat: boolean;
  house: boolean;
};

export type ProjectState = {
  createdFrom: CreatedFrom;
  creator?: string;
  enabled: boolean;
  agentOwner: nullOrString;
  source: OptionProps | null;
  creditStage: OptionProps | null;
  isSeller: OptionProps | null;
  purchaseStage: OptionProps | null;
  internalComment?: string;
};

export type CriteriaState = {
  budget: MinMax;
  enabled: boolean;
  isMap: boolean;
  locationState: LocationState[];
  nbBedrooms: MinMax;
  propertyTypes: SelectedProperties;
  rooms: MinMax;
  surface: MinMax;
  land?: MinMax;
  other?: OtherCriteriasProps;
};

export type UserAlert = UserProps & {
  promotionalConsent: boolean;
};

export type AlertFormState = {
  criteria: CriteriaState;
  project: ProjectState;
  user: UserAlert;
  notification: boolean;
  creator?: string;
  reallocateActivities?: boolean;
};

export type AlertUpdateFormState = AlertFormState & {
  alertID: string;
  createdAt: string;
  lastModified: string;
  projectID: string;
};

export type ProjectPayload = {
  enabled: boolean;
  createdFrom: CreatedFrom;
  agentOwner?: nullOrNumber;
  creator?: string;
  source?: BuyerSource;
  isSeller?: boolean;
  loanStage?: LoanStage;
  purchaseStage?: PurchaseStage;
  internalComment?: string;
  modifiedAt?: string;
};

export type AlertObjectPayload = {
  enabled: boolean;
  isMap: boolean;
  zones: LocationState[];
  propertyTypes: string[];
  minRooms?: nullOrNumber;
  maxRooms?: nullOrNumber;
  minBedrooms?: nullOrNumber;
  maxBedrooms?: nullOrNumber;
  minPrice?: nullOrNumber;
  maxPrice?: nullOrNumber;
  minSurface?: nullOrNumber;
  maxSurface?: nullOrNumber;
  minLandingArea?: nullOrNumber;
  maxLandingArea?: nullOrNumber;
  groundFloor?: nullOrBoolean;
  hasCellarOrAnnex?: nullOrBoolean;
  hasLift?: nullOrBoolean;
  hasParking?: nullOrBoolean;
  hasPool?: nullOrBoolean;
  hasOutdoorSpace?: nullOrBoolean;
  withoutRenovationWork?: nullOrBoolean;
};

export type UserPayload = {
  email: string;
  firstname: string;
  lastname: string;
  phoneNumber: nullOrString;
  promotionalConsent: boolean;
};

export type RealAlertObjectPayload = Omit<AlertObjectPayload, 'zones'> & {
  zones: string[];
};

type AlertPayloadUser = UserPayload & {
  language: Language;
};

export type AlertPayload = {
  alert: RealAlertObjectPayload;
  project: ProjectPayload;
  user: AlertPayloadUser;
  notification: boolean;
  transferActivities: boolean;
};

export type AreaPayload = {
  postalCode?: string;
  city?: string;
};

export enum LocationSearchType {
  COUNTY = 'county',
  IRIS = 'iris',
  MUNICIPALITY = 'municipality',
  NEIGHBORHOOD = 'neighborhood',
  REGION = 'region',
  SUBMUNICIPALITY = 'submunicipality'
}

export type LocationState = {
  geom: string[][];
  id: string;
  name: string;
  type: LocationSearchType;
};

export type AreaFiltered = {
  type: LocationSearchType;
};

import { endOfToday } from 'date-fns/endOfToday';
import { useTranslation } from 'next-i18next';
import { Fragment, useCallback, useEffect, useMemo } from 'react';

import { DEFAULT_TABLE_PAGE_SIZE } from '@/components/myCustomers/myCustomers.utils';
import {
  ExternalLeadViewTableColumns,
  ExternalLeadViewTableData,
  ExternalOpportunityNormalized,
  LeadViewTab
} from '@/components/myCustomers/mySellers/interfaces';
import ActivityCell from '@/components/myCustomers/mySellers/leads/externalLeads/activityCell/ActivityCell';
import AdvertiserCell from '@/components/myCustomers/mySellers/leads/externalLeads/advertiserCell/AdvertiserCell';
import AgentCell from '@/components/myCustomers/mySellers/leads/externalLeads/agentCell/AgentCell';
import AttributionCell from '@/components/myCustomers/mySellers/leads/externalLeads/attributionCell/AttributionCell';
import ListingDataCell from '@/components/myCustomers/mySellers/leads/externalLeads/listingDataCell/ListingDataCell';
import NameCell from '@/components/myCustomers/mySellers/leads/externalLeads/nameCell/NameCell';
import PriceCell from '@/components/myCustomers/mySellers/leads/externalLeads/priceCell/PriceCell';
import RemainingTimeCell from '@/components/myCustomers/mySellers/leads/externalLeads/remainingTimeCell/RemainingTimeCell';
import ScoresCell from '@/components/myCustomers/mySellers/leads/externalLeads/scoresCell/ScoresCell';
import { getRemainingTimeToAttributeLead } from '@/components/myCustomers/mySellers/LeadView.utils';
import { getColumnVisibility } from '@/utils/reactTable.utils';
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  useReactTable
} from '@tanstack/react-table';

import { getHiddenColumns } from './utils';

type ExternalLeadViewTableHookProps = {
  currentTab: LeadViewTab;
  isTeamLead: boolean;
  opportunities: ExternalOpportunityNormalized[];
  pageIndex: number;
};

export const useExternalLeadViewTable = ({
  currentTab,
  isTeamLead,
  opportunities,
  pageIndex
}: ExternalLeadViewTableHookProps) => {
  const { t } = useTranslation();

  const sortPriceColumn = useCallback(
    (
      rowA: Row<ExternalLeadViewTableData>,
      rowB: Row<ExternalLeadViewTableData>
    ) => rowB.original.opportunity.price - rowA.original.opportunity.price,
    []
  );

  const sortRemainingTimeColumn = useCallback(
    (
      rowA: Row<ExternalLeadViewTableData>,
      rowB: Row<ExternalLeadViewTableData>
    ) => {
      const rowAHoursRemaining = getRemainingTimeToAttributeLead(
        rowA.original.opportunity.assignedEndDate
      );
      const rowBHoursRemaining = getRemainingTimeToAttributeLead(
        rowB.original.opportunity.assignedEndDate
      );

      return rowBHoursRemaining - rowAHoursRemaining;
    },
    []
  );

  const sortPastActivity = useCallback(
    (
      rowA: Row<ExternalLeadViewTableData>,
      rowB: Row<ExternalLeadViewTableData>
    ) => {
      const activityA = rowA.original.opportunity.lastActivity;
      const activityB = rowB.original.opportunity.lastActivity;

      const dateA = activityA?.completionDate
        ? new Date(activityA.completionDate)
        : endOfToday();
      const dateB = activityB?.completionDate
        ? new Date(activityB.completionDate)
        : endOfToday();

      return dateA.getTime() - dateB.getTime();
    },
    []
  );

  const sortUpcomingActivity = useCallback(
    (
      rowA: Row<ExternalLeadViewTableData>,
      rowB: Row<ExternalLeadViewTableData>
    ) => {
      const activityA = rowA.original.opportunity.upcomingActivity;
      const activityB = rowB.original.opportunity.upcomingActivity;

      const dateA = activityA ? new Date(activityA.targetDate) : endOfToday();
      const dateB = activityB ? new Date(activityB.targetDate) : endOfToday();

      return dateB.getTime() - dateA.getTime();
    },
    []
  );

  const columnHelper = createColumnHelper<ExternalLeadViewTableData>();

  const tableColumns = useMemo(
    () => [
      columnHelper.accessor(({ opportunity }) => ({ opportunity }), {
        cell: opportunity => {
          const agentId = opportunity.getValue().opportunity.agentId;

          return agentId ? <AgentCell agentId={agentId} /> : <Fragment />;
        },
        enableSorting: false,
        header: () => t('agent'),
        id: ExternalLeadViewTableColumns.AGENT
      }),
      columnHelper.accessor(({ opportunity }) => ({ opportunity }), {
        cell: opportunity => (
          <NameCell
            firstname={opportunity.getValue().opportunity.firstname}
            lastname={opportunity.getValue().opportunity.lastname}
          />
        ),
        enableSorting: false,
        header: () => t('lastName'),
        id: ExternalLeadViewTableColumns.NAME
      }),
      columnHelper.accessor(({ opportunity }) => ({ opportunity }), {
        cell: opportunity => (
          <AdvertiserCell
            dealerTypes={opportunity.getValue().opportunity.dealerTypes}
          />
        ),
        enableSorting: false,
        header: () => t('advertiser'),
        id: ExternalLeadViewTableColumns.ADVERTISER
      }),
      columnHelper.accessor(({ opportunity }) => ({ opportunity }), {
        cell: opportunity => (
          <ListingDataCell {...opportunity.getValue().opportunity} />
        ),
        enableSorting: false,
        header: () => t('listingInformation'),
        id: ExternalLeadViewTableColumns.LISTING
      }),
      columnHelper.accessor(({ opportunity }) => ({ opportunity }), {
        cell: opportunity => (
          <PriceCell price={opportunity.getValue().opportunity.price} />
        ),
        enableSorting: true,
        header: () => t('price'),
        id: ExternalLeadViewTableColumns.PRICE,
        sortingFn: sortPriceColumn
      }),
      columnHelper.accessor(({ opportunity }) => ({ opportunity }), {
        cell: opportunity => (
          <ScoresCell
            pigeCreatedAt={opportunity.getValue().opportunity.pigeCreatedAt}
            scores={opportunity.getValue().opportunity.scores}
          />
        ),
        enableSorting: false,
        header: () => t('buyer_other'),
        id: ExternalLeadViewTableColumns.BUYERS
      }),
      columnHelper.accessor(({ opportunity }) => ({ opportunity }), {
        cell: opportunity => (
          <RemainingTimeCell
            assignedEndDate={opportunity.getValue().opportunity.assignedEndDate}
          />
        ),
        enableSorting: true,
        header: () => t('remainingTime'),
        id: ExternalLeadViewTableColumns.REMAINING_TIME,
        sortingFn: sortRemainingTimeColumn
      }),
      columnHelper.accessor(({ opportunity }) => ({ opportunity }), {
        cell: opportunity => (
          <ActivityCell
            activity={opportunity.getValue().opportunity.lastActivity}
            assignedDate={opportunity.getValue().opportunity.assignedDate}
            pigeStatus={opportunity.getValue().opportunity.status}
          />
        ),
        enableSorting: true,
        header: () => t('done_other'),
        id: ExternalLeadViewTableColumns.LAST_ACTIVITY,
        sortingFn: sortPastActivity
      }),
      columnHelper.accessor(({ opportunity }) => ({ opportunity }), {
        cell: opportunity => (
          <ActivityCell
            activity={opportunity.getValue().opportunity.upcomingActivity}
            assignedDate={opportunity.getValue().opportunity.assignedDate}
            isUpcomingActivity={true}
            pigeStatus={opportunity.getValue().opportunity.status}
          />
        ),
        enableSorting: true,
        header: () => t('toBeDone_other'),
        id: ExternalLeadViewTableColumns.UPCOMING_ACTIVITY,
        sortingFn: sortUpcomingActivity
      }),
      columnHelper.accessor(({ opportunity }) => ({ opportunity }), {
        cell: opportunity => (
          <AttributionCell
            pigeId={opportunity.getValue().opportunity.pigeId}
            status={opportunity.getValue().opportunity.status}
          />
        ),
        enableSorting: false,
        header: () => '',
        id: ExternalLeadViewTableColumns.ATTRIBUTION
      })
    ],
    [opportunities]
  );

  const tableData = useMemo(
    () => opportunities.map(opportunity => ({ opportunity })),
    [opportunities]
  );

  const tableHook = useReactTable<ExternalLeadViewTableData>({
    autoResetPageIndex: false,
    columns: tableColumns,
    data: tableData,
    enableMultiSort: false,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      pagination: { pageIndex, pageSize: DEFAULT_TABLE_PAGE_SIZE }
    },
    state: {
      columnVisibility: getColumnVisibility(
        Object.values(ExternalLeadViewTableColumns),
        getHiddenColumns(currentTab, isTeamLead)
      )
    }
  });

  const { getState, setPageIndex } = tableHook;
  const { pageIndex: currentPageIndex } = getState().pagination;

  useEffect(() => {
    if (pageIndex !== currentPageIndex) {
      setPageIndex(pageIndex);
    }
  }, [pageIndex]);

  return tableHook;
};

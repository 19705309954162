import { useTranslation } from 'next-i18next';
import { FC, Fragment } from 'react';

import { TAG_CLOSE_ARIA_LABEL } from '@/constants/global';
import { LeadSource } from '@proprioo/hokkaido';
import { Tag, TagAppearance, TagColorsTypes } from '@proprioo/salatim';

import { isCasavoLeadSource } from './utils';

type LeadTagProps = {
  source: LeadSource;
};

const LeadTag: FC<LeadTagProps> = ({ source }) => {
  const { t } = useTranslation();

  const isExternalLeadSource = isCasavoLeadSource(source);

  return (
    <Fragment>
      <Tag
        appearance={TagAppearance.BUSINESS}
        closeAriaLabel={t(TAG_CLOSE_ARIA_LABEL)}
        color={
          isExternalLeadSource ? TagColorsTypes.TERRACOTA : TagColorsTypes.GREEN
        }
        dataTest="lead-tag"
        labels={[isExternalLeadSource ? t('casavoLead') : t('agentLead')]}
      />
      {source === LeadSource.CASAVO_VALUATION && (
        <Tag
          appearance={TagAppearance.BUSINESS}
          closeAriaLabel={t(TAG_CLOSE_ARIA_LABEL)}
          color={TagColorsTypes.GREEN}
          dataTest="simple-valuation-tag"
          labels={[t('simpleValuation')]}
        />
      )}
    </Fragment>
  );
};

export default LeadTag;

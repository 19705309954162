import styled from 'styled-components';

import { colors, convertPxToRem, paragraphs } from '@proprioo/salatim';

export const Layout = styled.div`
  & + & {
    margin-top: ${convertPxToRem(8)};
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-left: ${convertPxToRem(8)};
`;

export const LabelWrapper = styled.span`
  ${paragraphs.body2};
  flex: 1;
  margin-left: ${convertPxToRem(4)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${colors.dark.base};
`;

import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { Fragment } from 'react';
import { useRecoilValue } from 'recoil';

import AccountEditIcon from '@/assets/icons/account-edit.svg';
import { menuSelector } from '@/components/state/MenuState';
import { useCurrentAgent } from '@/hooks/agents/useCurrentAgent';

import { ItemLabel, ToolItem } from '../../menuItems/MenuItems.styles';

const PersonalParametersItem = () => {
  const { t } = useTranslation();

  const currentAgent = useCurrentAgent();
  const isOpen = useRecoilValue(menuSelector);

  if (!currentAgent?.id) {
    return <Fragment />;
  }

  return (
    <Link
      href={`/agent/${currentAgent.id}/personal-parameters`}
      id="sidebar-menu_personal-parameters"
    >
      <ToolItem isOpen={isOpen}>
        <AccountEditIcon />
        <ItemLabel>{t('personalParameters')}</ItemLabel>
      </ToolItem>
    </Link>
  );
};

export default PersonalParametersItem;

import styled, { css } from 'styled-components';

import { convertPxToRem, paragraphs } from '@proprioo/salatim';

export const Layout = styled.span<{ color: string }>`
  ${paragraphs.body2}
  display: inline-flex;
  align-items: center;

  ${({ color }) => css`
    color: ${color};

    path {
      fill: currentColor;
    }
  `}
`;

export const IconWrapper = styled.span`
  display: inline-flex;
  align-items: center;

  svg {
    margin-right: ${convertPxToRem(4)};
    width: ${convertPxToRem(16)};
    height: ${convertPxToRem(16)};
  }
`;

import styled from 'styled-components';

import { breakpoints, convertPxToRem } from '@proprioo/salatim';

export const ActivityIndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${convertPxToRem(8)};
  height: 100%;

  @media (min-width: ${breakpoints.small}px) {
    align-items: center;
  }
`;

export const IconWrapper = styled.span<{ color: string }>`
  align-self: flex-start;
  width: ${convertPxToRem(18)};
  height: ${convertPxToRem(18)};

  > svg {
    width: 100%;
    height: 100%;

    path {
      fill: ${({ color }) => color};
    }
  }
`;

import { useTranslation } from 'next-i18next';
import { FC, useEffect, useState } from 'react';
import { useActionBarContext } from '@/components/app/actionBar/ActionBarContext';

import { ButtonBox } from './ActionBar.styles';
import { SaveProps, SaveStatus } from './ActionBarContext.interfaces';

import Check from '@/assets/icons/check-circle.svg';

import { Button, IconPosition } from '@proprioo/salatim';

const TIMED_STATUS = [SaveStatus.SUCCESS, SaveStatus.FAIL];

const SaveBox: FC<SaveProps> = ({ disabled, saveIdButton, label, onClick }) => {
  const { updateSaveActions } = useActionBarContext();
  const { t } = useTranslation();
  const [saveStatus, setSaveStatus] = useState<SaveStatus>(SaveStatus.NONE);

  useEffect(() => {
    if (TIMED_STATUS.includes(saveStatus)) {
      const timer = setTimeout(() => {
        setSaveStatus(SaveStatus.NONE);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [saveStatus]);

  useEffect(() => {
    updateSaveActions({
      onFail: () => setSaveStatus(SaveStatus.FAIL),
      onReset: () => setSaveStatus(SaveStatus.NONE),
      onSuccess: () => setSaveStatus(SaveStatus.SUCCESS)
    });
  }, []);

  const onSaveClick = () => {
    if (saveStatus === SaveStatus.NONE) {
      setSaveStatus(SaveStatus.PENDING);
      onClick();
    }
  };

  return (
    <ButtonBox
      data-test="save-form"
      {...(saveIdButton && { id: saveIdButton })}
      isVisible={!disabled}
    >
      {' '}
      {label ? (
        label
      ) : (
        <Button
          icon={<Check />}
          disabled={disabled}
          label={t('saveActionBar')}
          onClick={onSaveClick}
          iconPosition={IconPosition.LEFT}
        />
      )}
    </ButtonBox>
  );
};

export default SaveBox;

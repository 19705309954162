import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRecoilValue } from 'recoil';

import AdminSettingsIcon from '@/assets/icons/settings-admin.svg';
import { menuSelector } from '@/components/state/MenuState';

import { ItemLabel, ToolItem } from '../../menuItems/MenuItems.styles';

const AdminItem = () => {
  const { t } = useTranslation();

  const isOpen = useRecoilValue(menuSelector);

  return (
    <Link href="/admin" id="sidebar-menu_agent-admin">
      <ToolItem isOpen={isOpen}>
        <AdminSettingsIcon />
        <ItemLabel>{t('administration')}</ItemLabel>
      </ToolItem>
    </Link>
  );
};

export default AdminItem;
